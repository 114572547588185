// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

var Types = {};

var variablesConverter = {"createUnclaimedBfcustUserInput":{"toBrandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"residenceCountry":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"postalCode":{"c":"Util.CustomScalars.PostalCode.Exn"},"halMarinerNumber":{"c":"Util.CustomScalars.HalMarinerNumber.Exn"},"docLastname":{"c":"Util.CustomScalars.NonemptyString.Exn"},"docFirstname":{"c":"Util.CustomScalars.NonemptyString.Exn"},"docCitizenship":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"docBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"custodianId":{"c":"Util.CustomScalars.AccountId.Exn"},"accountLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"accountFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"}},"__root":{"input":{"r":"createUnclaimedBfcustUserInput"}}};

var variablesConverterMap = {
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.serialize,
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.serialize,
  "Util.CustomScalars.ISO3166_1.Alpha3.Exn": CS_ISO3166_1$Util.Alpha3.Exn.serialize,
  "Util.CustomScalars.HalMarinerNumber.Exn": CS_LoyaltyNumbers$Util.HalMarinerNumber.Exn.serialize,
  "Util.CustomScalars.PostalCode.Exn": CS_NonemptyStrings$Util.PostalCode.Exn.serialize,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function createBfcustUserErr_decode($$enum) {
  if ($$enum === "EMAIL_ALREADY_IN_USE" || $$enum === "USER_CANT_BE_CREATED_ASK_SUPPORT") {
    return $$enum;
  }
  
}

function createBfcustUserErr_fromString(str) {
  return createBfcustUserErr_decode(str);
}

function docGender_decode($$enum) {
  if ($$enum === "F" || $$enum === "M" || $$enum === "X") {
    return $$enum;
  }
  
}

function docGender_fromString(str) {
  return docGender_decode(str);
}

var Utils = {
  createBfcustUserErr_decode: createBfcustUserErr_decode,
  createBfcustUserErr_fromString: createBfcustUserErr_fromString,
  docGender_decode: docGender_decode,
  docGender_fromString: docGender_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUnclaimedBfcustUserPayload",
    "kind": "LinkedField",
    "name": "createUnclaimedBfcustUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createBfcustUserErr",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateLinkedAccountSubmit_CreateUnclaimedBfcustUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateLinkedAccountSubmit_CreateUnclaimedBfcustUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "06ff3bfef4e8c25ae35114d0a28a9288",
    "id": null,
    "metadata": {},
    "name": "CreateLinkedAccountSubmit_CreateUnclaimedBfcustUserMutation",
    "operationKind": "mutation",
    "text": "mutation CreateLinkedAccountSubmit_CreateUnclaimedBfcustUserMutation(\n  $input: CreateUnclaimedBfcustUserInput!\n) {\n  createUnclaimedBfcustUser(input: $input) {\n    createBfcustUserErr\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
