// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var DraftUtils$Thick = require("./DraftUtils.bs.js");
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function SuppressAutoBillingLineModal(props) {
  var versionSuppressions = props.versionSuppressions;
  var currentApplied = props.currentApplied;
  var hookFiredAt = props.hookFiredAt;
  var fareRuleSlug = props.fareRuleSlug;
  var passthruId = props.passthruId;
  var versionUrl = props.versionUrl;
  var onClose = props.onClose;
  var total = currentApplied + props.currentSuppressed | 0;
  var options = Belt_Array.range(0, total);
  var match = React.useState(function () {
        return currentApplied;
      });
  var setNApplied = match[1];
  var nApplied = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var onSubmit = function (e) {
    e.preventDefault();
    var autoLine_nSuppressed = total - nApplied | 0;
    var autoLine = {
      fareRuleSlug: fareRuleSlug,
      hookFiredAt: hookFiredAt,
      nSuppressed: autoLine_nSuppressed
    };
    var applyDiff = BookingDiff$Thick.Alter.suppressAutoBillingLine(diffStruct, autoLine, passthruId, versionSuppressions);
    DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
  };
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(Dialog$Thick.make, {
                        open_: props.open_,
                        onClose: (function () {
                            onClose();
                          }),
                        title: "Edit Rule Application Count",
                        children: [
                          JsxRuntime.jsx(DialogContent, {
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          spacing: 2,
                                          children: [
                                            JsxRuntime.jsx(Typography, {
                                                  textColor: "text.tertiary",
                                                  children: Caml_option.some("You are editing the rule application count for the billing line \"" + props.billingLineDescription + ".\" How many times should this rule be applied?")
                                                }),
                                            JsxRuntime.jsx(Stack, {
                                                  spacing: 1,
                                                  children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                            children: [
                                                              JsxRuntime.jsx(FormLabel, {
                                                                    children: "Total Applications"
                                                                  }),
                                                              JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                    children: Caml_option.some(Belt_Array.mapWithIndex(options, (function (i, val) {
                                                                                return JsxRuntime.jsx($$Option, {
                                                                                            value: val,
                                                                                            children: Caml_option.some(String(val))
                                                                                          }, String(i));
                                                                              }))),
                                                                    onChange: (function (param, val) {
                                                                        if (val === undefined) {
                                                                          return ;
                                                                        }
                                                                        var val$1 = Caml_option.valFromOption(val);
                                                                        if (val$1 !== undefined) {
                                                                          return setNApplied(function (param) {
                                                                                      return val$1;
                                                                                    });
                                                                        }
                                                                        
                                                                      }),
                                                                    required: true,
                                                                    size: "sm",
                                                                    value: nApplied
                                                                  })
                                                            ]
                                                          }))
                                                })
                                          ]
                                        }))
                              }),
                          JsxRuntime.jsx(DialogActions, {
                                children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                          component: "form",
                                          onSubmit: onSubmit,
                                          children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                    type: "submit",
                                                    disabled: nApplied === currentApplied,
                                                    children: "Modify"
                                                  }))
                                        }))
                              })
                        ]
                      }))
            });
}

var make = SuppressAutoBillingLineModal;

exports.make = make;
/* react Not a pure module */
