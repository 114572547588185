// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function RevokeNewPaxInvitationModal(props) {
  var shouldAttachToIdx = props.shouldAttachToIdx;
  var invitee = props.invitee;
  var accountId = props.accountId;
  var onClose = props.onClose;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, onClose);
  var clearPreviewFields = match.clearPreviewFields;
  var setApplyDiff = match.setApplyDiff;
  var setError = match.setError;
  var error = match.error;
  var generatePreview = match.generatePreview;
  var onSubmit = function (e) {
    e.preventDefault();
    var applyDiff = BookingDiff$Thick.Alter.revokePaxInvitation(diffStruct, shouldAttachToIdx, accountId);
    setApplyDiff(function (param) {
          return applyDiff;
        });
    generatePreview(applyDiff);
  };
  var description = "Are you sure you want to rescind the invitation to " + Belt_Option.getWithDefault(invitee, "this passenger") + "?";
  var previewModalDescription = "You are proposing to rescind the invitation to " + Belt_Option.getWithDefault(invitee, "this passenger") + ".";
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.open_,
                      onClose: (function () {
                          onClose();
                          setError(function (param) {
                                
                              });
                        }),
                      title: "Revoke a New Passenger Invitation",
                      children: [
                        JsxRuntime.jsx(DialogContent, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        spacing: 2,
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                textColor: "text.tertiary",
                                                children: Caml_option.some(description)
                                              }),
                                          error !== undefined ? JsxRuntime.jsx(Typography, {
                                                  color: "danger",
                                                  children: Caml_option.some(error)
                                                }) : null
                                        ]
                                      }))
                            }),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        component: "form",
                                        onSubmit: onSubmit,
                                        children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                  loading: match.loading,
                                                  type: "submit",
                                                  color: "danger",
                                                  children: "Revoke invitation"
                                                }))
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match.billPayNew,
                      applyDiff: match.applyDiff,
                      title: "Revoke a New Passenger Invitation",
                      description: previewModalDescription,
                      onClose: (function () {
                          clearPreviewFields();
                        }),
                      open_: match.previewModalOpen,
                      versionUrl: props.versionUrl,
                      error: error
                    })
              ]
            });
}

var make = RevokeNewPaxInvitationModal;

exports.make = make;
/* Dialog-Thick Not a pure module */
