// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var BFGrantWorkflowsQuery_graphql$Thick = require("./BFGrantWorkflowsQuery_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"viewableBFGrants_nodes_brandFamilyGrantWorkflows":{"f":""},"actionableBFGrants_nodes_brandFamilyGrantWorkflows":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

function actionT_decode($$enum) {
  if ($$enum === "EXECUTE_AS_GRANTEE" || $$enum === "EXECUTE" || $$enum === "CANCEL" || $$enum === "APPROVE" || $$enum === "INITIATE") {
    return $$enum;
  }
  
}

function actionT_fromString(str) {
  return actionT_decode(str);
}

var Utils = {
  actionT_decode: actionT_decode,
  actionT_fromString: actionT_fromString
};

function makeNode(rescript_graphql_node_BFGrantWorkflowsQuery) {
  return ((function(){
var v0 = {
  "kind": "Variable",
  "name": "brandFamilySlug",
  "variableName": "brandFamily"
},
v1 = {
  "kind": "Literal",
  "name": "workflowT",
  "value": "request_brandfam_invite"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BrandFamilyWorkflow",
    "kind": "LinkedField",
    "name": "nodes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandFamilyGrantWorkflow",
        "kind": "LinkedField",
        "name": "brandFamilyGrantWorkflows",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BFGrantWorkflowView_workflow"
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserCan",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_BFGrantWorkflowsQuery
    }
  },
  "name": "BFGrantWorkflows_query",
  "selections": [
    {
      "alias": "actionableBFGrants",
      "args": [
        {
          "fields": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "isOpenStatus",
              "value": true
            },
            (v1/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "BrandFamilyWorkflowsConnection",
      "kind": "LinkedField",
      "name": "brandFamilyWorkflows",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": "viewableBFGrants",
      "args": [
        {
          "fields": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "isOpenStatus",
              "value": false
            },
            (v1/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "BrandFamilyWorkflowsConnection",
      "kind": "LinkedField",
      "name": "brandFamilyWorkflows",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})());
}

var node = makeNode(BFGrantWorkflowsQuery_graphql$Thick.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
