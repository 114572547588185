// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var RelayRuntime = require("relay-runtime");

function commitMutation(convertVariables, node, convertResponse, convertWrapRawResponse) {
  return function (environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables) {
    return RelayRuntime.commitMutation(environment, {
                mutation: node,
                variables: convertVariables(variables),
                onCompleted: onCompleted !== undefined ? (function (res, err) {
                      onCompleted(convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                    }) : undefined,
                onError: onError,
                optimisticResponse: optimisticResponse !== undefined ? Caml_option.some(convertWrapRawResponse(Caml_option.valFromOption(optimisticResponse))) : undefined,
                optimisticUpdater: optimisticUpdater,
                updater: updater !== undefined ? (function (store, response) {
                      updater(store, convertResponse(response));
                    }) : undefined,
                uploadables: uploadables
              });
  };
}

function useMutation(convertVariables, node, convertResponse, convertWrapRawResponse) {
  return function () {
    var match = ReactRelay.useMutation(node);
    var mutate = match[0];
    return [
            React.useMemo((function () {
                    return function (variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables) {
                      return mutate({
                                  onError: onError,
                                  onCompleted: onCompleted !== undefined ? (function (res, err) {
                                        onCompleted(convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                                      }) : undefined,
                                  optimisticResponse: optimisticResponse !== undefined ? Caml_option.some(convertWrapRawResponse(Caml_option.valFromOption(optimisticResponse))) : undefined,
                                  optimisticUpdater: optimisticUpdater,
                                  updater: updater !== undefined ? (function (store, response) {
                                        updater(store, convertResponse(response));
                                      }) : undefined,
                                  variables: convertVariables(variables),
                                  uploadables: uploadables
                                });
                    };
                  }), [mutate]),
            match[1]
          ];
  };
}

exports.commitMutation = commitMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
