// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");

function makeRefetchVariables(brandFamily, email) {
  return {
          brandFamily: brandFamily,
          email: email
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"email":{"c":"Util.CustomScalars.Email.Exn"},"brandFamily":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"currentUserProfiles_nodes":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"currentUserProfiles_nodes":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function brandFamilyPermission_decode($$enum) {
  if ($$enum === "MANAGE_PRICING" || $$enum === "VIEW_PRICING" || $$enum === "MANAGE_BOOKINGS" || $$enum === "VIEW_BOOKINGS" || $$enum === "MANAGE_BF_CUSTOMERS" || $$enum === "VIEW_CUSTOMER_DETAILS" || $$enum === "MANAGE_CABIN_INVENTORY" || $$enum === "VIEW_CABIN_INVENTORY" || $$enum === "REQUEST_BRAND_FAMILY_GRANT" || $$enum === "WORKFLOW_PARTICIPANT" || $$enum === "VIEW_VOYAGE_META" || $$enum === "VIEW_ALL_VOYAGES" || $$enum === "VIEW_ALL_USERS" || $$enum === "MANAGE_USER" || $$enum === "ADD_USER" || $$enum === "GRANT_REVOKE" || $$enum === "VIEW_MANIFEST" || $$enum === "VIEW_BF_METADATA") {
    return $$enum;
  }
  
}

function brandFamilyPermission_fromString(str) {
  return brandFamilyPermission_decode(str);
}

var Utils = {
  brandFamilyPermission_decode: brandFamilyPermission_decode,
  brandFamilyPermission_fromString: brandFamilyPermission_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandFamily"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "brandFamilySlug",
        "variableName": "brandFamily"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v2 = {
  "alias": "userPerms",
  "args": [
    {
      "kind": "Variable",
      "name": "forBrandFamilySlug",
      "variableName": "brandFamily"
    }
  ],
  "kind": "ScalarField",
  "name": "currentUserCanForBrand",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LeftNavQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LeftNavMenu_profile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LeftNavQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandFamilyVoyages",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "3fc659771ad252fe3861e3163cb27ea0",
    "id": null,
    "metadata": {},
    "name": "LeftNavQuery",
    "operationKind": "query",
    "text": "query LeftNavQuery(\n  $brandFamily: BrandFamilySlug!\n  $email: Email!\n) {\n  currentUserProfiles(condition: {brandFamilySlug: $brandFamily, email: $email}) {\n    nodes {\n      ...LeftNavMenu_profile\n    }\n  }\n  userPerms: currentUserCanForBrand(forBrandFamilySlug: $brandFamily)\n}\n\nfragment LeftNavMenu_profile on CurrentUserProfile {\n  brandFamilyVoyages\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
