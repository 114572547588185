// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BDBillPayTable$Thick = require("../billing-payments/BDBillPayTable.bs.js");
var Check = require("@mui/icons-material/Check").default;

function PreviewTable$PreviewTableLine(props) {
  var line = props.line;
  if (line.TAG === "Billing") {
    var bl = line._0;
    var shouldStrikeThru = !bl.isManualLine && bl.ruleApplicationCount === 0 && Belt_Option.isSome(bl.fareRuleSlug);
    return JsxRuntime.jsxs("tr", {
                children: [
                  JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Typography, {
                              level: "inherit",
                              noWrap: true,
                              sx: {
                                fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount) <= 0.0 ? "italic" : "normal",
                                textDecoration: shouldStrikeThru ? "line-through" : "none"
                              },
                              textColor: shouldStrikeThru ? "neutral.500" : "inherit",
                              fontWeight: "inherit",
                              children: Caml_option.some(shouldStrikeThru ? Belt_Option.getWithDefault(bl.unsuppressedDescription, bl.billingLineDescription) : bl.billingLineDescription)
                            })
                      }),
                  JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "flex-end",
                              sx: {
                                width: "100%"
                              },
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        level: "inherit",
                                        noWrap: true,
                                        sx: {
                                          fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount) <= 0.0 ? "italic" : "normal",
                                          textDecoration: shouldStrikeThru ? "line-through" : "none"
                                        },
                                        textColor: shouldStrikeThru ? "neutral.500" : "inherit",
                                        fontWeight: "inherit",
                                        children: Caml_option.some(shouldStrikeThru ? Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.unsuppressedAmount)) : Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount)))
                                      }))
                            })
                      })
                ]
              });
  }
  var pl = line._0;
  var url = pl.paymentMethodImage;
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            noWrap: true,
                            sx: {
                              fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                            },
                            textColor: "inherit",
                            fontWeight: "inherit",
                            children: Caml_option.some(pl.shouldRenderStatus ? Common$Thick.PmtLifecycle.toString(pl.lifecycleStatus) : pl.date)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            noWrap: true,
                            sx: {
                              fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                            },
                            textColor: "inherit",
                            fontWeight: "inherit",
                            children: Caml_option.some(pl.payer)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            alignItems: "center",
                            children: [
                              url !== undefined ? JsxRuntime.jsx(Stack, {
                                      direction: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      sx: {
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        pr: "4px"
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx("img", {
                                                style: {
                                                  width: "100%"
                                                },
                                                alt: "payment method",
                                                src: url
                                              }))
                                    }) : null,
                              JsxRuntime.jsx(Typography, {
                                    level: "inherit",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"
                                    },
                                    textColor: "inherit",
                                    fontWeight: "inherit",
                                    children: Caml_option.some(pl.paymentMethod)
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Stack, {
                            direction: "row",
                            justifyContent: "flex-end",
                            sx: {
                              width: "100%"
                            },
                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                      level: "inherit",
                                      noWrap: true,
                                      sx: {
                                        fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                                      },
                                      textColor: "inherit",
                                      fontWeight: "inherit",
                                      children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(pl.amount)))
                                    }))
                          })
                    })
              ]
            });
}

var PreviewTableLine = {
  make: PreviewTable$PreviewTableLine
};

function PreviewTable$PreviewDetails(props) {
  var canManage = props.canManage;
  var snapshot = props.snapshot;
  var isActive = props.isActive;
  var content = props.content;
  var billingColumns = [
    {
      heading: "Description",
      width: "8rem"
    },
    {
      heading: "Amount",
      width: "2rem"
    }
  ];
  var paymentColumns = [
    {
      heading: "Date",
      width: "3rem"
    },
    {
      heading: "Payer",
      width: "3rem"
    },
    {
      heading: "Payment Method",
      width: "4rem"
    },
    {
      heading: "Amount",
      width: "3rem"
    }
  ];
  var zeroed = CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 4,
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        level: "h4",
                                        component: "h4",
                                        children: "Booking Charges"
                                      }))
                            }),
                        content.billing.billingLines.length !== 0 ? JsxRuntime.jsx(BDBillPayTable$Thick.make, {
                                columns: billingColumns,
                                totalDescription: "Total Billed",
                                total: content.billing.billingLineSubtotal,
                                pendingDescription: "Pending Billed",
                                pendingTotal: zeroed,
                                isActive: isActive,
                                snapshot: snapshot,
                                canManage: canManage,
                                children: Belt_Array.mapWithIndex(content.billing.billingLines, (function (i, line) {
                                        return JsxRuntime.jsx(PreviewTable$PreviewTableLine, {
                                                    line: {
                                                      TAG: "Billing",
                                                      _0: line
                                                    }
                                                  }, String(i));
                                      }))
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  height: "7rem"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "title-lg",
                                          component: "p",
                                          textColor: "neutral.500",
                                          children: "No charges added."
                                        }))
                              })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        level: "h4",
                                        component: "h4",
                                        children: "Payments"
                                      }))
                            }),
                        content.payment.paymentLines.length !== 0 ? JsxRuntime.jsx(BDBillPayTable$Thick.make, {
                                columns: paymentColumns,
                                totalDescription: "Total Paid",
                                total: content.payment.completedPaymentSubtotal,
                                pendingDescription: "Pending",
                                pendingTotal: content.arePendingPayments ? content.payment.pendingPaymentSubtotal : zeroed,
                                isActive: isActive,
                                snapshot: snapshot,
                                canManage: canManage,
                                children: Belt_Array.mapWithIndex(content.payment.paymentLines, (function (i, line) {
                                        return JsxRuntime.jsx(PreviewTable$PreviewTableLine, {
                                                    line: {
                                                      TAG: "Payment",
                                                      _0: line
                                                    }
                                                  }, String(i));
                                      }))
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  height: "7rem"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "title-lg",
                                          component: "p",
                                          textColor: "neutral.500",
                                          children: "No payments made."
                                        }))
                              }),
                        JsxRuntime.jsx(Sheet, {
                              sx: {
                                border: "1px solid",
                                borderColor: "divider",
                                borderRadius: "4px",
                                p: 1,
                                my: 2
                              },
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "flex-start",
                                        children: [
                                          JsxRuntime.jsx(Check, {
                                                sx: {
                                                  color: "#037E3A",
                                                  pr: 1
                                                }
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "column",
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "title-md",
                                                        textColor: "#037E3A",
                                                        children: "Remaining Unpaid Balance"
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "title-md",
                                                        textColor: "text.secondary",
                                                        children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(content.totalAmount)))
                                                      }),
                                                  content.arePendingPayments ? JsxRuntime.jsx(Typography, {
                                                          level: "title-sm",
                                                          sx: {
                                                            fontStyle: "italic"
                                                          },
                                                          textColor: "text.secondary",
                                                          fontWeight: "normal",
                                                          children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(content.totalAmountIncludingPending)) + " after pending payments")
                                                        }) : null
                                                ]
                                              })
                                        ]
                                      }))
                            })
                      ]
                    })
              ]
            });
}

var PreviewDetails = {
  make: PreviewTable$PreviewDetails
};

function PreviewTable(props) {
  return JsxRuntime.jsx(PreviewTable$PreviewDetails, {
              content: props.billPayRec,
              isActive: props.isActive,
              snapshot: props.snapshot,
              canManage: props.canManage
            });
}

var make = PreviewTable;

exports.PreviewTableLine = PreviewTableLine;
exports.PreviewDetails = PreviewDetails;
exports.make = make;
/* Common-Thick Not a pure module */
