// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var AlterUserSubmit_AlterUserAccessMutation_graphql$Thick = require("../../../__generated__/AlterUserSubmit_AlterUserAccessMutation_graphql.bs.js");

var convertVariables = AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var AlterUserAccessMutation_brandfamRole_decode = AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.Utils.brandfamRole_decode;

var AlterUserAccessMutation_brandfamRole_fromString = AlterUserSubmit_AlterUserAccessMutation_graphql$Thick.Utils.brandfamRole_fromString;

var AlterUserAccessMutation = {
  brandfamRole_decode: AlterUserAccessMutation_brandfamRole_decode,
  brandfamRole_fromString: AlterUserAccessMutation_brandfamRole_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(email, brandFamily, initialRolesOpt, initialVoyagesOpt, refreshTeam, onClose) {
  var initialRoles = initialRolesOpt !== undefined ? initialRolesOpt : [];
  var initialVoyages = initialVoyagesOpt !== undefined ? initialVoyagesOpt : [];
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return initialRoles;
      });
  var selectedRoles = match$1[0];
  var match$2 = React.useState(function () {
        return initialVoyages;
      });
  var selectedVoyages = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    var forTheseVoyagesOnly = Belt_Array.map(selectedVoyages, (function (voyage) {
            return Caml_option.some(voyage.voyageSlug);
          }));
    var toMatchRoles = Belt_Array.map(selectedRoles, (function (role) {
            return role.brandfamRole;
          }));
    mutate({
          input: {
            forUserWithEmail: email,
            onBrandFamily: brandFamily,
            forTheseVoyagesOnly: forTheseVoyagesOnly,
            toMatchRoles: toMatchRoles
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.alterUserAccess.boolean;
              if (match !== undefined && match) {
                console.log("Successfully altered user");
                refreshTeam();
                onClose();
                return ;
              }
              console.log("Failed to alter user");
              return setError(function (param) {
                          return "Failed to alter user";
                        });
            }
            console.log("Failed to alter user");
            setError(function (param) {
                  return "Failed to alter user";
                });
          }), (function (err) {
            console.log(err);
            setError(function (param) {
                  return "Error altering user";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          selectedRoles: selectedRoles,
          setSelectedRoles: match$1[1],
          selectedVoyages: selectedVoyages,
          setSelectedVoyages: match$2[1],
          error: match$3[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

exports.AlterUserAccessMutation = AlterUserAccessMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
