// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Menu = require("@mui/joy/Menu").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var CustTableToolbar$Thick = require("./CustTableToolbar.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Send = require("@mui/icons-material/Send").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var CreateNewAccountForm$Thick = require("./CreateNewAccountForm.bs.js");
var ResendInvitationForm$Thick = require("./ResendInvitationForm.bs.js");
var RevokeInvitationForm$Thick = require("./RevokeInvitationForm.bs.js");
var Warning = require("@mui/icons-material/Warning").default;
var Settings = require("@mui/icons-material/Settings").default;
var UpdateAccountDataModal$Thick = require("./UpdateAccountDataModal.bs.js");
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var CreateLinkedAccountForm$Thick = require("./CreateLinkedAccountForm.bs.js");
var ArrowOutward = require("@mui/icons-material/ArrowOutward").default;
var CustomerTableQuery_graphql$Thick = require("../../__generated__/CustomerTableQuery_graphql.bs.js");
var CustomerTable_user_graphql$Thick = require("../../__generated__/CustomerTable_user_graphql.bs.js");
var CustomerTable_query_graphql$Thick = require("../../__generated__/CustomerTable_query_graphql.bs.js");
var TransferConnectedAccountForm$Thick = require("./TransferConnectedAccountForm.bs.js");
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var ScheduleSendOutlined = require("@mui/icons-material/ScheduleSendOutlined").default;
var PersonAddDisabledOutlined = require("@mui/icons-material/PersonAddDisabledOutlined").default;
var SupervisorAccountOutlined = require("@mui/icons-material/SupervisorAccountOutlined").default;

var convertFragment = CustomerTable_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(CustomerTable_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CustomerTable_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(CustomerTable_query_graphql$Thick.node, convertFragment, fRef);
}

var makeRefetchVariables = CustomerTableQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = CustomerTableQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(CustomerTable_query_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

var convertFragment$1 = CustomerTable_user_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(CustomerTable_user_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CustomerTable_user_graphql$Thick.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(CustomerTable_user_graphql$Thick.node, convertFragment$1, fRef);
}

var UserFragment_accountStatus_decode = CustomerTable_user_graphql$Thick.Utils.accountStatus_decode;

var UserFragment_accountStatus_fromString = CustomerTable_user_graphql$Thick.Utils.accountStatus_fromString;

var UserFragment_docGender_decode = CustomerTable_user_graphql$Thick.Utils.docGender_decode;

var UserFragment_docGender_fromString = CustomerTable_user_graphql$Thick.Utils.docGender_fromString;

var UserFragment = {
  accountStatus_decode: UserFragment_accountStatus_decode,
  accountStatus_fromString: UserFragment_accountStatus_fromString,
  docGender_decode: UserFragment_docGender_decode,
  docGender_fromString: UserFragment_docGender_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function CustomerTable$TableRow(props) {
  var onSelectUserToUpdate = props.onSelectUserToUpdate;
  var brandFamily = props.brandFamily;
  var refresh = props.refresh;
  var user = use$1(props.fragmentRefs);
  var match = React.useState(function () {
        return false;
      });
  var setCreateDialogOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setTransferDialogOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setCopied = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setResendDialogOpen = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setRevokeDialogOpen = match$4[1];
  var match$5 = React.useState(function () {
        return "transfer";
      });
  var setTargetUserType = match$5[1];
  var onResendRequest = function (t) {
    setTargetUserType(function (param) {
          return t;
        });
    setResendDialogOpen(function (param) {
          return true;
        });
  };
  var email = user.invitationEmail;
  var invitee = email !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) : "another user";
  var match$6 = Belt_Array.get(user.passenger_record.nodes, 0);
  var inviteeBookingUrl = match$6 !== undefined ? "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + "/" + CS_Slugs$Util.VoyageSlug.toString(match$6.voyageSlug) + "/bookings/" + CS_NonemptyStrings$Util.BookingId.toString(match$6.bookingId) + "/" + String(match$6.bookingVersionNumber) : undefined;
  var isInLimbo = user.accountStatus === "TRANSFERRING" || user.accountStatus === "INVITED";
  var expiry = user.invitationExpiry;
  var match$7;
  if (expiry !== undefined) {
    var expiry$1 = Caml_option.valFromOption(expiry);
    match$7 = CS_Make$Util.Dateable.Plain.isInThePast(expiry$1) ? [
        true,
        (function (param) {
            return "Invitation has expired! Please resend or revoke.";
          })
      ] : [
        false,
        (function (i) {
            return "Invitation to " + i + " expires on " + CS_Make$Util.Dateable.Plain.format(expiry$1, "MM/dd/yyyy") + " at " + CS_Make$Util.Dateable.Plain.format(expiry$1, "hh:mm b") + ".";
          })
      ];
  } else {
    match$7 = [
      false,
      (function (param) {
          return "";
        })
    ];
  }
  var custodian = user.custodian;
  var custodianName = custodian !== undefined ? custodian.fullName : undefined;
  var match$8 = user.accountStatus;
  var tmp;
  if (match$8 === "UNCLAIMED" || match$8 === "TRANSFERRING" || match$8 === "INVITED" || match$8 === "CLAIMED" || match$8 === "DELETED") {
    switch (match$8) {
      case "INVITED" :
          tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsxs(MenuItem, {
                        children: [
                          JsxRuntime.jsx(ListItemDecorator, {
                                children: Caml_option.some(JsxRuntime.jsx(Send, {}))
                              }),
                          "Resend New User Invitation"
                        ],
                        onClick: (function (param) {
                            onResendRequest("newpax");
                          })
                      }),
                  inviteeBookingUrl !== undefined ? JsxRuntime.jsxs(MenuItem, {
                          children: [
                            JsxRuntime.jsx(ListItemDecorator, {
                                  children: Caml_option.some(JsxRuntime.jsx(Settings, {}))
                                }),
                            "Manage Booking"
                          ],
                          onClick: (function (param) {
                              RescriptReactRouter.push(inviteeBookingUrl);
                            })
                        }) : null
                ]
              });
          break;
      case "DELETED" :
          tmp = null;
          break;
      default:
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(MenuItem, {
                      children: [
                        JsxRuntime.jsx(ListItemDecorator, {
                              children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {}))
                            }),
                        "Edit User Details"
                      ],
                      onClick: (function (param) {
                          onSelectUserToUpdate(user);
                        })
                    }),
                user.accountStatus === "CLAIMED" ? JsxRuntime.jsxs(MenuItem, {
                        children: [
                          JsxRuntime.jsx(ListItemDecorator, {
                                children: Caml_option.some(JsxRuntime.jsx(SupervisorAccountOutlined, {}))
                              }),
                          "Create Connected Account"
                        ],
                        onClick: (function (param) {
                            setCreateDialogOpen(function (param) {
                                  return true;
                                });
                          })
                      }) : (
                    user.accountStatus === "UNCLAIMED" ? JsxRuntime.jsxs(MenuItem, {
                            children: [
                              JsxRuntime.jsx(ListItemDecorator, {
                                    children: Caml_option.some(JsxRuntime.jsx(ArrowOutward, {}))
                                  }),
                              "Transfer Connected Account"
                            ],
                            onClick: (function (param) {
                                setTransferDialogOpen(function (param) {
                                      return true;
                                    });
                              })
                          }) : (
                        user.accountStatus === "TRANSFERRING" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsxs(MenuItem, {
                                        children: [
                                          JsxRuntime.jsx(ListItemDecorator, {
                                                children: Caml_option.some(JsxRuntime.jsx(Send, {}))
                                              }),
                                          "Resend Transfer Invitation"
                                        ],
                                        onClick: (function (param) {
                                            onResendRequest("transfer");
                                          })
                                      }),
                                  JsxRuntime.jsxs(MenuItem, {
                                        children: [
                                          JsxRuntime.jsx(ListItemDecorator, {
                                                children: Caml_option.some(JsxRuntime.jsx(PersonAddDisabledOutlined, {}))
                                              }),
                                          "Revoke Transfer Invitation"
                                        ],
                                        color: "danger",
                                        onClick: (function (param) {
                                            setRevokeDialogOpen(function (param) {
                                                  return true;
                                                });
                                          })
                                      })
                                ]
                              }) : null
                      )
                  )
              ]
            });
    }
  } else {
    tmp = null;
  }
  var match$9 = user.email;
  var match$10 = user.accountStatus;
  var match$11 = user.invitationEmail;
  var tmp$1;
  if (match$9 !== undefined) {
    if ((match$10 === "UNCLAIMED" || match$10 === "TRANSFERRING" || match$10 === "INVITED" || match$10 === "CLAIMED" || match$10 === "DELETED") && match$10 === "CLAIMED") {
      var email$1 = Caml_option.valFromOption(match$9);
      tmp$1 = JsxRuntime.jsx(Tooltip, {
            children: JsxRuntime.jsx(Typography, {
                  level: "body-sm",
                  noWrap: true,
                  children: Caml_option.some(CS_Emails$Util.Email.toString(email$1))
                }),
            arrow: true,
            size: "sm",
            sx: {
              cursor: "pointer"
            },
            title: match$2[0] ? "Copied to clipboard!" : CS_Emails$Util.Email.toString(email$1),
            onClick: (function (param) {
                return Common$Thick.copyToClipboard(CS_Emails$Util.Email.toString(email$1), setCopied);
              })
          });
    } else {
      tmp$1 = null;
    }
  } else if (match$11 !== undefined && isInLimbo) {
    var invitee$1 = Caml_option.valFromOption(match$11);
    tmp$1 = JsxRuntime.jsx(Tooltip, {
          children: JsxRuntime.jsx(Typography, {
                startDecorator: Caml_option.some(match$7[0] ? JsxRuntime.jsx(Warning, {
                            color: "danger",
                            fontSize: "md"
                          }) : JsxRuntime.jsx(ScheduleSendOutlined, {
                            fontSize: "md"
                          })),
                level: "body-sm",
                noWrap: true,
                sx: {
                  fontStyle: "italic",
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                },
                children: Caml_option.some(CS_Emails$Util.Email.toString(invitee$1))
              }),
          arrow: true,
          size: "sm",
          sx: {
            cursor: "pointer",
            maxWidth: "15rem"
          },
          title: match$7[1](CS_Emails$Util.Email.toString(invitee$1))
        });
  } else {
    tmp$1 = null;
  }
  var lsInfo = user.loyalty_status;
  var tmp$2;
  if (lsInfo !== undefined) {
    var tier = lsInfo.currentTierTitle;
    tmp$2 = tier !== undefined ? JsxRuntime.jsx(Tooltip, {
            children: JsxRuntime.jsx(Typography, {
                  level: "body-md",
                  children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(tier)) + " (" + String(lsInfo.numEligibleVoyages) + ")")
                }),
            arrow: true,
            placement: "top",
            size: "sm",
            sx: {
              justifySelf: "flex-start",
              cursor: "pointer",
              maxWidth: "250px"
            },
            title: Belt_Array.joinWith(lsInfo.eligible_voyage.nodes, ", ", (function (v) {
                    var voy = v.voyage;
                    if (voy === undefined) {
                      return "";
                    }
                    var vname = voy.voyageName;
                    if (vname !== undefined) {
                      return CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(vname));
                    } else {
                      return "";
                    }
                  }))
          }) : null;
  } else {
    tmp$2 = null;
  }
  var match$12 = user.accountStatus;
  var tmp$3;
  var exit = 0;
  if (match$12 === "UNCLAIMED" || match$12 === "TRANSFERRING" || match$12 === "INVITED" || match$12 === "CLAIMED" || match$12 === "DELETED") {
    switch (match$12) {
      case "INVITED" :
      case "TRANSFERRING" :
          exit = 2;
          break;
      case "CLAIMED" :
      case "UNCLAIMED" :
          exit = 1;
          break;
      case "DELETED" :
          tmp$3 = null;
          break;
      
    }
  } else {
    tmp$3 = null;
  }
  switch (exit) {
    case 1 :
        tmp$3 = JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(user.accountStatus === "CLAIMED" ? JsxRuntime.jsx(CreateLinkedAccountForm$Thick.make, {
                          open_: match[0],
                          onClose: (function () {
                              setCreateDialogOpen(function (param) {
                                    return false;
                                  });
                            }),
                          brandFamily: brandFamily,
                          refresh: refresh,
                          custodian: user.fullName,
                          custodianId: user.accountId
                        }) : (
                      user.accountStatus === "UNCLAIMED" ? JsxRuntime.jsx(TransferConnectedAccountForm$Thick.make, {
                              fullName: user.fullName,
                              open_: match$1[0],
                              onClose: (function () {
                                  setTransferDialogOpen(function (param) {
                                        return false;
                                      });
                                }),
                              refresh: refresh,
                              accountId: user.accountId,
                              brandFamily: brandFamily
                            }) : null
                    ))
            });
        break;
    case 2 :
        tmp$3 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ResendInvitationForm$Thick.make, {
                      fullName: user.fullName,
                      open_: match$3[0],
                      onClose: (function () {
                          setResendDialogOpen(function (param) {
                                return false;
                              });
                        }),
                      refresh: refresh,
                      accountId: user.accountId,
                      brandFamily: brandFamily,
                      invitee: invitee,
                      targetUserType: match$5[0]
                    }),
                JsxRuntime.jsx(RevokeInvitationForm$Thick.make, {
                      fullName: user.fullName,
                      open_: match$4[0],
                      onClose: (function () {
                          setRevokeDialogOpen(function (param) {
                                return false;
                              });
                        }),
                      refresh: refresh,
                      accountId: user.accountId,
                      brandFamily: brandFamily,
                      invitee: invitee
                    })
              ]
            });
        break;
    
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("tr", {
                      children: [
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Stack, {
                                    direction: "row",
                                    justifyContent: "flex-end",
                                    children: Caml_option.some(JsxRuntime.jsxs(Dropdown, {
                                              children: [
                                                JsxRuntime.jsx(MenuButton, {
                                                      children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                                                      size: "md",
                                                      slotProps: {
                                                        root: {
                                                          color: "neutral"
                                                        }
                                                      },
                                                      slots: {
                                                        root: IconButton
                                                      }
                                                    }),
                                                JsxRuntime.jsx(Menu, {
                                                      children: Caml_option.some(tmp),
                                                      placement: "bottom-start",
                                                      size: "sm"
                                                    })
                                              ]
                                            }))
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    children: Caml_option.some(tmp$1)
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: isInLimbo ? "italic" : "normal"
                                    },
                                    children: Caml_option.some(user.fullName)
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: isInLimbo ? "italic" : "normal"
                                    },
                                    children: Caml_option.some(Common$Thick.AccountStatus.toString(user.accountStatus))
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: isInLimbo ? "italic" : "normal"
                                    },
                                    children: Caml_option.some(CS_NonemptyStrings$Util.LoyaltyNumber.toString(user.loyaltyNumber))
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: isInLimbo ? "italic" : "normal"
                                    },
                                    children: Caml_option.some(tmp$2)
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: isInLimbo ? "italic" : "normal"
                                    },
                                    children: Caml_option.some(custodianName !== undefined ? custodianName : "")
                                  })
                            })
                      ]
                    }),
                tmp$3
              ]
            });
}

var TableRow = {
  make: CustomerTable$TableRow
};

function CustomerTable(props) {
  var primaryFilter = props.filter;
  var brandFamily = props.brandFamily;
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var data = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setDialogOpen = match$1[1];
  var match$2 = React.useState(function () {
        return "all";
      });
  var setAccountSelect = match$2[1];
  var accountSelect = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setUpdateDialogOpen = match$3[1];
  var match$4 = React.useState(function () {
        
      });
  var setUpdateUser = match$4[1];
  var updateUser = match$4[0];
  var onSelectUserToUpdate = function (u) {
    setUpdateUser(function (param) {
          return u;
        });
    setUpdateDialogOpen(function (param) {
          return true;
        });
  };
  var refetchAll = function (param) {
    return refetch(makeRefetchVariables(undefined, undefined, undefined), "store-and-network", undefined);
  };
  var match$5 = React.useState(function () {
        return primaryFilter;
      });
  var setFilter = match$5[1];
  var filter = match$5[0];
  var match$6 = React.useState(function () {
        return 0;
      });
  var setOffset = match$6[1];
  var offset = match$6[0];
  var match$7 = React.useState(function () {
        return false;
      });
  var setFilterChange = match$7[1];
  var filterChange = match$7[0];
  var refetchPage = function (newOffset) {
    refetch({
          brandFamily: Caml_option.some(brandFamily),
          filter: Caml_option.some(filter),
          offset: Caml_option.some(newOffset)
        }, "store-and-network", undefined);
  };
  var match$8 = data.bfcustUsers;
  var totalCount = match$8 !== undefined ? match$8.totalCount : 0;
  var stringFromOffset = function (o, t) {
    var start = o + 1 | 0;
    var end = o + 50 | 0;
    var end$1 = end > t ? t : end;
    if (t !== 0) {
      return String(start) + " - " + String(end$1) + " / " + String(t);
    } else {
      return "0";
    }
  };
  var onLoadNext = function (param) {
    var newOffset = offset + 50 | 0;
    setOffset(function (param) {
          return newOffset;
        });
    refetchPage(newOffset);
  };
  var onLoadPrev = function (param) {
    var newOffset = offset + 50 | 0;
    setOffset(function (param) {
          return newOffset;
        });
    refetchPage(newOffset);
  };
  var search = function (t) {
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.or = [
      {
        fullName: {
          includesInsensitive: t
        }
      },
      {
        emailString: {
          includesInsensitive: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive(t))
        }
      }
    ];
    setFilter(function (param) {
          return newrecord;
        });
    setFilterChange(function (param) {
          return !filterChange;
        });
  };
  var clearSearch = function (param) {
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.or = [];
    setFilter(function (param) {
          return newrecord;
        });
    setFilterChange(function (param) {
          return !filterChange;
        });
  };
  var handleAccountSelect = function (s) {
    var arr;
    switch (s) {
      case "claimed" :
          arr = ["CLAIMED"];
          break;
      case "invited" :
          arr = ["INVITED"];
          break;
      case "transferring" :
          arr = ["TRANSFERRING"];
          break;
      case "unclaimed" :
          arr = ["UNCLAIMED"];
          break;
      default:
        arr = [
          "UNCLAIMED",
          "CLAIMED",
          "INVITED",
          "TRANSFERRING"
        ];
    }
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.accountStatus = {
      in: arr
    };
    setFilter(function (param) {
          return newrecord;
        });
    setFilterChange(function (param) {
          return !filterChange;
        });
  };
  React.useEffect((function () {
          refetch(makeRefetchVariables(undefined, Caml_option.some(filter), undefined), "store-and-network", undefined);
        }), [filterChange]);
  var match$9 = data.bfcustUsers;
  var tmp;
  if (match$9 !== undefined) {
    var edges = match$9.edges;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(CustTableToolbar$Thick.make, {
                  totalCount: totalCount,
                  offset: offset,
                  onLoadNext: onLoadNext,
                  onLoadPrev: onLoadPrev,
                  stringFromOffset: stringFromOffset,
                  searchBar: true,
                  search: search,
                  clearSearch: clearSearch,
                  handleAccountSelect: handleAccountSelect,
                  accountSelect: accountSelect,
                  setAccountSelect: setAccountSelect,
                  setDialogOpen: setDialogOpen
                }),
            edges.length !== 0 ? JsxRuntime.jsxs(Table, {
                    stripe: "odd",
                    hoverRow: true,
                    sx: {
                      captionSide: "top",
                      "& tbody": {
                        bgcolor: "background.surface"
                      }
                    },
                    children: [
                      JsxRuntime.jsx("thead", {
                            children: JsxRuntime.jsxs("tr", {
                                  children: [
                                    JsxRuntime.jsx("th", {
                                          style: {
                                            width: "2rem"
                                          }
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Email"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Name"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Account Status"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Loyalty Number"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Loyalty Tier"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Managed by"
                                        })
                                  ]
                                })
                          }),
                      JsxRuntime.jsx("tbody", {
                            children: Belt_Array.map(edges, (function (edge) {
                                    return JsxRuntime.jsx(CustomerTable$TableRow, {
                                                fragmentRefs: edge.node.fragmentRefs,
                                                refresh: clearSearch,
                                                brandFamily: brandFamily,
                                                countryFrag: data.fragmentRefs,
                                                refetchAll: refetchAll,
                                                onSelectUserToUpdate: onSelectUserToUpdate
                                              }, edge.__id);
                                  }))
                          })
                    ]
                  }) : JsxRuntime.jsx(Stack, {
                    justifyContent: "center",
                    alignItems: "center",
                    sx: {
                      height: "15rem",
                      width: "100%"
                    },
                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                              level: "title-lg",
                              sx: {
                                color: "neutral.500"
                              },
                              children: "No customers found"
                            }))
                  }),
            JsxRuntime.jsx(CustTableToolbar$Thick.make, {
                  totalCount: totalCount,
                  offset: offset,
                  onLoadNext: onLoadNext,
                  onLoadPrev: onLoadPrev,
                  stringFromOffset: stringFromOffset,
                  search: search,
                  clearSearch: clearSearch,
                  handleAccountSelect: handleAccountSelect,
                  accountSelect: accountSelect,
                  setAccountSelect: setAccountSelect,
                  setDialogOpen: setDialogOpen
                })
          ]
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      spacing: 1,
                      sx: {
                        width: "100%"
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                                variant: "outlined",
                                sx: {
                                  pt: 1,
                                  borderRadius: "sm",
                                  width: "100%"
                                },
                                children: [
                                  tmp,
                                  JsxRuntime.jsx(CreateNewAccountForm$Thick.make, {
                                        open_: match$1[0],
                                        onClose: (function () {
                                            setDialogOpen(function (param) {
                                                  return false;
                                                });
                                          }),
                                        brandFamily: brandFamily,
                                        refresh: clearSearch
                                      })
                                ]
                              }))
                    }),
                updateUser !== undefined ? JsxRuntime.jsx(UpdateAccountDataModal$Thick.make, {
                        open_: match$3[0],
                        onClose: (function () {
                            setUpdateDialogOpen(function (param) {
                                  return false;
                                });
                          }),
                        user: updateUser,
                        refetch: refetchAll,
                        brandFamily: brandFamily,
                        countryFrag: data.fragmentRefs
                      }) : null
              ]
            });
}

var make = CustomerTable;

exports.QueryFragment = QueryFragment;
exports.UserFragment = UserFragment;
exports.TableRow = TableRow;
exports.make = make;
/* react Not a pure module */
