// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var DraftUtils$Thick = require("../components/booking-details/drafts/DraftUtils.bs.js");
var BillPayInfo$Thick = require("../components/booking-details/billing-payments/BillPayInfo.bs.js");
var BookingDiff$Thick = require("../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BookingDetailsPage$Thick = require("../pages/BookingDetailsPage.bs.js");
var BrandPermProtected$Thick = require("../components/wrappers/BrandPermProtected.bs.js");
var VoyageProtectedRoute$Thick = require("../components/wrappers/VoyageProtectedRoute.bs.js");
var BookingDetailsRouteQuery_graphql$Thick = require("../__generated__/BookingDetailsRouteQuery_graphql.bs.js");
var BookingDetailsRoute_query_graphql$Thick = require("../__generated__/BookingDetailsRoute_query_graphql.bs.js");
var BookingDetailsRouteRefetchQuery_graphql$Thick = require("../__generated__/BookingDetailsRouteRefetchQuery_graphql.bs.js");

var convertVariables = BookingDetailsRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BookingDetailsRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BookingDetailsRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, BookingDetailsRouteQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BookingDetailsRouteQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BookingDetailsRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BookingDetailsRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BookingDetailsRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BookingDetailsRouteQuery_graphql$Thick.node, convertVariables);

var Query_billingKind_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_docGender_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_longitudinality_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_paymentAmountType_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.paymentAmountType_decode;

var Query_paymentAmountType_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.paymentAmountType_fromString;

var Query_paymentPlanPeriodStatus_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.paymentPlanPeriodStatus_decode;

var Query_paymentPlanPeriodStatus_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.paymentPlanPeriodStatus_fromString;

var Query_pmtLifecycleStatus_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_subtotalCategory_decode = BookingDetailsRouteQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = BookingDetailsRouteQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query = {
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  paymentAmountType_decode: Query_paymentAmountType_decode,
  paymentAmountType_fromString: Query_paymentAmountType_fromString,
  paymentPlanPeriodStatus_decode: Query_paymentPlanPeriodStatus_decode,
  paymentPlanPeriodStatus_fromString: Query_paymentPlanPeriodStatus_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertFragment = BookingDetailsRoute_query_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingDetailsRoute_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingDetailsRoute_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BookingDetailsRoute_query_graphql$Thick.node, convertFragment, fRef);
}

var makeRefetchVariables = BookingDetailsRouteRefetchQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = BookingDetailsRouteRefetchQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(BookingDetailsRoute_query_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_billingKind_decode = BookingDetailsRoute_query_graphql$Thick.Utils.billingKind_decode;

var QueryFragment_billingKind_fromString = BookingDetailsRoute_query_graphql$Thick.Utils.billingKind_fromString;

var QueryFragment_paymentAmountType_decode = BookingDetailsRoute_query_graphql$Thick.Utils.paymentAmountType_decode;

var QueryFragment_paymentAmountType_fromString = BookingDetailsRoute_query_graphql$Thick.Utils.paymentAmountType_fromString;

var QueryFragment_paymentPlanPeriodStatus_decode = BookingDetailsRoute_query_graphql$Thick.Utils.paymentPlanPeriodStatus_decode;

var QueryFragment_paymentPlanPeriodStatus_fromString = BookingDetailsRoute_query_graphql$Thick.Utils.paymentPlanPeriodStatus_fromString;

var QueryFragment_pmtLifecycleStatus_decode = BookingDetailsRoute_query_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var QueryFragment_pmtLifecycleStatus_fromString = BookingDetailsRoute_query_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var QueryFragment_pmtMethod_decode = BookingDetailsRoute_query_graphql$Thick.Utils.pmtMethod_decode;

var QueryFragment_pmtMethod_fromString = BookingDetailsRoute_query_graphql$Thick.Utils.pmtMethod_fromString;

var QueryFragment_pmtProcessor_decode = BookingDetailsRoute_query_graphql$Thick.Utils.pmtProcessor_decode;

var QueryFragment_pmtProcessor_fromString = BookingDetailsRoute_query_graphql$Thick.Utils.pmtProcessor_fromString;

var QueryFragment = {
  billingKind_decode: QueryFragment_billingKind_decode,
  billingKind_fromString: QueryFragment_billingKind_fromString,
  paymentAmountType_decode: QueryFragment_paymentAmountType_decode,
  paymentAmountType_fromString: QueryFragment_paymentAmountType_fromString,
  paymentPlanPeriodStatus_decode: QueryFragment_paymentPlanPeriodStatus_decode,
  paymentPlanPeriodStatus_fromString: QueryFragment_paymentPlanPeriodStatus_fromString,
  pmtLifecycleStatus_decode: QueryFragment_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: QueryFragment_pmtLifecycleStatus_fromString,
  pmtMethod_decode: QueryFragment_pmtMethod_decode,
  pmtMethod_fromString: QueryFragment_pmtMethod_fromString,
  pmtProcessor_decode: QueryFragment_pmtProcessor_decode,
  pmtProcessor_fromString: QueryFragment_pmtProcessor_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use$1,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function BookingDetailsRoute(props) {
  var brandFamily = props.brandFamily;
  var voyage = props.voyage;
  var bookingId = props.bookingId;
  var bookingVersionNumber = Belt_Int.fromString(props.bookingVersionNumber);
  if (bookingVersionNumber !== undefined) {
    var url = RescriptReactRouter.useUrl(undefined, undefined);
    var diffStructInitial = BookingDiff$Thick.parse(url.search);
    var diffStruct = React.useDeferredValue(diffStructInitial);
    var data = use({
          bookingDiff: DraftUtils$Thick.makeBookingDiffInput(diffStruct),
          bookingId: bookingId,
          bookingVersionNumber: bookingVersionNumber,
          brandFamily: brandFamily,
          voyage: voyage
        }, undefined, undefined, undefined);
    var fragmentRefs = data.fragmentRefs;
    var match = data.allVersions;
    var latestVersion;
    if (match !== undefined) {
      var match$1 = Belt_Array.get(match.nodes, 0);
      latestVersion = match$1 !== undefined ? match$1.bookingVersionNumber : undefined;
    } else {
      latestVersion = undefined;
    }
    var match$2 = useRefetchable(data.fragmentRefs);
    var refetchBillPayData = match$2[1];
    var billPayData = match$2[0];
    var refetchBilling = function (param) {
      return refetchBillPayData(makeRefetchVariables(undefined, undefined, undefined, undefined, undefined), "store-and-network", undefined);
    };
    var billPayRec;
    if (diffStruct.hasDiff) {
      var match$3 = billPayData.diffChanges;
      if (match$3 !== undefined) {
        var current = Belt_Array.get(Belt_Array.keepMap(match$3.nodes, (function (param) {
                    var quote = param.quote;
                    var bookingId = param.bookingId;
                    var augmentedPayments = param.augmentedPayments;
                    if (quote === undefined) {
                      return ;
                    }
                    if (bookingId === undefined) {
                      return ;
                    }
                    if (augmentedPayments === undefined) {
                      return ;
                    }
                    var quoteBookingId = Caml_option.valFromOption(bookingId);
                    if (Caml_obj.equal(Caml_option.some(quoteBookingId), bookingId)) {
                      console.log(BillPayInfo$Thick.parse({
                                TAG: "Diff",
                                _0: quote,
                                _1: quoteBookingId,
                                _2: augmentedPayments
                              }));
                      return BillPayInfo$Thick.parse({
                                  TAG: "Diff",
                                  _0: quote,
                                  _1: quoteBookingId,
                                  _2: augmentedPayments
                                });
                    }
                    
                  })), 0);
        billPayRec = current !== undefined ? current : undefined;
      } else {
        billPayRec = undefined;
      }
    } else {
      var match$4 = billPayData.currentBilling;
      if (match$4 !== undefined) {
        var node = Belt_Array.get(match$4.nodes, 0);
        billPayRec = node !== undefined ? BillPayInfo$Thick.parse({
                TAG: "Current",
                _0: node,
                _1: bookingId
              }) : undefined;
      } else {
        billPayRec = undefined;
      }
    }
    var match$5 = billPayData.currentBilling;
    var versionBilling;
    if (match$5 !== undefined) {
      var node$1 = Belt_Array.get(match$5.nodes, 0);
      versionBilling = node$1 !== undefined ? BillPayInfo$Thick.parse({
              TAG: "Current",
              _0: node$1,
              _1: bookingId
            }) : undefined;
    } else {
      versionBilling = undefined;
    }
    var match$6 = billPayData.currentBilling;
    var versionSuppressions;
    if (match$6 !== undefined) {
      var match$7 = Belt_Array.get(match$6.nodes, 0);
      versionSuppressions = match$7 !== undefined ? Belt_MapString.fromArray(Belt_Array.keepMap(match$7.billingLine.nodes, (function (param) {
                    var originatedAt = param.originatedAt;
                    var fareRuleSlug = param.fareRuleSlug;
                    if (fareRuleSlug !== undefined && originatedAt !== undefined) {
                      return [
                              CS_Slugs$Util.FareRuleSlug.toString(Caml_option.valFromOption(fareRuleSlug)) + "-" + CS_Make$Util.Dateable.Plain.toPrimitive(Caml_option.valFromOption(originatedAt)),
                              param.suppressedCount
                            ];
                    }
                    
                  }))) : undefined;
    } else {
      versionSuppressions = undefined;
    }
    var generatePreviewFromDiff = DraftUtils$Thick.previewCallback(bookingId, bookingVersionNumber, voyage);
    return JsxRuntime.jsx(DashboardLayout$Thick.make, {
                fragmentRefs: fragmentRefs,
                children: JsxRuntime.jsx(React.Suspense, {
                      children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                                fragmentRefs: fragmentRefs,
                                children: JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                      fragmentRefs: fragmentRefs,
                                      neededPerm: "VIEW_BOOKINGS",
                                      children: JsxRuntime.jsx(VoyageProtectedRoute$Thick.make, {
                                            fragmentRefs: fragmentRefs,
                                            brandFamily: brandFamily,
                                            voyage: voyage,
                                            children: JsxRuntime.jsx(BookingDetailsPage$Thick.make, {
                                                  fragmentRefs: fragmentRefs,
                                                  bookingId: bookingId,
                                                  brandFamily: brandFamily,
                                                  voyage: voyage,
                                                  latestVersion: latestVersion,
                                                  billPayRec: billPayRec,
                                                  versionBilling: versionBilling,
                                                  generatePreviewFromDiff: generatePreviewFromDiff,
                                                  versionSuppressions: versionSuppressions,
                                                  refetchBilling: refetchBilling
                                                })
                                          }),
                                      isRoute: true,
                                      redirect: "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily)
                                    })
                              })),
                      fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
                    }),
                brandFamily: Caml_option.some(brandFamily),
                voyage: Caml_option.some(voyage),
                bookingDetail: true
              });
  }
  React.useEffect((function () {
          RescriptReactRouter.push("/404");
        }), []);
  return null;
}

var make = BookingDetailsRoute;

exports.Query = Query;
exports.QueryFragment = QueryFragment;
exports.make = make;
/* use Not a pure module */
