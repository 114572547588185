// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDCommentsFragmentRefetch_graphql$Thick = require("./BDCommentsFragmentRefetch_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"booking_comment_nodes_createdAt":{"c":"Util.CustomScalars.SQLDateTime.Exn"},"booking_comment_nodes_commentBody":{"c":"Util.CustomScalars.NonemptyString.Exn"},"booking_comment_nodes_brand_family_user_email":{"c":"Util.CustomScalars.Email.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

function makeNode(rescript_graphql_node_BDCommentsFragmentRefetch) {
  return ({
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": rescript_graphql_node_BDCommentsFragmentRefetch,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "BDComments_version",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED_AT_DESC"
        }
      ],
      "concreteType": "BookingCommentsConnection",
      "kind": "LinkedField",
      "name": "booking_comment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingComment",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "commentBody",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "commentRecordId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BrandFamilyUser",
              "kind": "LinkedField",
              "name": "brand_family_user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "booking_comment(orderBy:\"CREATED_AT_DESC\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BookingVersion",
  "abstractKey": null
});
}

var node = makeNode(BDCommentsFragmentRefetch_graphql$Thick.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
