// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"voyages_nodes_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function brandfamRole_decode($$enum) {
  if ($$enum === "CUSTOMER_SERVICE" || $$enum === "BRAND_FAMILY_ADMIN" || $$enum === "INVENTORY_MANAGER") {
    return $$enum;
  }
  
}

function brandfamRole_fromString(str) {
  return brandfamRole_decode(str);
}

var Utils = {
  brandfamRole_decode: brandfamRole_decode,
  brandfamRole_fromString: brandfamRole_fromString
};

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "InviteUserForm_query",
  "selections": [
    {
      "alias": "voyages",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "brandFamilySlug",
              "variableName": "brandFamily"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "BrandFamilyVoyagesConnection",
      "kind": "LinkedField",
      "name": "brandFamilyVoyages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandFamilyVoyage",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyageSlug",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "roles",
      "args": null,
      "concreteType": "BrandFamilyRolesConnection",
      "kind": "LinkedField",
      "name": "brandFamilyRoles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandFamilyRole",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "brandfamRole",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Slugs-Util Not a pure module */
