// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../utils/Common.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BrandPermProtected$Thick = require("../components/wrappers/BrandPermProtected.bs.js");
var CabinInventoryPage$Thick = require("../pages/CabinInventoryPage.bs.js");
var VoyageProtectedRoute$Thick = require("../components/wrappers/VoyageProtectedRoute.bs.js");
var CabinInventoryFilters$Thick = require("../utils/CabinInventoryFilters.bs.js");
var CabinInventoryRouteQuery_graphql$Thick = require("../__generated__/CabinInventoryRouteQuery_graphql.bs.js");

var convertVariables = CabinInventoryRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = CabinInventoryRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = CabinInventoryRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, CabinInventoryRouteQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, CabinInventoryRouteQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(CabinInventoryRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(CabinInventoryRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(CabinInventoryRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(CabinInventoryRouteQuery_graphql$Thick.node, convertVariables);

var Query_accountStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_billingKind_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_bookingSessionPassengerType_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var Query_bookingSessionPassengerType_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var Query_brandfamRole_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.brandfamRole_decode;

var Query_brandfamRole_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.brandfamRole_fromString;

var Query_custSignonStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_invitationType_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.invitationType_decode;

var Query_invitationType_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.invitationType_fromString;

var Query_lifecycleStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_longitudinality_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_paxDataStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_signonStatus_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.signonStatus_decode;

var Query_signonStatus_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.signonStatus_fromString;

var Query_subtotalCategory_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query_versionProvenance_decode = CabinInventoryRouteQuery_graphql$Thick.Utils.versionProvenance_decode;

var Query_versionProvenance_fromString = CabinInventoryRouteQuery_graphql$Thick.Utils.versionProvenance_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  bookingSessionPassengerType_decode: Query_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: Query_bookingSessionPassengerType_fromString,
  brandfamRole_decode: Query_brandfamRole_decode,
  brandfamRole_fromString: Query_brandfamRole_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  invitationType_decode: Query_invitationType_decode,
  invitationType_fromString: Query_invitationType_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  signonStatus_decode: Query_signonStatus_decode,
  signonStatus_fromString: Query_signonStatus_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  versionProvenance_decode: Query_versionProvenance_decode,
  versionProvenance_fromString: Query_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function CabinInventoryRoute(props) {
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var qStructInitial = CabinInventoryFilters$Thick.ColumnFilter.codec.decode(url.search);
  var qStruct = React.useDeferredValue(qStructInitial);
  var filterConfig = CabinInventoryFilters$Thick.$$Storage.toRelay(qStruct);
  var filter;
  if (Caml_obj.equal(qStruct, CabinInventoryFilters$Thick.$$Storage.empty)) {
    var newrecord = Caml_obj.obj_dup(filterConfig);
    newrecord.voyageSlug = {
      equalTo: CS_Slugs$Util.VoyageSlug.toString(voyage)
    };
    filter = newrecord;
  } else {
    filter = filterConfig;
  }
  var offsetFromPage = function (page) {
    return Math.imul(page - 1 | 0, 50);
  };
  var page = CabinInventoryFilters$Thick.Pagination.codec.decode(url.search);
  var variables_offset = offsetFromPage(page);
  var variables = {
    brandFamily: brandFamily,
    filter: filter,
    offset: variables_offset,
    voyage: voyage
  };
  var data = use(variables, undefined, undefined, undefined);
  var fragmentRefs = data.fragmentRefs;
  var match = data.allCabins;
  var allCabins = match !== undefined ? Belt_Set.fromArray(Belt_Array.map(match.nodes, (function (c) {
                return c.cabinNumber;
              })), Common$Thick.CabinCmp) : Belt_Set.make(Common$Thick.CabinCmp);
  var match$1 = React.useState(function () {
        return Belt_Set.make(Common$Thick.CabinCmp);
      });
  var setSelectedCabins = match$1[1];
  var selectedCabins = match$1[0];
  var handleCabinToggle = function (c) {
    if (Belt_Set.has(selectedCabins, c)) {
      return setSelectedCabins(function (param) {
                  return Belt_Set.remove(selectedCabins, c);
                });
    } else {
      return setSelectedCabins(function (param) {
                  return Belt_Set.add(selectedCabins, c);
                });
    }
  };
  return JsxRuntime.jsx(DashboardLayout$Thick.make, {
              fragmentRefs: fragmentRefs,
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                              fragmentRefs: fragmentRefs,
                              children: JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                    fragmentRefs: fragmentRefs,
                                    neededPerm: "VIEW_CABIN_INVENTORY",
                                    children: JsxRuntime.jsx(VoyageProtectedRoute$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          brandFamily: brandFamily,
                                          voyage: voyage,
                                          children: JsxRuntime.jsx(CabinInventoryPage$Thick.make, {
                                                fragmentRefs: fragmentRefs,
                                                brandFamily: brandFamily,
                                                voyage: CS_Slugs$Util.VoyageSlug.toString(voyage),
                                                allCabins: allCabins,
                                                selectedCabins: selectedCabins,
                                                setSelectedCabins: setSelectedCabins,
                                                handleCabinToggle: handleCabinToggle
                                              })
                                        }),
                                    isRoute: true,
                                    redirect: "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily)
                                  })
                            }))
                  }),
              bg: "primary.50",
              voyage: Caml_option.some(voyage),
              cabinDash: true,
              clearCabins: (function () {
                  setSelectedCabins(function (param) {
                        return Belt_Set.make(Common$Thick.CabinCmp);
                      });
                })
            });
}

var make = CabinInventoryRoute;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
