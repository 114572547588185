// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Js_math = require("rescript/lib/js/js_math.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var DraftUtilsUserQuery_graphql$Thick = require("../../../__generated__/DraftUtilsUserQuery_graphql.bs.js");
var PreviewChangesQuery_graphql$Thick = require("../../../__generated__/PreviewChangesQuery_graphql.bs.js");
var DraftUtilsCabinQuery_graphql$Thick = require("../../../__generated__/DraftUtilsCabinQuery_graphql.bs.js");

var genderOptions = [
  "F",
  "M",
  "X"
];

function genderToText(x) {
  if (!(x === "F" || x === "M" || x === "X")) {
    return "Unsupported";
  }
  switch (x) {
    case "M" :
        return "Male";
    case "F" :
        return "Female";
    case "X" :
        return "Unspecified";
    
  }
}

var Pax = {
  genderOptions: genderOptions,
  genderToText: genderToText
};

function makeBookingDiffInput(diffStruct) {
  var dropBerths = Belt_Option.map(diffStruct.dropBerths, (function (berths) {
          return Belt_Array.map(berths, (function (b) {
                        return b;
                      }));
        }));
  var detachPax = Belt_Option.map(diffStruct.detachPax, (function (pax) {
          return Belt_Array.map(pax, (function (a) {
                        return a;
                      }));
        }));
  var reindexPax = Belt_Option.map(diffStruct.reindexPax, (function (berths) {
          return Belt_Array.map(berths, (function (b) {
                        return b;
                      }));
        }));
  var suppressManualBillingLines = Belt_Option.map(diffStruct.suppressManualBillingLines, (function (lines) {
          return Belt_Array.map(lines, (function (l) {
                        return l;
                      }));
        }));
  var intMapToOptionWrappedArray = function (innerMapFn, intMap) {
    return Belt_Array.map(Belt_MapInt.toArray(intMap), (function (p) {
                  return innerMapFn(p);
                }));
  };
  var stringMapToOptionWrappedArray = function (innerMapFn, stringMap) {
    return Belt_Array.map(Belt_MapString.toArray(stringMap), (function (p) {
                  return innerMapFn(p);
                }));
  };
  var mapPaxApplication = function (p) {
    return {
            idx: p[0],
            accountId: p[1]
          };
  };
  var mapPaxInvitation = function (p) {
    var reco = p[1];
    return {
            idx: p[0],
            invitationEmail: reco.email,
            preferredFirstName: reco.firstName,
            preferredLastName: reco.lastName
          };
  };
  var mapNewConnectedPax = function (p) {
    var reco = p[1];
    return {
            idx: p[0],
            custodianAccountId: reco.custodianAccountId,
            docFirstname: reco.docFirstName,
            docLastname: reco.docLastName,
            docGender: Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.docGender_decode(reco.docGender), "X"),
            docCitizenship: reco.docCitizenship,
            docBirthdate: reco.docBirthdate,
            preferredFirstName: reco.preferredFirstName,
            preferredLastName: reco.preferredLastName,
            halMarinerNumber: reco.halMarinerNumber
          };
  };
  var mapManualBillingLine = function (p) {
    var line = p[1];
    return {
            subtotalCategory: Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.subtotalCategory_decode(line.subtotalCategory), "NON_CORE_BOOKING_FEES"),
            amount: line.amount,
            description: line.description,
            passthruId: Caml_option.some(Belt_Result.getWithDefault(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(p[0]), CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive("exn_passthru_" + String(Js_math.random_int(1, 1000)))))
          };
  };
  var mapSuppressAutoBillingLine = function (p) {
    var suppression = p[1];
    return {
            fareRuleSlug: suppression.fareRuleSlug,
            hookFiredAt: suppression.hookFiredAt,
            nSuppressed: suppression.nSuppressed,
            passthruId: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive(p[0]))
          };
  };
  var attachExistingPax = Belt_Option.map(diffStruct.attachExistingPax, (function (extra) {
          return intMapToOptionWrappedArray(mapPaxApplication, extra);
        }));
  var replacePaxExisting = Belt_Option.map(diffStruct.replacePaxExisting, (function (extra) {
          return intMapToOptionWrappedArray(mapPaxApplication, extra);
        }));
  var revokePaxInvitation = Belt_Option.map(diffStruct.revokePaxInvitation, (function (extra) {
          return intMapToOptionWrappedArray(mapPaxApplication, extra);
        }));
  var attachInvitedPax = Belt_Option.map(diffStruct.attachInvitedPax, (function (extra) {
          return intMapToOptionWrappedArray(mapPaxInvitation, extra);
        }));
  var replacePaxInvited = Belt_Option.map(diffStruct.replaceInvitedPax, (function (extra) {
          return intMapToOptionWrappedArray(mapPaxInvitation, extra);
        }));
  var attachNewConnectedPax = Belt_Option.map(diffStruct.attachNewConnectedPax, (function (extra) {
          return intMapToOptionWrappedArray(mapNewConnectedPax, extra);
        }));
  var replaceNewConnectedPax = Belt_Option.map(diffStruct.replaceNewConnectedPax, (function (extra) {
          return intMapToOptionWrappedArray(mapNewConnectedPax, extra);
        }));
  var updateBookingPreferences = Belt_Option.map(diffStruct.updateBookingPreferences, (function (param) {
          var cabinLongitudePref = Belt_Option.map(param.cabinLongitudePref, (function (p) {
                  return Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.longitudinality_decode(p), "FORE");
                }));
          var cabinElevatorPref = Belt_Option.map(param.cabinElevatorPref, (function (p) {
                  return Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.elevatorProximity_decode(p), "FARELEVATORS");
                }));
          return {
                  bedsTogetherPref: param.bedsTogetherPref,
                  cabinLongitudePref: cabinLongitudePref,
                  cabinElevatorPref: cabinElevatorPref,
                  cabinPlacementRequest: param.cabinPlacementRequest,
                  earlyDinnerSeatingPref: param.earlyDinnerSeatingPref,
                  generalPref: param.generalPref
                };
        }));
  var changeCabinBooked = Belt_Option.map(diffStruct.changeCabinB, (function (param) {
          return {
                  cabinNumber: param.cabinNumber,
                  bookingId: param.bookingId,
                  bookingVersionNumber: param.bookingVersionNumber
                };
        }));
  var addManualBillingLines = Belt_Option.map(diffStruct.addManualBillingLines, (function (extra) {
          return stringMapToOptionWrappedArray(mapManualBillingLine, extra);
        }));
  var suppressAutoBillingLines = Belt_Option.map(diffStruct.suppressAutoBillingLines, (function (extra) {
          return stringMapToOptionWrappedArray(mapSuppressAutoBillingLine, extra);
        }));
  var mapPaxPatch = function (p) {
    var reco = p[1];
    var updateOtherVoyages = reco.updateOtherVoyages;
    var docGender = Belt_Option.map(reco.pDocGender, (function (g) {
            return Belt_Option.getWithDefault(PreviewChangesQuery_graphql$Thick.Utils.docGender_decode(g), "X");
          }));
    var updateOtherVoyages$1 = updateOtherVoyages.length === 0 ? undefined : Belt_Array.map(updateOtherVoyages, (function (v) {
              return Caml_option.some(v);
            }));
    return {
            accountId: reco.accountId,
            docFirstName: reco.pDocFirstName,
            docLastName: reco.pDocLastName,
            docGender: docGender,
            docCitizenship: reco.pDocCitizenship,
            updateOtherVoyages: updateOtherVoyages$1,
            updateUserAccount: reco.updateUserAccount
          };
  };
  var mapManualRefunds = function (p) {
    var reco = p[1];
    var referencePmtPublicId = Belt_Option.map(reco.referencePmtPublicId, CS_NonemptyStrings$Util.NonEmptyString.toString);
    var lifecycleStatus = PreviewChangesQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode(reco.lifecycleStatus);
    return {
            payee: reco.payee,
            teamUserInitiator: reco.teamUserInitiator,
            processor: reco.processor,
            netRefundedAmount: reco.netRefundedAmount,
            netRefundedBfFeeAmount: reco.netRefundedBfFeeAmount,
            referencePmtPublicId: referencePmtPublicId,
            lifecycleStatus: lifecycleStatus,
            processorTransactionId: reco.processorTransactionId,
            pmtMethod: reco.pmtMethod,
            bfcustPmtMethodIdentifier: reco.bfcustPmtMethodIdentifier,
            bfcustPmtSubtype: reco.bfcustPmtSubtype,
            bfcustPmtNote: reco.bfcustPmtNote
          };
  };
  var mapMovePayments = function (p) {
    var publicPmtId = CS_NonemptyStrings$Util.PmtTransactionId.toString(p.publicPmtId);
    return {
            toBookingId: Caml_option.some(p.toBookingId),
            toBookingVersionNumber: p.toBookingVersionNumber,
            publicPmtId: publicPmtId
          };
  };
  var patchPax = Belt_Option.map(diffStruct.patchPax, (function (extra) {
          return intMapToOptionWrappedArray(mapPaxPatch, extra);
        }));
  var recordManualRefunds = Belt_Option.map(diffStruct.recordManualRefunds, (function (extra) {
          return intMapToOptionWrappedArray(mapManualRefunds, extra);
        }));
  var movePayments = Belt_Option.map(diffStruct.movePayments, (function (extra) {
          return Belt_Array.map(extra, (function (p) {
                        return mapMovePayments(p);
                      }));
        }));
  return {
          dropBerths: dropBerths,
          addBerths: diffStruct.addBerths,
          detachPax: detachPax,
          attachExistingPax: attachExistingPax,
          attachInvitedPax: attachInvitedPax,
          attachNewConnectedPax: attachNewConnectedPax,
          replacePaxExisting: replacePaxExisting,
          replacePaxInvited: replacePaxInvited,
          replaceNewConnectedPax: replaceNewConnectedPax,
          revokePaxInvitation: revokePaxInvitation,
          changeCabinUnbooked: diffStruct.changeCabinU,
          changeCabinBooked: changeCabinBooked,
          changeBillingContact: diffStruct.changeBillingContact,
          suppressAutoBillingLines: suppressAutoBillingLines,
          suppressManualBillingLines: suppressManualBillingLines,
          addManualBillingLines: addManualBillingLines,
          updateBookingPreferences: updateBookingPreferences,
          patchPax: patchPax,
          recordManualRefunds: recordManualRefunds,
          movePayments: movePayments,
          cancellationReason: diffStruct.cancellationReason,
          reindexPax: reindexPax
        };
}

function previewCallback(bookingId, bookingVersionNumber, voyage) {
  return function (diff) {
    return {
            bookingDiff: makeBookingDiffInput(diff),
            bookingId: bookingId,
            bookingVersionNumber: bookingVersionNumber,
            voyageSlug: voyage
          };
  };
}

var convertVariables = DraftUtilsUserQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = DraftUtilsUserQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = DraftUtilsUserQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, DraftUtilsUserQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, DraftUtilsUserQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(DraftUtilsUserQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(DraftUtilsUserQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(DraftUtilsUserQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(DraftUtilsUserQuery_graphql$Thick.node, convertVariables);

var FetchAllBfCustUsers_accountStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.accountStatus_decode;

var FetchAllBfCustUsers_accountStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.accountStatus_fromString;

var FetchAllBfCustUsers_billingKind_decode = DraftUtilsUserQuery_graphql$Thick.Utils.billingKind_decode;

var FetchAllBfCustUsers_billingKind_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.billingKind_fromString;

var FetchAllBfCustUsers_bookingSessionPassengerType_decode = DraftUtilsUserQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var FetchAllBfCustUsers_bookingSessionPassengerType_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var FetchAllBfCustUsers_brandfamRole_decode = DraftUtilsUserQuery_graphql$Thick.Utils.brandfamRole_decode;

var FetchAllBfCustUsers_brandfamRole_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.brandfamRole_fromString;

var FetchAllBfCustUsers_custSignonStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.custSignonStatus_decode;

var FetchAllBfCustUsers_custSignonStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var FetchAllBfCustUsers_docGender_decode = DraftUtilsUserQuery_graphql$Thick.Utils.docGender_decode;

var FetchAllBfCustUsers_docGender_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.docGender_fromString;

var FetchAllBfCustUsers_elevatorProximity_decode = DraftUtilsUserQuery_graphql$Thick.Utils.elevatorProximity_decode;

var FetchAllBfCustUsers_elevatorProximity_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var FetchAllBfCustUsers_invitationType_decode = DraftUtilsUserQuery_graphql$Thick.Utils.invitationType_decode;

var FetchAllBfCustUsers_invitationType_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.invitationType_fromString;

var FetchAllBfCustUsers_lifecycleStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var FetchAllBfCustUsers_lifecycleStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var FetchAllBfCustUsers_longitudinality_decode = DraftUtilsUserQuery_graphql$Thick.Utils.longitudinality_decode;

var FetchAllBfCustUsers_longitudinality_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.longitudinality_fromString;

var FetchAllBfCustUsers_paxDataStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.paxDataStatus_decode;

var FetchAllBfCustUsers_paxDataStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var FetchAllBfCustUsers_pmtDirection_decode = DraftUtilsUserQuery_graphql$Thick.Utils.pmtDirection_decode;

var FetchAllBfCustUsers_pmtDirection_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.pmtDirection_fromString;

var FetchAllBfCustUsers_pmtLifecycleStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var FetchAllBfCustUsers_pmtLifecycleStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var FetchAllBfCustUsers_pmtMethod_decode = DraftUtilsUserQuery_graphql$Thick.Utils.pmtMethod_decode;

var FetchAllBfCustUsers_pmtMethod_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.pmtMethod_fromString;

var FetchAllBfCustUsers_pmtProcessor_decode = DraftUtilsUserQuery_graphql$Thick.Utils.pmtProcessor_decode;

var FetchAllBfCustUsers_pmtProcessor_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var FetchAllBfCustUsers_signonStatus_decode = DraftUtilsUserQuery_graphql$Thick.Utils.signonStatus_decode;

var FetchAllBfCustUsers_signonStatus_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.signonStatus_fromString;

var FetchAllBfCustUsers_subtotalCategory_decode = DraftUtilsUserQuery_graphql$Thick.Utils.subtotalCategory_decode;

var FetchAllBfCustUsers_subtotalCategory_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var FetchAllBfCustUsers_versionProvenance_decode = DraftUtilsUserQuery_graphql$Thick.Utils.versionProvenance_decode;

var FetchAllBfCustUsers_versionProvenance_fromString = DraftUtilsUserQuery_graphql$Thick.Utils.versionProvenance_fromString;

var FetchAllBfCustUsers = {
  accountStatus_decode: FetchAllBfCustUsers_accountStatus_decode,
  accountStatus_fromString: FetchAllBfCustUsers_accountStatus_fromString,
  billingKind_decode: FetchAllBfCustUsers_billingKind_decode,
  billingKind_fromString: FetchAllBfCustUsers_billingKind_fromString,
  bookingSessionPassengerType_decode: FetchAllBfCustUsers_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: FetchAllBfCustUsers_bookingSessionPassengerType_fromString,
  brandfamRole_decode: FetchAllBfCustUsers_brandfamRole_decode,
  brandfamRole_fromString: FetchAllBfCustUsers_brandfamRole_fromString,
  custSignonStatus_decode: FetchAllBfCustUsers_custSignonStatus_decode,
  custSignonStatus_fromString: FetchAllBfCustUsers_custSignonStatus_fromString,
  docGender_decode: FetchAllBfCustUsers_docGender_decode,
  docGender_fromString: FetchAllBfCustUsers_docGender_fromString,
  elevatorProximity_decode: FetchAllBfCustUsers_elevatorProximity_decode,
  elevatorProximity_fromString: FetchAllBfCustUsers_elevatorProximity_fromString,
  invitationType_decode: FetchAllBfCustUsers_invitationType_decode,
  invitationType_fromString: FetchAllBfCustUsers_invitationType_fromString,
  lifecycleStatus_decode: FetchAllBfCustUsers_lifecycleStatus_decode,
  lifecycleStatus_fromString: FetchAllBfCustUsers_lifecycleStatus_fromString,
  longitudinality_decode: FetchAllBfCustUsers_longitudinality_decode,
  longitudinality_fromString: FetchAllBfCustUsers_longitudinality_fromString,
  paxDataStatus_decode: FetchAllBfCustUsers_paxDataStatus_decode,
  paxDataStatus_fromString: FetchAllBfCustUsers_paxDataStatus_fromString,
  pmtDirection_decode: FetchAllBfCustUsers_pmtDirection_decode,
  pmtDirection_fromString: FetchAllBfCustUsers_pmtDirection_fromString,
  pmtLifecycleStatus_decode: FetchAllBfCustUsers_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: FetchAllBfCustUsers_pmtLifecycleStatus_fromString,
  pmtMethod_decode: FetchAllBfCustUsers_pmtMethod_decode,
  pmtMethod_fromString: FetchAllBfCustUsers_pmtMethod_fromString,
  pmtProcessor_decode: FetchAllBfCustUsers_pmtProcessor_decode,
  pmtProcessor_fromString: FetchAllBfCustUsers_pmtProcessor_fromString,
  signonStatus_decode: FetchAllBfCustUsers_signonStatus_decode,
  signonStatus_fromString: FetchAllBfCustUsers_signonStatus_fromString,
  subtotalCategory_decode: FetchAllBfCustUsers_subtotalCategory_decode,
  subtotalCategory_fromString: FetchAllBfCustUsers_subtotalCategory_fromString,
  versionProvenance_decode: FetchAllBfCustUsers_versionProvenance_decode,
  versionProvenance_fromString: FetchAllBfCustUsers_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function generateAvatarInitials(pax) {
  var match = pax.firstName;
  var match$1 = pax.lastName;
  var match$2 = pax.legalDocumentFirstName;
  var match$3 = pax.legalDocumentLastName;
  if (match !== undefined) {
    if (match$1 === undefined) {
      return match[0].toLocaleUpperCase();
    }
    var firstInitial = match[0];
    var lastInitial = match$1[0];
    return (firstInitial + lastInitial).toLocaleUpperCase();
  }
  if (match$1 !== undefined) {
    return match$1[0].toLocaleUpperCase();
  }
  if (match$2 === undefined) {
    return "TBA";
  }
  if (match$3 === undefined) {
    return "TBA";
  }
  var firstInitial$1 = match$2[0];
  var lastInitial$1 = match$3[0];
  return (firstInitial$1 + lastInitial$1).toLocaleUpperCase();
}

function generateInvitedNames(paxInvite) {
  var lastName = paxInvite.lastName;
  if (lastName === undefined) {
    return {
            fullName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(paxInvite.firstName),
            firstName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(paxInvite.firstName),
            lastName: undefined
          };
  }
  var lastName$1 = Caml_option.valFromOption(lastName);
  return {
          fullName: CS_NonemptyStrings$Util.NonEmptyString.toString(paxInvite.firstName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toString(lastName$1),
          firstName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(paxInvite.firstName),
          lastName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(lastName$1)
        };
}

function generateNewConnectedNames(pax) {
  var match = pax.preferredFirstName;
  var match$1 = pax.preferredLastName;
  var fullName;
  if (match !== undefined) {
    var fn = Caml_option.valFromOption(match);
    fullName = match$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fn) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fn);
  } else {
    fullName = match$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(match$1)) : CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(pax.docFirstName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(pax.docLastName);
  }
  return {
          fullName: fullName,
          firstName: Belt_Option.map(pax.preferredFirstName, (function (fn) {
                  return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(fn);
                })),
          lastName: Belt_Option.map(pax.preferredLastName, (function (ln) {
                  return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(ln);
                })),
          legalDocumentFirstName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(pax.docFirstName),
          legalDocumentLastName: CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(pax.docLastName),
          legalDocumentBirthdate: Caml_option.some(pax.docBirthdate),
          legalDocumentGender: pax.docGender,
          legalDocumentCitizenship: Caml_option.some(pax.docCitizenship),
          halMarinerNumber: pax.halMarinerNumber,
          custodianAccountId: Caml_option.some(pax.custodianAccountId)
        };
}

function generatePaxList(dataPaxes, diffStruct, searchString, brandFamily, numBerths) {
  var match = React.useState(function () {
        
      });
  var setToAttachOrReplaceExisting = match[1];
  var toAttachOrReplaceExisting = match[0];
  React.useEffect((function () {
          var match = diffStruct.attachExistingPax;
          var match$1 = diffStruct.replacePaxExisting;
          var exit = 0;
          if (match !== undefined || match$1 !== undefined) {
            exit = 1;
          } else {
            setToAttachOrReplaceExisting(function (param) {
                  
                });
          }
          if (exit === 1) {
            var attachExistingMap = Belt_Option.getWithDefault(diffStruct.attachExistingPax, undefined);
            var replaceExistingMap = Belt_Option.getWithDefault(diffStruct.replacePaxExisting, undefined);
            var fullMap = Belt_MapInt.fromArray(Belt_Array.concat(Belt_MapInt.toArray(attachExistingMap), Belt_MapInt.toArray(replaceExistingMap)));
            var acctIds = Belt_Array.concat(Belt_MapInt.valuesToArray(attachExistingMap), Belt_MapInt.valuesToArray(replaceExistingMap));
            var acctIdToIdx = Belt_MapString.fromArray(Belt_Array.map(Belt_MapInt.toArray(fullMap), (function (param) {
                        return [
                                CS_NonemptyStrings$Util.AccountId.toString(param[1]),
                                param[0]
                              ];
                      })));
            var filter_accountId = {
              in: acctIds
            };
            var filter = {
              accountId: filter_accountId
            };
            $$fetch(RelayEnv$Thick.environment, {
                  brandFamily: brandFamily,
                  filter: filter
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var match = res._0.bfcustUsers;
                      if (match !== undefined) {
                        var idMap = Belt_MapInt.fromArray(Belt_Array.map(match.nodes, (function (node) {
                                    var idx = Belt_MapString.getExn(acctIdToIdx, CS_NonemptyStrings$Util.AccountId.toString(node.accountId));
                                    var email = node.email;
                                    var name = node.lastName;
                                    var hmn = node.halMarinerNumber;
                                    var name$1 = node.legalDocumentFirstName;
                                    var name$2 = node.legalDocumentLastName;
                                    var email$1 = node.invitationEmail;
                                    var expiry = node.invitationExpiry;
                                    var tmp;
                                    if (expiry !== undefined) {
                                      var expiry$1 = Caml_option.valFromOption(expiry);
                                      tmp = CS_Make$Util.Dateable.Plain.isInThePast(expiry$1) ? "Invitation has expired! Please resend or revoke." : "Invitation expires on " + CS_Make$Util.Dateable.Plain.format(expiry$1, "MM/dd/yyyy") + " at " + CS_Make$Util.Dateable.Plain.format(expiry$1, "hh:mm b") + ".";
                                    } else {
                                      tmp = undefined;
                                    }
                                    var expiry$2 = node.invitationExpiry;
                                    var node_paxDataStatus = Belt_MapInt.has(attachExistingMap, idx) ? ({
                                          TAG: "Draft",
                                          _0: "attach"
                                        }) : ({
                                          TAG: "Draft",
                                          _0: "replace"
                                        });
                                    var node_accountId = Caml_option.some(node.accountId);
                                    var node_email = email !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) : undefined;
                                    var node_fullName = node.fullName;
                                    var node_firstName = CS_NonemptyStrings$Util.NonEmptyString.toString(node.firstName);
                                    var node_lastName = name !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name)) : undefined;
                                    var node_loyaltyNumber = CS_NonemptyStrings$Util.LoyaltyNumber.toString(node.loyaltyNumber);
                                    var node_halMarinerNumber = hmn !== undefined ? CS_LoyaltyNumbers$Util.HalMarinerNumber.toString(Caml_option.valFromOption(hmn)) : undefined;
                                    var node_legalDocumentFirstName = name$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name$1)) : undefined;
                                    var node_legalDocumentLastName = name$2 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name$2)) : undefined;
                                    var node_legalDocumentBirthdate = node.legalDocumentBirthdate;
                                    var node_legalDocumentGender = node.legalDocumentGender;
                                    var node_legalDocumentCitizenship = node.legalDocumentCitizenship;
                                    var node_invitationEmail = email$1 !== undefined ? CS_Emails$Util.Email.toPrimitive(Caml_option.valFromOption(email$1)) : undefined;
                                    var node_invitationExpired = expiry$2 !== undefined && CS_Make$Util.Dateable.Plain.isInThePast(Caml_option.valFromOption(expiry$2)) ? true : false;
                                    var node_custodianAccountId = node.custodianAccountId;
                                    var node$1 = {
                                      paxDataStatus: node_paxDataStatus,
                                      idx: idx,
                                      reorderedIdx: idx,
                                      accountId: node_accountId,
                                      email: node_email,
                                      fullName: node_fullName,
                                      firstName: node_firstName,
                                      lastName: node_lastName,
                                      loyaltyNumber: node_loyaltyNumber,
                                      halMarinerNumber: node_halMarinerNumber,
                                      legalDocumentFirstName: node_legalDocumentFirstName,
                                      legalDocumentLastName: node_legalDocumentLastName,
                                      legalDocumentBirthdate: node_legalDocumentBirthdate,
                                      legalDocumentGender: node_legalDocumentGender,
                                      legalDocumentCitizenship: node_legalDocumentCitizenship,
                                      shouldAttachToIdx: idx,
                                      invitationEmail: node_invitationEmail,
                                      invitationExpiry: tmp,
                                      invitationExpired: node_invitationExpired,
                                      custodianAccountId: node_custodianAccountId
                                    };
                                    return [
                                            idx,
                                            node$1
                                          ];
                                  })));
                        setToAttachOrReplaceExisting(function (param) {
                              return Caml_option.some(idMap);
                            });
                      }
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined);
          }
          
        }), [searchString]);
  var calcNewIdx = function (oldIdx, reorderArr) {
    if (reorderArr === undefined) {
      return oldIdx;
    }
    var $$new = Belt_Array.getIndexBy(reorderArr, (function (x) {
            return x === oldIdx;
          }));
    if ($$new !== undefined) {
      return $$new + 1 | 0;
    } else {
      return oldIdx + Belt_Array.reduce(reorderArr, 0, (function (acc, x) {
                    if (x > oldIdx) {
                      return 1 + acc | 0;
                    } else {
                      return acc;
                    }
                  })) | 0;
    }
  };
  var nonTBAPaxPreDropDetach = Belt_Array.map(dataPaxes, (function (node) {
          var match = node.bfcustUser;
          var match$1 = node.bfcustUser;
          var tmp;
          if (match$1 !== undefined) {
            var email = match$1.email;
            tmp = email !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) : undefined;
          } else {
            tmp = undefined;
          }
          var match$2 = node.bfcustUser;
          var match$3 = node.bfcustUser;
          var match$4 = node.bfcustUser;
          var tmp$1;
          if (match$4 !== undefined) {
            var name = match$4.lastName;
            tmp$1 = name !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name)) : undefined;
          } else {
            tmp$1 = undefined;
          }
          var match$5 = node.bfcustUser;
          var match$6 = node.bfcustUser;
          var tmp$2;
          if (match$6 !== undefined) {
            var halMarinerNumber = match$6.halMarinerNumber;
            tmp$2 = halMarinerNumber !== undefined ? CS_LoyaltyNumbers$Util.HalMarinerNumber.toString(Caml_option.valFromOption(halMarinerNumber)) : undefined;
          } else {
            tmp$2 = undefined;
          }
          var name$1 = node.docFirstName;
          var name$2 = node.docLastName;
          var docBirthdate = node.docBirthdate;
          var docGender = node.docGender;
          var docCitizenship = node.docCitizenship;
          var match$7 = node.bfcustUser;
          var tmp$3;
          if (match$7 !== undefined) {
            var email$1 = match$7.invitationEmail;
            tmp$3 = email$1 !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email$1)) : undefined;
          } else {
            tmp$3 = undefined;
          }
          var match$8 = node.bfcustUser;
          var tmp$4;
          if (match$8 !== undefined) {
            var expiry = match$8.invitationExpiry;
            if (expiry !== undefined) {
              var expiry$1 = Caml_option.valFromOption(expiry);
              tmp$4 = CS_Make$Util.Dateable.Plain.isInThePast(expiry$1) ? "Invitation has expired! Please resend or revoke." : "Invitation expires on " + CS_Make$Util.Dateable.Plain.format(expiry$1, "MM/dd/yyyy") + " at " + CS_Make$Util.Dateable.Plain.format(expiry$1, "hh:mm b") + ".";
            } else {
              tmp$4 = undefined;
            }
          } else {
            tmp$4 = undefined;
          }
          var match$9 = node.bfcustUser;
          var tmp$5;
          if (match$9 !== undefined) {
            var expiry$2 = match$9.invitationExpiry;
            tmp$5 = expiry$2 !== undefined && CS_Make$Util.Dateable.Plain.isInThePast(Caml_option.valFromOption(expiry$2)) ? true : false;
          } else {
            tmp$5 = false;
          }
          var match$10 = node.bfcustUser;
          var passenger_paxDataStatus = {
            TAG: "Status",
            _0: node.paxDataStatus
          };
          var passenger_idx = node.idx;
          var passenger_reorderedIdx = calcNewIdx(node.idx, diffStruct.reindexPax);
          var passenger_accountId = match !== undefined ? Caml_option.some(match.accountId) : undefined;
          var passenger_fullName = match$2 !== undefined ? match$2.fullName : undefined;
          var passenger_firstName = match$3 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(match$3.firstName) : undefined;
          var passenger_loyaltyNumber = match$5 !== undefined ? CS_NonemptyStrings$Util.LoyaltyNumber.toString(match$5.loyaltyNumber) : undefined;
          var passenger_legalDocumentFirstName = name$1 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name$1)) : undefined;
          var passenger_legalDocumentLastName = name$2 !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(name$2)) : undefined;
          var passenger_legalDocumentBirthdate = docBirthdate !== undefined ? Caml_option.some(Caml_option.valFromOption(docBirthdate)) : undefined;
          var passenger_legalDocumentGender = docGender !== undefined ? docGender : undefined;
          var passenger_legalDocumentCitizenship = docCitizenship !== undefined ? Caml_option.some(Caml_option.valFromOption(docCitizenship)) : undefined;
          var passenger_shouldAttachToIdx = node.idx;
          var passenger_custodianAccountId = match$10 !== undefined ? match$10.custodianAccountId : undefined;
          var passenger = {
            paxDataStatus: passenger_paxDataStatus,
            idx: passenger_idx,
            reorderedIdx: passenger_reorderedIdx,
            accountId: passenger_accountId,
            email: tmp,
            fullName: passenger_fullName,
            firstName: passenger_firstName,
            lastName: tmp$1,
            loyaltyNumber: passenger_loyaltyNumber,
            halMarinerNumber: tmp$2,
            legalDocumentFirstName: passenger_legalDocumentFirstName,
            legalDocumentLastName: passenger_legalDocumentLastName,
            legalDocumentBirthdate: passenger_legalDocumentBirthdate,
            legalDocumentGender: passenger_legalDocumentGender,
            legalDocumentCitizenship: passenger_legalDocumentCitizenship,
            shouldAttachToIdx: passenger_shouldAttachToIdx,
            invitationEmail: tmp$3,
            invitationExpiry: tmp$4,
            invitationExpired: tmp$5,
            custodianAccountId: passenger_custodianAccountId
          };
          var replaceExistingMap = Belt_Option.getWithDefault(toAttachOrReplaceExisting, undefined);
          var replaceInvitedMap = Belt_Option.getWithDefault(diffStruct.replaceInvitedPax, undefined);
          var replaceNewConnectedMap = Belt_Option.getWithDefault(diffStruct.replaceNewConnectedPax, undefined);
          if (Belt_MapInt.has(replaceExistingMap, node.idx)) {
            return Belt_MapInt.getWithDefault(replaceExistingMap, node.idx, passenger);
          }
          if (Belt_MapInt.has(replaceInvitedMap, node.idx)) {
            var paxInvite = Belt_MapInt.get(replaceInvitedMap, node.idx);
            if (paxInvite === undefined) {
              return passenger;
            }
            var match$11 = generateInvitedNames(paxInvite);
            return {
                    paxDataStatus: {
                      TAG: "Draft",
                      _0: "invite"
                    },
                    idx: node.idx,
                    reorderedIdx: calcNewIdx(node.idx, diffStruct.reindexPax),
                    accountId: undefined,
                    email: CS_Emails$Util.Email.toString(paxInvite.email),
                    fullName: match$11.fullName,
                    firstName: match$11.firstName,
                    lastName: match$11.lastName,
                    loyaltyNumber: undefined,
                    halMarinerNumber: undefined,
                    legalDocumentFirstName: undefined,
                    legalDocumentLastName: undefined,
                    legalDocumentBirthdate: undefined,
                    legalDocumentGender: undefined,
                    legalDocumentCitizenship: undefined,
                    shouldAttachToIdx: node.idx,
                    invitationEmail: undefined,
                    invitationExpiry: undefined,
                    invitationExpired: false,
                    custodianAccountId: undefined
                  };
          }
          if (!Belt_MapInt.has(replaceNewConnectedMap, node.idx)) {
            return passenger;
          }
          var paxNewConnected = Belt_MapInt.get(replaceNewConnectedMap, node.idx);
          if (paxNewConnected === undefined) {
            return passenger;
          }
          var match$12 = generateNewConnectedNames(paxNewConnected);
          return {
                  paxDataStatus: {
                    TAG: "Draft",
                    _0: "replace"
                  },
                  idx: node.idx,
                  reorderedIdx: calcNewIdx(node.idx, diffStruct.reindexPax),
                  accountId: undefined,
                  email: undefined,
                  fullName: match$12.fullName,
                  firstName: match$12.firstName,
                  lastName: match$12.lastName,
                  loyaltyNumber: undefined,
                  halMarinerNumber: Belt_Option.map(match$12.halMarinerNumber, CS_LoyaltyNumbers$Util.HalMarinerNumber.toString),
                  legalDocumentFirstName: match$12.legalDocumentFirstName,
                  legalDocumentLastName: match$12.legalDocumentLastName,
                  legalDocumentBirthdate: match$12.legalDocumentBirthdate,
                  legalDocumentGender: match$12.legalDocumentGender,
                  legalDocumentCitizenship: match$12.legalDocumentCitizenship,
                  shouldAttachToIdx: node.idx,
                  invitationEmail: undefined,
                  invitationExpiry: undefined,
                  invitationExpired: false,
                  custodianAccountId: match$12.custodianAccountId
                };
        }));
  var postDropDetachPaxes = Belt_Array.keep(Belt_Array.keep(Belt_Array.keep(nonTBAPaxPreDropDetach, (function (pax) {
                  var match = diffStruct.dropBerths;
                  var match$1 = pax.idx;
                  if (match !== undefined) {
                    return !Belt_Array.some(match, (function (b) {
                                  return b === match$1;
                                }));
                  } else {
                    return true;
                  }
                })), (function (pax) {
              var match = diffStruct.detachPax;
              var match$1 = pax.idx;
              if (match !== undefined) {
                return !Belt_Array.some(match, (function (b) {
                              return b === match$1;
                            }));
              } else {
                return true;
              }
            })), (function (pax) {
          var match = diffStruct.revokePaxInvitation;
          var match$1 = pax.paxDataStatus;
          var match$2 = pax.idx;
          if (match === undefined) {
            return true;
          }
          if (typeof match$1 !== "object") {
            return true;
          }
          if (match$1.TAG !== "Status") {
            return true;
          }
          var tmp = match$1._0;
          if ((tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED") && tmp === "INVITED") {
            return !Belt_MapInt.has(Caml_option.valFromOption(match), match$2);
          } else {
            return true;
          }
        }));
  var latestIndex = Belt_Array.reduce(postDropDetachPaxes, 0, (function (acc, pax) {
          return Math.max(acc, pax.idx);
        }));
  var loop = function (_i, _acc) {
    while(true) {
      var acc = _acc;
      var i = _i;
      if (i >= numBerths) {
        return acc;
      }
      var newIdx = i + 1 | 0;
      var blankTBA_shouldAttachToIdx = latestIndex + 1 | 0;
      var blankTBA = {
        paxDataStatus: "TBA",
        idx: newIdx,
        reorderedIdx: newIdx,
        accountId: undefined,
        email: undefined,
        fullName: undefined,
        firstName: undefined,
        lastName: undefined,
        loyaltyNumber: undefined,
        halMarinerNumber: undefined,
        legalDocumentFirstName: undefined,
        legalDocumentLastName: undefined,
        legalDocumentBirthdate: undefined,
        legalDocumentGender: undefined,
        legalDocumentCitizenship: undefined,
        shouldAttachToIdx: blankTBA_shouldAttachToIdx,
        invitationEmail: undefined,
        invitationExpiry: undefined,
        invitationExpired: false,
        custodianAccountId: undefined
      };
      var attachExistingMap = Belt_Option.getWithDefault(toAttachOrReplaceExisting, undefined);
      var attachInvitedMap = Belt_Option.getWithDefault(diffStruct.attachInvitedPax, undefined);
      var attachNewConnectedMap = Belt_Option.getWithDefault(diffStruct.attachNewConnectedPax, undefined);
      var newPax;
      if (Belt_MapInt.has(attachExistingMap, newIdx)) {
        newPax = Belt_MapInt.getWithDefault(attachExistingMap, newIdx, blankTBA);
      } else if (Belt_MapInt.has(attachInvitedMap, newIdx)) {
        var paxInvite = Belt_MapInt.get(attachInvitedMap, newIdx);
        if (paxInvite !== undefined) {
          var match = generateInvitedNames(paxInvite);
          var newrecord = Caml_obj.obj_dup(blankTBA);
          newrecord.lastName = match.lastName;
          newrecord.firstName = match.firstName;
          newrecord.fullName = match.fullName;
          newrecord.email = CS_Emails$Util.Email.toString(paxInvite.email);
          newrecord.idx = newIdx;
          newrecord.paxDataStatus = {
            TAG: "Draft",
            _0: "invite"
          };
          newPax = newrecord;
        } else {
          newPax = blankTBA;
        }
      } else if (Belt_MapInt.has(attachNewConnectedMap, newIdx)) {
        var paxNewConnected = Belt_MapInt.get(attachNewConnectedMap, newIdx);
        if (paxNewConnected !== undefined) {
          var match$1 = generateNewConnectedNames(paxNewConnected);
          var newrecord$1 = Caml_obj.obj_dup(blankTBA);
          newrecord$1.legalDocumentCitizenship = match$1.legalDocumentCitizenship;
          newrecord$1.legalDocumentGender = match$1.legalDocumentGender;
          newrecord$1.legalDocumentBirthdate = match$1.legalDocumentBirthdate;
          newrecord$1.legalDocumentLastName = match$1.legalDocumentLastName;
          newrecord$1.legalDocumentFirstName = match$1.legalDocumentFirstName;
          newrecord$1.halMarinerNumber = Belt_Option.map(match$1.halMarinerNumber, CS_LoyaltyNumbers$Util.HalMarinerNumber.toString);
          newrecord$1.lastName = match$1.lastName;
          newrecord$1.firstName = match$1.firstName;
          newrecord$1.fullName = match$1.fullName;
          newrecord$1.idx = newIdx;
          newrecord$1.paxDataStatus = {
            TAG: "Draft",
            _0: "attach"
          };
          newPax = newrecord$1;
        } else {
          newPax = blankTBA;
        }
      } else {
        newPax = blankTBA;
      }
      _acc = Belt_Array.concat(acc, [newPax]);
      _i = i + 1 | 0;
      continue ;
    };
  };
  var allPassengers = loop(latestIndex, postDropDetachPaxes);
  var patchPaxes = function (paxes, diff) {
    return Belt_Array.map(paxes, (function (pax) {
                  var reco = Belt_MapInt.get(Belt_Option.getWithDefault(diff.patchPax, undefined), pax.idx);
                  if (reco === undefined) {
                    return pax;
                  }
                  var newrecord = Caml_obj.obj_dup(pax);
                  newrecord.legalDocumentCitizenship = pax.legalDocumentCitizenship === reco.pDocCitizenship ? pax.legalDocumentCitizenship : reco.pDocCitizenship;
                  newrecord.legalDocumentGender = pax.legalDocumentGender === reco.pDocGender ? pax.legalDocumentGender : reco.pDocGender;
                  newrecord.legalDocumentLastName = pax.legalDocumentLastName === Belt_Option.map(reco.pDocLastName, CS_NonemptyStrings$Util.NonEmptyString.toString) ? pax.legalDocumentLastName : Belt_Option.map(reco.pDocLastName, CS_NonemptyStrings$Util.NonEmptyString.toString);
                  newrecord.legalDocumentFirstName = pax.legalDocumentFirstName === Belt_Option.map(reco.pDocFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString) ? pax.legalDocumentFirstName : Belt_Option.map(reco.pDocFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString);
                  newrecord.paxDataStatus = {
                    TAG: "Draft",
                    _0: "update"
                  };
                  return newrecord;
                }));
  };
  return {
          passengers: patchPaxes(allPassengers, diffStruct),
          shouldBlockDrop: postDropDetachPaxes.length === 1
        };
}

var convertVariables$1 = DraftUtilsCabinQuery_graphql$Thick.Internal.convertVariables;

var convertResponse$1 = DraftUtilsCabinQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$1 = DraftUtilsCabinQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables$1, DraftUtilsCabinQuery_graphql$Thick.node, convertResponse$1);

var useLoader$1 = RescriptRelay_Query.useLoader(convertVariables$1, DraftUtilsCabinQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded$1 = RescriptRelay_Query.usePreloaded(DraftUtilsCabinQuery_graphql$Thick.node, convertResponse$1, (function (prim) {
        return prim;
      }));

var $$fetch$1 = RescriptRelay_Query.$$fetch(DraftUtilsCabinQuery_graphql$Thick.node, convertResponse$1, convertVariables$1);

var fetchPromised$1 = RescriptRelay_Query.fetchPromised(DraftUtilsCabinQuery_graphql$Thick.node, convertResponse$1, convertVariables$1);

var retain$1 = RescriptRelay_Query.retain(DraftUtilsCabinQuery_graphql$Thick.node, convertVariables$1);

var CabinQuery = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  use: use$1,
  useLoader: useLoader$1,
  usePreloaded: usePreloaded$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  retain: retain$1
};

function generateCabin(dataCabin, diffStruct, searchString, brandFamily, voyage, numBerths) {
  var stateroomImage = "https://i.ibb.co/kGcdwMS/stateroomtest.jpg";
  var match = dataCabin.cabin;
  var tmp;
  if (match !== undefined) {
    var num = match.deckNumber;
    tmp = num !== undefined ? Caml_option.some(Caml_option.valFromOption(num)) : undefined;
  } else {
    tmp = undefined;
  }
  var match$1 = dataCabin.cabin;
  var tmp$1;
  if (match$1 !== undefined) {
    var slug = match$1.voyageClassSlug;
    tmp$1 = slug !== undefined ? Caml_option.some(Caml_option.valFromOption(slug)) : undefined;
  } else {
    tmp$1 = undefined;
  }
  var match$2 = dataCabin.cabin;
  var tmp$2;
  if (match$2 !== undefined) {
    var slug$1 = match$2.voyageCategorySlug;
    tmp$2 = slug$1 !== undefined ? Caml_option.some(Caml_option.valFromOption(slug$1)) : undefined;
  } else {
    tmp$2 = undefined;
  }
  var match$3 = dataCabin.cabin;
  var tmp$3;
  if (match$3 !== undefined) {
    var slug$2 = match$3.voyageSubclassSlug;
    tmp$3 = slug$2 !== undefined ? Caml_option.some(Caml_option.valFromOption(slug$2)) : undefined;
  } else {
    tmp$3 = undefined;
  }
  var match$4 = dataCabin.occRange.start;
  var match$5 = dataCabin.occRange.end;
  var initialCabin_cabinNumber = dataCabin.cabinNumber;
  var initialCabin_occupancyMin = match$4 !== undefined ? (
      match$4.inclusive ? match$4.value : match$4.value + 1 | 0
    ) : undefined;
  var initialCabin_occupancyMax = match$5 !== undefined ? (
      match$5.inclusive ? match$5.value : match$5.value - 1 | 0
    ) : undefined;
  var initialCabin = {
    cabinNumber: initialCabin_cabinNumber,
    numBerths: numBerths,
    deck: tmp,
    cabinClassSlug: tmp$1,
    cabinCategorySlug: tmp$2,
    cabinSubclassSlug: tmp$3,
    occupancyMin: initialCabin_occupancyMin,
    occupancyMax: initialCabin_occupancyMax,
    stateroomImage: stateroomImage
  };
  var match$6 = React.useState(function () {
        
      });
  var setDraftCabin = match$6[1];
  var draftCabin = match$6[0];
  React.useEffect((function () {
          var match = diffStruct.changeCabinU;
          var match$1 = diffStruct.changeCabinB;
          var cabinNumber = match !== undefined ? Caml_option.some(Caml_option.valFromOption(match)) : (
              match$1 !== undefined ? Caml_option.some(match$1.cabinNumber) : undefined
            );
          if (cabinNumber !== undefined) {
            $$fetch$1(RelayEnv$Thick.environment, {
                  brandFamily: brandFamily,
                  cabinNumber: Caml_option.valFromOption(cabinNumber),
                  voyage: voyage
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var match = res._0.cabins;
                      if (match !== undefined) {
                        var match$1 = match.nodes;
                        if (match$1.length !== 1) {
                          console.log("No cabin found");
                        } else {
                          var cabin = match$1[0];
                          var match$2 = cabin.occRange;
                          var tmp;
                          if (match$2 !== undefined) {
                            var match$3 = match$2.start;
                            tmp = match$3 !== undefined ? (
                                match$3.inclusive ? match$3.value : match$3.value + 1 | 0
                              ) : undefined;
                          } else {
                            tmp = undefined;
                          }
                          var match$4 = cabin.occRange;
                          var tmp$1;
                          if (match$4 !== undefined) {
                            var match$5 = match$4.end;
                            tmp$1 = match$5 !== undefined ? (
                                match$5.inclusive ? match$5.value : match$5.value - 1 | 0
                              ) : undefined;
                          } else {
                            tmp$1 = undefined;
                          }
                          var newCabin_cabinNumber = cabin.cabinNumber;
                          var newCabin_deck = cabin.deckNumber;
                          var newCabin_cabinClassSlug = cabin.voyageClassSlug;
                          var newCabin_cabinCategorySlug = cabin.voyageCategorySlug;
                          var newCabin_cabinSubclassSlug = cabin.voyageSubclassSlug;
                          var newCabin = {
                            cabinNumber: newCabin_cabinNumber,
                            numBerths: numBerths,
                            deck: newCabin_deck,
                            cabinClassSlug: newCabin_cabinClassSlug,
                            cabinCategorySlug: newCabin_cabinCategorySlug,
                            cabinSubclassSlug: newCabin_cabinSubclassSlug,
                            occupancyMin: tmp,
                            occupancyMax: tmp$1,
                            stateroomImage: stateroomImage
                          };
                          setDraftCabin(function (param) {
                                return newCabin;
                              });
                        }
                      } else {
                        console.log("No cabin found");
                      }
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined);
          } else {
            setDraftCabin(function (param) {
                  
                });
          }
        }), [searchString]);
  if (draftCabin !== undefined) {
    return draftCabin;
  } else {
    return initialCabin;
  }
}

function addToDraft(diff, versionUrl) {
  var searchStr = BookingDiff$Thick.serialize(diff);
  var url = searchStr === "" ? versionUrl : versionUrl + "?" + searchStr;
  RescriptReactRouter.push(url);
}

exports.Pax = Pax;
exports.makeBookingDiffInput = makeBookingDiffInput;
exports.previewCallback = previewCallback;
exports.FetchAllBfCustUsers = FetchAllBfCustUsers;
exports.generateAvatarInitials = generateAvatarInitials;
exports.generateInvitedNames = generateInvitedNames;
exports.generateNewConnectedNames = generateNewConnectedNames;
exports.generatePaxList = generatePaxList;
exports.CabinQuery = CabinQuery;
exports.generateCabin = generateCabin;
exports.addToDraft = addToDraft;
/* use Not a pure module */
