// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Common$Thick = require("../utils/Common.bs.js");
var NavBar$Thick = require("../components/common/molecules/NavBar.bs.js");
var Storage$Util = require("util/src/Storage.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var LeftNav$Thick = require("../components/dashboard/LeftNav.bs.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var SegmentSelector$Thick = require("../components/cabins/SegmentSelector.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var DashboardLayout_query_graphql$Thick = require("../__generated__/DashboardLayout_query_graphql.bs.js");

var convertFragment = DashboardLayout_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(DashboardLayout_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, DashboardLayout_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(DashboardLayout_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment_signonStatus_decode = DashboardLayout_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = DashboardLayout_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function DashboardLayout(props) {
  var clearCabins = props.clearCabins;
  var __bookingDetail = props.bookingDetail;
  var __cabinDash = props.cabinDash;
  var voyage = props.voyage;
  var bg = props.bg;
  var brandFamily = props.brandFamily;
  var children = props.children;
  var cabinDash = __cabinDash !== undefined ? __cabinDash : false;
  var bookingDetail = __bookingDetail !== undefined ? __bookingDetail : false;
  var query = use(props.fragmentRefs);
  var brandFamily$1;
  if (brandFamily !== undefined) {
    brandFamily$1 = Caml_option.some(Caml_option.valFromOption(brandFamily));
  } else {
    var brandFamily$2 = Storage$Util.Local.getSelectedBrand();
    brandFamily$1 = brandFamily$2 !== undefined ? Caml_option.some(CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily$2)) : undefined;
  }
  var email = query.currentUserEmail;
  var emailOpt = email !== undefined ? Caml_option.some(CS_Emails$Util.Email.Exn.fromPrimitive(email)) : undefined;
  React.useEffect((function () {
          var match = query.currentUserSignonStatus;
          if ((match === "INVITED" || match === "PENDING_2FA" || match === "CLEAR_JWT" || match === "ANONYMOUS" || match === "SIGNED_ON") && match === "CLEAR_JWT") {
            Common$Thick.cookieLogout(Caml_option.some((function () {
                        ((location.reload()));
                      })), undefined, undefined);
          }
          
        }), [query.currentUserSignonStatus]);
  var tmp;
  var exit = 0;
  if (cabinDash) {
    if (bookingDetail) {
      if (bookingDetail) {
        tmp = children;
      } else {
        exit = 1;
      }
    } else if (voyage !== undefined && brandFamily$1 !== undefined && clearCabins !== undefined) {
      tmp = JsxRuntime.jsx(Box, {
            children: Caml_option.some(JsxRuntime.jsx(Sheet, {
                      sx: {
                        flex: 1,
                        height: "100%",
                        width: "100%"
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Box, {
                                children: [
                                  JsxRuntime.jsx(Box, {
                                        children: Caml_option.some(JsxRuntime.jsx(SegmentSelector$Thick.make, {
                                                  voyage: Caml_option.valFromOption(voyage),
                                                  brandFamily: Caml_option.valFromOption(brandFamily$1),
                                                  clearCabins: clearCabins
                                                })),
                                        sx: {
                                          height: "100%",
                                          borderRight: 1,
                                          borderColor: "neutral.300"
                                        }
                                      }),
                                  JsxRuntime.jsx(Box, {
                                        children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                  sx: {
                                                    width: "100%",
                                                    height: "100%",
                                                    maxWidth: "1536px"
                                                  },
                                                  children: Caml_option.some(children)
                                                })),
                                        sx: {
                                          flex: 1,
                                          overflow: "auto",
                                          p: 3,
                                          backgroundColor: bg !== undefined ? bg : "background.level1",
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          width: "100%"
                                        }
                                      })
                                ],
                                sx: {
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "100%",
                                  width: "100%",
                                  backgroundColor: "background.body"
                                }
                              }))
                    })),
            sx: {
              p: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%"
            }
          });
    } else {
      exit = 1;
    }
  } else if (bookingDetail) {
    tmp = children;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Box, {
          children: Caml_option.some(JsxRuntime.jsx(Stack, {
                    sx: {
                      width: "100%",
                      height: "100%",
                      maxWidth: "1536px"
                    },
                    children: Caml_option.some(children)
                  })),
          sx: {
            flex: 1,
            overflow: "auto",
            p: cabinDash ? 0 : 3,
            backgroundColor: bg !== undefined ? bg : "background.level1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }
        });
  }
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                        sx: {
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "background.level1"
                        },
                        children: [
                          JsxRuntime.jsx(NavBar$Thick.make, {
                                profile: query.fragmentRefs,
                                brandFamily: brandFamily$1
                              }),
                          JsxRuntime.jsx(Sheet, {
                                sx: {
                                  flex: 1
                                },
                                children: Caml_option.some(JsxRuntime.jsxs(Box, {
                                          children: [
                                            brandFamily$1 !== undefined && emailOpt !== undefined ? JsxRuntime.jsx(LeftNav$Thick.make, {
                                                    brandFamily: Caml_option.valFromOption(brandFamily$1),
                                                    email: Caml_option.valFromOption(emailOpt),
                                                    voyage: voyage
                                                  }) : null,
                                            tmp
                                          ],
                                          sx: {
                                            display: "flex",
                                            height: "100%",
                                            flexDirection: "row"
                                          }
                                        }))
                              })
                        ]
                      })),
              fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
            });
}

var make = DashboardLayout;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
