// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");

var Graphql_error = /* @__PURE__ */Caml_exceptions.create("RelayEnv-Thick.Graphql_error");

async function fetchQuery(operation, variables, _cacheConfig, _uploadables) {
  var resp = await fetch(process.env.GRAPHILE_URL, {
        method: "POST",
        body: Caml_option.some(Belt_Option.getExn(JSON.stringify({
                      query: operation.text,
                      variables: variables
                    }))),
        headers: Caml_option.some(new Headers(Belt_Array.keep([
                      [
                        "content-type",
                        "application/json"
                      ],
                      [
                        "accept",
                        "application/json"
                      ]
                    ], (function (param) {
                        return param[1].trim() !== "";
                      }))))
      });
  if (resp.ok) {
    return await resp.json();
  }
  throw {
        RE_EXN_ID: Graphql_error,
        _1: "Request failed: " + resp.statusText,
        Error: new Error()
      };
}

var network = RelayRuntime.Network.create(fetchQuery);

var environment = RescriptRelay.Environment.make(network, RescriptRelay.Store.make(new RelayRuntime.RecordSource(), 10, undefined), undefined, undefined, undefined, undefined, undefined);

exports.Graphql_error = Graphql_error;
exports.fetchQuery = fetchQuery;
exports.network = network;
exports.environment = environment;
/* network Not a pure module */
