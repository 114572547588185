// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BrandPermProtected_query_graphql$Thick = require("../../__generated__/BrandPermProtected_query_graphql.bs.js");

var convertFragment = BrandPermProtected_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BrandPermProtected_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BrandPermProtected_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BrandPermProtected_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment_brandFamilyPermission_decode = BrandPermProtected_query_graphql$Thick.Utils.brandFamilyPermission_decode;

var QueryFragment_brandFamilyPermission_fromString = BrandPermProtected_query_graphql$Thick.Utils.brandFamilyPermission_fromString;

var QueryFragment_signonStatus_decode = BrandPermProtected_query_graphql$Thick.Utils.signonStatus_decode;

var QueryFragment_signonStatus_fromString = BrandPermProtected_query_graphql$Thick.Utils.signonStatus_fromString;

var QueryFragment = {
  brandFamilyPermission_decode: QueryFragment_brandFamilyPermission_decode,
  brandFamilyPermission_fromString: QueryFragment_brandFamilyPermission_fromString,
  signonStatus_decode: QueryFragment_signonStatus_decode,
  signonStatus_fromString: QueryFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BrandPermProtected(props) {
  var __redirect = props.redirect;
  var __isRoute = props.isRoute;
  var isRoute = __isRoute !== undefined ? __isRoute : false;
  var redirect = __redirect !== undefined ? __redirect : "/dashboard";
  var query = use(props.fragmentRefs);
  var userPerms = Belt_Set.fromArray(Belt_Array.keepMap(Belt_Option.getWithDefault(query.currentUserCanForBrand, []), (function (x) {
              if (x !== undefined) {
                return x;
              }
              
            })), Common$Thick.PermCmp);
  var hasPerm = Belt_Set.has(userPerms, props.neededPerm);
  React.useEffect((function () {
          var match = query.currentUserSignonStatus;
          if ((match === "INVITED" || match === "PENDING_2FA" || match === "CLEAR_JWT" || match === "ANONYMOUS" || match === "SIGNED_ON") && match === "SIGNED_ON") {
            if (isRoute && !hasPerm) {
              RescriptReactRouter.push(redirect);
            }
            
          }
          
        }), [
        query.currentUserSignonStatus,
        hasPerm,
        isRoute
      ]);
  if (hasPerm) {
    return props.children;
  } else {
    return null;
  }
}

var make = BrandPermProtected;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
