// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var JsxRuntime = require("react/jsx-runtime");
var Cancel = require("@mui/icons-material/Cancel").default;
var Search = require("@mui/icons-material/Search").default;

function SearchBar(props) {
  var showClear = props.showClear;
  return JsxRuntime.jsx(Joy$Util.Input.make, {
              type_: "text",
              placeholder: props.placeholder,
              value: props.value,
              onChange: props.onChange,
              startDecorator: Caml_option.some(JsxRuntime.jsx(Search, {})),
              endDecorator: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                        children: JsxRuntime.jsx(Cancel, {
                              color: "action",
                              sx: {
                                opacity: showClear ? 1 : 0,
                                pointerEvents: showClear ? "auto" : "none"
                              },
                              onClick: props.onClear
                            }),
                        arrow: true,
                        placement: "top",
                        size: "sm",
                        title: "Clear Search"
                      }))
            });
}

var make = SearchBar;

exports.make = make;
/* Joy-Util Not a pure module */
