// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var RevokeBfCustUserTransfer_RevokeBfcustUserTransferMutation_graphql$Thick = require("../../../__generated__/RevokeBfCustUserTransfer_RevokeBfcustUserTransferMutation_graphql.bs.js");

var convertVariables = RevokeBfCustUserTransfer_RevokeBfcustUserTransferMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = RevokeBfCustUserTransfer_RevokeBfcustUserTransferMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = RevokeBfCustUserTransfer_RevokeBfcustUserTransferMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, RevokeBfCustUserTransfer_RevokeBfcustUserTransferMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, RevokeBfCustUserTransfer_RevokeBfcustUserTransferMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var RevokeBfcustUserTransferMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(refresh, accountId, brandFamily, onClose) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    mutate({
          input: {
            _brandFamilySlug: brandFamily,
            _accountId: accountId
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (resp, err) {
            if (err !== undefined) {
              console.error(err);
              setError(function (param) {
                    return "An error occurred revoking transfer 1";
                  });
              return ;
            }
            var match = resp.revokeBfcustUserTransfer;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                refresh();
                setError(function (param) {
                      
                    });
                onClose();
                return ;
              }
              
            }
            setError(function (param) {
                  return "An error occurred revoking transfer 2";
                });
          }), (function (err) {
            console.log(err);
            setError(function (param) {
                  return "An error occurred in revoking transfer 3";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          error: match$1[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

exports.RevokeBfcustUserTransferMutation = RevokeBfcustUserTransferMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
