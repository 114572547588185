// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var ChangeCabinSegmentForm$Thick = require("./ChangeCabinSegmentForm.bs.js");
var ChangeCabinCapacityForm$Thick = require("./ChangeCabinCapacityForm.bs.js");

function BulkActionForm(props) {
  var refetch = props.refetch;
  var brandFamily = props.brandFamily;
  var voyage = props.voyage;
  var setSelectedCabins = props.setSelectedCabins;
  var actionType = props.actionType;
  var onClose = props.onClose;
  var selectedCabins = props.selectedCabins;
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var error = match[0];
  var tmp;
  if (actionType !== undefined) {
    switch (actionType) {
      case "change_cabin_capacity" :
          tmp = "Change Cabin Capacity";
          break;
      case "change_cabin_segment" :
          tmp = "Change Cabin Segment";
          break;
      default:
        tmp = "Select an action";
    }
  } else {
    tmp = "Select an action";
  }
  var tmp$1;
  if (actionType !== undefined) {
    switch (actionType) {
      case "change_cabin_capacity" :
          tmp$1 = JsxRuntime.jsx(ChangeCabinCapacityForm$Thick.make, {
                selectedCabins: selectedCabins,
                setSelectedCabins: setSelectedCabins,
                voyage: voyage,
                brandFamily: brandFamily,
                setError: setError,
                onClose: onClose,
                refetch: refetch
              });
          break;
      case "change_cabin_segment" :
          tmp$1 = JsxRuntime.jsx(ChangeCabinSegmentForm$Thick.make, {
                fragmentRefs: props.fragmentRefs,
                selectedCabins: selectedCabins,
                setSelectedCabins: setSelectedCabins,
                voyage: voyage,
                brandFamily: brandFamily,
                setError: setError,
                onClose: onClose,
                refetch: refetch
              });
          break;
      default:
        tmp$1 = null;
    }
  } else {
    tmp$1 = JsxRuntime.jsx(Typography, {
          children: "Select an action to perform on the selected cabins."
        });
  }
  return JsxRuntime.jsx(Dialog$Thick.make, {
              open_: props.isOpen,
              onClose: (function () {
                  onClose();
                  setError(function (param) {
                        
                      });
                }),
              title: tmp,
              children: JsxRuntime.jsx(DialogContent, {
                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                              spacing: 2,
                              children: [
                                tmp$1,
                                error !== undefined ? JsxRuntime.jsx(Typography, {
                                        color: "danger",
                                        children: Caml_option.some(error)
                                      }) : null
                              ]
                            }))
                  })
            });
}

var make = BulkActionForm;

exports.make = make;
/* react Not a pure module */
