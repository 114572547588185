// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Belt_HashMapString = require("rescript/lib/js/belt_HashMapString.js");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var AttachReplaceNewConnectedModalQuery_graphql$Thick = require("../../../__generated__/AttachReplaceNewConnectedModalQuery_graphql.bs.js");
var AttachReplaceNewConnectedModal_query_graphql$Thick = require("../../../__generated__/AttachReplaceNewConnectedModal_query_graphql.bs.js");
var AttachReplaceNewConnectedModal_version_graphql$Thick = require("../../../__generated__/AttachReplaceNewConnectedModal_version_graphql.bs.js");

var convertVariables = AttachReplaceNewConnectedModalQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = AttachReplaceNewConnectedModalQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = AttachReplaceNewConnectedModalQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, AttachReplaceNewConnectedModalQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, AttachReplaceNewConnectedModalQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(AttachReplaceNewConnectedModalQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(AttachReplaceNewConnectedModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(AttachReplaceNewConnectedModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(AttachReplaceNewConnectedModalQuery_graphql$Thick.node, convertVariables);

var Query_accountStatus_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_billingKind_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_bookingSessionPassengerType_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var Query_bookingSessionPassengerType_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var Query_brandfamRole_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.brandfamRole_decode;

var Query_brandfamRole_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.brandfamRole_fromString;

var Query_custSignonStatus_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_invitationType_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.invitationType_decode;

var Query_invitationType_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.invitationType_fromString;

var Query_lifecycleStatus_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_longitudinality_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_paxDataStatus_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_signonStatus_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.signonStatus_decode;

var Query_signonStatus_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.signonStatus_fromString;

var Query_subtotalCategory_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query_versionProvenance_decode = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.versionProvenance_decode;

var Query_versionProvenance_fromString = AttachReplaceNewConnectedModalQuery_graphql$Thick.Utils.versionProvenance_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  bookingSessionPassengerType_decode: Query_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: Query_bookingSessionPassengerType_fromString,
  brandfamRole_decode: Query_brandfamRole_decode,
  brandfamRole_fromString: Query_brandfamRole_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  invitationType_decode: Query_invitationType_decode,
  invitationType_fromString: Query_invitationType_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  signonStatus_decode: Query_signonStatus_decode,
  signonStatus_fromString: Query_signonStatus_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  versionProvenance_decode: Query_versionProvenance_decode,
  versionProvenance_fromString: Query_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertFragment = AttachReplaceNewConnectedModal_version_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(AttachReplaceNewConnectedModal_version_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AttachReplaceNewConnectedModal_version_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(AttachReplaceNewConnectedModal_version_graphql$Thick.node, convertFragment, fRef);
}

var VersionFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use$1,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = AttachReplaceNewConnectedModal_query_graphql$Thick.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(AttachReplaceNewConnectedModal_query_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AttachReplaceNewConnectedModal_query_graphql$Thick.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(AttachReplaceNewConnectedModal_query_graphql$Thick.node, convertFragment$1, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$2,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function AttachReplaceNewConnectedModal(props) {
  var name = props.name;
  var __action = props.action;
  var shouldAttachToIdx = props.shouldAttachToIdx;
  var brandFamily = props.brandFamily;
  var onClose = props.onClose;
  var action = __action !== undefined ? __action : "attachNewConnected";
  var fragmentData = use$1(props.fragmentRefs);
  var countryData = use$2(props.countryFragmentRefs);
  var match = fragmentData.billingContact;
  var defaultCustodian = match !== undefined ? ({
        fullName: match.fullName,
        accountId: match.accountId,
        email: match.email
      }) : undefined;
  var match$1 = React.useState(function () {
        return true;
      });
  var setJustOpened = match$1[1];
  var justOpened = match$1[0];
  var match$2 = countryData.countries;
  var countryOptions = match$2 !== undefined ? Belt_Array.keepMap(match$2.nodes, (function (node) {
            var alpha3 = node.alpha3;
            var englishName = node.englishName;
            return {
                    alpha3: alpha3,
                    englishName: englishName
                  };
          })) : [];
  var match$3 = React.useState(function () {
        if (defaultCustodian !== undefined) {
          return [defaultCustodian];
        } else {
          return [];
        }
      });
  var setSelectCustodianOptions = match$3[1];
  var selectCustodianOptions = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setAutocompleteOpen = match$4[1];
  var match$5 = React.useState(function () {
        if (defaultCustodian !== undefined) {
          return Caml_option.some(defaultCustodian.accountId);
        }
        
      });
  var setCustodianAcct = match$5[1];
  var custodianAcct = match$5[0];
  var match$6 = React.useState(function () {
        
      });
  var setCitizenship = match$6[1];
  var citizenship = match$6[0];
  var match$7 = React.useState(function () {
        
      });
  var setGender = match$7[1];
  var gender = match$7[0];
  var selectMap = React.useMemo((function () {
          return Belt_HashMapString.fromArray(Belt_Array.map(Belt_Array.keepMap(selectCustodianOptions, (function (val) {
                                return val;
                              })), (function (val) {
                            return [
                                    CS_NonemptyStrings$Util.AccountId.toString(val.accountId),
                                    {
                                      fullName: val.fullName,
                                      accountId: val.accountId,
                                      email: val.email
                                    }
                                  ];
                          })));
        }), [selectCustodianOptions]);
  var displayCustodian = function (c) {
    if (c === undefined) {
      return "No custodian selected";
    }
    var email = c.email;
    var fullName = c.fullName;
    if (email !== undefined) {
      return fullName + " (" + CS_Emails$Util.Email.toPrimitive(Caml_option.valFromOption(email)) + ")";
    } else {
      return fullName;
    }
  };
  var custodianName = React.useMemo((function () {
          if (custodianAcct === undefined) {
            return ;
          }
          var custodian = Belt_HashMapString.get(selectMap, CS_NonemptyStrings$Util.AccountId.toString(Caml_option.valFromOption(custodianAcct)));
          if (custodian !== undefined) {
            return displayCustodian(custodian);
          }
          
        }), [
        custodianAcct,
        selectMap
      ]);
  var match$8 = React.useState(function () {
        return "";
      });
  var setDocFirstName = match$8[1];
  var docFirstName = match$8[0];
  var match$9 = React.useState(function () {
        return "";
      });
  var setDocLastName = match$9[1];
  var docLastName = match$9[0];
  var match$10 = React.useState(function () {
        return "";
      });
  var setPrefFirstName = match$10[1];
  var prefFirstName = match$10[0];
  var match$11 = React.useState(function () {
        return "";
      });
  var setPrefLastName = match$11[1];
  var prefLastName = match$11[0];
  var match$12 = React.useState(function () {
        return "";
      });
  var setHalMarinerNumber = match$12[1];
  var halMarinerNumber = match$12[0];
  var match$13 = React.useState(function () {
        return "";
      });
  var setDob = match$13[1];
  var dob = match$13[0];
  var match$14 = React.useState(function () {
        return false;
      });
  var setDateFocused = match$14[1];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match$15 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, onClose);
  var clearPreviewFields = match$15.clearPreviewFields;
  var setApplyDiff = match$15.setApplyDiff;
  var setError = match$15.setError;
  var setLoading = match$15.setLoading;
  var generatePreview = match$15.generatePreview;
  var clearAll = function () {
    clearPreviewFields();
    setCustodianAcct(function (param) {
          
        });
    setSelectCustodianOptions(function (param) {
          if (defaultCustodian !== undefined) {
            return [defaultCustodian];
          } else {
            return [];
          }
        });
    setDocFirstName(function (param) {
          return "";
        });
    setDocLastName(function (param) {
          return "";
        });
    setPrefFirstName(function (param) {
          return "";
        });
    setPrefLastName(function (param) {
          return "";
        });
    setHalMarinerNumber(function (param) {
          return "";
        });
    setDob(function (param) {
          return "";
        });
    setCitizenship(function (param) {
          
        });
    setGender(function (param) {
          
        });
    setDateFocused(function (param) {
          return false;
        });
    setAutocompleteOpen(function (param) {
          return false;
        });
    setLoading(function (param) {
          return false;
        });
    setJustOpened(function (param) {
          return true;
        });
  };
  var createFilter = function (s) {
    var s$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(s);
    if (s$1.TAG !== "Ok") {
      return {
              fullName: {
                includesInsensitive: s
              },
              accountStatus: {
                equalTo: "CLAIMED"
              }
            };
    }
    var s$2 = s$1._0;
    return {
            accountStatus: {
              equalTo: "CLAIMED"
            },
            or: [
              {
                fullName: {
                  includesInsensitive: CS_NonemptyStrings$Util.NonEmptyString.toString(s$2)
                }
              },
              {
                emailString: {
                  includesInsensitive: Caml_option.some(s$2)
                }
              }
            ]
          };
  };
  var onSubmit = function (e) {
    e.preventDefault();
    var match = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docFirstName);
    var match$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docLastName);
    var match$2 = CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(dob);
    if (custodianAcct !== undefined && match.TAG === "Ok" && match$1.TAG === "Ok" && match$2.TAG === "Ok" && citizenship !== undefined && gender !== undefined) {
      var halMarinerNumber$1 = CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive(halMarinerNumber);
      var tmp;
      tmp = halMarinerNumber$1.TAG === "Ok" ? Caml_option.some(halMarinerNumber$1._0) : undefined;
      var name = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefFirstName);
      var tmp$1;
      tmp$1 = name.TAG === "Ok" ? Caml_option.some(name._0) : undefined;
      var name$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(prefLastName);
      var tmp$2;
      tmp$2 = name$1.TAG === "Ok" ? Caml_option.some(name$1._0) : undefined;
      var newConnected_custodianAccountId = Caml_option.valFromOption(custodianAcct);
      var newConnected_docFirstName = match._0;
      var newConnected_docLastName = match$1._0;
      var newConnected_docBirthdate = match$2._0;
      var newConnected_docCitizenship = Caml_option.valFromOption(citizenship);
      var newConnected = {
        custodianAccountId: newConnected_custodianAccountId,
        docFirstName: newConnected_docFirstName,
        docLastName: newConnected_docLastName,
        docGender: gender,
        docBirthdate: newConnected_docBirthdate,
        docCitizenship: newConnected_docCitizenship,
        halMarinerNumber: tmp,
        preferredFirstName: tmp$1,
        preferredLastName: tmp$2
      };
      var applyDiff = action === "attachNewConnected" ? BookingDiff$Thick.Alter.attachNewConnectedPax(diffStruct, shouldAttachToIdx, newConnected) : BookingDiff$Thick.Alter.replaceWithNewConnectedPax(diffStruct, shouldAttachToIdx, newConnected);
      setApplyDiff(function (param) {
            return applyDiff;
          });
      generatePreview(applyDiff);
      return ;
    }
    setError(function (param) {
          return "Invalid input. Please try again.";
        });
  };
  var match$16 = action === "attachNewConnected" ? [
      "Attach a New Connected Passenger",
      "Please select the custodian of the new connected account and enter the passenger information below.",
      "Attach a New Connected Passenger",
      "You are proposing to attach a new connected account to this berth."
    ] : [
      "Replace with a New Connected Passenger",
      "Please select the custodian of the new connected account and enter the passenger information below for the person you'd like to replace " + Belt_Option.getWithDefault(name, "this passenger") + ".",
      "Replace with a New Connected Passenger",
      "You are proposing to create a new connected account \"" + docFirstName + " " + docLastName + "\" managed by " + Belt_Option.getWithDefault(custodianName, "another user") + " to replace " + Belt_Option.getWithDefault(name, "this passenger") + "."
    ];
  var m = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docFirstName);
  var tmp;
  tmp = m.TAG === "Ok" ? null : m._0;
  var m$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docLastName);
  var tmp$1;
  tmp$1 = m$1.TAG === "Ok" ? null : m$1._0;
  var match$17 = dob === "";
  var m$2 = CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(dob);
  var tmp$2;
  tmp$2 = m$2.TAG === "Ok" ? null : m$2._0;
  var match$18 = Belt_Option.isSome(custodianAcct);
  var match$19 = Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docFirstName));
  var match$20 = Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docLastName));
  var match$21 = Belt_Result.isOk(CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(dob));
  var match$22 = Belt_Option.isSome(citizenship);
  var match$23 = Belt_Option.isSome(gender);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.open_,
                      onClose: onClose,
                      title: match$16[0],
                      children: [
                        JsxRuntime.jsxs(DialogContent, {
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "title-md",
                                      sx: {
                                        marginBottom: "1rem"
                                      },
                                      children: Caml_option.some(match$16[1])
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 1,
                                      children: [
                                        JsxRuntime.jsx(Typography, {
                                              component: "h3",
                                              children: "Custodian"
                                            }),
                                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                                              children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                        children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                  children: [
                                                                    JsxRuntime.jsx(FormLabel, {
                                                                          children: "Select custodian"
                                                                        }),
                                                                    JsxRuntime.jsx(Joy$Util.JoyAuto.Single.make, {
                                                                          options: selectCustodianOptions,
                                                                          defaultValue: Caml_option.some(defaultCustodian),
                                                                          getOptionLabel: (function (option) {
                                                                              return displayCustodian(option);
                                                                            }),
                                                                          isOptionEqualToValue: (function (option, value) {
                                                                              if (option !== undefined && value !== undefined) {
                                                                                return Caml_obj.equal(option.accountId, value.accountId);
                                                                              } else {
                                                                                return false;
                                                                              }
                                                                            }),
                                                                          loading: match$15.loading,
                                                                          noOptionsText: "Type to search for a custodian",
                                                                          onChange: (function (param, value) {
                                                                              if (value !== undefined) {
                                                                                var match = Caml_option.valFromOption(value);
                                                                                if (match !== undefined) {
                                                                                  var accountId = match.accountId;
                                                                                  setCustodianAcct(function (param) {
                                                                                        return Caml_option.some(accountId);
                                                                                      });
                                                                                  return ;
                                                                                }
                                                                                
                                                                              }
                                                                              setCustodianAcct(function (param) {
                                                                                    
                                                                                  });
                                                                            }),
                                                                          onClose: (function () {
                                                                              setAutocompleteOpen(function (param) {
                                                                                    return false;
                                                                                  });
                                                                            }),
                                                                          onInputChange: (function (param, v, param$1) {
                                                                              if (v === "") {
                                                                                setSelectCustodianOptions(function (param) {
                                                                                      return [];
                                                                                    });
                                                                                setAutocompleteOpen(function (param) {
                                                                                      return false;
                                                                                    });
                                                                                setError(function (param) {
                                                                                      
                                                                                    });
                                                                                setLoading(function (param) {
                                                                                      return false;
                                                                                    });
                                                                                return ;
                                                                              } else if (defaultCustodian !== undefined && defaultCustodian.fullName === v) {
                                                                                return ;
                                                                              } else {
                                                                                setLoading(function (param) {
                                                                                      return true;
                                                                                    });
                                                                                setAutocompleteOpen(function (param) {
                                                                                      return false;
                                                                                    });
                                                                                setError(function (param) {
                                                                                      
                                                                                    });
                                                                                $$fetch(RelayEnv$Thick.environment, {
                                                                                      brandFamily: brandFamily,
                                                                                      filter: createFilter(v)
                                                                                    }, (function (res) {
                                                                                        if (res.TAG === "Ok") {
                                                                                          var match = res._0.bfcustUsers;
                                                                                          if (match !== undefined) {
                                                                                            var results = Belt_Array.keepMap(match.nodes, (function (node) {
                                                                                                    var accountId = node.accountId;
                                                                                                    var fullName = node.fullName;
                                                                                                    var email = node.email;
                                                                                                    return Caml_option.some({
                                                                                                                fullName: fullName,
                                                                                                                accountId: accountId,
                                                                                                                email: email
                                                                                                              });
                                                                                                  }));
                                                                                            setSelectCustodianOptions(function (param) {
                                                                                                  return results;
                                                                                                });
                                                                                            if (justOpened) {
                                                                                              setJustOpened(function (param) {
                                                                                                    return false;
                                                                                                  });
                                                                                            } else {
                                                                                              setAutocompleteOpen(function (param) {
                                                                                                    return true;
                                                                                                  });
                                                                                            }
                                                                                            setLoading(function (param) {
                                                                                                  return false;
                                                                                                });
                                                                                            return ;
                                                                                          }
                                                                                          setError(function (param) {
                                                                                                return "No users found.";
                                                                                              });
                                                                                          setLoading(function (param) {
                                                                                                return false;
                                                                                              });
                                                                                          return ;
                                                                                        }
                                                                                        console.error(res._0);
                                                                                        setError(function (param) {
                                                                                              return "Something went wrong. Please try again.";
                                                                                            });
                                                                                        setLoading(function (param) {
                                                                                              return false;
                                                                                            });
                                                                                      }), undefined, undefined);
                                                                                return ;
                                                                              }
                                                                            }),
                                                                          onOpen: (function () {
                                                                              setAutocompleteOpen(function (param) {
                                                                                    return true;
                                                                                  });
                                                                            }),
                                                                          open: match$4[0],
                                                                          placeholder: "Search for a custodian"
                                                                        }),
                                                                    JsxRuntime.jsx(FormHelperText, {
                                                                          children: "The account to whom the new connected account will be linked"
                                                                        })
                                                                  ]
                                                                })),
                                                        xs: 6
                                                      })),
                                              container: true,
                                              rowSpacing: 1,
                                              columnSpacing: 1
                                            }),
                                        JsxRuntime.jsx(Typography, {
                                              component: "h3",
                                              children: "Passenger"
                                            }),
                                        JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                              children: [
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                error: Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docFirstName)),
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "Legal first name"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                        type_: "text",
                                                                        placeholder: "First name",
                                                                        required: true,
                                                                        value: docFirstName,
                                                                        onChange: (function (e) {
                                                                            var val = e.currentTarget.value;
                                                                            setDocFirstName(function (param) {
                                                                                  return val;
                                                                                });
                                                                          })
                                                                      }),
                                                                  JsxRuntime.jsx(FormHelperText, {
                                                                        children: Caml_option.some(tmp)
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 6
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                error: Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(docLastName)),
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "Legal last name"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                        type_: "text",
                                                                        placeholder: "Last name",
                                                                        required: true,
                                                                        value: docLastName,
                                                                        onChange: (function (e) {
                                                                            var val = e.currentTarget.value;
                                                                            setDocLastName(function (param) {
                                                                                  return val;
                                                                                });
                                                                          })
                                                                      }),
                                                                  JsxRuntime.jsx(FormHelperText, {
                                                                        children: Caml_option.some(tmp$1)
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 6
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "Preferred first name"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                        type_: "text",
                                                                        placeholder: "Preferred first name (optional)",
                                                                        required: false,
                                                                        value: prefFirstName,
                                                                        onChange: (function (e) {
                                                                            var val = e.currentTarget.value;
                                                                            setPrefFirstName(function (param) {
                                                                                  return val;
                                                                                });
                                                                          })
                                                                      }),
                                                                  JsxRuntime.jsx(FormHelperText, {
                                                                        children: "The passenger's preferred first name"
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 6
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "Preferred last name"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                        type_: "text",
                                                                        placeholder: "Preferred last name (optional)",
                                                                        required: false,
                                                                        value: prefLastName,
                                                                        onChange: (function (e) {
                                                                            var val = e.currentTarget.value;
                                                                            setPrefLastName(function (param) {
                                                                                  return val;
                                                                                });
                                                                          })
                                                                      }),
                                                                  JsxRuntime.jsx(FormHelperText, {
                                                                        children: "The passenger's preferred last name"
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 6
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                error: Belt_Option.isNone(citizenship),
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "Citizenship"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                        children: Caml_option.some(Belt_Array.map(countryOptions, (function (param) {
                                                                                    var alpha3 = param.alpha3;
                                                                                    return JsxRuntime.jsx($$Option, {
                                                                                                value: CS_ISO3166_1$Util.Alpha3.toString(alpha3),
                                                                                                children: Caml_option.some(param.englishName)
                                                                                              }, CS_ISO3166_1$Util.Alpha3.toString(alpha3));
                                                                                  }))),
                                                                        onChange: (function (param, v) {
                                                                            setCitizenship(function (param) {
                                                                                  return Belt_Option.getWithDefault(v, undefined);
                                                                                });
                                                                          }),
                                                                        placeholder: "Citizenship",
                                                                        required: true,
                                                                        value: citizenship
                                                                      }),
                                                                  JsxRuntime.jsx(FormHelperText, {
                                                                        children: "Citizenship on travel documents"
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 4
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                error: Belt_Option.isNone(gender),
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "Gender"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                        children: Caml_option.some(Belt_Array.map([
                                                                                  "M",
                                                                                  "F",
                                                                                  "X"
                                                                                ], (function (g) {
                                                                                    return JsxRuntime.jsx($$Option, {
                                                                                                value: g,
                                                                                                children: Caml_option.some(Common$Thick.DocGender.toString(g))
                                                                                              }, g);
                                                                                  }))),
                                                                        onChange: (function (param, v) {
                                                                            setGender(function (param) {
                                                                                  return Belt_Option.getWithDefault(v, undefined);
                                                                                });
                                                                          }),
                                                                        placeholder: "Gender",
                                                                        required: true,
                                                                        value: gender
                                                                      }),
                                                                  JsxRuntime.jsx(FormHelperText, {
                                                                        children: "Gender on travel documents"
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 4
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                error: Belt_Result.isError(CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(dob)),
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "Birthdate"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                        type_: match$14[0] || !match$17 ? "date" : "text",
                                                                        placeholder: "Birthdate",
                                                                        error: Belt_Result.isError(CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(dob)),
                                                                        value: dob,
                                                                        onChange: (function (e) {
                                                                            var val = e.currentTarget.value;
                                                                            setDob(function (param) {
                                                                                  return val;
                                                                                });
                                                                          }),
                                                                        onFocus: (function (param) {
                                                                            setDateFocused(function (param) {
                                                                                  return true;
                                                                                });
                                                                          }),
                                                                        onBlur: (function (param) {
                                                                            setDateFocused(function (param) {
                                                                                  return false;
                                                                                });
                                                                          })
                                                                      }),
                                                                  JsxRuntime.jsx(FormHelperText, {
                                                                        children: Caml_option.some(tmp$2)
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 4
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                                                children: [
                                                                  JsxRuntime.jsx(FormLabel, {
                                                                        children: "HAL Mariner Number"
                                                                      }),
                                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                        type_: "text",
                                                                        placeholder: "HAL Mariner Number (optional)",
                                                                        required: false,
                                                                        value: halMarinerNumber,
                                                                        onChange: (function (e) {
                                                                            var val = e.currentTarget.value;
                                                                            setHalMarinerNumber(function (param) {
                                                                                  return val;
                                                                                });
                                                                          })
                                                                      }),
                                                                  JsxRuntime.jsx(FormHelperText, {
                                                                        children: "The passenger's HAL Mariner Number"
                                                                      })
                                                                ]
                                                              })),
                                                      xs: 6
                                                    })
                                              ],
                                              container: true,
                                              rowSpacing: 2,
                                              columnSpacing: 1
                                            })
                                      ]
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        component: "form",
                                        onSubmit: onSubmit,
                                        children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                  type: "submit",
                                                  disabled: match$18 && match$19 && match$20 && match$21 && match$22 && match$23 ? false : true,
                                                  children: "Preview invitation"
                                                }))
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$15.billPayNew,
                      applyDiff: match$15.applyDiff,
                      title: match$16[2],
                      description: match$16[3],
                      onClose: (function () {
                          clearAll();
                        }),
                      open_: match$15.previewModalOpen,
                      versionUrl: props.versionUrl,
                      error: match$15.error
                    })
              ]
            });
}

var make = AttachReplaceNewConnectedModal;

exports.Query = Query;
exports.VersionFragment = VersionFragment;
exports.QueryFragment = QueryFragment;
exports.make = make;
/* use Not a pure module */
