// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var SearchBar$Thick = require("../common/molecules/SearchBar.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var Add = require("@mui/icons-material/Add").default;
var ChevronLeft = require("@mui/icons-material/ChevronLeft").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;

function CustTableToolbar(props) {
  var setDialogOpen = props.setDialogOpen;
  var setAccountSelect = props.setAccountSelect;
  var handleAccountSelect = props.handleAccountSelect;
  var clearSearch = props.clearSearch;
  var search = props.search;
  var __searchBar = props.searchBar;
  var onLoadPrev = props.onLoadPrev;
  var onLoadNext = props.onLoadNext;
  var offset = props.offset;
  var totalCount = props.totalCount;
  var searchBar = __searchBar !== undefined ? __searchBar : false;
  var match = React.useState(function () {
        return "";
      });
  var setSearchVal = match[1];
  var searchVal = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setFilterApplied = match$1[1];
  var onSubmit = function (e) {
    e.preventDefault();
    search(searchVal);
    setFilterApplied(function (param) {
          return true;
        });
  };
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: 1
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        children: [
                          searchBar ? JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  component: "form",
                                  spacing: 1,
                                  onSubmit: onSubmit,
                                  children: [
                                    JsxRuntime.jsx(SearchBar$Thick.make, {
                                          value: searchVal,
                                          onChange: (function (e) {
                                              var val = e.currentTarget.value;
                                              setSearchVal(function (param) {
                                                    return val;
                                                  });
                                              if (val === "") {
                                                setFilterApplied(function (param) {
                                                      return false;
                                                    });
                                                return clearSearch();
                                              }
                                              
                                            }),
                                          placeholder: "Search users",
                                          onClear: (function (param) {
                                              setSearchVal(function (param) {
                                                    return "";
                                                  });
                                              setFilterApplied(function (param) {
                                                    return false;
                                                  });
                                              clearSearch();
                                            }),
                                          showClear: searchVal !== "" || match$1[0]
                                        }),
                                    JsxRuntime.jsx(Button, {
                                          type: "submit",
                                          variant: "outlined",
                                          disabled: searchVal === "",
                                          children: "Search"
                                        })
                                  ]
                                }) : JsxRuntime.jsx(Button, {
                                  onClick: (function (param) {
                                      setDialogOpen(function (param) {
                                            return true;
                                          });
                                    }),
                                  startDecorator: Caml_option.some(JsxRuntime.jsx(Add, {})),
                                  "aria-label": "Create New Account",
                                  variant: "outlined",
                                  size: "sm",
                                  children: "Create New Account"
                                }),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                spacing: 1,
                                children: [
                                  searchBar ? JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                                          children: [
                                            JsxRuntime.jsx($$Option, {
                                                  value: "all",
                                                  children: "All Accounts"
                                                }),
                                            JsxRuntime.jsx($$Option, {
                                                  value: "invited",
                                                  children: "Invited"
                                                }),
                                            JsxRuntime.jsx($$Option, {
                                                  value: "claimed",
                                                  children: "Claimed"
                                                }),
                                            JsxRuntime.jsx($$Option, {
                                                  value: "transferring",
                                                  children: "Transferring"
                                                }),
                                            JsxRuntime.jsx($$Option, {
                                                  value: "unclaimed",
                                                  children: "Unclaimed"
                                                })
                                          ],
                                          onChange: (function (param, val) {
                                              if (val === undefined) {
                                                return ;
                                              }
                                              var val$1 = Caml_option.valFromOption(val);
                                              if (val$1 !== undefined) {
                                                setAccountSelect(function (param) {
                                                      return val$1;
                                                    });
                                                return handleAccountSelect(val$1);
                                              }
                                              
                                            }),
                                          sx: {
                                            width: "10rem"
                                          },
                                          value: props.accountSelect
                                        }) : JsxRuntime.jsx("div", {}),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        children: [
                                          JsxRuntime.jsx(IconButton, {
                                                onClick: (function (param) {
                                                    onLoadPrev();
                                                  }),
                                                variant: "plain",
                                                disabled: offset === 0,
                                                children: Caml_option.some(JsxRuntime.jsx(ChevronLeft, {}))
                                              }),
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                children: Caml_option.some(props.stringFromOffset(offset, totalCount))
                                              }),
                                          JsxRuntime.jsx(IconButton, {
                                                onClick: (function (param) {
                                                    onLoadNext();
                                                  }),
                                                variant: "plain",
                                                disabled: (offset + 50 | 0) >= totalCount,
                                                children: Caml_option.some(JsxRuntime.jsx(ChevronRight, {}))
                                              })
                                        ]
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var make = CustTableToolbar;

exports.make = make;
/* react Not a pure module */
