// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("date-fns");

var Impl = {};

function parseISO(str) {
  var parsed = DateFns.parseISO(str);
  if (DateFns.isValid(parsed)) {
    return {
            TAG: "Ok",
            _0: parsed
          };
  } else {
    return {
            TAG: "Error",
            _0: undefined
          };
  }
}

function daysUntil(date) {
  var now = new Date();
  return DateFns.differenceInCalendarDays(date, now);
}

function hasExpired(date) {
  var now = new Date();
  return DateFns.isBefore(date, now);
}

function padSeconds(seconds) {
  if (seconds < 10) {
    return "0" + String(seconds);
  } else {
    return String(seconds);
  }
}

function getRemainingTime(expiry) {
  var now = new Date();
  var duration = DateFns.intervalToDuration({
        start: now,
        end: expiry
      });
  return String(duration.minutes) + ":" + padSeconds(duration.seconds);
}

var $$Date$1;

exports.$$Date = $$Date$1;
exports.Impl = Impl;
exports.parseISO = parseISO;
exports.daysUntil = daysUntil;
exports.hasExpired = hasExpired;
exports.padSeconds = padSeconds;
exports.getRemainingTime = getRemainingTime;
/* date-fns Not a pure module */
