// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDMovePaymentModalQuery_graphql$Thick = require("../../../__generated__/BDMovePaymentModalQuery_graphql.bs.js");

var convertVariables = BDMovePaymentModalQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BDMovePaymentModalQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BDMovePaymentModalQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, BDMovePaymentModalQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BDMovePaymentModalQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BDMovePaymentModalQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BDMovePaymentModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BDMovePaymentModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BDMovePaymentModalQuery_graphql$Thick.node, convertVariables);

var Query_accountStatus_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_billingKind_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_bookingSessionPassengerType_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var Query_bookingSessionPassengerType_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var Query_brandfamRole_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.brandfamRole_decode;

var Query_brandfamRole_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.brandfamRole_fromString;

var Query_custSignonStatus_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_invitationType_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.invitationType_decode;

var Query_invitationType_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.invitationType_fromString;

var Query_lifecycleStatus_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_longitudinality_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_paxDataStatus_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_signonStatus_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.signonStatus_decode;

var Query_signonStatus_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.signonStatus_fromString;

var Query_subtotalCategory_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query_versionProvenance_decode = BDMovePaymentModalQuery_graphql$Thick.Utils.versionProvenance_decode;

var Query_versionProvenance_fromString = BDMovePaymentModalQuery_graphql$Thick.Utils.versionProvenance_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  bookingSessionPassengerType_decode: Query_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: Query_bookingSessionPassengerType_fromString,
  brandfamRole_decode: Query_brandfamRole_decode,
  brandfamRole_fromString: Query_brandfamRole_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  invitationType_decode: Query_invitationType_decode,
  invitationType_fromString: Query_invitationType_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  signonStatus_decode: Query_signonStatus_decode,
  signonStatus_fromString: Query_signonStatus_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  versionProvenance_decode: Query_versionProvenance_decode,
  versionProvenance_fromString: Query_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BDMovePaymentModal(props) {
  var brandFamily = props.brandFamily;
  var pmtLine = props.pmtLine;
  var pmtToMove = props.pmtToMove;
  var onClose = props.onClose;
  var match = React.useState(function () {
        return [];
      });
  var setSelectOptions = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setSelectedBooking = match$1[1];
  var selectedBooking = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setAutocompleteOpen = match$2[1];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match$3 = React.useState(function () {
        return false;
      });
  var setSearchLoading = match$3[1];
  var match$4 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, onClose);
  var clearPreviewFields = match$4.clearPreviewFields;
  var setApplyDiff = match$4.setApplyDiff;
  var setError = match$4.setError;
  var error = match$4.error;
  var generatePreview = match$4.generatePreview;
  var createFilter = function (s) {
    var s$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(s);
    if (s$1.TAG === "Ok") {
      return {
              bookingIdString: {
                includesInsensitive: Caml_option.some(s$1._0)
              }
            };
    } else {
      return {
              bookingIdString: {
                notIn: []
              }
            };
    }
  };
  var clearAll = function () {
    clearPreviewFields();
    setAutocompleteOpen(function (param) {
          return false;
        });
    setSelectedBooking(function (param) {
          
        });
    setSelectOptions(function (param) {
          return [];
        });
    setError(function (param) {
          
        });
  };
  var cancel = function () {
    clearAll();
    onClose();
  };
  var search = function (val) {
    setSearchLoading(function (param) {
          return true;
        });
    setAutocompleteOpen(function (param) {
          return false;
        });
    setError(function (param) {
          
        });
    $$fetch(RelayEnv$Thick.environment, {
          brandFamily: brandFamily,
          filter: createFilter(val)
        }, (function (res) {
            if (res.TAG === "Ok") {
              var match = res._0.bookings;
              if (match !== undefined) {
                var results = Belt_Array.keepMap(match.nodes, (function (node) {
                        var bookingId = node.bookingId;
                        var node$1 = Belt_Array.get(node.bookingVersion.nodes, 0);
                        if (node$1 === undefined) {
                          return ;
                        }
                        var bookingVersionNumber = node$1.bookingVersionNumber;
                        return {
                                bookingId: bookingId,
                                bookingVersionNumber: bookingVersionNumber
                              };
                      }));
                setSelectOptions(function (param) {
                      return results;
                    });
                setAutocompleteOpen(function (param) {
                      return true;
                    });
                setSearchLoading(function (param) {
                      return false;
                    });
                return ;
              }
              setError(function (param) {
                    return "No bookings found.";
                  });
              setSearchLoading(function (param) {
                    return false;
                  });
              return ;
            }
            console.error(res._0);
            setError(function (param) {
                  return "Something went wrong. Please try again.";
                });
            setSearchLoading(function (param) {
                  return false;
                });
          }), undefined, undefined);
  };
  var onSubmit = function (e) {
    e.preventDefault();
    if (selectedBooking !== undefined && pmtToMove !== undefined && pmtLine !== undefined) {
      var toBookingVersionNumber = selectedBooking.bookingVersionNumber;
      var toBookingId = selectedBooking.bookingId;
      var applyDiff = Belt_Array.reduce(Belt_Array.keepMap(pmtLine.pmtGroup, (function (v) {
                  return v;
                })), diffStruct, (function (diffStruct, pmtGroupItm) {
              var paymentMove = {
                toBookingId: toBookingId,
                toBookingVersionNumber: toBookingVersionNumber,
                publicPmtId: pmtGroupItm
              };
              return BookingDiff$Thick.Alter.movePayment(diffStruct, paymentMove);
            }));
      setApplyDiff(function (param) {
            return applyDiff;
          });
      generatePreview(applyDiff);
      return ;
    }
    setError(function (param) {
          return "No booking or payment ID selected";
        });
  };
  var previewModalDescription;
  if (pmtToMove !== undefined) {
    if (selectedBooking !== undefined) {
      if (pmtLine !== undefined) {
        var publicPmtId = Caml_option.valFromOption(pmtToMove);
        var bookingId = selectedBooking.bookingId;
        if (pmtLine.pmtGroup.length === 1) {
          previewModalDescription = "You are electing to move payment " + CS_NonemptyStrings$Util.PmtTransactionId.toString(publicPmtId) + " to booking " + CS_NonemptyStrings$Util.BookingId.toString(bookingId) + ".";
        } else if (pmtLine.pmtGroup.length > 1) {
          previewModalDescription = "You are electing to move payment " + CS_NonemptyStrings$Util.PmtTransactionId.toString(publicPmtId) + " to booking " + CS_NonemptyStrings$Util.BookingId.toString(bookingId) + ". There are " + String(pmtLine.pmtGroup.length - 1 | 0) + " linked payments/refunds that will also be moved.";
        } else {
          throw {
                RE_EXN_ID: "Match_failure",
                _1: [
                  "BDMovePaymentModal.res",
                  166,
                  32
                ],
                Error: new Error()
              };
        }
      } else {
        previewModalDescription = "No payment line.";
      }
    } else {
      previewModalDescription = "No booking selected.";
    }
  } else {
    previewModalDescription = "No payment selected.";
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.open_,
                      onClose: cancel,
                      title: "Move payment",
                      children: [
                        JsxRuntime.jsxs(DialogContent, {
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "title-md",
                                      children: "Search by booking ID to move your selected payment."
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      spacing: 2,
                                      sx: {
                                        py: 1
                                      },
                                      children: [
                                        pmtToMove !== undefined ? JsxRuntime.jsxs(FormControl, {
                                                error: Belt_Option.isNone(selectedBooking),
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Booking"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.JoyAuto.Single.make, {
                                                        options: match[0],
                                                        getOptionLabel: (function (option) {
                                                            return CS_NonemptyStrings$Util.BookingId.toString(option.bookingId);
                                                          }),
                                                        isOptionEqualToValue: (function (option, value) {
                                                            return option.bookingId === value.bookingId;
                                                          }),
                                                        loading: match$3[0],
                                                        onChange: (function (param, value) {
                                                            if (value !== undefined) {
                                                              setSelectedBooking(function (param) {
                                                                    return value;
                                                                  });
                                                              return ;
                                                            }
                                                            
                                                          }),
                                                        onClose: (function () {
                                                            setAutocompleteOpen(function (param) {
                                                                  return false;
                                                                });
                                                          }),
                                                        onInputChange: (function (param, v, param$1) {
                                                            if (v === "") {
                                                              setSelectOptions(function (param) {
                                                                    return [];
                                                                  });
                                                              setAutocompleteOpen(function (param) {
                                                                    return false;
                                                                  });
                                                              setError(function (param) {
                                                                    
                                                                  });
                                                              setSearchLoading(function (param) {
                                                                    return false;
                                                                  });
                                                            } else {
                                                              search(v);
                                                            }
                                                          }),
                                                        onOpen: (function () {
                                                            setAutocompleteOpen(function (param) {
                                                                  return true;
                                                                });
                                                          }),
                                                        open: match$2[0],
                                                        placeholder: "Search for a booking"
                                                      })
                                                ]
                                              }) : JsxRuntime.jsx(Typography, {
                                                color: "danger",
                                                children: "No payment selected."
                                              }),
                                        error !== undefined ? JsxRuntime.jsx(Typography, {
                                                color: "danger",
                                                children: Caml_option.some(error)
                                              }) : null
                                      ]
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(Divider, {}),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: {
                                          xs: "column-reverse",
                                          sm: "row"
                                        },
                                        component: "form",
                                        justifyContent: "space-between",
                                        spacing: 1,
                                        sx: {
                                          width: "100%"
                                        },
                                        onSubmit: onSubmit,
                                        children: [
                                          JsxRuntime.jsx(Button, {
                                                onClick: cancel,
                                                variant: "outlined",
                                                color: "neutral",
                                                children: "Cancel"
                                              }),
                                          JsxRuntime.jsx(Button, {
                                                loading: match$4.loading,
                                                type: "submit",
                                                disabled: Belt_Option.isNone(pmtToMove) || Belt_Option.isNone(selectedBooking),
                                                children: "Add to draft"
                                              })
                                        ]
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$4.billPayNew,
                      applyDiff: match$4.applyDiff,
                      title: "Move payment",
                      description: previewModalDescription,
                      onClose: (function () {
                          clearAll();
                        }),
                      open_: match$4.previewModalOpen,
                      versionUrl: props.versionUrl,
                      error: error
                    })
              ]
            });
}

var make = BDMovePaymentModal;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
