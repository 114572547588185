// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var Button = require("@mui/joy/Button").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ChangeCabinModalQuery_graphql$Thick = require("../../../__generated__/ChangeCabinModalQuery_graphql.bs.js");

var convertVariables = ChangeCabinModalQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangeCabinModalQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangeCabinModalQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ChangeCabinModalQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ChangeCabinModalQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ChangeCabinModalQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ChangeCabinModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ChangeCabinModalQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ChangeCabinModalQuery_graphql$Thick.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function ChangeCabinModal$CabinPreview(props) {
  var content = props.content;
  var match = content.occupancyMin;
  var match$1 = content.occupancyMax;
  var bookingId = content.bookingId;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 1,
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              sx: {
                                fontWeight: "bold"
                              },
                              children: "Cabin Number:"
                            }),
                        JsxRuntime.jsx(Typography, {
                              children: Caml_option.some(CS_NonemptyStrings$Util.CabinNumber.toEl(content.cabinNumber))
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              sx: {
                                fontWeight: "bold"
                              },
                              children: "Cabin Category:"
                            }),
                        JsxRuntime.jsx(Typography, {
                              children: Caml_option.some(Belt_Option.getWithDefault(Belt_Option.map(content.cabinCategorySlug, CS_Slugs$Util.CabinCategorySlug.toEl), "Unknown"))
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              sx: {
                                fontWeight: "bold"
                              },
                              children: "Deck Number:"
                            }),
                        JsxRuntime.jsx(Typography, {
                              children: Caml_option.some(Belt_Option.getWithDefault(Belt_Option.map(content.deckNumber, CS_NonemptyStrings$Util.DeckNumber.toEl), "Unknown"))
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              sx: {
                                fontWeight: "bold"
                              },
                              children: "Occupancy:"
                            }),
                        JsxRuntime.jsx(Typography, {
                              children: Caml_option.some(match !== undefined ? (
                                      match$1 !== undefined ? "Between " + String(match) + " and " + String(match$1) + " people" : "At least " + String(match) + " people"
                                    ) : (
                                      match$1 !== undefined ? "Up to " + String(match$1) + " people" : "Unknown"
                                    ))
                            })
                      ]
                    }),
                bookingId !== undefined ? JsxRuntime.jsx(Stack, {
                        direction: "row",
                        spacing: 1,
                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  children: Caml_option.some("Warning: This cabin is currently attached to booking " + CS_NonemptyStrings$Util.BookingId.toString(Caml_option.valFromOption(bookingId)) + ".")
                                }))
                      }) : null
              ]
            });
}

var CabinPreview = {
  make: ChangeCabinModal$CabinPreview
};

function ChangeCabinModal(props) {
  var currentCabinNumber = props.currentCabinNumber;
  var onClose = props.onClose;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var match = React.useState(function () {
        return "";
      });
  var setSearchStr = match[1];
  var searchStr = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setSearchErr = match$1[1];
  var searchErr = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setCabin = match$2[1];
  var cabin = match$2[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match$3 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, onClose);
  var clearPreviewFields = match$3.clearPreviewFields;
  var setApplyDiff = match$3.setApplyDiff;
  var setError = match$3.setError;
  var loading = match$3.loading;
  var generatePreview = match$3.generatePreview;
  var clearAll = function () {
    clearPreviewFields();
    setSearchStr(function (param) {
          return "";
        });
    setCabin(function (param) {
          
        });
    setSearchErr(function (param) {
          
        });
  };
  var changeCabinOnSubmit = function (e) {
    e.preventDefault();
    if (cabin === undefined) {
      return setError(function (param) {
                  return "Please select a cabin.";
                });
    }
    var match = cabin.bookingId;
    var match$1 = cabin.bookingVersionNumber;
    var applyDiff;
    var exit = 0;
    if (match !== undefined && match$1 !== undefined) {
      var bookedCabin_bookingId = Caml_option.valFromOption(match);
      var bookedCabin_cabinNumber = cabin.cabinNumber;
      var bookedCabin = {
        bookingId: bookedCabin_bookingId,
        bookingVersionNumber: match$1,
        cabinNumber: bookedCabin_cabinNumber
      };
      applyDiff = BookingDiff$Thick.Alter.changeCabinBooked(diffStruct, bookedCabin);
    } else {
      exit = 1;
    }
    if (exit === 1) {
      applyDiff = BookingDiff$Thick.Alter.changeCabinUnbooked(diffStruct, Caml_option.some(cabin.cabinNumber));
    }
    setApplyDiff(function (param) {
          return applyDiff;
        });
    generatePreview(applyDiff);
  };
  var cabinChangeDescription = React.useMemo((function () {
          if (cabin === undefined) {
            return "You are proposing to change the cabin on this booking.";
          }
          var bookingId = cabin.bookingId;
          var newCabin = cabin.cabinNumber;
          if (bookingId === undefined) {
            return "You are proposing to change the cabin on this booking from " + CS_NonemptyStrings$Util.CabinNumber.toString(currentCabinNumber) + " to " + CS_NonemptyStrings$Util.CabinNumber.toString(newCabin) + ".";
          }
          var num = cabin.bookingVersionNumber;
          if (num !== undefined) {
            return "You are proposing to swap the cabin currently attached to this booking (" + CS_NonemptyStrings$Util.CabinNumber.toString(currentCabinNumber) + ") with the one currently attatched to booking " + CS_NonemptyStrings$Util.BookingId.toString(Caml_option.valFromOption(bookingId)) + " version " + String(num) + " (" + CS_NonemptyStrings$Util.CabinNumber.toString(newCabin) + ").";
          } else {
            return "You are proposing to change the cabin on this booking.";
          }
        }), [cabin]);
  var search = function (e) {
    e.preventDefault();
    setSearchErr(function (param) {
          
        });
    var cabinNumber = CS_NonemptyStrings$Util.CabinNumber.fromPrimitive(searchStr);
    if (cabinNumber.TAG !== "Ok") {
      return setSearchErr(function (param) {
                  return "Invalid cabin number. Please try again.";
                });
    }
    $$fetch(RelayEnv$Thick.environment, {
          brandFamily: brandFamily,
          cabinNumber: cabinNumber._0,
          voyage: voyage
        }, (function (res) {
            if (res.TAG === "Ok") {
              var match = res._0.cabins;
              var exit = 0;
              if (match !== undefined) {
                var match$1 = match.nodes;
                if (match$1.length !== 1) {
                  exit = 1;
                } else {
                  var cabin = match$1[0];
                  var match$2 = Belt_Array.get(cabin.booking.nodes, 0);
                  var match$3;
                  if (match$2 !== undefined) {
                    var match$4 = match$2.bookingVersion.nodes;
                    if (match$4.length !== 1) {
                      match$3 = [
                        undefined,
                        undefined
                      ];
                    } else {
                      var match$5 = match$4[0];
                      match$3 = [
                        Caml_option.some(match$2.bookingId),
                        match$5.bookingVersionNumber
                      ];
                    }
                  } else {
                    match$3 = [
                      undefined,
                      undefined
                    ];
                  }
                  var match$6 = cabin.occRange;
                  var tmp;
                  if (match$6 !== undefined) {
                    var match$7 = match$6.end;
                    tmp = match$7 !== undefined ? (
                        match$7.inclusive ? match$7.value : match$7.value - 1 | 0
                      ) : undefined;
                  } else {
                    tmp = undefined;
                  }
                  var match$8 = cabin.occRange;
                  var tmp$1;
                  if (match$8 !== undefined) {
                    var match$9 = match$8.start;
                    tmp$1 = match$9 !== undefined ? (
                        match$9.inclusive ? match$9.value : match$9.value + 1 | 0
                      ) : undefined;
                  } else {
                    tmp$1 = undefined;
                  }
                  var proposedCabin_cabinNumber = cabin.cabinNumber;
                  var proposedCabin_cabinCategorySlug = cabin.voyageCategorySlug;
                  var proposedCabin_deckNumber = cabin.deckNumber;
                  var proposedCabin_bookingId = match$3[0];
                  var proposedCabin_bookingVersionNumber = match$3[1];
                  var proposedCabin = {
                    cabinNumber: proposedCabin_cabinNumber,
                    cabinCategorySlug: proposedCabin_cabinCategorySlug,
                    deckNumber: proposedCabin_deckNumber,
                    occupancyMax: tmp,
                    occupancyMin: tmp$1,
                    bookingId: proposedCabin_bookingId,
                    bookingVersionNumber: proposedCabin_bookingVersionNumber
                  };
                  setCabin(function (param) {
                        return proposedCabin;
                      });
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                setSearchErr(function (param) {
                      return "No cabin found. Please try again.";
                    });
                setCabin(function (param) {
                      
                    });
              }
              return ;
            }
            console.error(res._0);
          }), undefined, undefined);
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.open_,
                      onClose: (function () {
                          clearAll();
                          onClose();
                        }),
                      title: "Change Cabin",
                      children: [
                        JsxRuntime.jsxs(DialogContent, {
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "title-md",
                                      sx: {
                                        marginBottom: "1rem"
                                      },
                                      children: "Enter a cabin number to attach to this booking."
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 1,
                                      children: [
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "row",
                                              component: "form",
                                              spacing: 1,
                                              sx: {
                                                width: "100%"
                                              },
                                              onSubmit: search,
                                              children: [
                                                JsxRuntime.jsx(Joy$Util.Input.make, {
                                                      type_: "text",
                                                      placeholder: "Enter cabin number",
                                                      sx: {
                                                        flex: 1
                                                      },
                                                      value: searchStr,
                                                      onChange: (function (e) {
                                                          var val = e.currentTarget.value;
                                                          setSearchStr(function (param) {
                                                                return val;
                                                              });
                                                        })
                                                    }),
                                                JsxRuntime.jsx(Button, {
                                                      type: "submit",
                                                      disabled: loading || Belt_Result.isError(CS_NonemptyStrings$Util.CabinNumber.fromPrimitive(searchStr)),
                                                      children: "Search"
                                                    })
                                              ]
                                            }),
                                        searchErr !== undefined ? JsxRuntime.jsx(Typography, {
                                                sx: {
                                                  color: "red"
                                                },
                                                children: Caml_option.some(searchErr)
                                              }) : null,
                                        cabin !== undefined ? JsxRuntime.jsx(ChangeCabinModal$CabinPreview, {
                                                content: cabin
                                              }) : null
                                      ]
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        component: "form",
                                        onSubmit: changeCabinOnSubmit,
                                        children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                  loading: loading,
                                                  type: "submit",
                                                  disabled: Belt_Option.isNone(cabin),
                                                  children: "Change Cabin"
                                                }))
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$3.billPayNew,
                      applyDiff: match$3.applyDiff,
                      title: "Change Cabin",
                      description: cabinChangeDescription,
                      onClose: (function () {
                          clearAll();
                        }),
                      open_: match$3.previewModalOpen,
                      versionUrl: props.versionUrl,
                      error: match$3.error
                    })
              ]
            });
}

var make = ChangeCabinModal;

exports.Query = Query;
exports.CabinPreview = CabinPreview;
exports.make = make;
/* use Not a pure module */
