// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"bookingVersions_nodes_versionCreatedAt":{"c":"Util.CustomScalars.SQLDateTime.Exn"},"bookingVersions_nodes_paxes_nodes_idx":{"b":""},"bookingVersions_nodes_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"},"bookingVersions_nodes":{"f":""},"":{"f":""}}};

var fragmentConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function brandFamilyPermission_decode($$enum) {
  if ($$enum === "MANAGE_PRICING" || $$enum === "VIEW_PRICING" || $$enum === "MANAGE_BOOKINGS" || $$enum === "VIEW_BOOKINGS" || $$enum === "MANAGE_BF_CUSTOMERS" || $$enum === "VIEW_CUSTOMER_DETAILS" || $$enum === "MANAGE_CABIN_INVENTORY" || $$enum === "VIEW_CABIN_INVENTORY" || $$enum === "REQUEST_BRAND_FAMILY_GRANT" || $$enum === "WORKFLOW_PARTICIPANT" || $$enum === "VIEW_VOYAGE_META" || $$enum === "VIEW_ALL_VOYAGES" || $$enum === "VIEW_ALL_USERS" || $$enum === "MANAGE_USER" || $$enum === "ADD_USER" || $$enum === "GRANT_REVOKE" || $$enum === "VIEW_MANIFEST" || $$enum === "VIEW_BF_METADATA") {
    return $$enum;
  }
  
}

function brandFamilyPermission_fromString(str) {
  return brandFamilyPermission_decode(str);
}

function lifecycleStatus_decode($$enum) {
  if ($$enum === "CANCELED" || $$enum === "STAGED" || $$enum === "DRAFT" || $$enum === "ACTIVE" || $$enum === "HISTORY") {
    return $$enum;
  }
  
}

function lifecycleStatus_fromString(str) {
  return lifecycleStatus_decode(str);
}

var Utils = {
  brandFamilyPermission_decode: brandFamilyPermission_decode,
  brandFamilyPermission_fromString: brandFamilyPermission_fromString,
  lifecycleStatus_decode: lifecycleStatus_decode,
  lifecycleStatus_fromString: lifecycleStatus_fromString
};

var node = ((function(){
var v0 = {
  "kind": "Variable",
  "name": "bookingId",
  "variableName": "bookingId"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "bookingId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "bookingVersionNumber"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookingDetailsPage_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "forBrandFamilySlug",
          "variableName": "brandFamily"
        }
      ],
      "kind": "ScalarField",
      "name": "currentUserCanForBrand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "bookingVersionNumber",
              "variableName": "bookingVersionNumber"
            },
            {
              "kind": "Variable",
              "name": "brandFamilySlug",
              "variableName": "brandFamily"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "BookingVersionsConnection",
      "kind": "LinkedField",
      "name": "bookingVersions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingVersion",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDHero_version"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDBillingContact_version"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDCabin_version"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDBerthsPassengers_version"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDComments_version"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDPreferences_version"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AttachReplaceNewConnectedModal_version"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "versionCreatedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bookingId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lifecycleStatus",
              "storageKey": null
            },
            {
              "alias": "paxes",
              "args": null,
              "concreteType": "PassengersConnection",
              "kind": "LinkedField",
              "name": "passenger",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Passenger",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BfcustUser",
                      "kind": "LinkedField",
                      "name": "bfcustUser",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "fullName",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "idx",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "brandFamily",
          "variableName": "brandFamily"
        }
      ],
      "kind": "FragmentSpread",
      "name": "BDVersionHistory_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AttachReplaceNewConnectedModal_query"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateAccountAndPaxData_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
