// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var RescriptRelay_Internal = require("./RescriptRelay_Internal.bs.js");
var ResolverFragments = require("relay-runtime/lib/store/ResolverFragments");
var UseBlockingPaginationFragment = require("react-relay/lib/relay-hooks/legacy/useBlockingPaginationFragment").default;

function useFragment(node, convertFragment, fRef) {
  var __x = ReactRelay.useFragment(node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(convertFragment, __x);
}

function useFragmentOpt(fRef, node, convertFragment) {
  var data = ReactRelay.useFragment(node, fRef);
  return React.useMemo((function () {
                if (!(data == null)) {
                  return Caml_option.some(convertFragment(data));
                }
                
              }), [(data == null) ? undefined : Caml_option.some(data)]);
}

function readInlineData(node, convertFragment, fRef) {
  return convertFragment(ReactRelay.readInlineData(node, fRef));
}

function read(node, convertFragment, fRef) {
  return convertFragment(ResolverFragments.readFragment(node, fRef));
}

function internal_makeRefetchableFnOpts(fetchPolicy, onComplete, param) {
  return {
          fetchPolicy: fetchPolicy,
          onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
        };
}

function usePaginationFragment(node, fRef, convertFragment, convertRefetchVariables) {
  var p = ReactRelay.usePaginationFragment(node, fRef);
  var data = RescriptRelay_Internal.internal_useConvertedValue(convertFragment, p.data);
  return {
          data: data,
          loadNext: React.useMemo((function () {
                  return function (count, onComplete) {
                    return p.loadNext(count, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                              });
                  };
                }), [p.loadNext]),
          loadPrevious: React.useMemo((function () {
                  return function (count, onComplete) {
                    return p.loadPrevious(count, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                              });
                  };
                }), [p.loadPrevious]),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: React.useMemo((function () {
                  return function (variables, fetchPolicy, onComplete) {
                    return p.refetch(RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(convertRefetchVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, onComplete, undefined));
                  };
                }), [p.refetch])
        };
}

function useBlockingPaginationFragment(node, fRef, convertFragment, convertRefetchVariables) {
  var p = UseBlockingPaginationFragment(node, fRef);
  var data = RescriptRelay_Internal.internal_useConvertedValue(convertFragment, p.data);
  return {
          data: data,
          loadNext: React.useMemo((function () {
                  return function (count, onComplete) {
                    return p.loadNext(count, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                              });
                  };
                }), [p.loadNext]),
          loadPrevious: React.useMemo((function () {
                  return function (count, onComplete) {
                    return p.loadPrevious(count, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(onComplete)
                              });
                  };
                }), [p.loadPrevious]),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          refetch: React.useMemo((function () {
                  return function (variables, fetchPolicy, onComplete) {
                    return p.refetch(RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(convertRefetchVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, onComplete, undefined));
                  };
                }), [p.refetch])
        };
}

function useRefetchableFragment(node, convertFragment, convertRefetchVariables, fRef) {
  var match = ReactRelay.useRefetchableFragment(node, fRef);
  var refetchFn = match[1];
  var data = RescriptRelay_Internal.internal_useConvertedValue(convertFragment, match[0]);
  return [
          data,
          React.useMemo((function () {
                  return function (variables, fetchPolicy, onComplete) {
                    return refetchFn(RescriptRelay_Internal.internal_removeUndefinedAndConvertNullsRaw(convertRefetchVariables(variables)), internal_makeRefetchableFnOpts(fetchPolicy, onComplete, undefined));
                  };
                }), [refetchFn])
        ];
}

exports.useFragment = useFragment;
exports.useFragmentOpt = useFragmentOpt;
exports.readInlineData = readInlineData;
exports.read = read;
exports.usePaginationFragment = usePaginationFragment;
exports.useBlockingPaginationFragment = useBlockingPaginationFragment;
exports.useRefetchableFragment = useRefetchableFragment;
/* react Not a pure module */
