// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function BDContentBlock(props) {
  var __actions = props.actions;
  var actions = __actions !== undefined ? Caml_option.valFromOption(__actions) : null;
  return JsxRuntime.jsxs(Sheet, {
              sx: {
                p: 2,
                border: "1px solid",
                borderRadius: "4px",
                borderColor: "divider"
              },
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      justifyContent: "space-between",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "h4",
                              component: "h2",
                              fontWeight: "500",
                              children: Caml_option.some(props.title)
                            }),
                        JsxRuntime.jsx(Box, {
                              children: Caml_option.some(actions)
                            })
                      ]
                    }),
                JsxRuntime.jsx(Box, {
                      children: Caml_option.some(props.children),
                      sx: {
                        mt: 3
                      }
                    })
              ]
            });
}

var make = BDContentBlock;

exports.make = make;
/* @mui/joy/Box Not a pure module */
