// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ResendBfCustUserTransfer_ResendBfcustUserTransferMutation_graphql$Thick = require("../../../__generated__/ResendBfCustUserTransfer_ResendBfcustUserTransferMutation_graphql.bs.js");

var convertVariables = ResendBfCustUserTransfer_ResendBfcustUserTransferMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ResendBfCustUserTransfer_ResendBfcustUserTransferMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ResendBfCustUserTransfer_ResendBfcustUserTransferMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ResendBfCustUserTransfer_ResendBfcustUserTransferMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ResendBfCustUserTransfer_ResendBfcustUserTransferMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var ResendBfcustUserTransferMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(refresh, accountId, brandFamily, onClose) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    mutate({
          input: {
            _brandFamilySlug: brandFamily,
            _accountId: accountId
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (resp, err) {
            if (err !== undefined) {
              console.error(err);
              setError(function (param) {
                    return "An error occurred resending transfer invitation 1";
                  });
              return ;
            }
            var match = resp.resendBfcustUserTransfer;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                refresh();
                setError(function (param) {
                      
                    });
                onClose();
                return ;
              }
              
            }
            setError(function (param) {
                  return "An error occurred resending transfer invitation 2";
                });
          }), (function (err) {
            console.log(err);
            setError(function (param) {
                  return "An error occurred in revoking transfer invitation 3";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          error: match$1[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

exports.ResendBfcustUserTransferMutation = ResendBfcustUserTransferMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
