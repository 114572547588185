// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CustomScalars$Thick = require("../utils/CustomScalars.bs.js");

var Types = {};

var variablesConverter = {"requestBrandFamilyGrantInput":{"toVoyages":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"toBrandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"inviteeEmail":{"c":"Util.CustomScalars.Email.Exn"}},"__root":{"input":{"r":"requestBrandFamilyGrantInput"}}};

var variablesConverterMap = {
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.serialize,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"requestBrandFamilyGrant_workflowInstance":{"c":"CustomScalars.WorkflowInstance"}}};

var wrapResponseConverterMap = {
  "CustomScalars.WorkflowInstance": CustomScalars$Thick.WorkflowInstance.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"requestBrandFamilyGrant_workflowInstance":{"c":"CustomScalars.WorkflowInstance"}}};

var responseConverterMap = {
  "CustomScalars.WorkflowInstance": CustomScalars$Thick.WorkflowInstance.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function brandfamRole_decode($$enum) {
  if ($$enum === "CUSTOMER_SERVICE" || $$enum === "BRAND_FAMILY_ADMIN" || $$enum === "INVENTORY_MANAGER") {
    return $$enum;
  }
  
}

function brandfamRole_fromString(str) {
  return brandfamRole_decode(str);
}

var Utils = {
  brandfamRole_decode: brandfamRole_decode,
  brandfamRole_fromString: brandfamRole_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestBrandFamilyGrantPayload",
    "kind": "LinkedField",
    "name": "requestBrandFamilyGrant",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "workflowInstance",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestBFGrantSubmit_RequestBrandFamilyGrantMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestBFGrantSubmit_RequestBrandFamilyGrantMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc18f8021c3ad82b16aec95539261ad1",
    "id": null,
    "metadata": {},
    "name": "RequestBFGrantSubmit_RequestBrandFamilyGrantMutation",
    "operationKind": "mutation",
    "text": "mutation RequestBFGrantSubmit_RequestBrandFamilyGrantMutation(\n  $input: RequestBrandFamilyGrantInput!\n) {\n  requestBrandFamilyGrant(input: $input) {\n    workflowInstance\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
