// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick = require("../../../__generated__/AcceptInvite_ClaimInvitationWithTokenMutation_graphql.bs.js");

var convertVariables = AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, AcceptInvite_ClaimInvitationWithTokenMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var ClaimInvitationWithTokenMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation() {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setNewPasswd = match$1[1];
  var newPasswd = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setConfirmPasswd = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setSetupSuccess = match$3[1];
  var match$4 = React.useState(function () {
        
      });
  var setError = match$4[1];
  var onSubmit = function (e, token) {
    e.preventDefault();
    setError(function (param) {
          
        });
    mutate({
          input: {
            allegedToken: token,
            newPasswd: newPasswd
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (resp, err) {
            if (err !== undefined) {
              return setError(function (param) {
                          return "An error occurred while setting up your account";
                        });
            }
            var match = resp.claimInvitationWithToken;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                setNewPasswd(function (param) {
                      return "";
                    });
                setConfirmPasswd(function (param) {
                      return "";
                    });
                return setSetupSuccess(function (param) {
                            return true;
                          });
              }
              
            }
            setError(function (param) {
                  return "An error occurred while finishing setting up your account";
                });
          }), (function (err) {
            console.log(err);
            setError(function (param) {
                  return "An error occurred while finishing account setup";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          newPasswd: newPasswd,
          setNewPasswd: setNewPasswd,
          confirmPasswd: match$2[0],
          setConfirmPasswd: setConfirmPasswd,
          setupSuccess: match$3[0],
          error: match$4[0],
          onSubmit: onSubmit
        };
}

exports.ClaimInvitationWithTokenMutation = ClaimInvitationWithTokenMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
