// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var SharedDrawer$Thick = require("../../common/molecules/SharedDrawer.bs.js");
var Typography = require("@mui/joy/Typography").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var BDInvoiceDetails$Thick = require("./BDInvoiceDetails.bs.js");
var BDDepositScheduleDetails$Thick = require("./BDDepositScheduleDetails.bs.js");
var BDRecordCheckPaymentModal$Thick = require("./BDRecordCheckPaymentModal.bs.js");

function BDBillPayDrawer(props) {
  var brandFamily = props.brandFamily;
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var billPayRec = props.billPayRec;
  var isActive = props.isActive;
  var match = React.useState(function () {
        return "invoice";
      });
  var setTab = match[1];
  var tab = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setRecordCheckOpen = match$1[1];
  var tmp;
  if (tab === "deposit") {
    if (billPayRec !== undefined) {
      var depositSchedule = billPayRec.depositSchedule;
      tmp = depositSchedule !== undefined && isActive ? JsxRuntime.jsx(BDDepositScheduleDetails$Thick.make, {
              depositSchedule: depositSchedule
            }) : null;
    } else {
      tmp = null;
    }
  } else {
    tmp = JsxRuntime.jsx(BDInvoiceDetails$Thick.make, {
          isActive: isActive,
          snapshot: props.snapshot,
          canManage: props.canManage,
          billPayRec: billPayRec,
          versionUrl: props.versionUrl,
          generatePreviewFromDiff: generatePreviewFromDiff,
          versionTimestamp: props.versionTimestamp,
          versionSuppressions: props.versionSuppressions,
          currentUserEmail: props.currentUserEmail,
          brandFamily: brandFamily,
          openRecordCheckModal: (function () {
              setRecordCheckOpen(function (param) {
                    return true;
                  });
            })
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(SharedDrawer$Thick.make, {
                      open_: props.open_,
                      title: "Billing & Payment Details",
                      onClose: props.onClose,
                      children: [
                        billPayRec !== undefined && billPayRec.depositSchedule !== undefined && isActive ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                        children: [
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                          direction: "column",
                                                          justifyContent: "center",
                                                          alignItems: "center",
                                                          onClick: (function (param) {
                                                              setTab(function (param) {
                                                                    return "invoice";
                                                                  });
                                                            }),
                                                          children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                                    sx: {
                                                                      width: "fit-content"
                                                                    },
                                                                    children: [
                                                                      JsxRuntime.jsx(Typography, {
                                                                            level: "body-sm",
                                                                            sx: {
                                                                              my: 2,
                                                                              color: tab === "invoice" ? "text.primary" : "neutral.500"
                                                                            },
                                                                            children: "Invoice"
                                                                          }),
                                                                      JsxRuntime.jsx(Stack, {
                                                                            sx: {
                                                                              height: "3px",
                                                                              width: "100%",
                                                                              backgroundColor: tab === "invoice" ? "text.secondary" : "transparent",
                                                                              borderTopRightRadius: "100px",
                                                                              borderTopLeftRadius: "100px"
                                                                            }
                                                                          })
                                                                    ]
                                                                  }))
                                                        })),
                                                sx: {
                                                  "&:hover": {
                                                    backgroundColor: "background.level2",
                                                    cursor: "pointer"
                                                  }
                                                },
                                                xs: 6
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                          direction: "column",
                                                          justifyContent: "center",
                                                          alignItems: "center",
                                                          onClick: (function (param) {
                                                              setTab(function (param) {
                                                                    return "deposit";
                                                                  });
                                                            }),
                                                          children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                                    sx: {
                                                                      width: "fit-content",
                                                                      height: "100"
                                                                    },
                                                                    children: [
                                                                      JsxRuntime.jsx(Typography, {
                                                                            level: "body-sm",
                                                                            sx: {
                                                                              my: 2,
                                                                              color: tab === "deposit" ? "text.primary" : "neutral.500"
                                                                            },
                                                                            children: "Deposit Schedule"
                                                                          }),
                                                                      JsxRuntime.jsx(Stack, {
                                                                            sx: {
                                                                              height: "3px",
                                                                              width: "100%",
                                                                              backgroundColor: tab === "deposit" ? "text.secondary" : "transparent",
                                                                              borderTopRightRadius: "100px",
                                                                              borderTopLeftRadius: "100px"
                                                                            }
                                                                          })
                                                                    ]
                                                                  }))
                                                        })),
                                                sx: {
                                                  "&:hover": {
                                                    backgroundColor: "background.level2",
                                                    cursor: "pointer"
                                                  }
                                                },
                                                xs: 6
                                              })
                                        ],
                                        container: true,
                                        sx: {
                                          backgroundColor: "background.level1"
                                        }
                                      }),
                                  JsxRuntime.jsx(Divider, {})
                                ]
                              }) : null,
                        JsxRuntime.jsx(DialogContent, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        spacing: 2,
                                        sx: {
                                          px: 2,
                                          py: 3
                                        },
                                        children: Caml_option.some(tmp)
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(BDRecordCheckPaymentModal$Thick.make, {
                      open_: match$1[0],
                      onClose: (function () {
                          setRecordCheckOpen(function (param) {
                                return false;
                              });
                        }),
                      brandFamily: brandFamily,
                      refetchBilling: props.refetchBilling,
                      generatePreviewFromDiff: generatePreviewFromDiff
                    })
              ]
            });
}

var make = BDBillPayDrawer;

exports.make = make;
/* react Not a pure module */
