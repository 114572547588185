// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"userVoyageGrants":{"f":""},"userRoleGrants":{"f":""},"email":{"c":"Util.CustomScalars.Email.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamPage_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserRoleGrantsConnection",
      "kind": "LinkedField",
      "name": "userRoleGrants",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RoleGrantsView_rolegrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserVoyageGrantsConnection",
      "kind": "LinkedField",
      "name": "userVoyageGrants",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VoyageGrantsView_voyagegrant"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BrandFamilyUser",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
