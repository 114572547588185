// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_SetInt = require("rescript/lib/js/belt_SetInt.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

var emptyDiff = {
  hasDiff: false,
  addBerths: undefined,
  dropBerths: undefined,
  reindexPax: undefined,
  changeBillingContact: undefined,
  detachPax: undefined,
  attachExistingPax: undefined,
  attachInvitedPax: undefined,
  attachNewConnectedPax: undefined,
  replacePaxExisting: undefined,
  replaceInvitedPax: undefined,
  replaceNewConnectedPax: undefined,
  updateBookingPreferences: undefined,
  revokePaxInvitation: undefined,
  changeCabinU: undefined,
  changeCabinB: undefined,
  suppressManualBillingLines: undefined,
  suppressAutoBillingLines: undefined,
  addManualBillingLines: undefined,
  patchPax: undefined,
  recordManualRefunds: undefined,
  movePayments: undefined,
  cancellationReason: undefined
};

function setHasDiff(d) {
  if (Caml_obj.equal(d, emptyDiff)) {
    return emptyDiff;
  }
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.hasDiff = true;
  return newrecord;
}

function arePreferenceRecordsSame(record1, record2) {
  if (Caml_obj.equal(record1.bedsTogetherPref, record2.bedsTogetherPref) && Caml_obj.equal(record1.cabinLongitudePref, record2.cabinLongitudePref) && Caml_obj.equal(record1.cabinElevatorPref, record2.cabinElevatorPref) && Caml_obj.equal(record1.cabinPlacementRequest, record2.cabinPlacementRequest) && Caml_obj.equal(record1.earlyDinnerSeatingPref, record2.earlyDinnerSeatingPref)) {
    return Caml_obj.equal(record1.generalPref, record2.generalPref);
  } else {
    return false;
  }
}

function changeBillingContactIfAny_get(diff) {
  return diff.changeBillingContact;
}

function changeBillingContactIfAny_set(diff, acct) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.changeBillingContact = acct, newrecord));
}

var changeBillingContactIfAny = {
  get: changeBillingContactIfAny_get,
  set: changeBillingContactIfAny_set
};

function cancellationReasonIfAny_get(diff) {
  return diff.cancellationReason;
}

function cancellationReasonIfAny_set(diff, r) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.cancellationReason = r, newrecord));
}

var cancellationReasonIfAny = {
  get: cancellationReasonIfAny_get,
  set: cancellationReasonIfAny_set
};

function addBerthsIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.addBerths, 0);
}

function addBerthsIfAny_set(diff, num) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.addBerths = num < 1 ? undefined : num, newrecord));
}

var addBerthsIfAny = {
  get: addBerthsIfAny_get,
  set: addBerthsIfAny_set
};

function movePaymentsIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.movePayments, []);
}

function movePaymentsIfAny_set(diff, mp) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.movePayments = mp.length === 0 ? undefined : mp, newrecord));
}

var movePaymentsIfAny = {
  get: movePaymentsIfAny_get,
  set: movePaymentsIfAny_set
};

function recordManualRefundsIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.recordManualRefunds, undefined);
}

function recordManualRefundsIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.recordManualRefunds = Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var recordManualRefundsIfAny = {
  get: recordManualRefundsIfAny_get,
  set: recordManualRefundsIfAny_set
};

function dropBerthsIfAny_get(diff) {
  return Belt_Option.mapWithDefault(diff.dropBerths, undefined, Belt_SetInt.fromArray);
}

function dropBerthsIfAny_set(diff, set) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.dropBerths = Belt_SetInt.isEmpty(set) ? undefined : Belt_SetInt.toArray(set), newrecord));
}

var dropBerthsIfAny = {
  get: dropBerthsIfAny_get,
  set: dropBerthsIfAny_set
};

function reindexPaxIfAny_get(diff) {
  return Belt_Option.mapWithDefault(diff.reindexPax, [], (function (arr) {
                return arr;
              }));
}

function reindexPaxIfAny_set(diff, set) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.reindexPax = set.length === 0 ? undefined : set, newrecord));
}

var reindexPaxIfAny = {
  get: reindexPaxIfAny_get,
  set: reindexPaxIfAny_set
};

function patchPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.patchPax, undefined);
}

function patchPaxIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.patchPax = Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var patchPaxIfAny = {
  get: patchPaxIfAny_get,
  set: patchPaxIfAny_set
};

function attachExistingPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.attachExistingPax, undefined);
}

function attachExistingPaxIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.attachExistingPax = Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var attachExistingPaxIfAny = {
  get: attachExistingPaxIfAny_get,
  set: attachExistingPaxIfAny_set
};

function attachInvitedPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.attachInvitedPax, undefined);
}

function attachInvitedPaxIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.attachInvitedPax = Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var attachInvitedPaxIfAny = {
  get: attachInvitedPaxIfAny_get,
  set: attachInvitedPaxIfAny_set
};

function replacePaxExistingIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.replacePaxExisting, undefined);
}

function replacePaxExistingIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.replacePaxExisting = Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var replacePaxExistingIfAny = {
  get: replacePaxExistingIfAny_get,
  set: replacePaxExistingIfAny_set
};

function replaceInvitedPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.replaceInvitedPax, undefined);
}

function replaceInvitedPaxIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.replaceInvitedPax = Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var replaceInvitedPaxIfAny = {
  get: replaceInvitedPaxIfAny_get,
  set: replaceInvitedPaxIfAny_set
};

function attachNewConnectedPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.attachNewConnectedPax, undefined);
}

function attachNewConnectedPaxIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.attachNewConnectedPax = Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var attachNewConnectedPaxIfAny = {
  get: attachNewConnectedPaxIfAny_get,
  set: attachNewConnectedPaxIfAny_set
};

function replaceNewConnectedPaxIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.replaceNewConnectedPax, undefined);
}

function replaceNewConnectedPaxIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.replaceNewConnectedPax = Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var replaceNewConnectedPaxIfAny = {
  get: replaceNewConnectedPaxIfAny_get,
  set: replaceNewConnectedPaxIfAny_set
};

function detachPaxIfAny_get(diff) {
  return Belt_Option.mapWithDefault(diff.detachPax, undefined, Belt_SetInt.fromArray);
}

function detachPaxIfAny_set(diff, set) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.detachPax = Belt_SetInt.isEmpty(set) ? undefined : Belt_SetInt.toArray(set), newrecord));
}

var detachPaxIfAny = {
  get: detachPaxIfAny_get,
  set: detachPaxIfAny_set
};

function updateBookingPreferencesIfAny_get(diff) {
  return diff.updateBookingPreferences;
}

function updateBookingPreferencesIfAny_set(diff, newPrefs, versionPrefs) {
  var newrecord = Caml_obj.obj_dup(diff);
  var tmp;
  if (newPrefs !== undefined) {
    var exit = 0;
    if (newPrefs.bedsTogetherPref !== undefined || newPrefs.cabinLongitudePref !== undefined || newPrefs.cabinElevatorPref !== undefined || newPrefs.cabinPlacementRequest !== undefined || newPrefs.earlyDinnerSeatingPref !== undefined || newPrefs.generalPref !== undefined) {
      exit = 1;
    } else {
      tmp = undefined;
    }
    if (exit === 1) {
      tmp = arePreferenceRecordsSame(newPrefs, versionPrefs) ? undefined : newPrefs;
    }
    
  } else {
    tmp = undefined;
  }
  newrecord.updateBookingPreferences = tmp;
  return setHasDiff(newrecord);
}

var updateBookingPreferencesIfAny = {
  get: updateBookingPreferencesIfAny_get,
  set: updateBookingPreferencesIfAny_set
};

function changeCabinBIfAny_get(diff) {
  return diff.changeCabinB;
}

function changeCabinBIfAny_set(diff, cabin) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.changeCabinB = cabin, newrecord));
}

var changeCabinBIfAny = {
  get: changeCabinBIfAny_get,
  set: changeCabinBIfAny_set
};

function revokePaxInvitationIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.revokePaxInvitation, undefined);
}

function revokePaxInvitationIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.revokePaxInvitation = Belt_MapInt.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var revokePaxInvitationIfAny = {
  get: revokePaxInvitationIfAny_get,
  set: revokePaxInvitationIfAny_set
};

function changeCabinUIfAny_get(diff) {
  return diff.changeCabinU;
}

function changeCabinUIfAny_set(diff, cabin) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.changeCabinU = cabin, newrecord));
}

var changeCabinUIfAny = {
  get: changeCabinUIfAny_get,
  set: changeCabinUIfAny_set
};

function suppressManualBillingLinesIfAny_get(diff) {
  return Belt_Option.mapWithDefault(diff.suppressManualBillingLines, undefined, Belt_SetInt.fromArray);
}

function suppressManualBillingLinesIfAny_set(diff, set) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.suppressManualBillingLines = Belt_SetInt.isEmpty(set) ? undefined : Belt_SetInt.toArray(set), newrecord));
}

var suppressManualBillingLinesIfAny = {
  get: suppressManualBillingLinesIfAny_get,
  set: suppressManualBillingLinesIfAny_set
};

function suppressAutoBillingLinesIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.suppressAutoBillingLines, undefined);
}

function suppressAutoBillingLinesIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.suppressAutoBillingLines = Belt_MapString.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var suppressAutoBillingLinesIfAny = {
  get: suppressAutoBillingLinesIfAny_get,
  set: suppressAutoBillingLinesIfAny_set
};

function addManualBillingLinesIfAny_get(diff) {
  return Belt_Option.getWithDefault(diff.addManualBillingLines, undefined);
}

function addManualBillingLinesIfAny_set(diff, map) {
  var newrecord = Caml_obj.obj_dup(diff);
  return setHasDiff((newrecord.addManualBillingLines = Belt_MapString.isEmpty(map) ? undefined : Caml_option.some(map), newrecord));
}

var addManualBillingLinesIfAny = {
  get: addManualBillingLinesIfAny_get,
  set: addManualBillingLinesIfAny_set
};

var UnnestedDiff = {
  setHasDiff: setHasDiff,
  arePreferenceRecordsSame: arePreferenceRecordsSame,
  changeBillingContactIfAny: changeBillingContactIfAny,
  cancellationReasonIfAny: cancellationReasonIfAny,
  addBerthsIfAny: addBerthsIfAny,
  movePaymentsIfAny: movePaymentsIfAny,
  recordManualRefundsIfAny: recordManualRefundsIfAny,
  dropBerthsIfAny: dropBerthsIfAny,
  reindexPaxIfAny: reindexPaxIfAny,
  patchPaxIfAny: patchPaxIfAny,
  attachExistingPaxIfAny: attachExistingPaxIfAny,
  attachInvitedPaxIfAny: attachInvitedPaxIfAny,
  replacePaxExistingIfAny: replacePaxExistingIfAny,
  replaceInvitedPaxIfAny: replaceInvitedPaxIfAny,
  attachNewConnectedPaxIfAny: attachNewConnectedPaxIfAny,
  replaceNewConnectedPaxIfAny: replaceNewConnectedPaxIfAny,
  detachPaxIfAny: detachPaxIfAny,
  updateBookingPreferencesIfAny: updateBookingPreferencesIfAny,
  changeCabinBIfAny: changeCabinBIfAny,
  revokePaxInvitationIfAny: revokePaxInvitationIfAny,
  changeCabinUIfAny: changeCabinUIfAny,
  suppressManualBillingLinesIfAny: suppressManualBillingLinesIfAny,
  suppressAutoBillingLinesIfAny: suppressAutoBillingLinesIfAny,
  addManualBillingLinesIfAny: addManualBillingLinesIfAny
};

function changeBillingContact(diff, newBc) {
  return changeBillingContactIfAny_set(diff, newBc);
}

function updateCancellationReason(diff, reason) {
  return cancellationReasonIfAny_set(diff, reason);
}

function changeCabinUnbooked(diff, newCabin) {
  return changeCabinUIfAny_set(diff, newCabin);
}

function changeCabinBooked(diff, newCabin) {
  return changeCabinBIfAny_set(diff, newCabin);
}

function recordManualRefunds(diff, refund) {
  var refunds = recordManualRefundsIfAny_get(diff);
  var identifier = Belt_MapInt.size(refunds) + 1 | 0;
  return recordManualRefundsIfAny_set(diff, Belt_MapInt.set(refunds, identifier, refund));
}

function removeManualRefund(diff, identifier) {
  return recordManualRefundsIfAny_set(diff, Belt_MapInt.remove(recordManualRefundsIfAny_get(diff), identifier));
}

function movePayment(diff, move) {
  return movePaymentsIfAny_set(diff, Belt_Array.concat(movePaymentsIfAny_get(diff), [move]));
}

function updateBookingPreferences(diff, newPrefs, versionPrefs) {
  return updateBookingPreferencesIfAny_set(diff, newPrefs, versionPrefs);
}

function revokePaxInvitation(diff, revokeAtIndex, revokeAccountWithId) {
  return revokePaxInvitationIfAny_set(diff, Belt_MapInt.set(revokePaxInvitationIfAny_get(diff), revokeAtIndex, revokeAccountWithId));
}

function patchPax(diff, idx, paxPatch) {
  var patchPaxMap = patchPaxIfAny_get(diff);
  return patchPaxIfAny_set(diff, Belt_MapInt.set(patchPaxMap, idx, paxPatch));
}

function removePaxPatch(diff, idx) {
  var patchPaxMap = patchPaxIfAny_get(diff);
  return patchPaxIfAny_set(diff, Belt_MapInt.remove(patchPaxMap, idx));
}

function attachAnExistingPax(diff, attachAtIndex, attachAccountWithId) {
  var attachExistingPaxMap = attachExistingPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var replacePaxExistingMap = replacePaxExistingIfAny_get(diff);
  if (Belt_SetInt.has(detachPaxSet, attachAtIndex)) {
    return replacePaxExistingIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.remove(detachPaxSet, attachAtIndex)), Belt_MapInt.set(replacePaxExistingMap, attachAtIndex, attachAccountWithId));
  } else {
    return attachExistingPaxIfAny_set(diff, Belt_MapInt.set(attachExistingPaxMap, attachAtIndex, attachAccountWithId));
  }
}

function attachAnInvitedPax(diff, attachAtIndex, paxInvitation) {
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var replaceInvitedPaxMap = replaceInvitedPaxIfAny_get(diff);
  if (Belt_SetInt.has(detachPaxSet, attachAtIndex)) {
    return replaceInvitedPaxIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.remove(detachPaxSet, attachAtIndex)), Belt_MapInt.set(replaceInvitedPaxMap, attachAtIndex, paxInvitation));
  } else {
    return attachInvitedPaxIfAny_set(diff, Belt_MapInt.set(attachInvitedPaxMap, attachAtIndex, paxInvitation));
  }
}

function attachNewConnectedPax(diff, attachAtIndex, connectedAcct) {
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var replaceNewConnectedPaxMap = replaceNewConnectedPaxIfAny_get(diff);
  if (Belt_SetInt.has(detachPaxSet, attachAtIndex)) {
    return replaceNewConnectedPaxIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.remove(detachPaxSet, attachAtIndex)), Belt_MapInt.set(replaceNewConnectedPaxMap, attachAtIndex, connectedAcct));
  } else {
    return attachNewConnectedPaxIfAny_set(diff, Belt_MapInt.set(attachNewConnectedPaxMap, attachAtIndex, connectedAcct));
  }
}

function replaceWithExistingPax(diff, replaceAtIndex, replaceWithAccountWithId) {
  var attachExistingPaxMap = attachExistingPaxIfAny_get(diff);
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var replacePaxExistingMap = replacePaxExistingIfAny_get(diff);
  if (Belt_MapInt.has(attachExistingPaxMap, replaceAtIndex) || Belt_MapInt.has(attachInvitedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachNewConnectedPaxMap, replaceAtIndex)) {
    return attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingPaxIfAny_set(diff, Belt_MapInt.set(attachExistingPaxMap, replaceAtIndex, replaceWithAccountWithId)), Belt_MapInt.remove(attachInvitedPaxMap, replaceAtIndex)), Belt_MapInt.remove(attachNewConnectedPaxMap, replaceAtIndex));
  } else {
    return replacePaxExistingIfAny_set(diff, Belt_MapInt.set(replacePaxExistingMap, replaceAtIndex, replaceWithAccountWithId));
  }
}

function replaceWithInvitedPax(diff, replaceAtIndex, paxInvitation) {
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachExistingPaxMap = attachExistingPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var replaceInvitedPaxMap = replaceInvitedPaxIfAny_get(diff);
  if (Belt_MapInt.has(attachExistingPaxMap, replaceAtIndex) || Belt_MapInt.has(attachInvitedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachNewConnectedPaxMap, replaceAtIndex)) {
    return attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingPaxIfAny_set(diff, Belt_MapInt.remove(attachExistingPaxMap, replaceAtIndex)), Belt_MapInt.set(attachInvitedPaxMap, replaceAtIndex, paxInvitation)), Belt_MapInt.remove(attachNewConnectedPaxMap, replaceAtIndex));
  } else {
    return replaceInvitedPaxIfAny_set(diff, Belt_MapInt.set(replaceInvitedPaxMap, replaceAtIndex, paxInvitation));
  }
}

function replaceWithNewConnectedPax(diff, replaceAtIndex, connectedAcct) {
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachExistingPaxMap = attachExistingPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var replaceNewConnectedPaxMap = replaceNewConnectedPaxIfAny_get(diff);
  if (Belt_MapInt.has(attachExistingPaxMap, replaceAtIndex) || Belt_MapInt.has(attachInvitedPaxMap, replaceAtIndex) || Belt_MapInt.has(attachNewConnectedPaxMap, replaceAtIndex)) {
    return attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingPaxIfAny_set(diff, Belt_MapInt.remove(attachExistingPaxMap, replaceAtIndex)), Belt_MapInt.remove(attachInvitedPaxMap, replaceAtIndex)), Belt_MapInt.set(attachNewConnectedPaxMap, replaceAtIndex, connectedAcct));
  } else {
    return replaceNewConnectedPaxIfAny_set(diff, Belt_MapInt.set(replaceNewConnectedPaxMap, replaceAtIndex, connectedAcct));
  }
}

function addSingleBerth(diff) {
  var addBerthsNum = addBerthsIfAny_get(diff);
  var dropBerthsSet = dropBerthsIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var higestDroppingIdx = Belt_SetInt.maximum(dropBerthsSet);
  if (higestDroppingIdx !== undefined) {
    return dropBerthsIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.add(detachPaxSet, higestDroppingIdx)), Belt_SetInt.remove(dropBerthsSet, higestDroppingIdx));
  } else {
    return addBerthsIfAny_set(diff, addBerthsNum + 1 | 0);
  }
}

function makePax1(diff, makePax1Idx) {
  var reindexPaxArr = reindexPaxIfAny_get(diff);
  console.log("reorder " + makePax1Idx.toString());
  console.log(reindexPaxArr);
  return reindexPaxIfAny_set(diff, Belt_Array.concat([makePax1Idx], Belt_Array.keepMap(reindexPaxArr, (function (v) {
                        if (v === makePax1Idx) {
                          return ;
                        } else {
                          return v;
                        }
                      }))));
}

function suppressManualBillingLine(diff, lineToSuppress) {
  var suppressManualBillingLinesSet = suppressManualBillingLinesIfAny_get(diff);
  var addManualBillingLinesMap = addManualBillingLinesIfAny_get(diff);
  if (lineToSuppress.TAG === "Suppress") {
    return suppressManualBillingLinesIfAny_set(diff, Belt_SetInt.add(suppressManualBillingLinesSet, lineToSuppress._0));
  } else {
    return addManualBillingLinesIfAny_set(diff, Belt_MapString.remove(addManualBillingLinesMap, lineToSuppress._0));
  }
}

function addManualBillingLine(diff, lineToAdd, passthruId) {
  var addManualBillingLinesMap = addManualBillingLinesIfAny_get(diff);
  return addManualBillingLinesIfAny_set(diff, Belt_MapString.set(addManualBillingLinesMap, passthruId, lineToAdd));
}

function suppressAutoBillingLine(diff, lineToAdd, passthruId, versionSuppressions) {
  var suppressAutoBillingLinesMap = suppressAutoBillingLinesIfAny_get(diff);
  if (lineToAdd.nSuppressed === versionSuppressions) {
    return suppressAutoBillingLinesIfAny_set(diff, Belt_MapString.remove(suppressAutoBillingLinesMap, passthruId));
  } else {
    return suppressAutoBillingLinesIfAny_set(diff, Belt_MapString.set(suppressAutoBillingLinesMap, passthruId, lineToAdd));
  }
}

function dropSingleBerth(diff, newIdxToDrop) {
  var addBerthsNum = addBerthsIfAny_get(diff);
  var dropBerthsSet = dropBerthsIfAny_get(diff);
  var attachExistingPaxMap = attachExistingPaxIfAny_get(diff);
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var replacePaxExistingMap = replacePaxExistingIfAny_get(diff);
  var replaceInvitedPaxMap = replaceInvitedPaxIfAny_get(diff);
  var replaceNewConnectedPaxMap = replaceNewConnectedPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var patchPaxMap = patchPaxIfAny_get(diff);
  var reindexPaxArr = reindexPaxIfAny_get(diff);
  if (addBerthsNum > 0) {
    return reindexPaxIfAny_set(patchPaxIfAny_set(replaceNewConnectedPaxIfAny_set(replaceInvitedPaxIfAny_set(replacePaxExistingIfAny_set(attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingPaxIfAny_set(detachPaxIfAny_set(addBerthsIfAny_set(diff, addBerthsNum - 1 | 0), Belt_SetInt.remove(detachPaxSet, newIdxToDrop)), Belt_MapInt.remove(attachExistingPaxMap, newIdxToDrop)), Belt_MapInt.remove(attachInvitedPaxMap, newIdxToDrop)), Belt_MapInt.remove(attachNewConnectedPaxMap, newIdxToDrop)), Belt_MapInt.remove(replacePaxExistingMap, newIdxToDrop)), Belt_MapInt.remove(replaceInvitedPaxMap, newIdxToDrop)), Belt_MapInt.remove(replaceNewConnectedPaxMap, newIdxToDrop)), Belt_MapInt.remove(patchPaxMap, newIdxToDrop)), Belt_Array.keepMap(reindexPaxArr, (function (x) {
                      if (x === newIdxToDrop) {
                        return ;
                      } else {
                        return x;
                      }
                    })));
  } else {
    return reindexPaxIfAny_set(patchPaxIfAny_set(replaceNewConnectedPaxIfAny_set(replaceInvitedPaxIfAny_set(replacePaxExistingIfAny_set(attachNewConnectedPaxIfAny_set(attachInvitedPaxIfAny_set(attachExistingPaxIfAny_set(dropBerthsIfAny_set(diff, Belt_SetInt.add(dropBerthsSet, newIdxToDrop)), Belt_MapInt.remove(attachExistingPaxMap, newIdxToDrop)), Belt_MapInt.remove(attachInvitedPaxMap, newIdxToDrop)), Belt_MapInt.remove(attachNewConnectedPaxMap, newIdxToDrop)), Belt_MapInt.remove(replacePaxExistingMap, newIdxToDrop)), Belt_MapInt.remove(replaceInvitedPaxMap, newIdxToDrop)), Belt_MapInt.remove(replaceNewConnectedPaxMap, newIdxToDrop)), Belt_MapInt.remove(patchPaxMap, newIdxToDrop)), Belt_Array.keepMap(reindexPaxArr, (function (x) {
                      if (x === newIdxToDrop) {
                        return ;
                      } else {
                        return x;
                      }
                    })));
  }
}

function detachSingleBerth(diff, indexToDetach) {
  var attachExistingPaxMap = attachExistingPaxIfAny_get(diff);
  var attachInvitedPaxMap = attachInvitedPaxIfAny_get(diff);
  var attachNewConnectedPaxMap = attachNewConnectedPaxIfAny_get(diff);
  var replacePaxExistingMap = replacePaxExistingIfAny_get(diff);
  var replaceInvitedPaxMap = replaceInvitedPaxIfAny_get(diff);
  var replaceNewConnectedPaxMap = replaceNewConnectedPaxIfAny_get(diff);
  var detachPaxSet = detachPaxIfAny_get(diff);
  var patchPaxMap = patchPaxIfAny_get(diff);
  var reindexPaxArr = reindexPaxIfAny_get(diff);
  if (Belt_MapInt.has(attachExistingPaxMap, indexToDetach) || Belt_MapInt.has(replacePaxExistingMap, indexToDetach) || Belt_MapInt.has(attachInvitedPaxMap, indexToDetach) || Belt_MapInt.has(replaceInvitedPaxMap, indexToDetach) || Belt_MapInt.has(attachNewConnectedPaxMap, indexToDetach) || Belt_MapInt.has(replaceNewConnectedPaxMap, indexToDetach)) {
    return reindexPaxIfAny_set(patchPaxIfAny_set(replaceNewConnectedPaxIfAny_set(attachNewConnectedPaxIfAny_set(replaceInvitedPaxIfAny_set(attachInvitedPaxIfAny_set(replacePaxExistingIfAny_set(attachExistingPaxIfAny_set(diff, Belt_MapInt.remove(attachExistingPaxMap, indexToDetach)), Belt_MapInt.remove(replacePaxExistingMap, indexToDetach)), Belt_MapInt.remove(attachInvitedPaxMap, indexToDetach)), Belt_MapInt.remove(replaceInvitedPaxMap, indexToDetach)), Belt_MapInt.remove(attachNewConnectedPaxMap, indexToDetach)), Belt_MapInt.remove(replaceNewConnectedPaxMap, indexToDetach)), Belt_MapInt.remove(patchPaxMap, indexToDetach)), Belt_Array.keepMap(reindexPaxArr, (function (x) {
                      if (x === indexToDetach) {
                        return ;
                      } else {
                        return x;
                      }
                    })));
  } else {
    return reindexPaxIfAny_set(patchPaxIfAny_set(detachPaxIfAny_set(diff, Belt_SetInt.add(detachPaxSet, indexToDetach)), Belt_MapInt.remove(patchPaxMap, indexToDetach)), Belt_Array.keepMap(reindexPaxArr, (function (x) {
                      if (x === indexToDetach) {
                        return ;
                      } else {
                        return x;
                      }
                    })));
  }
}

var Alter = {
  changeBillingContact: changeBillingContact,
  updateCancellationReason: updateCancellationReason,
  changeCabinUnbooked: changeCabinUnbooked,
  changeCabinBooked: changeCabinBooked,
  recordManualRefunds: recordManualRefunds,
  removeManualRefund: removeManualRefund,
  movePayment: movePayment,
  updateBookingPreferences: updateBookingPreferences,
  revokePaxInvitation: revokePaxInvitation,
  patchPax: patchPax,
  removePaxPatch: removePaxPatch,
  attachAnExistingPax: attachAnExistingPax,
  attachAnInvitedPax: attachAnInvitedPax,
  attachNewConnectedPax: attachNewConnectedPax,
  replaceWithExistingPax: replaceWithExistingPax,
  replaceWithInvitedPax: replaceWithInvitedPax,
  replaceWithNewConnectedPax: replaceWithNewConnectedPax,
  addSingleBerth: addSingleBerth,
  makePax1: makePax1,
  suppressManualBillingLine: suppressManualBillingLine,
  addManualBillingLine: addManualBillingLine,
  suppressAutoBillingLine: suppressAutoBillingLine,
  dropSingleBerth: dropSingleBerth,
  detachSingleBerth: detachSingleBerth
};

function fieldToString(diffField) {
  return diffField;
}

var genderCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "M",
        "F",
        "X"
      ],
      toString: (function (a) {
          return a;
        })
    });

var longitudeCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "FORE",
        "MID",
        "AFT"
      ],
      toString: (function (a) {
          return a;
        })
    });

var elevProximityCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "NEARELEVATORS",
        "FARELEVATORS"
      ],
      toString: (function (a) {
          return a;
        })
    });

var subtotalCategoryCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "BOOKING_CORE",
        "NON_CORE_BOOKING_FEES",
        "CHANGE_FEES",
        "SUBTOTAL_OF_SUBTOTALS"
      ],
      toString: (function (a) {
          return a;
        })
    });

var paymentProcessorCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "INCREASE_COM",
        "BRAINTREEPAYMENTS_COM"
      ],
      toString: (function (a) {
          return a;
        })
    });

var paymentLifecycleStatusCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "NASCENT",
        "PENDING",
        "COMPLETED",
        "CANCELLED",
        "ERROR"
      ],
      toString: (function (a) {
          return a;
        })
    });

var paymentMethodCodec = QueryString$Util.Codec.customStringable({
      legalVals: [
        "SAME_DAY_ACH",
        "RTP",
        "WIRE",
        "CREDIT_CARD",
        "CHECK",
        "FCC"
      ],
      toString: (function (a) {
          return a;
        })
    });

var preferencesCodec = QueryString$Util.Codec.map(QueryString$Util.Codec.tuple6(QueryString$Util.Codec.bool, longitudeCodec, elevProximityCodec, QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString), QueryString$Util.Codec.bool, QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), {
      decode: (function (param) {
          return {
                  TAG: "Ok",
                  _0: {
                    bedsTogetherPref: param[0],
                    cabinLongitudePref: param[1],
                    cabinElevatorPref: param[2],
                    cabinPlacementRequest: param[3],
                    earlyDinnerSeatingPref: param[4],
                    generalPref: param[5]
                  }
                };
        }),
      encode: (function (param) {
          return [
                  param.bedsTogetherPref,
                  param.cabinLongitudePref,
                  param.cabinElevatorPref,
                  param.cabinPlacementRequest,
                  param.earlyDinnerSeatingPref,
                  param.generalPref
                ];
        })
    });

var bookedCabinCodec = QueryString$Util.Codec.map(QueryString$Util.Codec.tuple3(QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.BookingId)), QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.CabinNumber))), {
      decode: (function (param) {
          return {
                  TAG: "Ok",
                  _0: {
                    bookingId: param[0],
                    bookingVersionNumber: param[1],
                    cabinNumber: param[2]
                  }
                };
        }),
      encode: (function (param) {
          return [
                  param.bookingId,
                  param.bookingVersionNumber,
                  param.cabinNumber
                ];
        })
    });

var movePaymentCodec = QueryString$Util.Codec.map(QueryString$Util.Codec.tuple3(QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.BookingId)), QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.PmtTransactionId))), {
      decode: (function (param) {
          return {
                  TAG: "Ok",
                  _0: {
                    toBookingId: param[0],
                    toBookingVersionNumber: param[1],
                    publicPmtId: param[2]
                  }
                };
        }),
      encode: (function (param) {
          return [
                  param.toBookingId,
                  param.toBookingVersionNumber,
                  param.publicPmtId
                ];
        })
    });

function paxApplicationMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsIntMap.make(field, fieldToString, QueryString$Util.Codec.tuple2(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId))), set, get, (function (v) {
                return v;
              }), (function (v) {
                return v;
              }));
}

function paxInvitationMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsIntMap.make(field, fieldToString, QueryString$Util.Codec.tuple4(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Emails$Util.Email)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), set, get, (function (param) {
                return [
                        param[0],
                        {
                          email: param[1],
                          firstName: param[2],
                          lastName: param[3]
                        }
                      ];
              }), (function (param) {
                var match = param[1];
                return [
                        param[0],
                        match.email,
                        match.firstName,
                        match.lastName
                      ];
              }));
}

function manualBillingLineMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsStringMap.make(field, fieldToString, QueryString$Util.Codec.tuple4(QueryString$Util.Codec.required(QueryString$Util.Codec.string), QueryString$Util.Codec.required(subtotalCategoryCodec), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Decimal$Util.Decimal10_2)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString))), set, get, (function (param) {
                return [
                        param[0],
                        {
                          subtotalCategory: param[1],
                          amount: param[2],
                          description: param[3]
                        }
                      ];
              }), (function (param) {
                var match = param[1];
                return [
                        param[0],
                        match.subtotalCategory,
                        match.amount,
                        match.description
                      ];
              }));
}

function suppressAutoBillingLineMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsStringMap.make(field, fieldToString, QueryString$Util.Codec.tuple4(QueryString$Util.Codec.required(QueryString$Util.Codec.string), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Slugs$Util.FareRuleSlug)), QueryString$Util.Codec.customScalar(CS_Make$Util.Dateable.Plain), QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), set, get, (function (param) {
                return [
                        param[0],
                        {
                          fareRuleSlug: param[1],
                          hookFiredAt: param[2],
                          nSuppressed: param[3]
                        }
                      ];
              }), (function (param) {
                var match = param[1];
                return [
                        param[0],
                        match.fareRuleSlug,
                        match.hookFiredAt,
                        match.nSuppressed
                      ];
              }));
}

function connectedPaxMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsIntMap.make(field, fieldToString, QueryString$Util.Codec.tuple10(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), QueryString$Util.Codec.required(genderCodec), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_SQLDatesAndTimes$Util.DateOnly)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_ISO3166_1$Util.Alpha3)), QueryString$Util.Codec.customScalar(CS_LoyaltyNumbers$Util.HalMarinerNumber), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), set, get, (function (param) {
                return [
                        param[0],
                        {
                          custodianAccountId: param[1],
                          docFirstName: param[2],
                          docLastName: param[3],
                          docGender: param[4],
                          docBirthdate: param[5],
                          docCitizenship: param[6],
                          halMarinerNumber: param[7],
                          preferredFirstName: param[8],
                          preferredLastName: param[9]
                        }
                      ];
              }), (function (param) {
                var match = param[1];
                return [
                        param[0],
                        match.custodianAccountId,
                        match.docFirstName,
                        match.docLastName,
                        match.docGender,
                        match.docBirthdate,
                        match.docCitizenship,
                        match.halMarinerNumber,
                        match.preferredFirstName,
                        match.preferredLastName
                      ];
              }));
}

function patchPaxMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsIntMap.make(field, fieldToString, QueryString$Util.Codec.tuple9(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId)), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString), QueryString$Util.Codec.customScalar(CS_SQLDatesAndTimes$Util.DateOnly), genderCodec, QueryString$Util.Codec.customScalar(CS_ISO3166_1$Util.Alpha3), QueryString$Util.Codec.required(QueryString$Util.Codec.bool), QueryString$Util.Codec.delimitedArray(undefined, QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Slugs$Util.VoyageSlug)))), set, get, (function (param) {
                return [
                        param[0],
                        {
                          accountId: param[1],
                          pDocFirstName: param[2],
                          pDocLastName: param[3],
                          pDocBirthdate: param[4],
                          pDocGender: param[5],
                          pDocCitizenship: param[6],
                          updateUserAccount: param[7],
                          updateOtherVoyages: param[8]
                        }
                      ];
              }), (function (param) {
                var match = param[1];
                return [
                        param[0],
                        match.accountId,
                        match.pDocFirstName,
                        match.pDocLastName,
                        match.pDocBirthdate,
                        match.pDocGender,
                        match.pDocCitizenship,
                        match.updateUserAccount,
                        match.updateOtherVoyages
                      ];
              }));
}

function recordManualRefundMapField(field, fieldToString, get, set) {
  return QueryString$Util.Param.Multiple.AsIntMap.make(field, fieldToString, QueryString$Util.Codec.tuple13(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Emails$Util.Email)), paymentProcessorCodec, QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Decimal$Util.Decimal10_2)), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_Decimal$Util.Decimal10_2)), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString), QueryString$Util.Codec.required(paymentLifecycleStatusCodec), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString), QueryString$Util.Codec.required(paymentMethodCodec), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString)), QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString))), set, get, (function (param) {
                return [
                        param[0],
                        {
                          payee: param[1],
                          teamUserInitiator: param[2],
                          processor: param[3],
                          netRefundedAmount: param[4],
                          netRefundedBfFeeAmount: param[5],
                          referencePmtPublicId: param[6],
                          lifecycleStatus: param[7],
                          processorTransactionId: param[8],
                          pmtMethod: param[9],
                          bfcustPmtMethodIdentifier: param[10],
                          bfcustPmtSubtype: param[11],
                          bfcustPmtNote: param[12]
                        }
                      ];
              }), (function (param) {
                var match = param[1];
                return [
                        param[0],
                        match.payee,
                        match.teamUserInitiator,
                        match.processor,
                        match.netRefundedAmount,
                        match.netRefundedBfFeeAmount,
                        match.referencePmtPublicId,
                        match.lifecycleStatus,
                        match.processorTransactionId,
                        match.pmtMethod,
                        match.bfcustPmtMethodIdentifier,
                        match.bfcustPmtSubtype,
                        match.bfcustPmtNote
                      ];
              }));
}

var bookingDiffCodec = QueryString$Util.Parser.make([
      QueryString$Util.Param.Singular.make("dp.dropBerths", fieldToString, QueryString$Util.Codec.array(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), false, (function (diff, dropBerths) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.dropBerths = dropBerths;
              return newrecord;
            }), (function (diff) {
              return diff.dropBerths;
            })),
      QueryString$Util.Param.Singular.make("dp.reindexPax", fieldToString, QueryString$Util.Codec.array(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), false, (function (diff, reindexPax) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.reindexPax = reindexPax;
              return newrecord;
            }), (function (diff) {
              return diff.reindexPax;
            })),
      QueryString$Util.Param.Singular.make("dp.addBerths", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), false, (function (diff, addBerths) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.addBerths = addBerths;
              return newrecord;
            }), (function (diff) {
              return diff.addBerths;
            })),
      QueryString$Util.Param.Singular.make("dp.changeBc", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId))), false, (function (diff, changeBillingContact) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.changeBillingContact = changeBillingContact;
              return newrecord;
            }), (function (diff) {
              return diff.changeBillingContact;
            })),
      QueryString$Util.Param.Singular.make("dp.detachPax", fieldToString, QueryString$Util.Codec.array(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), false, (function (diff, detachPax) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.detachPax = detachPax;
              return newrecord;
            }), (function (diff) {
              return diff.detachPax;
            })),
      QueryString$Util.Param.Multiple.AsIntMap.make("dp.attachExistingPax", fieldToString, QueryString$Util.Codec.tuple2(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId))), (function (diff, attachExistingPax) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.attachExistingPax = attachExistingPax;
              return newrecord;
            }), (function (diff) {
              return diff.attachExistingPax;
            }), (function (v) {
              return v;
            }), (function (v) {
              return v;
            })),
      QueryString$Util.Param.Multiple.AsIntMap.make("dp.replacePaxExisting", fieldToString, QueryString$Util.Codec.tuple2(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.AccountId))), (function (diff, replacePaxExisting) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.replacePaxExisting = replacePaxExisting;
              return newrecord;
            }), (function (diff) {
              return diff.replacePaxExisting;
            }), (function (v) {
              return v;
            }), (function (v) {
              return v;
            })),
      paxInvitationMapField("dp.attachInvitedPax", fieldToString, (function (diff) {
              return diff.attachInvitedPax;
            }), (function (diff, attachInvitedPax) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.attachInvitedPax = attachInvitedPax;
              return newrecord;
            })),
      paxInvitationMapField("dp.replaceInvitedPax", fieldToString, (function (diff) {
              return diff.replaceInvitedPax;
            }), (function (diff, replaceInvitedPax) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.replaceInvitedPax = replaceInvitedPax;
              return newrecord;
            })),
      connectedPaxMapField("dp.attachNewConnectedPax", fieldToString, (function (diff) {
              return diff.attachNewConnectedPax;
            }), (function (diff, attachNewConnectedPax) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.attachNewConnectedPax = attachNewConnectedPax;
              return newrecord;
            })),
      connectedPaxMapField("dp.replaceNewConnectedPax", fieldToString, (function (diff) {
              return diff.replaceNewConnectedPax;
            }), (function (diff, replaceNewConnectedPax) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.replaceNewConnectedPax = replaceNewConnectedPax;
              return newrecord;
            })),
      QueryString$Util.Param.Singular.make("dp.updateBookingPreferences", fieldToString, preferencesCodec, false, (function (diff, updateBookingPreferences) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.updateBookingPreferences = updateBookingPreferences;
              return newrecord;
            }), (function (diff) {
              return diff.updateBookingPreferences;
            })),
      paxApplicationMapField("dp.revokePaxInvitation", fieldToString, (function (diff) {
              return diff.revokePaxInvitation;
            }), (function (diff, revokePaxInvitation) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.revokePaxInvitation = revokePaxInvitation;
              return newrecord;
            })),
      QueryString$Util.Param.Singular.make("dp.changeCabinU", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.CabinNumber))), false, (function (diff, changeCabinU) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.changeCabinU = changeCabinU;
              return newrecord;
            }), (function (diff) {
              return diff.changeCabinU;
            })),
      QueryString$Util.Param.Singular.make("dp.changeCabinB", fieldToString, bookedCabinCodec, false, (function (diff, changeCabinB) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.changeCabinB = changeCabinB;
              return newrecord;
            }), (function (diff) {
              return diff.changeCabinB;
            })),
      QueryString$Util.Param.Singular.make("dp.suppressManualBillingLines", fieldToString, QueryString$Util.Codec.array(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), false, (function (diff, suppressManualBillingLines) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.suppressManualBillingLines = suppressManualBillingLines;
              return newrecord;
            }), (function (diff) {
              return diff.suppressManualBillingLines;
            })),
      manualBillingLineMapField("dp.addManualBillingLines", fieldToString, (function (diff) {
              return diff.addManualBillingLines;
            }), (function (diff, addManualBillingLines) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.addManualBillingLines = addManualBillingLines;
              return newrecord;
            })),
      QueryString$Util.Param.Singular.make("dp.suppressManualBillingLines", fieldToString, QueryString$Util.Codec.array(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int)), false, (function (diff, suppressManualBillingLines) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.suppressManualBillingLines = suppressManualBillingLines;
              return newrecord;
            }), (function (diff) {
              return diff.suppressManualBillingLines;
            })),
      suppressAutoBillingLineMapField("dp.suppressAutoBillingLines", fieldToString, (function (diff) {
              return diff.suppressAutoBillingLines;
            }), (function (diff, suppressAutoBillingLines) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.suppressAutoBillingLines = suppressAutoBillingLines;
              return newrecord;
            })),
      manualBillingLineMapField("dp.addManualBillingLines", fieldToString, (function (diff) {
              return diff.addManualBillingLines;
            }), (function (diff, addManualBillingLines) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.addManualBillingLines = addManualBillingLines;
              return newrecord;
            })),
      patchPaxMapField("dp.patchPax", fieldToString, (function (diff) {
              return diff.patchPax;
            }), (function (diff, patchPax) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.patchPax = patchPax;
              return newrecord;
            })),
      recordManualRefundMapField("dp.recordManualRefunds", fieldToString, (function (diff) {
              return diff.recordManualRefunds;
            }), (function (diff, recordManualRefunds) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.recordManualRefunds = recordManualRefunds;
              return newrecord;
            })),
      QueryString$Util.Param.Multiple.AsArray.make("dp.movePayments", fieldToString, movePaymentCodec, (function (diff, movePayments) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.movePayments = movePayments;
              return newrecord;
            }), (function (diff) {
              return diff.movePayments;
            })),
      QueryString$Util.Param.Singular.make("dp.cancellationReason", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.customScalar(CS_NonemptyStrings$Util.NonEmptyString))), false, (function (diff, cancellationReason) {
              var newrecord = Caml_obj.obj_dup(diff);
              newrecord.cancellationReason = cancellationReason;
              return newrecord;
            }), (function (diff) {
              return diff.cancellationReason;
            }))
    ], emptyDiff);

function parse(queryString) {
  var bd = bookingDiffCodec.decode(queryString);
  if (Caml_obj.equal(bd, emptyDiff)) {
    return emptyDiff;
  }
  var newrecord = Caml_obj.obj_dup(bd);
  newrecord.hasDiff = true;
  return newrecord;
}

function serialize(queryString) {
  return bookingDiffCodec.encode(queryString);
}

var $$String;

var Int;

var $$JSON;

var emptyPrefs = {
  bedsTogetherPref: undefined,
  cabinLongitudePref: undefined,
  cabinElevatorPref: undefined,
  cabinPlacementRequest: undefined,
  earlyDinnerSeatingPref: undefined,
  generalPref: undefined
};

exports.$$String = $$String;
exports.Int = Int;
exports.$$JSON = $$JSON;
exports.emptyDiff = emptyDiff;
exports.emptyPrefs = emptyPrefs;
exports.UnnestedDiff = UnnestedDiff;
exports.Alter = Alter;
exports.fieldToString = fieldToString;
exports.genderCodec = genderCodec;
exports.longitudeCodec = longitudeCodec;
exports.elevProximityCodec = elevProximityCodec;
exports.subtotalCategoryCodec = subtotalCategoryCodec;
exports.paymentProcessorCodec = paymentProcessorCodec;
exports.paymentLifecycleStatusCodec = paymentLifecycleStatusCodec;
exports.paymentMethodCodec = paymentMethodCodec;
exports.preferencesCodec = preferencesCodec;
exports.bookedCabinCodec = bookedCabinCodec;
exports.movePaymentCodec = movePaymentCodec;
exports.bookingDiffCodec = bookingDiffCodec;
exports.parse = parse;
exports.serialize = serialize;
/* genderCodec Not a pure module */
