// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var Check = require("@mui/icons-material/Check").default;
var $$Event = require("@mui/icons-material/Event").default;
var $$History = require("@mui/icons-material/History").default;
var QuestionMark = require("@mui/icons-material/QuestionMark").default;
var WarningAmber = require("@mui/icons-material/WarningAmber").default;

var longDateFormat = "MMMM d, yyyy";

function getIcon(s) {
  var tmp;
  if (s === "PAID_UP" || s === "BALANCE_DUE" || s === "BALANCE_OVERDUE" || s === "UPCOMING_BALANCE") {
    switch (s) {
      case "BALANCE_OVERDUE" :
          tmp = JsxRuntime.jsx(WarningAmber, {
                fontSize: "md",
                sx: {
                  color: "white"
                }
              });
          break;
      case "BALANCE_DUE" :
          tmp = JsxRuntime.jsx($$History, {
                fontSize: "md",
                sx: {
                  color: "white"
                }
              });
          break;
      case "PAID_UP" :
          tmp = JsxRuntime.jsx(Check, {
                fontSize: "md",
                sx: {
                  color: "white"
                }
              });
          break;
      case "UPCOMING_BALANCE" :
          tmp = JsxRuntime.jsx($$Event, {
                fontSize: "md",
                sx: {
                  color: "#6E7F8C"
                }
              });
          break;
      
    }
  } else {
    tmp = JsxRuntime.jsx(QuestionMark, {
          fontSize: "md",
          sx: {
            color: "#6E7F8C"
          }
        });
  }
  var tmp$1;
  if (s === "PAID_UP" || s === "BALANCE_DUE" || s === "BALANCE_OVERDUE" || s === "UPCOMING_BALANCE") {
    switch (s) {
      case "BALANCE_OVERDUE" :
          tmp$1 = "#DE4F48";
          break;
      case "BALANCE_DUE" :
          tmp$1 = "#30557A";
          break;
      case "PAID_UP" :
          tmp$1 = "primary.500";
          break;
      case "UPCOMING_BALANCE" :
          tmp$1 = "#F0F3F5";
          break;
      
    }
  } else {
    tmp$1 = "#F0F3F5";
  }
  return JsxRuntime.jsx(Box, {
              children: Caml_option.some(tmp),
              sx: {
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                backgroundColor: tmp$1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }
            });
}

function getText(dueDate, newAmountDue, numericAmount, paymentAmtType, periodStatus) {
  var dateClause = dueDate !== undefined ? "By " + CS_Make$Util.Dateable.Plain.format(Caml_option.valFromOption(dueDate), longDateFormat) + ", " : "";
  var parenthetical;
  var exit = 0;
  if (periodStatus === "PAID_UP" || periodStatus === "BALANCE_DUE" || periodStatus === "BALANCE_OVERDUE" || periodStatus === "UPCOMING_BALANCE") {
    switch (periodStatus) {
      case "BALANCE_OVERDUE" :
      case "UPCOMING_BALANCE" :
          exit = 1;
          break;
      default:
        parenthetical = "";
    }
  } else {
    parenthetical = "";
  }
  if (exit === 1) {
    parenthetical = " (" + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(newAmountDue)) + ")";
  }
  var amtClause;
  if (paymentAmtType === "FLAT_AMOUNT" || paymentAmtType === "PER_GUEST_AMOUNT" || paymentAmtType === "PERCENTAGE_OF_APPLICABLE_BALANCE") {
    switch (paymentAmtType) {
      case "PER_GUEST_AMOUNT" :
          amtClause = Common$Thick.formatCurrency(numericAmount) + " per berth" + parenthetical;
          break;
      case "FLAT_AMOUNT" :
          amtClause = Common$Thick.formatCurrency(numericAmount);
          break;
      case "PERCENTAGE_OF_APPLICABLE_BALANCE" :
          amtClause = String(numericAmount * 100.0 | 0) + "% of applicable balance" + parenthetical;
          break;
      
    }
  } else {
    amtClause = "If you're seeing this, something went wrong";
  }
  return dateClause + amtClause;
}

function BDDepositScheduleDetails$DepositScheduleList(props) {
  var content = props.content;
  var len = content.length;
  return JsxRuntime.jsx(List, {
              children: Caml_option.some(Belt_Array.mapWithIndex(content, (function (i, item) {
                          var periodStatus = item.periodStatus;
                          var tmp;
                          tmp = (periodStatus === "PAID_UP" || periodStatus === "BALANCE_DUE" || periodStatus === "BALANCE_OVERDUE" || periodStatus === "UPCOMING_BALANCE") && periodStatus === "PAID_UP" ? "neutral.400" : "inherit";
                          return JsxRuntime.jsx(ListItem, {
                                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                spacing: 2,
                                                sx: {
                                                  py: 1,
                                                  width: "100%"
                                                },
                                                children: [
                                                  JsxRuntime.jsx(Stack, {
                                                        direction: "column",
                                                        alignItems: "center",
                                                        sx: {
                                                          position: "relative",
                                                          "&::before": {
                                                            content: "\"\"",
                                                            position: "absolute",
                                                            top: "26px",
                                                            borderLeft: (i + 1 | 0) === len ? "0px" : "2px dotted",
                                                            height: "94%",
                                                            borderColor: "divider"
                                                          }
                                                        },
                                                        children: Caml_option.some(getIcon(periodStatus))
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        sx: {
                                                          color: tmp
                                                        },
                                                        children: Caml_option.some(getText(item.dueDate, item.newAmountDue, item.numericAmount, item.paymentAmtType, periodStatus))
                                                      })
                                                ]
                                              }))
                                    }, String(i));
                        }))),
              size: "lg",
              sx: {
                position: "relative"
              }
            });
}

var DepositScheduleList = {
  getIcon: getIcon,
  getText: getText,
  make: BDDepositScheduleDetails$DepositScheduleList
};

function BDDepositScheduleDetails(props) {
  return JsxRuntime.jsxs(Stack, {
              spacing: 2,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h4",
                      component: "h4",
                      children: "Deposit Schedule"
                    }),
                JsxRuntime.jsx(BDDepositScheduleDetails$DepositScheduleList, {
                      content: props.depositSchedule
                    })
              ]
            });
}

var make = BDDepositScheduleDetails;

exports.longDateFormat = longDateFormat;
exports.DepositScheduleList = DepositScheduleList;
exports.make = make;
/* @mui/joy/Box Not a pure module */
