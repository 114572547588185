// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick = require("../../../__generated__/CreateNewAccountSubmit_CreateBfcustUserMutation_graphql.bs.js");

var convertVariables = CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var CreateBfcustUserMutation_createBfcustUserErr_decode = CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.Utils.createBfcustUserErr_decode;

var CreateBfcustUserMutation_createBfcustUserErr_fromString = CreateNewAccountSubmit_CreateBfcustUserMutation_graphql$Thick.Utils.createBfcustUserErr_fromString;

var CreateBfcustUserMutation = {
  createBfcustUserErr_decode: CreateBfcustUserMutation_createBfcustUserErr_decode,
  createBfcustUserErr_fromString: CreateBfcustUserMutation_createBfcustUserErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(toBrandFamilySlug, refresh, onClose) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = React.useState(function () {
        return "";
      });
  var email = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var firstName = match$3[0];
  var match$4 = React.useState(function () {
        return "";
      });
  var lastName = match$4[0];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    var match = CS_Emails$Util.Email.fromPrimitive(email);
    var match$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(firstName);
    var match$2 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(lastName);
    if (match.TAG === "Ok" && match$1.TAG === "Ok" && match$2.TAG === "Ok") {
      mutate({
            input: {
              toBrandFamilySlug: toBrandFamilySlug,
              accountEmail: match._0,
              accountFirstName: match$1._0,
              accountLastName: match$2._0
            }
          }, undefined, undefined, (function (store, param) {
              store.invalidateStore();
            }), (function (resp, err) {
              if (err === undefined) {
                var match = resp.createBfcustUser;
                if (match === undefined) {
                  return setError(function (param) {
                              return "Something went wrong, please try again.";
                            });
                }
                var createBfcustUserErr = match.createBfcustUserErr;
                if (createBfcustUserErr !== undefined) {
                  var err$1;
                  err$1 = createBfcustUserErr === "EMAIL_ALREADY_IN_USE" || createBfcustUserErr === "USER_CANT_BE_CREATED_ASK_SUPPORT" ? (
                      createBfcustUserErr === "EMAIL_ALREADY_IN_USE" ? "Email already in use" : "User can't be created, please contact support"
                    ) : "Something went wrong, please try again.";
                  setError(function (param) {
                        return err$1;
                      });
                  return ;
                }
                console.log("Success");
                refresh();
                onClose();
                return ;
              }
              setError(function (param) {
                    return "Something went wrong, please try again.";
                  });
            }), (function (err) {
              console.log(err);
              setError(function (param) {
                    return "Something went wrong, please try again.";
                  });
            }), undefined);
      return ;
    }
    setError(function (param) {
          return "Please enter a valid email address, first name, and last name.";
        });
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: match$2[1],
          firstName: firstName,
          setFirstName: match$3[1],
          lastName: lastName,
          setLastName: match$4[1],
          error: match$1[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

exports.CreateBfcustUserMutation = CreateBfcustUserMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
