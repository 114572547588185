// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Styles = require("@mui/joy/styles");
var TeamRoute$Thick = require("./routes/TeamRoute.bs.js");
var LoginRoute$Thick = require("./routes/LoginRoute.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var AccountRoute$Thick = require("./routes/AccountRoute.bs.js");
var DeckMapRoute$Thick = require("./routes/DeckMapRoute.bs.js");
var BookingsRoute$Thick = require("./routes/BookingsRoute.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var WaitlistRoute$Thick = require("./routes/WaitlistRoute.bs.js");
var DashboardRoute$Thick = require("./routes/DashboardRoute.bs.js");
var PassengersRoute$Thick = require("./routes/PassengersRoute.bs.js");
var BrandFamilyRoute$Thick = require("./routes/BrandFamilyRoute.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BookingDetailsRoute$Thick = require("./routes/BookingDetailsRoute.bs.js");
var CabinInventoryRoute$Thick = require("./routes/CabinInventoryRoute.bs.js");
var ForgotPasswordRoute$Thick = require("./routes/ForgotPasswordRoute.bs.js");
var VoyageSettingsRoute$Thick = require("./routes/VoyageSettingsRoute.bs.js");
var VoyageDashboardRoute$Thick = require("./routes/VoyageDashboardRoute.bs.js");
var CustomerAccountsRoute$Thick = require("./routes/CustomerAccountsRoute.bs.js");
var LatestBookingVersionRedirectRoute$Thick = require("./routes/LatestBookingVersionRedirectRoute.bs.js");

function ThickApp$RedirectToLogin(props) {
  React.useEffect((function () {
          RescriptReactRouter.push("/login");
        }), []);
  return JsxRuntime.jsx("div", {});
}

var RedirectToLogin = {
  make: ThickApp$RedirectToLogin
};

function ThickApp(props) {
  var urlInitial = RescriptReactRouter.useUrl(undefined, undefined);
  var url = React.useDeferredValue(urlInitial);
  console.log(url.path);
  var match = url.path;
  var tmp;
  if (match) {
    var brandFamily = match.hd;
    var exit = 0;
    switch (brandFamily) {
      case "account" :
          if (match.tl) {
            exit = 1;
          } else {
            tmp = JsxRuntime.jsx(AccountRoute$Thick.make, {});
          }
          break;
      case "dashboard" :
          if (match.tl) {
            exit = 1;
          } else {
            tmp = JsxRuntime.jsx(DashboardRoute$Thick.make, {});
          }
          break;
      case "forgot-password" :
          if (match.tl) {
            exit = 1;
          } else {
            tmp = JsxRuntime.jsx(ForgotPasswordRoute$Thick.make, {});
          }
          break;
      case "login" :
          if (match.tl) {
            exit = 1;
          } else {
            tmp = JsxRuntime.jsx(LoginRoute$Thick.make, {});
          }
          break;
      default:
        exit = 1;
    }
    if (exit === 1) {
      var match$1 = match.tl;
      if (match$1) {
        var voyage = match$1.hd;
        var exit$1 = 0;
        switch (voyage) {
          case "customers" :
              if (match$1.tl) {
                exit$1 = 2;
              } else {
                tmp = JsxRuntime.jsx(CustomerAccountsRoute$Thick.make, {
                      brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily)
                    });
              }
              break;
          case "team" :
              if (match$1.tl) {
                exit$1 = 2;
              } else {
                tmp = JsxRuntime.jsx(TeamRoute$Thick.make, {
                      brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily)
                    });
              }
              break;
          default:
            exit$1 = 2;
        }
        if (exit$1 === 2) {
          var match$2 = match$1.tl;
          if (match$2) {
            switch (match$2.hd) {
              case "bookings" :
                  var match$3 = match$2.tl;
                  if (match$3) {
                    var match$4 = match$3.tl;
                    var bookingId = match$3.hd;
                    tmp = match$4 ? (
                        match$4.tl ? "Not found" : JsxRuntime.jsx(BookingDetailsRoute$Thick.make, {
                                bookingId: CS_NonemptyStrings$Util.BookingId.Exn.fromPrimitive(bookingId),
                                voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage),
                                brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                                bookingVersionNumber: match$4.hd
                              })
                      ) : JsxRuntime.jsx(LatestBookingVersionRedirectRoute$Thick.make, {
                            brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                            voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage),
                            bookingId: CS_NonemptyStrings$Util.BookingId.Exn.fromPrimitive(bookingId)
                          });
                  } else {
                    tmp = JsxRuntime.jsx(BookingsRoute$Thick.make, {
                          brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                          voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                        });
                  }
                  break;
              case "cabins" :
                  tmp = match$2.tl ? "Not found" : JsxRuntime.jsx(CabinInventoryRoute$Thick.make, {
                          brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                          voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                        });
                  break;
              case "deck-map" :
                  tmp = match$2.tl ? "Not found" : JsxRuntime.jsx(DeckMapRoute$Thick.make, {
                          brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                          voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                        });
                  break;
              case "passengers" :
                  tmp = match$2.tl ? "Not found" : JsxRuntime.jsx(PassengersRoute$Thick.make, {
                          brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                          voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                        });
                  break;
              case "settings" :
                  tmp = match$2.tl ? "Not found" : JsxRuntime.jsx(VoyageSettingsRoute$Thick.make, {
                          brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                          voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                        });
                  break;
              case "waitlist" :
                  tmp = match$2.tl ? "Not found" : JsxRuntime.jsx(WaitlistRoute$Thick.make, {
                          brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                          voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                        });
                  break;
              default:
                tmp = "Not found";
            }
          } else {
            tmp = JsxRuntime.jsx(VoyageDashboardRoute$Thick.make, {
                  brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily),
                  voyage: CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage)
                });
          }
        }
        
      } else {
        tmp = JsxRuntime.jsx(BrandFamilyRoute$Thick.make, {
              brandFamily: CS_Slugs$Util.BrandFamilySlug.Exn.fromPrimitive(brandFamily)
            });
      }
    }
    
  } else {
    tmp = JsxRuntime.jsx(ThickApp$RedirectToLogin, {});
  }
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsx(Styles.CssVarsProvider, {
                        children: Caml_option.some(tmp)
                      })),
              fallback: "Loading"
            });
}

var make = ThickApp;

exports.RedirectToLogin = RedirectToLogin;
exports.make = make;
/* react Not a pure module */
