// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"brandFamilyVoyages":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function brandfamRole_decode($$enum) {
  if ($$enum === "CUSTOMER_SERVICE" || $$enum === "BRAND_FAMILY_ADMIN" || $$enum === "INVENTORY_MANAGER") {
    return $$enum;
  }
  
}

function brandfamRole_fromString(str) {
  return brandfamRole_decode(str);
}

var Utils = {
  brandfamRole_decode: brandfamRole_decode,
  brandfamRole_fromString: brandfamRole_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrandFamilyAccessSection_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandFamilySlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandFamilyRoles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandFamilyVoyages",
      "storageKey": null
    }
  ],
  "type": "CurrentUserProfile",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Slugs-Util Not a pure module */
