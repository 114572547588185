// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var SendResetToken_RequestPasswordResetMutation_graphql$Thick = require("../../../__generated__/SendResetToken_RequestPasswordResetMutation_graphql.bs.js");

var convertVariables = SendResetToken_RequestPasswordResetMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = SendResetToken_RequestPasswordResetMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = SendResetToken_RequestPasswordResetMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, SendResetToken_RequestPasswordResetMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SendResetToken_RequestPasswordResetMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var RequestPasswordResetMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation() {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var email = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setTokenSent = match$3[1];
  var requestPasswordReset = function (param) {
    setError(function (param) {
          
        });
    mutate({
          input: {
            allegedEmail: email
          }
        }, undefined, undefined, undefined, (function (param, err) {
            if (err !== undefined) {
              return setError(function (param) {
                          return "An error occurred sending reset token. Please try again.";
                        });
            } else {
              return setTokenSent(function (param) {
                          return true;
                        });
            }
          }), (function (param) {
            setError(function (param) {
                  return "An error occurred sending reset token. Please try again.";
                });
          }), undefined);
  };
  var onSubmit = function (e) {
    e.preventDefault();
    requestPasswordReset();
  };
  var onClick = function (e) {
    e.preventDefault();
    requestPasswordReset();
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: match$1[1],
          error: match$2[0],
          tokenSent: match$3[0],
          onSubmit: onSubmit,
          onClick: onClick
        };
}

exports.RequestPasswordResetMutation = RequestPasswordResetMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
