// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var PervasivesU = require("rescript/lib/js/pervasivesU.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Storage$Util = require("util/src/Storage.bs.js");
var List = require("@mui/joy/List").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var ListItem = require("@mui/joy/ListItem").default;
var LeftNavMenu$Thick = require("./molecules/LeftNavMenu.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var ListDivider = require("@mui/joy/ListDivider").default;
var ListItemButton = require("@mui/joy/ListItemButton").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var LeftNavQuery_graphql$Thick = require("../../__generated__/LeftNavQuery_graphql.bs.js");
var ArrowForward = require("@mui/icons-material/ArrowForward").default;
var HomeOutlined = require("@mui/icons-material/HomeOutlined").default;
var Groups2Outlined = require("@mui/icons-material/Groups2Outlined").default;

var convertVariables = LeftNavQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = LeftNavQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = LeftNavQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, LeftNavQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, LeftNavQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(LeftNavQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(LeftNavQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(LeftNavQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(LeftNavQuery_graphql$Thick.node, convertVariables);

var Query_brandFamilyPermission_decode = LeftNavQuery_graphql$Thick.Utils.brandFamilyPermission_decode;

var Query_brandFamilyPermission_fromString = LeftNavQuery_graphql$Thick.Utils.brandFamilyPermission_fromString;

var Query = {
  brandFamilyPermission_decode: Query_brandFamilyPermission_decode,
  brandFamilyPermission_fromString: Query_brandFamilyPermission_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function LeftNav(props) {
  var selectedVoyage = props.voyage;
  var brandFamily = props.brandFamily;
  var navKey = "leftnav:" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + ":expanded";
  var match = React.useState(function () {
        var match = Storage$Util.Local.get(navKey);
        if (match !== undefined) {
          switch (match) {
            case "false" :
                return false;
            case "true" :
                return true;
            default:
              Storage$Util.Local.set(navKey, "false");
              return false;
          }
        } else {
          Storage$Util.Local.set(navKey, "false");
          return false;
        }
      });
  var setIsExpanded = match[1];
  var isExpanded = match[0];
  var handleExpand = function (v) {
    var val = v !== undefined ? PervasivesU.string_of_bool(v) : PervasivesU.string_of_bool(!isExpanded);
    Storage$Util.Local.set(navKey, val);
    setIsExpanded(function (param) {
          return !isExpanded;
        });
  };
  var data = use({
        brandFamily: brandFamily,
        email: props.email
      }, undefined, undefined, undefined);
  var userPerms = Belt_Set.fromArray(Belt_Array.keepMap(Belt_Option.getWithDefault(data.userPerms, []), (function (x) {
              if (x !== undefined) {
                return x;
              }
              
            })), Common$Thick.PermCmp);
  var match$1 = data.currentUserProfiles;
  return JsxRuntime.jsxs(Sheet, {
              sx: {
                maxWidth: isExpanded ? "250px" : "65px",
                borderRight: 1,
                borderColor: "neutral.300",
                backgroundColor: "background.body",
                transition: "max-width 0.2s ease-in-out",
                display: "flex",
                flexGrow: 0,
                flexDirection: "column"
              },
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      justifyContent: "center",
                      spacing: 2,
                      children: Caml_option.some(JsxRuntime.jsxs(List, {
                                children: [
                                  JsxRuntime.jsx(ListItem, {
                                        children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                  children: JsxRuntime.jsxs(ListItemButton, {
                                                        children: [
                                                          JsxRuntime.jsx(ListItemDecorator, {
                                                                children: Caml_option.some(JsxRuntime.jsx(HomeOutlined, {}))
                                                              }),
                                                          JsxRuntime.jsx(Typography, {
                                                                level: "body-sm",
                                                                noWrap: true,
                                                                sx: {
                                                                  opacity: isExpanded ? "1" : "0",
                                                                  maxWidth: isExpanded ? "100%" : "0",
                                                                  transition: "opacity 0.2s, max-width 0.2s",
                                                                  overflow: "hidden"
                                                                },
                                                                children: "Home"
                                                              })
                                                        ],
                                                        onClick: (function (param) {
                                                            RescriptReactRouter.push("/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily));
                                                          }),
                                                        sx: {
                                                          display: "flex",
                                                          px: 2.5
                                                        }
                                                      }),
                                                  arrow: true,
                                                  disableHoverListener: isExpanded,
                                                  placement: "right",
                                                  size: "sm",
                                                  title: "Home"
                                                }))
                                      }),
                                  Belt_Set.has(userPerms, "VIEW_CUSTOMER_DETAILS") ? JsxRuntime.jsx(ListItem, {
                                          children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                    children: JsxRuntime.jsxs(ListItemButton, {
                                                          children: [
                                                            JsxRuntime.jsx(ListItemDecorator, {
                                                                  children: Caml_option.some(JsxRuntime.jsx(Groups2Outlined, {}))
                                                                }),
                                                            JsxRuntime.jsx(Typography, {
                                                                  level: "body-sm",
                                                                  noWrap: true,
                                                                  sx: {
                                                                    opacity: isExpanded ? "1" : "0",
                                                                    maxWidth: isExpanded ? "100%" : "0",
                                                                    transition: "opacity 0.2s, max-width 0.2s",
                                                                    overflow: "hidden"
                                                                  },
                                                                  children: "Customer Accounts"
                                                                })
                                                          ],
                                                          onClick: (function (param) {
                                                              RescriptReactRouter.push("/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + "/customers");
                                                            }),
                                                          sx: {
                                                            overflow: "hidden",
                                                            display: "flex",
                                                            px: 2.5
                                                          }
                                                        }),
                                                    arrow: true,
                                                    disableHoverListener: isExpanded,
                                                    placement: "right",
                                                    size: "sm",
                                                    title: "Customer Accounts"
                                                  }))
                                        }) : null,
                                  JsxRuntime.jsx(ListDivider, {
                                        inset: "context"
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        direction: "row",
                                        sx: {
                                          height: "fit-content",
                                          alignSelf: "flex-start",
                                          width: "100%",
                                          justifyContent: "flex-end",
                                          position: "relative"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                                  onClick: (function (param) {
                                                      handleExpand(undefined);
                                                    }),
                                                  "aria-label": isExpanded ? "Collapse left menu" : "Expand left menu",
                                                  size: "sm",
                                                  sx: {
                                                    position: "absolute",
                                                    right: "-16px",
                                                    top: "-23px",
                                                    backgroundColor: "background.body",
                                                    borderRadius: "50%",
                                                    border: 1,
                                                    borderColor: "neutral.300",
                                                    zIndex: 50
                                                  },
                                                  children: Caml_option.some(JsxRuntime.jsx(ArrowForward, {
                                                            fontSize: "sm",
                                                            sx: {
                                                              transform: isExpanded ? "rotate(-180deg)" : "rotate(0deg)",
                                                              transition: "transform 0.2s ease-in-out"
                                                            }
                                                          }))
                                                }))
                                      })
                                ],
                                size: "md"
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      children: Caml_option.some(JsxRuntime.jsx(List, {
                                children: Caml_option.some(match$1 !== undefined ? Belt_Array.mapWithIndex(match$1.nodes, (function (i, node) {
                                              return JsxRuntime.jsx(LeftNavMenu$Thick.make, {
                                                          profile: node.fragmentRefs,
                                                          isExpanded: isExpanded,
                                                          setIsExpanded: handleExpand,
                                                          brandFamily: brandFamily,
                                                          selectedVoyage: selectedVoyage,
                                                          userPerms: userPerms
                                                        }, i);
                                            })) : null)
                              }))
                    })
              ]
            });
}

var make = LeftNav;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
