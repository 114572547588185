// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation_graphql$Thick = require("../../../__generated__/TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation_graphql.bs.js");

var convertVariables = TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var TransferUnclaimedBfcustUserMutation_transferErr_decode = TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation_graphql$Thick.Utils.transferErr_decode;

var TransferUnclaimedBfcustUserMutation_transferErr_fromString = TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation_graphql$Thick.Utils.transferErr_fromString;

var TransferUnclaimedBfcustUserMutation = {
  transferErr_decode: TransferUnclaimedBfcustUserMutation_transferErr_decode,
  transferErr_fromString: TransferUnclaimedBfcustUserMutation_transferErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(refresh, _accountId, _brandFamilySlug, onClose) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setEmail = match$1[1];
  var email = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    var _invitationEmail = CS_Emails$Util.Email.fromPrimitive(email);
    if (_invitationEmail.TAG === "Ok") {
      mutate({
            input: {
              _brandFamilySlug: _brandFamilySlug,
              _accountId: _accountId,
              _invitationEmail: _invitationEmail._0
            }
          }, undefined, undefined, (function (store, param) {
              store.invalidateStore();
            }), (function (resp, err) {
              if (err !== undefined) {
                console.log(err);
                setError(function (param) {
                      return "An error occurred in transfer 1";
                    });
                return ;
              }
              var resp$1 = resp.transferUnclaimedBfcustUser;
              if (resp$1 !== undefined) {
                var match = resp$1.inviteToTransferResult;
                if (match !== undefined) {
                  var err$1 = match.nullableErr;
                  if (err$1 !== undefined) {
                    if (err$1 === "USER_CANT_BE_TRANSFERRED") {
                      setError(function (param) {
                            return "User can't be transferred";
                          });
                      return ;
                    }
                    setError(function (param) {
                          return "An error occurred in transfer 4";
                        });
                    return ;
                  }
                  var match$1 = match.success;
                  if (match$1 !== undefined && match$1) {
                    setError(function (param) {
                          
                        });
                    setEmail(function (param) {
                          return "";
                        });
                    refresh();
                    onClose();
                    return ;
                  }
                  
                }
                setError(function (param) {
                      return "An error occurred in transfer 3";
                    });
                return ;
              }
              setError(function (param) {
                    return "An error occurred in transfer 2";
                  });
            }), (function (err) {
              console.log(err);
              setError(function (param) {
                    return "An error occurred in transfer 5";
                  });
            }), undefined);
      return ;
    }
    var err = _invitationEmail._0;
    setError(function (param) {
          return err;
        });
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: setEmail,
          error: match$2[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

exports.TransferUnclaimedBfcustUserMutation = TransferUnclaimedBfcustUserMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
