// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thick = require("../../../__generated__/ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql.bs.js");

var convertVariables = ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var ResetPasswordWithTokenMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation() {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var newPasswd = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setPassUpdateSuccess = match$4[1];
  var onSubmit = function (e, token) {
    e.preventDefault();
    setError(function (param) {
          
        });
    mutate({
          input: {
            allegedToken: token,
            newPasswd: newPasswd
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.resetPasswordWithToken;
              if (match !== undefined) {
                var match$1 = match.boolean;
                if (match$1 !== undefined && match$1) {
                  console.log("Successfully updated password");
                  setPassUpdateSuccess(function (param) {
                        return true;
                      });
                  return ;
                }
                
              }
              console.log("Failed to update password");
              return setError(function (param) {
                          return "Failed to update password";
                        });
            }
            console.log("Updating password failed");
            setError(function (param) {
                  return "Updating password failed";
                });
          }), (function (param) {
            console.log("Password update failed");
            setError(function (param) {
                  return "Password update failed";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          newPasswd: newPasswd,
          setNewPasswd: match$1[1],
          confirmPasswd: match$2[0],
          setConfirmPasswd: match$2[1],
          error: match$3[0],
          passUpdateSuccess: match$4[0],
          onSubmit: onSubmit
        };
}

exports.ResetPasswordWithTokenMutation = ResetPasswordWithTokenMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
