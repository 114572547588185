'use client';

import { deepmerge } from '@mui/utils';
import { unstable_createCssVarsProvider as createCssVarsProvider } from '@mui/system';
import defaultTheme from './defaultTheme';
import { createSoftInversion, createSolidInversion } from './variantUtils';
import THEME_ID from './identifier';
const {
  CssVarsProvider,
  useColorScheme,
  getInitColorSchemeScript
} = createCssVarsProvider({
  themeId: THEME_ID,
  theme: defaultTheme,
  attribute: 'data-joy-color-scheme',
  modeStorageKey: 'joy-mode',
  colorSchemeStorageKey: 'joy-color-scheme',
  defaultColorScheme: {
    light: 'light',
    dark: 'dark'
  },
  resolveTheme: mergedTheme => {
    const colorInversionInput = mergedTheme.colorInversion;
    mergedTheme.colorInversion = deepmerge({
      soft: createSoftInversion(mergedTheme),
      solid: createSolidInversion(mergedTheme)
    }, typeof colorInversionInput === 'function' ? colorInversionInput(mergedTheme) : colorInversionInput, {
      clone: false
    });
    return mergedTheme;
  }
});
export { CssVarsProvider, useColorScheme, getInitColorSchemeScript };