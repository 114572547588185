// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Float = require("rescript/lib/js/belt_Float.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var DraftUtils$Thick = require("./DraftUtils.bs.js");
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var subtotalCategories = [
  "BOOKING_CORE",
  "NON_CORE_BOOKING_FEES",
  "CHANGE_FEES",
  "SUBTOTAL_OF_SUBTOTALS"
];

function AddManualBillingLineModal(props) {
  var passthruId = props.passthruId;
  var versionUrl = props.versionUrl;
  var onClose = props.onClose;
  var match = React.useState(function () {
        return "";
      });
  var setDescription = match[1];
  var description = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setAmount = match$1[1];
  var amount = match$1[0];
  var match$2 = React.useState(function () {
        return "BOOKING_CORE";
      });
  var setSubtotalCategory = match$2[1];
  var subtotalCategory = match$2[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match$3 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, onClose);
  var clearPreviewFields = match$3.clearPreviewFields;
  var setApplyDiff = match$3.setApplyDiff;
  var setError = match$3.setError;
  var error = match$3.error;
  var clearAll = function () {
    clearPreviewFields();
    setAmount(function (param) {
          return "";
        });
    setDescription(function (param) {
          return "";
        });
    setSubtotalCategory(function (param) {
          return "BOOKING_CORE";
        });
  };
  var addManualBillingLine = function (e) {
    e.preventDefault();
    var match = CS_Decimal$Util.Decimal10_2.fromPrimitive(amount);
    var match$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(description);
    if (match.TAG === "Ok" && match$1.TAG === "Ok") {
      var newLine_amount = match._0;
      var newLine_description = match$1._0;
      var newLine = {
        subtotalCategory: subtotalCategory,
        amount: newLine_amount,
        description: newLine_description
      };
      var applyDiff = BookingDiff$Thick.Alter.addManualBillingLine(diffStruct, newLine, passthruId);
      setApplyDiff(function (param) {
            return applyDiff;
          });
      DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
      return onClose();
    }
    setError(function (param) {
          return "Please enter a valid amount and description.";
        });
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.open_,
                      onClose: onClose,
                      title: "Add Manual Billing Line",
                      children: [
                        JsxRuntime.jsxs(DialogContent, {
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "title-md",
                                      sx: {
                                        marginBottom: "1rem"
                                      },
                                      children: "Add a manual billing line to this booking."
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 1,
                                      children: [
                                        JsxRuntime.jsxs(FormControl, {
                                              children: [
                                                JsxRuntime.jsx(FormLabel, {
                                                      children: "Subtotal Category"
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                      children: Caml_option.some(Belt_Array.mapWithIndex(subtotalCategories, (function (i, cat) {
                                                                  return JsxRuntime.jsx($$Option, {
                                                                              value: cat,
                                                                              children: Caml_option.some(cat)
                                                                            }, String(i));
                                                                }))),
                                                      onChange: (function (param, val) {
                                                          if (val === undefined) {
                                                            return ;
                                                          }
                                                          var val$1 = Caml_option.valFromOption(val);
                                                          if (val$1 !== undefined) {
                                                            return setSubtotalCategory(function (param) {
                                                                        return val$1;
                                                                      });
                                                          }
                                                          
                                                        }),
                                                      placeholder: "",
                                                      required: true,
                                                      size: "sm",
                                                      value: subtotalCategory
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsxs(FormControl, {
                                              error: Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(description)),
                                              children: [
                                                JsxRuntime.jsx(FormLabel, {
                                                      children: "Description"
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Input.make, {
                                                      type_: "text",
                                                      placeholder: "Add a description",
                                                      required: true,
                                                      value: description,
                                                      onChange: (function (e) {
                                                          var val = e.currentTarget.value;
                                                          setDescription(function (param) {
                                                                return val;
                                                              });
                                                        })
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsxs(FormControl, {
                                              error: Belt_Option.isNone(Belt_Float.fromString(amount)) || Belt_Option.isNone(Caml_option.null_to_opt(new RegExp("^\\d+(\\.\\d\\d)?$").exec(amount))),
                                              children: [
                                                JsxRuntime.jsx(FormLabel, {
                                                      children: "Amount"
                                                    }),
                                                JsxRuntime.jsx(Joy$Util.Input.make, {
                                                      type_: "number",
                                                      placeholder: "Add an amount",
                                                      required: true,
                                                      value: amount,
                                                      onChange: (function (e) {
                                                          var val = e.currentTarget.value;
                                                          var match = new RegExp("^\\d+(\\.\\d{0,2})?$").exec(val);
                                                          if (match !== null) {
                                                            setAmount(function (param) {
                                                                  return val;
                                                                });
                                                          } else if (val === "") {
                                                            setAmount(function (param) {
                                                                  return "";
                                                                });
                                                          }
                                                          
                                                        }),
                                                      slotProps: {
                                                        input: {
                                                          step: 0.01
                                                        }
                                                      }
                                                    })
                                              ]
                                            }),
                                        error !== undefined ? JsxRuntime.jsx(Typography, {
                                                color: "danger",
                                                children: Caml_option.some(error)
                                              }) : null
                                      ]
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        component: "form",
                                        onSubmit: addManualBillingLine,
                                        children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                  loading: match$3.loading,
                                                  type: "submit",
                                                  children: "Add Manual Billing Line"
                                                }))
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$3.billPayNew,
                      applyDiff: match$3.applyDiff,
                      title: "Add Manual Billing Line",
                      description: "You are proposing to add a " + subtotalCategory + " manual billing line \"" + description + " (" + Common$Thick.formatCurrency(Belt_Option.getWithDefault(Belt_Float.fromString(amount), 0.0)) + ")\" .",
                      onClose: (function () {
                          clearAll();
                        }),
                      open_: match$3.previewModalOpen,
                      versionUrl: versionUrl,
                      error: error
                    })
              ]
            });
}

var make = AddManualBillingLineModal;

exports.subtotalCategories = subtotalCategories;
exports.make = make;
/* react Not a pure module */
