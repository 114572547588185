// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var AttachMoney = require("@mui/icons-material/AttachMoney").default;

function CurrencyInput(props) {
  var maximum = props.maximum;
  var minimum = props.minimum;
  var __disabled = props.disabled;
  var value = props.value;
  var onChange = props.onChange;
  var label = props.label;
  var disabled = __disabled !== undefined ? __disabled : false;
  var helperText;
  if (minimum !== undefined) {
    var minimum$1 = Caml_option.valFromOption(minimum);
    helperText = maximum !== undefined ? "Enter a value between " + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(minimum$1)) + " and " + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(minimum$1)) : "Enter a value greater than " + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(minimum$1));
  } else {
    helperText = maximum !== undefined ? "Enter a value less than " + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(maximum))) : undefined;
  }
  return JsxRuntime.jsxs(FormControl, {
              error: props.error(value),
              children: [
                label !== undefined ? JsxRuntime.jsx(FormLabel, {
                        children: Caml_option.some(label)
                      }) : null,
                JsxRuntime.jsx(Joy$Util.Input.make, {
                      type_: "number",
                      disabled: disabled,
                      value: value,
                      onChange: (function (e) {
                          var val = e.currentTarget.value;
                          var arr = val.split(".");
                          if (arr.length === 1) {
                            return onChange(val);
                          }
                          if (arr.length !== 2) {
                            return ;
                          }
                          var decimal = arr[1];
                          var d = decimal.length;
                          if (d > 2) {
                            return ;
                          } else {
                            return onChange(val);
                          }
                        }),
                      startDecorator: Caml_option.some(JsxRuntime.jsx(AttachMoney, {})),
                      slotProps: {
                        input: {
                          step: 0.01,
                          max: Belt_Option.getWithDefault(Belt_Option.map(maximum, CS_Decimal$Util.Decimal10_2.toFloat), Number.MAX_VALUE),
                          min: Belt_Option.getWithDefault(Belt_Option.map(minimum, CS_Decimal$Util.Decimal10_2.toFloat), Number.MIN_VALUE)
                        }
                      }
                    }),
                helperText !== undefined ? JsxRuntime.jsx(FormHelperText, {
                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                  level: "body-sm",
                                  children: Caml_option.some(helperText)
                                }))
                      }) : null
              ]
            });
}

var C;

var make = CurrencyInput;

exports.C = C;
exports.make = make;
/* Joy-Util Not a pure module */
