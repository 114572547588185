// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CS_Make$Util = require("./CS_Make.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");

var regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$");

function check(email) {
  var match = regex.exec(email);
  return match !== null;
}

var struct = S$RescriptSchema.refine(S$RescriptSchema.$$String.min(S$RescriptSchema.string, 5, undefined), (function (s) {
        return function (v) {
          if (check(v)) {
            return ;
          } else {
            return s.fail("Must be an email", undefined);
          }
        };
      }));

function toPrimitive(a) {
  return a;
}

function primitiveToEl(prim) {
  return prim;
}

function primitiveToJson(prim) {
  return prim;
}

var Email = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

exports.struct = struct;
exports.Email = Email;
/* regex Not a pure module */
