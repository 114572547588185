// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Http$Util = require("./Http.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Core__JSON = require("@rescript/core/src/Core__JSON.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_SetString = require("rescript/lib/js/belt_SetString.js");
var ReactState$Util = require("./ReactState.bs.js");
var RemoteData$Util = require("./RemoteData.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");

function flatten(json) {
  var dict = Core__JSON.Classify.classify(json);
  if (typeof dict !== "object") {
    return ;
  }
  switch (dict.TAG) {
    case "Object" :
        return Belt_Array.get(Belt_Array.keepMap(Object.entries(dict._0), (function (param) {
                          return flatten(param[1]);
                        })), 0);
    case "Array" :
        var els = dict._0;
        if (els.length === 0) {
          return ;
        }
        var raws = Belt_Array.map(els, (function (param) {
                return procRow("", param);
              }));
        var headers = procHeaders(raws);
        return {
                headers: headers,
                rows: Belt_Array.map(raws, (function (prim) {
                        return Object.fromEntries(prim);
                      }))
              };
    default:
      return ;
  }
}

function incPrefix(prefix, k) {
  if (prefix === "") {
    return k;
  } else {
    return prefix + "." + k;
  }
}

function procRow(prefix, _el) {
  while(true) {
    var el = _el;
    var dict = Core__JSON.Classify.classify(el);
    if (typeof dict !== "object") {
      return [[
                prefix,
                el
              ]];
    }
    switch (dict.TAG) {
      case "Object" :
          return Belt_Array.map(Object.entries(dict._0), (function (param) {
                        return [
                                incPrefix(prefix, param[0]),
                                param[1]
                              ];
                      }));
      case "Array" :
          var match = dict._0;
          var len = match.length;
          if (len !== 1) {
            if (len !== 0) {
              return [[
                        prefix,
                        el
                      ]];
            } else {
              return [];
            }
          }
          var oneEl = match[0];
          _el = oneEl;
          continue ;
      default:
        return [[
                  prefix,
                  el
                ]];
    }
  };
}

function procHeaders(arr) {
  return Belt_Array.reduce(arr, [
                undefined,
                []
              ], (function (param, rowArray) {
                  var seen = param[0];
                  var inorder = param[1];
                  return Belt_Array.reduce(rowArray, [
                              seen,
                              inorder
                            ], (function (param, param$1) {
                                var k = param$1[0];
                                var inorder = param[1];
                                var seen = param[0];
                                if (Belt_SetString.has(seen, k)) {
                                  return [
                                          seen,
                                          inorder
                                        ];
                                } else {
                                  return [
                                          Belt_SetString.add(seen, k),
                                          Belt_Array.concat(inorder, [k])
                                        ];
                                }
                              }));
                }))[1];
}

function __getHeader(row, header) {
  return Belt_Option.getWithDefault(row[header], null);
}

function toStr(val) {
  var b = Core__JSON.Classify.classify(val);
  if (typeof b !== "object") {
    return "";
  }
  switch (b.TAG) {
    case "Bool" :
        if (b._0) {
          return "true";
        } else {
          return "false";
        }
    case "String" :
        return b._0;
    case "Number" :
        return String(b._0);
    case "Object" :
    case "Array" :
        return JSON.stringify(val);
    
  }
}

function getHeader(row, header) {
  return toStr(__getHeader(row, header));
}

function rotate(param) {
  var rows = param.rows;
  return {
          headers: Belt_Array.map(Belt_Array.range(0, rows.length), (function (prim) {
                  return String(prim);
                })),
          rows: Belt_Array.map(Belt_Array.map(param.headers, (function (hdr) {
                      return Belt_Array.concat([[
                                    "0",
                                    hdr
                                  ]], Belt_Array.mapWithIndex(rows, (function (idx, row) {
                                        return [
                                                String(idx + 1 | 0),
                                                __getHeader(row, hdr)
                                              ];
                                      })));
                    })), (function (v) {
                  return Object.fromEntries(v);
                }))
        };
}

var Bindings = {};

function request(url, gqlQuery) {
  return Http$Util.jsonRequest(url, "POST", [
              Object.fromEntries([[
                      "query",
                      gqlQuery
                    ]]),
              S$RescriptSchema.json
            ], [], S$RescriptSchema.json);
}

function getTable(url, gqlQuery) {
  return RemoteData$Util.$$Promise.mapSuccess(request(url, gqlQuery), (function (v) {
                return flatten(v);
              }));
}

function getRotated(url, gqlQuery) {
  return RemoteData$Util.$$Promise.mapSuccess(getTable(url, gqlQuery), (function (v) {
                return Belt_Option.map(v, rotate);
              }));
}

function useTable(url, gqlQuery, rotateOpt) {
  var rotate = rotateOpt !== undefined ? rotateOpt : true;
  var state = React.useState(function () {
        return "NotAsked";
      });
  var fire = function () {
    RemoteData$Util.$$Promise.makeStatefulEffect(rotate ? getRotated(url, gqlQuery) : getTable(url, gqlQuery), state);
  };
  React.useEffect((function () {
          fire();
        }), []);
  return ReactState$Util.getter(state);
}

var Graphql = {
  request: request,
  getTable: getTable,
  getRotated: getRotated,
  useTable: useTable
};

exports.flatten = flatten;
exports.__getHeader = __getHeader;
exports.getHeader = getHeader;
exports.rotate = rotate;
exports.incPrefix = incPrefix;
exports.toStr = toStr;
exports.procRow = procRow;
exports.procHeaders = procHeaders;
exports.Bindings = Bindings;
exports.Graphql = Graphql;
/* react Not a pure module */
