// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var SearchBar$Thick = require("../common/molecules/SearchBar.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var ChevronLeft = require("@mui/icons-material/ChevronLeft").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;

function BookingsTableToolbar(props) {
  var clearSearch = props.clearSearch;
  var search = props.search;
  var __searchBar = props.searchBar;
  var onLoadPrev = props.onLoadPrev;
  var onLoadNext = props.onLoadNext;
  var offset = props.offset;
  var totalCount = props.totalCount;
  var searchBar = __searchBar !== undefined ? __searchBar : false;
  var match = React.useState(function () {
        return "";
      });
  var setSearchVal = match[1];
  var searchVal = match[0];
  var onSubmit = function (e) {
    e.preventDefault();
    search(searchVal);
  };
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: 1
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        children: [
                          searchBar ? JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  component: "form",
                                  spacing: 1,
                                  onSubmit: onSubmit,
                                  children: [
                                    JsxRuntime.jsx(SearchBar$Thick.make, {
                                          value: searchVal,
                                          onChange: (function (e) {
                                              var val = e.currentTarget.value;
                                              setSearchVal(function (param) {
                                                    return val;
                                                  });
                                            }),
                                          placeholder: "Search bookings",
                                          onClear: (function (param) {
                                              setSearchVal(function (param) {
                                                    return "";
                                                  });
                                              clearSearch();
                                            }),
                                          showClear: searchVal !== ""
                                        }),
                                    JsxRuntime.jsx(Button, {
                                          type: "submit",
                                          variant: "outlined",
                                          disabled: searchVal === "",
                                          children: "Search"
                                        })
                                  ]
                                }) : JsxRuntime.jsx("div", {}),
                          JsxRuntime.jsx(Stack, {
                                direction: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                spacing: 1,
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          direction: "row",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          children: [
                                            JsxRuntime.jsx(IconButton, {
                                                  onClick: (function (param) {
                                                      onLoadPrev();
                                                    }),
                                                  variant: "plain",
                                                  disabled: offset === 0,
                                                  children: Caml_option.some(JsxRuntime.jsx(ChevronLeft, {}))
                                                }),
                                            JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  children: Caml_option.some(props.stringFromOffset(offset, totalCount))
                                                }),
                                            JsxRuntime.jsx(IconButton, {
                                                  onClick: (function (param) {
                                                      onLoadNext();
                                                    }),
                                                  variant: "plain",
                                                  disabled: (offset + 50 | 0) >= totalCount,
                                                  children: Caml_option.some(JsxRuntime.jsx(ChevronRight, {}))
                                                })
                                          ]
                                        }))
                              })
                        ]
                      }))
            });
}

var make = BookingsTableToolbar;

exports.make = make;
/* react Not a pure module */
