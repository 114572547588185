// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var LatestBookingVersionRedirect_query_graphql$Thick = require("../__generated__/LatestBookingVersionRedirect_query_graphql.bs.js");

var convertFragment = LatestBookingVersionRedirect_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(LatestBookingVersionRedirect_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, LatestBookingVersionRedirect_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(LatestBookingVersionRedirect_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function LatestBookingVersionRedirect(props) {
  var query = use(props.fragmentRefs);
  var bookingsRoute = "/" + CS_Slugs$Util.BrandFamilySlug.toString(props.brandFamily) + "/" + CS_Slugs$Util.VoyageSlug.toString(props.voyage) + "/bookings";
  var match = query.bookingVersions;
  var redirect;
  if (match !== undefined) {
    var match$1 = Belt_Array.get(match.nodes, 0);
    redirect = match$1 !== undefined ? bookingsRoute + "/" + CS_NonemptyStrings$Util.BookingId.toString(props.bookingId) + "/" + String(match$1.bookingVersionNumber) : bookingsRoute;
  } else {
    redirect = bookingsRoute;
  }
  React.useEffect((function () {
          RescriptReactRouter.push(redirect);
        }), []);
  return null;
}

var make = LatestBookingVersionRedirect;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
