'use strict';

function deepFreeze(object) {
  if (!shouldBeFrozen(object)) {
    return object;
  }
  Object.freeze(object);
  Object.getOwnPropertyNames(object).forEach(function (name) {
    var property = object[name];
    if (property && typeof property === 'object' && !Object.isFrozen(property)) {
      deepFreeze(property);
    }
  });
  return object;
}
function shouldBeFrozen(value) {
  if (value === null || typeof value !== 'object') {
    return false;
  }
  if (ArrayBuffer.isView(value)) {
    return false;
  }
  return true;
}
module.exports = deepFreeze;