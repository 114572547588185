// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var BDButton$Thick = require("./atoms/BDButton.bs.js");
var DraftUtils$Thick = require("./drafts/DraftUtils.bs.js");
var BookingDiff$Thick = require("../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var AspectRatio = require("@mui/joy/AspectRatio").default;
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");
var ChangeCabinModal$Thick = require("./drafts/ChangeCabinModal.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDCabin_version_graphql$Thick = require("../../__generated__/BDCabin_version_graphql.bs.js");
var BDCancelReservationModal$Thick = require("./drafts/BDCancelReservationModal.bs.js");
var PublishedWithChanges = require("@mui/icons-material/PublishedWithChanges").default;

var convertFragment = BDCabin_version_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDCabin_version_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDCabin_version_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDCabin_version_graphql$Thick.node, convertFragment, fRef);
}

var VersionFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BDCabin$Cabin$DataStack(props) {
  var value = props.value;
  var label = props.label;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      noWrap: true,
                      sx: {
                        color: "neutral.500"
                      },
                      fontWeight: "normal",
                      children: Caml_option.some(label)
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 1,
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: props.size === "sm" ? "body-sm" : "body-md",
                                noWrap: true,
                                sx: {
                                  color: value !== undefined ? "text.primary" : "neutral.plainDisabledColor"
                                },
                                fontWeight: "normal",
                                children: Caml_option.some(value !== undefined ? value : "No " + label.toLowerCase() + " provided")
                              }))
                    })
              ]
            });
}

var DataStack = {
  make: BDCabin$Cabin$DataStack
};

function BDCabin$Cabin(props) {
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var versionUrl = props.versionUrl;
  var billPayRec = props.billPayRec;
  var isActive = props.isActive;
  var content = props.content;
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setCancelModalOpen = match$1[1];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var isToBeCancelled = isActive && Belt_Option.isSome(diffStruct.cancellationReason);
  var actions = isActive && !(props.snapshot || !props.canManage) ? JsxRuntime.jsxs(Stack, {
          direction: "row",
          spacing: 1,
          children: [
            JsxRuntime.jsx(BDButton$Thick.make, {
                  onClick: (function (param) {
                      if (!isToBeCancelled) {
                        return setCancelModalOpen(function (param) {
                                    return true;
                                  });
                      }
                      var applyDiff = BookingDiff$Thick.Alter.updateCancellationReason(diffStruct, undefined);
                      DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
                    }),
                  text: isToBeCancelled ? "Uncancel Booking" : "Cancel Booking"
                }),
            JsxRuntime.jsx(BDButton$Thick.make, {
                  endDecorator: Caml_option.some(JsxRuntime.jsx(PublishedWithChanges, {
                            fontSize: "sm",
                            sx: {
                              color: "text.secondary"
                            }
                          })),
                  onClick: (function (param) {
                      setModalOpen(function (param) {
                            return true;
                          });
                    }),
                  text: "Change"
                })
          ]
        }) : null;
  var match$2 = content.occupancyMin;
  var match$3 = content.occupancyMax;
  var occupancyRange = match$2 !== undefined ? (
      match$3 !== undefined ? String(match$2) + "-" + String(match$3) : String(match$2) + "+"
    ) : (
      match$3 !== undefined ? String(match$3) : undefined
    );
  var image = content.stateroomImage;
  var tmp;
  if (image !== undefined) {
    var slug = content.cabinClassSlug;
    tmp = JsxRuntime.jsx("img", {
          alt: slug !== undefined ? CS_Slugs$Util.CabinClassSlug.toString(Caml_option.valFromOption(slug)) : "Cabin",
          src: image
        });
  } else {
    tmp = JsxRuntime.jsx(Typography, {
          level: "body-sm",
          sx: {
            color: "text.secondary"
          },
          children: "No image"
        });
  }
  var slug$1 = content.cabinClassSlug;
  var slug$2 = content.cabinCategorySlug;
  return JsxRuntime.jsxs(BDContentBlock$Thick.make, {
              title: "Cabin",
              actions: Caml_option.some(actions),
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 2,
                      children: [
                        JsxRuntime.jsx(Box, {
                              children: Caml_option.some(JsxRuntime.jsx(AspectRatio, {
                                        children: Caml_option.some(tmp),
                                        objectFit: "cover",
                                        ratio: "4 / 3",
                                        sx: {
                                          backgroundColor: "background.level2",
                                          borderRadius: "4px"
                                        }
                                      })),
                              sx: {
                                width: "6rem"
                              }
                            }),
                        JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                              label: slug$1 !== undefined ? CS_Slugs$Util.CabinClassSlug.toString(Caml_option.valFromOption(slug$1)) : "Cabin Category",
                              value: slug$2 !== undefined ? CS_Slugs$Util.CabinCategorySlug.toString(Caml_option.valFromOption(slug$2)).toUpperCase() : undefined,
                              size: "md"
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Joy$Util.Grid.make, {
                      children: [
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                                        label: "Cabin Type",
                                        value: Belt_Option.map(content.cabinSubclassSlug, CS_Slugs$Util.CabinSubclassSlug.toString),
                                        size: "sm"
                                      })),
                              xs: 2
                            }),
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                                        label: "Deck",
                                        value: Belt_Option.map(content.deck, CS_NonemptyStrings$Util.DeckNumber.toString),
                                        size: "sm"
                                      })),
                              xs: 2
                            }),
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                                        label: "Cabin Number",
                                        value: CS_NonemptyStrings$Util.CabinNumber.toString(content.cabinNumber),
                                        size: "sm"
                                      })),
                              xs: 3
                            }),
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                                        label: "Berths",
                                        value: String(content.numBerths),
                                        size: "sm"
                                      })),
                              xs: 2
                            }),
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsx(BDCabin$Cabin$DataStack, {
                                        label: "Occupancy",
                                        value: occupancyRange,
                                        size: "sm"
                                      })),
                              xs: 2
                            })
                      ],
                      container: true,
                      sx: {
                        mt: 2
                      }
                    }),
                JsxRuntime.jsx(ChangeCabinModal$Thick.make, {
                      brandFamily: props.brandFamily,
                      voyage: props.voyage,
                      open_: match[0],
                      onClose: (function () {
                          setModalOpen(function (param) {
                                return false;
                              });
                        }),
                      billPayCurrent: billPayRec,
                      versionUrl: versionUrl,
                      currentCabinNumber: content.cabinNumber,
                      generatePreviewFromDiff: generatePreviewFromDiff
                    }),
                JsxRuntime.jsx(BDCancelReservationModal$Thick.make, {
                      open_: match$1[0],
                      onClose: (function () {
                          setCancelModalOpen(function (param) {
                                return false;
                              });
                        }),
                      bookingId: props.bookingId,
                      billPayCurrent: billPayRec,
                      versionUrl: versionUrl,
                      generatePreviewFromDiff: generatePreviewFromDiff
                    })
              ]
            });
}

var Cabin = {
  DataStack: DataStack,
  make: BDCabin$Cabin
};

function BDCabin(props) {
  var diffStruct = props.diffStruct;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var data = use(props.fragmentRefs);
  var match = diffStruct.addBerths;
  var match$1 = diffStruct.dropBerths;
  var numBerths = match !== undefined ? (
      match$1 !== undefined ? (data.numBerths + match | 0) - match$1.length | 0 : data.numBerths + match | 0
    ) : (
      match$1 !== undefined ? data.numBerths - match$1.length | 0 : data.numBerths
    );
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var content = DraftUtils$Thick.generateCabin(data, diffStruct, url.search, brandFamily, voyage, numBerths);
  return JsxRuntime.jsx(BDCabin$Cabin, {
              content: content,
              snapshot: props.snapshot,
              isActive: props.isActive,
              canManage: props.canManage,
              brandFamily: brandFamily,
              voyage: voyage,
              billPayRec: props.billPayRec,
              versionUrl: props.versionUrl,
              generatePreviewFromDiff: props.generatePreviewFromDiff,
              bookingId: props.bookingId
            });
}

var make = BDCabin;

exports.VersionFragment = VersionFragment;
exports.Cabin = Cabin;
exports.make = make;
/* react Not a pure module */
