// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactState$Util = require("./ReactState.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function schema(failureSchema, successSchema) {
  return S$RescriptSchema.union([
              S$RescriptSchema.object(function (s) {
                    s.t("kind", "NotAsked");
                    return "NotAsked";
                  }),
              S$RescriptSchema.object(function (s) {
                    s.t("kind", "Loading");
                    return "Loading";
                  }),
              S$RescriptSchema.object(function (s) {
                    s.t("kind", "Failure");
                    return {
                            TAG: "Failure",
                            _0: s.f("payload", failureSchema)
                          };
                  }),
              S$RescriptSchema.object(function (s) {
                    s.t("kind", "Success");
                    return {
                            TAG: "Success",
                            _0: s.f("payload", successSchema)
                          };
                  })
            ]);
}

function isNotAsked(t) {
  if (typeof t !== "object" && t === "NotAsked") {
    return true;
  } else {
    return false;
  }
}

function isLoading(t) {
  if (typeof t !== "object" && t === "Loading") {
    return true;
  } else {
    return false;
  }
}

function isError(t) {
  if (typeof t !== "object" || t.TAG !== "Failure") {
    return false;
  } else {
    return true;
  }
}

function isSuccess(t) {
  if (typeof t !== "object" || t.TAG !== "Success") {
    return false;
  } else {
    return true;
  }
}

function flatMapBoth(t, mapSucc, mapErr) {
  if (typeof t !== "object") {
    if (t === "NotAsked") {
      return "NotAsked";
    } else {
      return "Loading";
    }
  } else if (t.TAG === "Success") {
    return mapSucc(t._0);
  } else {
    return mapErr(t._0);
  }
}

function flatMapSuccess(t, mapFn) {
  return flatMapBoth(t, mapFn, (function (a) {
                return {
                        TAG: "Failure",
                        _0: a
                      };
              }));
}

function flatMapFailure(t, mapFn) {
  return flatMapBoth(t, (function (a) {
                return {
                        TAG: "Success",
                        _0: a
                      };
              }), mapFn);
}

function mapBoth(t, mapSucc, mapErr) {
  return flatMapBoth(t, (function (s) {
                return {
                        TAG: "Success",
                        _0: mapSucc(s)
                      };
              }), (function (f) {
                return {
                        TAG: "Failure",
                        _0: mapErr(f)
                      };
              }));
}

function mapSuccess(t, mapFn) {
  return mapBoth(t, mapFn, (function (a) {
                return a;
              }));
}

function mapFailure(t, mapFn) {
  return mapBoth(t, (function (a) {
                return a;
              }), mapFn);
}

function getSuccess(t) {
  if (typeof t !== "object" || t.TAG !== "Success") {
    return ;
  } else {
    return Caml_option.some(t._0);
  }
}

function getWithDefault(t, $$default) {
  return Belt_Option.getWithDefault(getSuccess(t), $$default);
}

function mapWithDefault(t, $$default, mapFn) {
  return getWithDefault(mapSuccess(t, mapFn), $$default);
}

function useLoadStatefulEffect(state, effect) {
  React.useEffect((function () {
          var match = ReactState$Util.getter(state);
          if (typeof match !== "object" && match === "NotAsked") {
            ReactState$Util.setter(state)(function (param) {
                  return "Loading";
                });
            effect();
          }
          
        }), [isNotAsked(ReactState$Util.getter(state))]);
}

function resolve(prim) {
  return Promise.resolve(prim);
}

async function flatMapBoth$1(prom, mapSucc, mapErr) {
  var res = await prom;
  if (typeof res !== "object") {
    if (res === "NotAsked") {
      return "NotAsked";
    } else {
      return "Loading";
    }
  } else if (res.TAG === "Success") {
    return await mapSucc(res._0);
  } else {
    return await mapErr(res._0);
  }
}

function flatMapSuccess$1(t, mapFn) {
  return flatMapBoth$1(t, mapFn, (function (a) {
                return Promise.resolve({
                            TAG: "Failure",
                            _0: a
                          });
              }));
}

function flatMapFailure$1(t, mapFn) {
  return flatMapBoth$1(t, (function (a) {
                return Promise.resolve({
                            TAG: "Success",
                            _0: a
                          });
              }), mapFn);
}

async function mapBoth$1(prom, mapSucc, mapErr) {
  var res = await prom;
  return mapBoth(res, mapSucc, mapErr);
}

function mapSuccess$1(t, mapFn) {
  return mapBoth$1(t, mapFn, (function (a) {
                return a;
              }));
}

function mapFailure$1(t, mapFn) {
  return mapBoth$1(t, (function (a) {
                return a;
              }), mapFn);
}

async function then(prom, next) {
  var res = await prom;
  if (typeof res !== "object") {
    if (res === "NotAsked") {
      return "NotAsked";
    } else {
      return "Loading";
    }
  } else if (res.TAG === "Success") {
    return await next(res._0);
  } else {
    return {
            TAG: "Failure",
            _0: res._0
          };
  }
}

function thenResolve(rd, fn) {
  return then(rd, (function (v) {
                return Promise.resolve(fn(v));
              }));
}

function makeStatefulEffect(prom, state) {
  var helper = async function () {
    ReactState$Util.setter(state)(function (param) {
          return "Loading";
        });
    var res = await prom;
    return ReactState$Util.setter(state)(function (param) {
                return res;
              });
  };
  helper();
}

var $$Promise$1 = {
  resolve: resolve,
  flatMapBoth: flatMapBoth$1,
  flatMapSuccess: flatMapSuccess$1,
  flatMapFailure: flatMapFailure$1,
  mapBoth: mapBoth$1,
  mapSuccess: mapSuccess$1,
  mapFailure: mapFailure$1,
  then: then,
  thenResolve: thenResolve,
  makeStatefulEffect: makeStatefulEffect
};

function fetchAsync($$fetch, map, mapErr, environment, variables, networkCacheConfig, fetchPolicy) {
  return new Promise((function (resolve, param) {
                $$fetch(environment, variables, (function (res) {
                        if (res.TAG === "Ok") {
                          return resolve({
                                      TAG: "Success",
                                      _0: map(res._0)
                                    });
                        } else {
                          return resolve({
                                      TAG: "Failure",
                                      _0: mapErr(res._0)
                                    });
                        }
                      }), networkCacheConfig, fetchPolicy);
              }));
}

function fetchQueryStatefully($$fetch, state, map, mapErr, environment, variables, networkCacheConfig, fetchPolicy) {
  return function () {
    makeStatefulEffect(fetchAsync($$fetch, map, mapErr, environment, variables, networkCacheConfig, fetchPolicy), state);
  };
}

function useMutationAsync(useMutation, map) {
  var match = useMutation();
  var mutate = match[0];
  var disposableT = {
    contents: undefined
  };
  return function (variables) {
    var prom = new Promise((function (resolve, param) {
            disposableT.contents = Caml_option.some(mutate(variables, undefined, undefined, undefined, (function (response, mutationErrors) {
                        if (mutationErrors !== undefined) {
                          if (mutationErrors.length !== 0) {
                            return resolve({
                                        TAG: "Failure",
                                        _0: "The following request errors occured: \n" + Belt_Array.map(mutationErrors, (function (param) {
                                                  return param.message;
                                                })).join("\n")
                                      });
                          } else {
                            return resolve({
                                        TAG: "Failure",
                                        _0: "An unspecified request error occurred"
                                      });
                          }
                        } else {
                          return resolve(map(response));
                        }
                      }), undefined, undefined));
          }));
    return [
            Belt_Option.getExn(disposableT.contents),
            prom
          ];
  };
}

function useMutationStatefully(useMutation, state, map) {
  var mut = useMutationAsync(useMutation, map);
  return function (vbls) {
    var match = mut(vbls);
    makeStatefulEffect(match[1], state);
    return match[0];
  };
}

var Relay = {
  fetchAsync: fetchAsync,
  fetchQueryStatefully: fetchQueryStatefully,
  useMutationAsync: useMutationAsync,
  useMutationStatefully: useMutationStatefully
};

function RemoteData$Debug$Show(props) {
  var __renderErr = props.renderErr;
  var __renderSuccess = props.renderSuccess;
  var remoteData = props.remoteData;
  var renderSuccess = __renderSuccess !== undefined ? __renderSuccess : (function (s) {
        return Belt_Option.getExn(JSON.stringify(s));
      });
  var renderErr = __renderErr !== undefined ? __renderErr : (function (s) {
        return Belt_Option.getExn(JSON.stringify(s));
      });
  var tmp;
  tmp = typeof remoteData !== "object" ? (
      remoteData === "NotAsked" ? "NotAsked" : "Loading"
    ) : (
      remoteData.TAG === "Success" ? renderSuccess(remoteData._0) : "Failure(" + renderErr(remoteData._0) + ")"
    );
  return JsxRuntime.jsx("pre", {
              children: tmp
            });
}

var Show = {
  make: RemoteData$Debug$Show
};

var Debug = {
  Show: Show
};

var toOption = getSuccess;

exports.schema = schema;
exports.isNotAsked = isNotAsked;
exports.isLoading = isLoading;
exports.isError = isError;
exports.isSuccess = isSuccess;
exports.flatMapBoth = flatMapBoth;
exports.flatMapSuccess = flatMapSuccess;
exports.flatMapFailure = flatMapFailure;
exports.mapBoth = mapBoth;
exports.mapSuccess = mapSuccess;
exports.mapFailure = mapFailure;
exports.getSuccess = getSuccess;
exports.toOption = toOption;
exports.getWithDefault = getWithDefault;
exports.mapWithDefault = mapWithDefault;
exports.useLoadStatefulEffect = useLoadStatefulEffect;
exports.$$Promise = $$Promise$1;
exports.Relay = Relay;
exports.Debug = Debug;
/* react Not a pure module */
