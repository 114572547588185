// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Http$Util = require("util/src/Http.bs.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Chip = require("@mui/joy/Chip").default;
var JsxRuntime = require("react/jsx-runtime");
var WarningAmber = require("@mui/icons-material/WarningAmber").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;
var ScheduleSendOutlined = require("@mui/icons-material/ScheduleSendOutlined").default;

function useStateExtended(useStateArgs) {
  var match = React.useState(Curry.__1(useStateArgs));
  var set = match[1];
  var onChange = function (e) {
    set(e.currentTarget.value);
  };
  return [
          match[0],
          set,
          onChange
        ];
}

function formatCurrency($$float) {
  var options = {
    style: "currency",
    currency: "USD"
  };
  var formatter = Intl.NumberFormat("en-US", options);
  return formatter.format($$float);
}

function cookieLogin(jwt, aBitLaterOpt, onErrorOpt, delayOpt) {
  var aBitLater = aBitLaterOpt !== undefined ? Caml_option.valFromOption(aBitLaterOpt) : undefined;
  var onError = onErrorOpt !== undefined ? Caml_option.valFromOption(onErrorOpt) : undefined;
  var delay = delayOpt !== undefined ? delayOpt : 100;
  return Http$Util.cookieLogin(process.env.THICK_LOGIN_URL, jwt, Caml_option.some(aBitLater), Caml_option.some(onError), delay);
}

function cookieLogout(aBitLaterOpt, onErrorOpt, delayOpt) {
  var aBitLater = aBitLaterOpt !== undefined ? Caml_option.valFromOption(aBitLaterOpt) : undefined;
  var onError = onErrorOpt !== undefined ? Caml_option.valFromOption(onErrorOpt) : undefined;
  var delay = delayOpt !== undefined ? delayOpt : 100;
  return Http$Util.cookieLogout(process.env.THICK_LOGOUT_URL, Caml_option.some(aBitLater), Caml_option.some(onError), delay);
}

var Clipboard = {};

var $$History = {};

var $$Window = {
  Clipboard: Clipboard,
  $$History: $$History
};

async function copyToClipboard(s, fn) {
  try {
    await window.navigator.clipboard.writeText(s);
    fn(function (param) {
          return true;
        });
    setTimeout((function () {
            fn(function (param) {
                  return false;
                });
          }), 1500);
    return ;
  }
  catch (exn){
    console.log("Error copying to clipboard");
    return ;
  }
}

var regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$");

function isValid(email) {
  var match = regex.exec(email);
  return match !== null;
}

var Email = {
  regex: regex,
  isValid: isValid
};

var Voyage = {};

function toString(role) {
  switch (role) {
    case "BRAND_FAMILY_ADMIN" :
        return "Brand Family Admin";
    case "CUSTOMER_SERVICE" :
        return "Customer Service";
    case "INVENTORY_MANAGER" :
        return "Inventory Manager";
    
  }
}

function toColor(role) {
  switch (role) {
    case "BRAND_FAMILY_ADMIN" :
        return "danger";
    case "CUSTOMER_SERVICE" :
        return "neutral";
    case "INVENTORY_MANAGER" :
        return "success";
    
  }
}

function fromInput(role) {
  return role;
}

function toInput(role) {
  return role;
}

var BrandfamRole = {
  toString: toString,
  toColor: toColor,
  fromInput: fromInput,
  toInput: toInput
};

function toString$1(status) {
  if (!(status === "UNCLAIMED" || status === "TRANSFERRING" || status === "INVITED" || status === "CLAIMED" || status === "DELETED")) {
    return "Unknown";
  }
  switch (status) {
    case "CLAIMED" :
        return "Claimed";
    case "INVITED" :
        return "Invited";
    case "TRANSFERRING" :
        return "Transferring";
    case "UNCLAIMED" :
        return "Unclaimed";
    case "DELETED" :
        return "Unknown";
    
  }
}

function fromInput$1(status) {
  return status;
}

function toInput$1(status) {
  return status;
}

var AccountStatus = {
  toString: toString$1,
  fromInput: fromInput$1,
  toInput: toInput$1
};

function toString$2(action) {
  switch (action) {
    case "APPROVE" :
        return "Approve";
    case "CANCEL" :
        return "Cancel";
    case "EXECUTE" :
        return "Execute";
    case "EXECUTE_AS_GRANTEE" :
        return "Execute as grantee";
    case "INITIATE" :
        return "Initiate";
    
  }
}

function fromInput$2(action) {
  return action;
}

function toInput$2(action) {
  return action;
}

var WorkflowAction = {
  toString: toString$2,
  fromInput: fromInput$2,
  toInput: toInput$2
};

function toString$3(status) {
  if (!(status === "CANCELLED" || status === "COMPLETED" || status === "PENDING" || status === "NASCENT" || status === "ERROR")) {
    return "Unknown";
  }
  switch (status) {
    case "NASCENT" :
        return "Unknown";
    case "PENDING" :
        return "Pending";
    case "COMPLETED" :
        return "Completed";
    case "CANCELLED" :
        return "Canceled";
    case "ERROR" :
        return "Error";
    
  }
}

function shouldRenderStatus(status) {
  if ((status === "CANCELLED" || status === "COMPLETED" || status === "PENDING" || status === "NASCENT" || status === "ERROR") && status === "COMPLETED") {
    return false;
  } else {
    return true;
  }
}

var PmtLifecycle = {
  toString: toString$3,
  shouldRenderStatus: shouldRenderStatus
};

function toString$4(status) {
  if (!(status === "CANCELED" || status === "STAGED" || status === "DRAFT" || status === "ACTIVE" || status === "HISTORY")) {
    return "Unknown";
  }
  switch (status) {
    case "ACTIVE" :
        return "Active";
    case "DRAFT" :
        return "Draft";
    case "STAGED" :
        return "Staged";
    case "CANCELED" :
        return "Canceled";
    case "HISTORY" :
        return "History";
    
  }
}

function getPrimaryColor(status) {
  if (!(status === "CANCELED" || status === "STAGED" || status === "DRAFT" || status === "ACTIVE" || status === "HISTORY")) {
    return "#7030A0";
  }
  switch (status) {
    case "ACTIVE" :
        return "#037E3A";
    case "DRAFT" :
        return "#30557A";
    case "STAGED" :
        return "primary.500";
    case "CANCELED" :
        return "#FF4949";
    case "HISTORY" :
        return "#B77401";
    
  }
}

function getSecondaryColor(status) {
  if (!(status === "CANCELED" || status === "STAGED" || status === "DRAFT" || status === "ACTIVE" || status === "HISTORY")) {
    return "danger.400";
  }
  switch (status) {
    case "ACTIVE" :
        return "success.400";
    case "DRAFT" :
        return "neutral.400";
    case "STAGED" :
        return "primary.400";
    case "CANCELED" :
        return "danger.400";
    case "HISTORY" :
        return "warning.400";
    
  }
}

function getBackgroundColor(status) {
  if (!(status === "CANCELED" || status === "STAGED" || status === "DRAFT" || status === "ACTIVE" || status === "HISTORY")) {
    return "#DFC9EF";
  }
  switch (status) {
    case "ACTIVE" :
        return "#E7FDF1";
    case "DRAFT" :
        return "#F0F3F5";
    case "STAGED" :
        return "primary.100";
    case "CANCELED" :
        return "#FBF2F2";
    case "HISTORY" :
        return "#FFF9EB";
    
  }
}

function makeChip(sizeOpt, status, versionNumber) {
  var size = sizeOpt !== undefined ? sizeOpt : "sm";
  var tmp;
  tmp = (status === "CANCELED" || status === "STAGED" || status === "DRAFT" || status === "ACTIVE" || status === "HISTORY") && status === "HISTORY" ? "Version " + String(versionNumber) : toString$4(status);
  return JsxRuntime.jsx(Chip, {
              children: Caml_option.some("• " + tmp),
              size: size,
              sx: {
                color: getPrimaryColor(status),
                backgroundColor: getBackgroundColor(status),
                borderRadius: "xs"
              }
            });
}

function fromInput$3(status) {
  return status;
}

function toInput$3(status) {
  return status;
}

var Lifecycle = {
  toString: toString$4,
  getPrimaryColor: getPrimaryColor,
  getSecondaryColor: getSecondaryColor,
  getBackgroundColor: getBackgroundColor,
  makeChip: makeChip,
  fromInput: fromInput$3,
  toInput: toInput$3
};

function toString$5(status) {
  if (typeof status !== "object") {
    return "TBA";
  }
  if (status.TAG === "Status") {
    var tmp = status._0;
    if (!(tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED")) {
      return "Unknown";
    }
    switch (tmp) {
      case "CONFIRMED" :
          return "Confirmed";
      case "INCOMPLETE" :
          return "Incomplete";
      case "INVITED" :
          return "Invited";
      
    }
  } else {
    var match = status._0;
    if (match === "update") {
      return "Update on Apply";
    } else if (match === "invite") {
      return "Invite on Apply";
    } else if (match === "replace") {
      return "Replace on Apply";
    } else {
      return "Attach on Apply";
    }
  }
}

function getPrimaryColor$1(status) {
  switch (status) {
    case "Confirmed" :
        return "#037E3A";
    case "Incomplete" :
        return "#B77401";
    case "TBA" :
        return "#100D0C";
    case "Attach on Apply" :
    case "Invite on Apply" :
    case "Invited" :
    case "Replace on Apply" :
    case "Update on Apply" :
        return "#30557A";
    default:
      return "#FF4949";
  }
}

function getBackgroundColor$1(status) {
  switch (status) {
    case "Confirmed" :
        return "#E7FDF1";
    case "Incomplete" :
        return "#FFF9EB";
    case "TBA" :
        return "#F4F4F4";
    case "Attach on Apply" :
    case "Invite on Apply" :
    case "Invited" :
    case "Replace on Apply" :
    case "Update on Apply" :
        return "#F0F3F5";
    default:
      return "#FBF2F2";
  }
}

function makeChip$1(sizeOpt, status) {
  var size = sizeOpt !== undefined ? sizeOpt : "sm";
  var tmp;
  if (typeof status !== "object") {
    tmp = null;
  } else if (status.TAG === "Status") {
    var tmp$1 = status._0;
    if (tmp$1 === "INCOMPLETE" || tmp$1 === "CONFIRMED" || tmp$1 === "INVITED") {
      switch (tmp$1) {
        case "CONFIRMED" :
            tmp = JsxRuntime.jsx(CheckCircleOutline, {});
            break;
        case "INCOMPLETE" :
            tmp = JsxRuntime.jsx(WarningAmber, {});
            break;
        case "INVITED" :
            tmp = JsxRuntime.jsx(ScheduleSendOutlined, {});
            break;
        
      }
    } else {
      tmp = null;
    }
  } else {
    tmp = JsxRuntime.jsx(ModeEditOutlined, {});
  }
  return JsxRuntime.jsx(Chip, {
              children: Caml_option.some(toString$5(status)),
              size: size,
              startDecorator: Caml_option.some(tmp),
              sx: {
                color: getPrimaryColor$1(toString$5(status)),
                backgroundColor: getBackgroundColor$1(toString$5(status)),
                borderRadius: "xs"
              }
            });
}

function fromInput$4(status) {
  return status;
}

function toInput$4(status) {
  return status;
}

var PaxDataStatus = {
  toString: toString$5,
  getPrimaryColor: getPrimaryColor$1,
  getBackgroundColor: getBackgroundColor$1,
  makeChip: makeChip$1,
  fromInput: fromInput$4,
  toInput: toInput$4
};

function toString$6(g) {
  if (!(g === "F" || g === "M" || g === "X")) {
    return "Unknown";
  }
  switch (g) {
    case "M" :
        return "Male";
    case "F" :
        return "Female";
    case "X" :
        return "Unspecified";
    
  }
}

function fromInput$5(gender) {
  return gender;
}

function toInput$5(gender) {
  return gender;
}

var DocGender = {
  toString: toString$6,
  fromInput: fromInput$5,
  toInput: toInput$5
};

function fromInput$6($$long) {
  return $$long;
}

function toInput$6($$long) {
  return $$long;
}

var Longitudinality = {
  fromInput: fromInput$6,
  toInput: toInput$6
};

function fromInput$7($$long) {
  return $$long;
}

function toInput$7($$long) {
  return $$long;
}

var ElevatorProximity = {
  fromInput: fromInput$7,
  toInput: toInput$7
};

var cmp = Caml_obj.compare;

var CabinCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

var cmp$1 = Caml_obj.compare;

var PermCmp = Belt_Id.MakeComparable({
      cmp: cmp$1
    });

var cmp$2 = Caml_obj.compare;

var WorkflowActionCmp = Belt_Id.MakeComparable({
      cmp: cmp$2
    });

var cmp$3 = Caml_obj.compare;

var VoyageSlugCmp = Belt_Id.MakeComparable({
      cmp: cmp$3
    });

var cmp$4 = Caml_obj.compare;

var StrCmp = Belt_Id.MakeComparable({
      cmp: cmp$4
    });

function format(s) {
  var str = String(s);
  var delimiter = String("_");
  return Belt_Array.joinWith(Belt_Array.map(Js_string.split(delimiter, str), (function (w) {
                    var capital = Js_string.substring(0, 1, w).toUpperCase();
                    var rest = Js_string.substringToEnd(1, w);
                    return Js_string.concat(rest, capital);
                  })), " ", (function (prim) {
                return prim;
              }));
}

var SegDisplay = {
  format: format
};

exports.useStateExtended = useStateExtended;
exports.formatCurrency = formatCurrency;
exports.cookieLogin = cookieLogin;
exports.cookieLogout = cookieLogout;
exports.$$Window = $$Window;
exports.copyToClipboard = copyToClipboard;
exports.Email = Email;
exports.Voyage = Voyage;
exports.BrandfamRole = BrandfamRole;
exports.AccountStatus = AccountStatus;
exports.WorkflowAction = WorkflowAction;
exports.PmtLifecycle = PmtLifecycle;
exports.Lifecycle = Lifecycle;
exports.PaxDataStatus = PaxDataStatus;
exports.DocGender = DocGender;
exports.Longitudinality = Longitudinality;
exports.ElevatorProximity = ElevatorProximity;
exports.CabinCmp = CabinCmp;
exports.PermCmp = PermCmp;
exports.WorkflowActionCmp = WorkflowActionCmp;
exports.VoyageSlugCmp = VoyageSlugCmp;
exports.StrCmp = StrCmp;
exports.SegDisplay = SegDisplay;
/* regex Not a pure module */
