// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var variablesConverter = {"transferUnclaimedBfcustUserInput":{"_invitationEmail":{"c":"Util.CustomScalars.Email.Exn"},"_brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"_accountId":{"c":"Util.CustomScalars.AccountId.Exn"}},"__root":{"input":{"r":"transferUnclaimedBfcustUserInput"}}};

var variablesConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function transferErr_decode($$enum) {
  if ($$enum === "USER_CANT_BE_TRANSFERRED") {
    return $$enum;
  }
  
}

function transferErr_fromString(str) {
  return transferErr_decode(str);
}

var Utils = {
  transferErr_decode: transferErr_decode,
  transferErr_fromString: transferErr_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TransferUnclaimedBfcustUserPayload",
    "kind": "LinkedField",
    "name": "transferUnclaimedBfcustUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InviteToTransferResult",
        "kind": "LinkedField",
        "name": "inviteToTransferResult",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nullableErr",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "46f1310c5f05a841f7b7281b310f681b",
    "id": null,
    "metadata": {},
    "name": "TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation",
    "operationKind": "mutation",
    "text": "mutation TransferUnclaimedBfCustUser_TransferUnclaimedBfcustUserMutation(\n  $input: TransferUnclaimedBfcustUserInput!\n) {\n  transferUnclaimedBfcustUser(input: $input) {\n    inviteToTransferResult {\n      success\n      nullableErr\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
