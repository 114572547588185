// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function serialize(x) {
  return x;
}

function parse(s) {
  return s;
}

var BigFloat = {
  serialize: serialize,
  parse: parse
};

function serialize$1(x) {
  return x;
}

function parse$1(s) {
  return s;
}

var BrandFamilySlug = {
  serialize: serialize$1,
  parse: parse$1
};

function serialize$2(x) {
  return x;
}

function parse$2(s) {
  return s;
}

var BookingId = {
  serialize: serialize$2,
  parse: parse$2
};

function serialize$3(x) {
  return x;
}

function parse$3(s) {
  return s;
}

var DeckNumber = {
  serialize: serialize$3,
  parse: parse$3
};

function serialize$4(x) {
  return x;
}

function parse$4(s) {
  return s;
}

var Email = {
  serialize: serialize$4,
  parse: parse$4
};

function serialize$5(x) {
  return x;
}

function parse$5(s) {
  return s;
}

var NonemptyString = {
  serialize: serialize$5,
  parse: parse$5
};

function serialize$6(x) {
  return x;
}

function parse$6(s) {
  return s;
}

var AccountId = {
  serialize: serialize$6,
  parse: parse$6
};

function serialize$7(x) {
  return x;
}

function parse$7(s) {
  return s;
}

var VoyageSlug = {
  serialize: serialize$7,
  parse: parse$7
};

function serialize$8(x) {
  return x;
}

function parse$8(s) {
  return s;
}

var LoyaltyNumber = {
  serialize: serialize$8,
  parse: parse$8
};

function serialize$9(x) {
  return x;
}

function parse$9(s) {
  return s;
}

var OrgName = {
  serialize: serialize$9,
  parse: parse$9
};

function serialize$10(x) {
  return x;
}

function parse$10(s) {
  return s;
}

var WorkflowInstance = {
  serialize: serialize$10,
  parse: parse$10
};

function serialize$11(x) {
  return x;
}

function parse$11(s) {
  return s;
}

var CabinNumber = {
  serialize: serialize$11,
  parse: parse$11
};

function serialize$12(x) {
  return x;
}

function parse$12(s) {
  return s;
}

var PrivateNoteMd = {
  serialize: serialize$12,
  parse: parse$12
};

function serialize$13(x) {
  return x;
}

function parse$13(s) {
  return s;
}

var PublicNoteMd = {
  serialize: serialize$13,
  parse: parse$13
};

function serialize$14(x) {
  return x;
}

function parse$14(s) {
  return s;
}

var CabinCategorySlug = {
  serialize: serialize$14,
  parse: parse$14
};

function serialize$15(x) {
  return x;
}

function parse$15(s) {
  return s;
}

var CabinClassSlug = {
  serialize: serialize$15,
  parse: parse$15
};

function serialize$16(x) {
  return x;
}

function parse$16(s) {
  return s;
}

var CabinSubclassSlug = {
  serialize: serialize$16,
  parse: parse$16
};

function serialize$17(x) {
  return x;
}

function parse$17(s) {
  return s;
}

var Cursor = {
  serialize: serialize$17,
  parse: parse$17
};

exports.BigFloat = BigFloat;
exports.BrandFamilySlug = BrandFamilySlug;
exports.BookingId = BookingId;
exports.DeckNumber = DeckNumber;
exports.Email = Email;
exports.NonemptyString = NonemptyString;
exports.AccountId = AccountId;
exports.VoyageSlug = VoyageSlug;
exports.LoyaltyNumber = LoyaltyNumber;
exports.OrgName = OrgName;
exports.WorkflowInstance = WorkflowInstance;
exports.CabinNumber = CabinNumber;
exports.PrivateNoteMd = PrivateNoteMd;
exports.PublicNoteMd = PublicNoteMd;
exports.CabinCategorySlug = CabinCategorySlug;
exports.CabinClassSlug = CabinClassSlug;
exports.CabinSubclassSlug = CabinSubclassSlug;
exports.Cursor = Cursor;
/* No side effect */
