// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Modal = require("@mui/joy/Modal").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var ModalClose = require("@mui/joy/ModalClose").default;
var DialogTitle = require("@mui/joy/DialogTitle").default;
var ModalDialog = require("@mui/joy/ModalDialog").default;

function Dialog(props) {
  return JsxRuntime.jsx(Modal, {
              open: props.open_,
              onClose: props.onClose,
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none",
                    backgroundColor: "rgba(16, 13, 12, 0.7)"
                  }
                }
              },
              children: Caml_option.some(JsxRuntime.jsxs(ModalDialog, {
                        variant: "outlined",
                        layout: "center",
                        sx: {
                          minWidth: "30rem",
                          borderRadius: "4px",
                          boxShadow: "lg"
                        },
                        children: [
                          JsxRuntime.jsx(DialogTitle, {
                                children: Caml_option.some(props.title)
                              }),
                          JsxRuntime.jsx(ModalClose, {
                                sx: {
                                  top: "0.8rem",
                                  right: "0.8rem"
                                }
                              }),
                          JsxRuntime.jsx(Divider, {}),
                          props.children
                        ]
                      }))
            });
}

var make = Dialog;

exports.make = make;
/* @mui/joy/Modal Not a pure module */
