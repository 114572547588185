// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var DateFns = require("date-fns");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function getter(param) {
  return param[0];
}

function setter(param) {
  return param[1];
}

function derive(parent, set, get) {
  return [
          get(getter(parent)),
          (function (callback) {
              setter(parent)(function (prevParent) {
                    return set(prevParent, callback(get(prevParent)));
                  });
            })
        ];
}

function useDebouncedState(initFn, millisOpt, suppressAllParentRerendersOpt) {
  var millis = millisOpt !== undefined ? millisOpt : 277;
  var suppressAllParentRerenders = suppressAllParentRerendersOpt !== undefined ? suppressAllParentRerendersOpt : false;
  var ref = React.useRef(initFn());
  var timerId = React.useRef(undefined);
  var debounced = React.useState(function () {
        return ref.current;
      });
  var uiNoopSet = function (fn) {
    ref.current = fn(ref.current);
    var tid = timerId.current;
    if (tid !== undefined) {
      clearTimeout(Caml_option.valFromOption(tid));
    }
    if (!suppressAllParentRerenders) {
      timerId.current = Caml_option.some(setTimeout((function () {
                  setter(debounced)(function (param) {
                        return ref.current;
                      });
                }), millis));
      return ;
    }
    
  };
  return {
          state: [
            getter(debounced),
            uiNoopSet
          ],
          ref: ref
        };
}

function useURLStateExtended(parse, getUnvalidatedParams, prependToQueryString, serializeToQueryString) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var queryState = parse(url.search);
  var toOpt = function (str) {
    if (str.trim().length > 0) {
      return str.trim();
    }
    
  };
  var prependToQueryString$1 = Belt_Option.getWithDefault(prependToQueryString, "");
  var join = function (l, r) {
    var match = toOpt(l);
    var match$1 = toOpt(r);
    if (match !== undefined) {
      if (match$1 !== undefined) {
        return "?" + match + "&" + match$1;
      } else {
        return "?" + match;
      }
    } else if (match$1 !== undefined) {
      return "?" + match$1;
    } else {
      return "";
    }
  };
  return [
          [
            queryState,
            (function (callback) {
                RescriptReactRouter.push(join(prependToQueryString$1, serializeToQueryString(callback(queryState), url.search)));
              })
          ],
          serializeToQueryString(queryState, Belt_Option.getWithDefault(getUnvalidatedParams(url.search), ""))
        ];
}

function useURLState(parse, prependToQueryString, getUnvalidatedParams, serializeToQueryString) {
  return useURLStateExtended(parse, getUnvalidatedParams, prependToQueryString, serializeToQueryString)[0];
}

function entangle(state, intervention) {
  return [
          getter(state),
          (function (callback) {
              setter(state)(function (prev) {
                    var intendedValue = callback(prev);
                    return intervention(intendedValue);
                  });
            })
        ];
}

function ReactState$Wrap$WithUnsyncedShadowState(props) {
  var isValid = props.isValid;
  var getFromParent = props.get;
  var state = props.state;
  var derived = derive(state, props.set, getFromParent);
  var local = React.useState(function () {
        return getFromParent(getter(state));
      });
  React.useEffect((function () {
          setter(derived)(function (prev) {
                setter(local)(function (param) {
                      return prev;
                    });
                return prev;
              });
        }), []);
  var touched = React.useState(function () {
        return false;
      });
  var entangled = entangle(local, (function (v) {
          setter(derived)(function (param) {
                return v;
              });
          setter(touched)(function (param) {
                return true;
              });
          return v;
        }));
  return props.children({
              get: getter(entangled),
              set: setter(entangled),
              valid: isValid(getter(entangled)),
              error: (props.forceValidate || getter(touched)) && !isValid(getter(entangled)),
              onChange: {
                withCurrentTarget: (function (e) {
                    var v = e.currentTarget.value;
                    setter(entangled)(function (param) {
                          return v;
                        });
                  }),
                withDoubleWrappedJoyOpt: (function (param, v) {
                    if (v === undefined) {
                      return ;
                    }
                    var opt = Caml_option.valFromOption(v);
                    setter(entangled)(function (param) {
                          return opt;
                        });
                  })
              }
            });
}

var WithUnsyncedShadowState = {
  make: ReactState$Wrap$WithUnsyncedShadowState
};

function useExpiringState(state, expiresAfter, clearExpiredWith, storageKey) {
  var timeOutId = React.useState(function () {
        
      });
  var $$clearTimeout$1 = function () {
    var priorTimeout = getter(timeOutId);
    if (priorTimeout !== undefined) {
      clearTimeout(Caml_option.valFromOption(priorTimeout));
      return ;
    }
    
  };
  var scheduleGarbageCollection = function (s) {
    var expiresAfter$1 = expiresAfter(s);
    if (expiresAfter$1 === undefined) {
      return $$clearTimeout$1();
    }
    $$clearTimeout$1();
    var timeUntilClear = Math.max(0.001, expiresAfter$1 - Date.now());
    var tid = setTimeout((function () {
            setter(state)(function (param) {
                  return clearExpiredWith;
                });
            console.log("useExpiringState is clearing your state for storageKey[" + storageKey + "] based on the expiry timestamp of [" + DateFns.formatISO(new Date(expiresAfter$1), {
                      representation: "complete"
                    }) + "] which you provided");
          }), timeUntilClear);
    setter(timeOutId)(function (param) {
          return Caml_option.some(tid);
        });
  };
  React.useEffect((function () {
          scheduleGarbageCollection(getter(state));
        }), []);
  return entangle(state, (function (valueToSet) {
                scheduleGarbageCollection(valueToSet);
                return valueToSet;
              }));
}

function useStorageBackedState(state, schema, storageKey, storeWhenOpt, storageOpt) {
  var storeWhen = storeWhenOpt !== undefined ? storeWhenOpt : (function (param) {
        return true;
      });
  var storage = storageOpt !== undefined ? Caml_option.valFromOption(storageOpt) : sessionStorage;
  var setStorage = function (s) {
    storage.setItem(storageKey, JSON.stringify(S$RescriptSchema.serializeOrRaiseWith(s, schema)));
  };
  var getStorage = function () {
    return Belt_Option.flatMap(Caml_option.null_to_opt(storage.getItem(storageKey)), (function (str) {
                  var rse = S$RescriptSchema.parseAnyWith(str, S$RescriptSchema.jsonString(schema, undefined));
                  if (rse.TAG === "Ok") {
                    return Caml_option.some(rse._0);
                  }
                  console.error("Trouble parsing useStorageBackedState item @ [" + storageKey + "]: " + S$RescriptSchema.$$Error.message(rse._0));
                }));
  };
  React.useEffect((function () {
          var foundItem = getStorage();
          if (foundItem !== undefined) {
            var foundItem$1 = Caml_option.valFromOption(foundItem);
            setter(state)(function (param) {
                  return foundItem$1;
                });
          }
          
        }), []);
  return entangle(state, (function (valueToSet) {
                if (storeWhen(valueToSet)) {
                  setStorage(valueToSet);
                } else {
                  storage.removeItem(storageKey);
                }
                return valueToSet;
              }));
}

function useExpiringStorageBackedState(state, schema, storageKey, storeWhenOpt, storageOpt, expiresAfter, clearExpiredWith) {
  var storeWhen = storeWhenOpt !== undefined ? storeWhenOpt : (function (param) {
        return true;
      });
  var storage = storageOpt !== undefined ? Caml_option.valFromOption(storageOpt) : sessionStorage;
  var s2 = useExpiringState(state, expiresAfter, clearExpiredWith, storageKey);
  return useStorageBackedState(s2, schema, storageKey, storeWhen, Caml_option.some(storage));
}

var Wrap = {
  entangle: entangle,
  WithUnsyncedShadowState: WithUnsyncedShadowState,
  useExpiringState: useExpiringState,
  useStorageBackedState: useStorageBackedState,
  useExpiringStorageBackedState: useExpiringStorageBackedState
};

function useStore(initFn) {
  var ref = React.useRef({
        contents: initFn(),
        subs: undefined,
        flag: "Notify"
      });
  return function (fn) {
    var updatedState = fn(ref.current);
    var flag = updatedState.flag;
    var subs = updatedState.subs;
    if (typeof flag !== "object") {
      if (flag !== "Notify") {
        return ;
      }
      ref.current = updatedState;
      return Belt_MapString.forEach(ref.current.subs, (function (param, fx) {
                    fx(ref.current.contents);
                  }));
    } else {
      if (flag.TAG === "Sub") {
        var init = ref.current;
        ref.current = {
          contents: init.contents,
          subs: Belt_MapString.set(subs, flag._0, flag._1),
          flag: init.flag
        };
        return ;
      }
      var init$1 = ref.current;
      ref.current = {
        contents: init$1.contents,
        subs: Belt_MapString.remove(subs, flag._0),
        flag: init$1.flag
      };
      return ;
    }
  };
}

function useObservableImpl(store, set, get, interventionOpt) {
  var intervention = interventionOpt !== undefined ? interventionOpt : (function (setter, newVal) {
        setter(function (param) {
              return newVal;
            });
      });
  var currentVal = {
    contents: undefined
  };
  store(function (previousStored) {
        currentVal.contents = Caml_option.some(get(previousStored.contents));
        return {
                contents: previousStored.contents,
                subs: previousStored.subs,
                flag: "Noop"
              };
      });
  var local = React.useState(function () {
        return Belt_Option.getExn(currentVal.contents);
      });
  var subId = React.useRef(undefined);
  if (Belt_Option.isNone(subId.current)) {
    subId.current = (crypto.randomUUID());
    store(function (prevStore) {
          return {
                  contents: prevStore.contents,
                  subs: prevStore.subs,
                  flag: {
                    TAG: "Sub",
                    _0: Belt_Option.getExn(subId.current),
                    _1: (function (storeContents) {
                        intervention(setter(local), get(storeContents));
                      })
                  }
                };
        });
  }
  React.useEffect((function () {
          return (function () {
                    store(function (previousStored) {
                          return {
                                  contents: previousStored.contents,
                                  subs: previousStored.subs,
                                  flag: {
                                    TAG: "Unsub",
                                    _0: Belt_Option.getExn(subId.current)
                                  }
                                };
                        });
                  });
        }), []);
  return [
          getter(local),
          (function (callback) {
              store(function (prevStore) {
                    var contents = prevStore.contents;
                    return {
                            contents: set(contents, callback(get(contents))),
                            subs: prevStore.subs,
                            flag: "Notify"
                          };
                  });
            })
        ];
}

function defaultIntervention(equalityFn, setter, newVal) {
  return setter(function (prevVal) {
              if (equalityFn === "PassThru" || !Caml_obj.equal(prevVal, newVal)) {
                return newVal;
              } else {
                return prevVal;
              }
            });
}

function useObservable(store, get, set, equalityFnOpt) {
  var equalityFn = equalityFnOpt !== undefined ? equalityFnOpt : "PassThru";
  return useObservableImpl(store, (function (parent, param) {
                return parent;
              }), get, (function (extra, extra$1) {
                return defaultIntervention(equalityFn, extra, extra$1);
              }));
}

function useRoObservable(store, get, equalityFnOpt) {
  var equalityFn = equalityFnOpt !== undefined ? equalityFnOpt : "PassThru";
  return getter(useObservable(store, get, (function (parent, param) {
                    return parent;
                  }), equalityFn));
}

function useDebouncedObservable(store, set, get, millisOpt, equalityFnOpt) {
  var millis = millisOpt !== undefined ? millisOpt : 277;
  var equalityFn = equalityFnOpt !== undefined ? equalityFnOpt : "PassThru";
  var timerId = React.useRef(undefined);
  return useObservableImpl(store, set, get, (function (setter, newVal) {
                var tid = timerId.current;
                if (tid !== undefined) {
                  clearTimeout(Caml_option.valFromOption(tid));
                }
                timerId.current = Caml_option.some(setTimeout((function () {
                            defaultIntervention(equalityFn, setter, newVal);
                          }), millis));
              }));
}

function ReactState$Observable$WithStore(props) {
  var isValid = props.isValid;
  var sub = useObservable(props.store, props.get, props.set, undefined);
  var touched = React.useState(function () {
        return false;
      });
  var entangled = entangle(sub, (function (v) {
          setter(touched)(function (param) {
                return true;
              });
          return v;
        }));
  return props.children({
              get: getter(entangled),
              set: setter(entangled),
              valid: isValid(getter(entangled)),
              error: (props.forceValidate || getter(touched)) && !isValid(getter(entangled)),
              onChange: {
                withCurrentTarget: (function (e) {
                    var v = e.currentTarget.value;
                    setter(entangled)(function (param) {
                          return v;
                        });
                  }),
                withDoubleWrappedJoyOpt: (function (param, v) {
                    if (v === undefined) {
                      return ;
                    }
                    var opt = Caml_option.valFromOption(v);
                    setter(entangled)(function (param) {
                          return opt;
                        });
                  })
              }
            });
}

var WithStore = {
  make: ReactState$Observable$WithStore
};

var Observable = {
  useStore: useStore,
  useObservableImpl: useObservableImpl,
  defaultIntervention: defaultIntervention,
  useObservable: useObservable,
  useRoObservable: useRoObservable,
  useDebouncedObservable: useDebouncedObservable,
  WithStore: WithStore
};

exports.getter = getter;
exports.setter = setter;
exports.derive = derive;
exports.useDebouncedState = useDebouncedState;
exports.useURLStateExtended = useURLStateExtended;
exports.useURLState = useURLState;
exports.Wrap = Wrap;
exports.Observable = Observable;
/* react Not a pure module */
