// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick = require("../../../__generated__/RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql.bs.js");

var convertVariables = RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, RevokeInvitation_RevokeBrandFamilyInvitationMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var RevokeBrandFamilyInvitationMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(brandFamily, email, refreshTeam) {
  var match = use();
  var mutate = match[0];
  var onClick = function (e, setRevokeError) {
    e.preventDefault();
    setRevokeError(function (param) {
          
        });
    mutate({
          input: {
            forEmail: email,
            fromBrandFamilySlug: brandFamily
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err !== undefined) {
              setRevokeError(function (param) {
                    return "An error occurred revoking invitation.";
                  });
              return ;
            }
            var match = resp.revokeBrandFamilyInvitation;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                console.log("Invitation revoked");
                refreshTeam();
                return ;
              }
              
            }
            setRevokeError(function (param) {
                  return "An error occurred while revoking invitation.";
                });
          }), (function (param) {
            setRevokeError(function (param) {
                  return "Revoking invitation failed.";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          onClick: onClick
        };
}

exports.RevokeBrandFamilyInvitationMutation = RevokeBrandFamilyInvitationMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
