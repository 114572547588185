// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var fragmentConverter = {};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

function brandfamRole_decode($$enum) {
  if ($$enum === "CUSTOMER_SERVICE" || $$enum === "BRAND_FAMILY_ADMIN" || $$enum === "INVENTORY_MANAGER") {
    return $$enum;
  }
  
}

function brandfamRole_fromString(str) {
  return brandfamRole_decode(str);
}

var Utils = {
  brandfamRole_decode: brandfamRole_decode,
  brandfamRole_fromString: brandfamRole_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleGrantsView_rolegrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserRoleGrant",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandfamRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasRole",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserRoleGrantsConnection",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
