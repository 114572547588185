// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Menu = require("@mui/joy/Menu").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var BDButton$Thick = require("../atoms/BDButton.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Button = require("@mui/joy/Button").default;
var Switch = require("@mui/joy/Switch").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var DraftUtils$Thick = require("../drafts/DraftUtils.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var BDRefundModal$Thick = require("../drafts/BDRefundModal.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var BDBillPayTable$Thick = require("./BDBillPayTable.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var Add = require("@mui/icons-material/Add").default;
var BDMovePaymentModal$Thick = require("../drafts/BDMovePaymentModal.bs.js");
var Check = require("@mui/icons-material/Check").default;
var Replay = require("@mui/icons-material/Replay").default;
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var AddManualBillingLineModal$Thick = require("../drafts/AddManualBillingLineModal.bs.js");
var DeleteForever = require("@mui/icons-material/DeleteForever").default;
var KeyboardReturn = require("@mui/icons-material/KeyboardReturn").default;
var SuppressAutoBillingLineModal$Thick = require("../drafts/SuppressAutoBillingLineModal.bs.js");
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var ReplayCircleFilled = require("@mui/icons-material/ReplayCircleFilled").default;

function BDInvoiceDetails$TableLine(props) {
  var moveSelectedPayment = props.moveSelectedPayment;
  var openRefundModal = props.openRefundModal;
  var versionSuppressions = props.versionSuppressions;
  var versionTimestamp = props.versionTimestamp;
  var versionUrl = props.versionUrl;
  var shouldRender = props.shouldRender;
  var line = props.line;
  var BDInvoiceDetails$TableLine$1 = function (props) {
    var bl = props.bl;
    var url = RescriptReactRouter.useUrl(undefined, undefined);
    var diffStruct = BookingDiff$Thick.parse(url.search);
    var id = bl.passthruId;
    var toSuppressManual = id !== undefined ? ({
          TAG: "Remove",
          _0: id
        }) : ({
          TAG: "Suppress",
          _0: bl.billingIdx
        });
    var id$1 = bl.passthruId;
    var toSuppressAuto;
    if (id$1 !== undefined) {
      toSuppressAuto = id$1;
    } else {
      var lines = diffStruct.suppressAutoBillingLines;
      toSuppressAuto = "suppress-" + (
        lines !== undefined ? String(Belt_MapString.size(Caml_option.valFromOption(lines))) : "0"
      );
    }
    var isManual = bl.isManualLine;
    var match = bl.isManualLine;
    var match$1 = bl.fareRuleSlug;
    var autoLine;
    if (match || match$1 === undefined) {
      autoLine = "CannotSuppress";
    } else {
      var fareRuleSlug = Caml_option.valFromOption(match$1);
      if ((bl.ruleApplicationCount + bl.suppressedCount | 0) === 1) {
        var hookFiredAt = bl.hookFiredAt;
        var hookFiredAt$1;
        if (hookFiredAt !== undefined) {
          var hookFiredAt$2 = Caml_option.valFromOption(hookFiredAt);
          hookFiredAt$1 = CS_Make$Util.Dateable.Plain.isBefore(hookFiredAt$2, versionTimestamp) || Caml_obj.equal(hookFiredAt$2, versionTimestamp) ? Caml_option.some(hookFiredAt$2) : undefined;
        } else {
          hookFiredAt$1 = undefined;
        }
        Belt_MapString.has(Belt_Option.getWithDefault(diffStruct.suppressAutoBillingLines, undefined), toSuppressAuto);
        var versionSuppressions$1;
        if (hookFiredAt$1 !== undefined) {
          var str = CS_Slugs$Util.FareRuleSlug.toString(fareRuleSlug) + "-" + CS_Make$Util.Dateable.Plain.toPrimitive(Caml_option.valFromOption(hookFiredAt$1));
          var count = Belt_MapString.get(versionSuppressions, str);
          versionSuppressions$1 = count !== undefined ? count : 0;
        } else {
          versionSuppressions$1 = 0;
        }
        autoLine = {
          TAG: "Toggle",
          _0: {
            fareRuleSlug: fareRuleSlug,
            hookFiredAt: hookFiredAt$1,
            nSuppressed: bl.suppressedCount === 1 ? 0 : 1,
            versionSuppressions: versionSuppressions$1
          }
        };
      } else {
        var hookFiredAt$3 = bl.hookFiredAt;
        var hookFiredAt$4;
        if (hookFiredAt$3 !== undefined) {
          var hookFiredAt$5 = Caml_option.valFromOption(hookFiredAt$3);
          hookFiredAt$4 = CS_Make$Util.Dateable.Plain.isBefore(hookFiredAt$5, versionTimestamp) || Caml_obj.equal(hookFiredAt$5, versionTimestamp) ? Caml_option.some(hookFiredAt$5) : undefined;
        } else {
          hookFiredAt$4 = undefined;
        }
        Belt_MapString.has(Belt_Option.getWithDefault(diffStruct.suppressAutoBillingLines, undefined), toSuppressAuto);
        var versionSuppressions$2;
        if (hookFiredAt$4 !== undefined) {
          var str$1 = CS_Slugs$Util.FareRuleSlug.toString(fareRuleSlug) + "-" + CS_Make$Util.Dateable.Plain.toPrimitive(Caml_option.valFromOption(hookFiredAt$4));
          var count$1 = Belt_MapString.get(versionSuppressions, str$1);
          versionSuppressions$2 = count$1 !== undefined ? count$1 : 0;
        } else {
          versionSuppressions$2 = 0;
        }
        autoLine = {
          TAG: "Select",
          _0: {
            fareRuleSlug: fareRuleSlug,
            hookFiredAt: hookFiredAt$4,
            currentSuppressed: bl.suppressedCount,
            currentApplied: bl.ruleApplicationCount,
            versionSuppressions: versionSuppressions$2
          }
        };
      }
    }
    if (!props.shouldRender) {
      return JsxRuntime.jsx("td", {});
    }
    if (isManual) {
      var match$2 = React.useState(function () {
            return false;
          });
      var setConfirmModalOpen = match$2[1];
      var suppressManualLineOnClick = function (param) {
        var applyDiff = BookingDiff$Thick.Alter.suppressManualBillingLine(diffStruct, toSuppressManual);
        DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
      };
      return JsxRuntime.jsxs("td", {
                  children: [
                    JsxRuntime.jsx(Stack, {
                          direction: "row",
                          justifyContent: "flex-end",
                          children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                    children: JsxRuntime.jsx(IconButton, {
                                          onClick: (function (param) {
                                              setConfirmModalOpen(function (param) {
                                                    return true;
                                                  });
                                            }),
                                          color: "danger",
                                          children: Caml_option.some(JsxRuntime.jsx(DeleteForever, {
                                                    fontSize: "xl"
                                                  }))
                                        }),
                                    arrow: true,
                                    placement: "top",
                                    size: "sm",
                                    title: "Delete"
                                  }))
                        }),
                    JsxRuntime.jsxs(Dialog$Thick.make, {
                          open_: match$2[0],
                          onClose: (function () {
                              setConfirmModalOpen(function (param) {
                                    return false;
                                  });
                            }),
                          title: "Delete Manual Billing Line",
                          children: [
                            JsxRuntime.jsx(DialogContent, {
                                  children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                            spacing: 2,
                                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                      textColor: "text.tertiary",
                                                      children: Caml_option.some("Are you sure you want to delete the manual billing line " + bl.billingLineDescription + " for " + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount)) + " in this draft? To recover, you may click the back button in your browser or discard your draft.")
                                                    }))
                                          }))
                                }),
                            JsxRuntime.jsx(DialogActions, {
                                  children: Caml_option.some(JsxRuntime.jsx(Button, {
                                            onClick: suppressManualLineOnClick,
                                            color: "danger",
                                            children: "Delete"
                                          }))
                                })
                          ]
                        })
                  ]
                });
    }
    if (typeof autoLine !== "object") {
      return JsxRuntime.jsx("td", {});
    }
    if (autoLine.TAG === "Toggle") {
      var match$3 = autoLine._0;
      var versionSuppressions$3 = match$3.versionSuppressions;
      var nSuppressed = match$3.nSuppressed;
      var hookFiredAt$6 = match$3.hookFiredAt;
      var fareRuleSlug$1 = match$3.fareRuleSlug;
      return JsxRuntime.jsx("td", {
                  children: JsxRuntime.jsx(Stack, {
                        direction: "row",
                        justifyContent: "flex-end",
                        children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                  children: JsxRuntime.jsx(Switch, {
                                        checked: nSuppressed !== 0,
                                        size: "sm",
                                        onChange: (function (param) {
                                            var applyDiff = BookingDiff$Thick.Alter.suppressAutoBillingLine(diffStruct, {
                                                  fareRuleSlug: fareRuleSlug$1,
                                                  hookFiredAt: hookFiredAt$6,
                                                  nSuppressed: nSuppressed
                                                }, toSuppressAuto, versionSuppressions$3);
                                            DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
                                          })
                                      }),
                                  arrow: true,
                                  placement: "top",
                                  size: "sm",
                                  title: nSuppressed !== 1 ? "Include" : "Omit"
                                }))
                      })
                });
    }
    var match$4 = autoLine._0;
    var match$5 = React.useState(function () {
          return false;
        });
    var setModalOpen = match$5[1];
    return JsxRuntime.jsxs("td", {
                children: [
                  JsxRuntime.jsx(Stack, {
                        direction: "row",
                        justifyContent: "flex-end",
                        children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                  children: JsxRuntime.jsx(IconButton, {
                                        onClick: (function (param) {
                                            setModalOpen(function (param) {
                                                  return true;
                                                });
                                          }),
                                        children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                                                  fontSize: "xl"
                                                }))
                                      }),
                                  arrow: true,
                                  placement: "top",
                                  size: "sm",
                                  title: "Edit"
                                }))
                      }),
                  JsxRuntime.jsx(SuppressAutoBillingLineModal$Thick.make, {
                        open_: match$5[0],
                        onClose: (function () {
                            setModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        billPayCurrent: props.billPayRec,
                        versionUrl: versionUrl,
                        passthruId: toSuppressAuto,
                        billingLineDescription: bl.billingLineDescription,
                        generatePreviewFromDiff: props.generatePreviewFromDiff,
                        fareRuleSlug: match$4.fareRuleSlug,
                        hookFiredAt: match$4.hookFiredAt,
                        currentApplied: match$4.currentApplied,
                        currentSuppressed: match$4.currentSuppressed,
                        versionSuppressions: match$4.versionSuppressions
                      })
                ]
              });
  };
  if (line.TAG === "Billing") {
    var bl = line._0;
    var shouldStrikeThru = !bl.isManualLine && bl.ruleApplicationCount === 0 && Belt_Option.isSome(bl.fareRuleSlug);
    var match = bl.suppressedCount > 0;
    var match$1 = bl.ruleApplicationCount > 0;
    var displayedAmount;
    var exit = 0;
    if (match && match$1) {
      displayedAmount = JsxRuntime.jsxs(Stack, {
            direction: "row",
            spacing: 1,
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "inherit",
                    noWrap: true,
                    sx: {
                      fontStyle: "italic",
                      textDecoration: "line-through"
                    },
                    textColor: "neutral.500",
                    fontWeight: "inherit",
                    children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.unsuppressedAmount)))
                  }),
              JsxRuntime.jsx(Typography, {
                    level: "inherit",
                    noWrap: true,
                    textColor: "inherit",
                    fontWeight: "inherit",
                    children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount)))
                  })
            ]
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      displayedAmount = JsxRuntime.jsx(Typography, {
            level: "inherit",
            noWrap: true,
            sx: {
              fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount) <= 0.0 ? "italic" : "normal",
              textDecoration: shouldStrikeThru ? "line-through" : "none"
            },
            textColor: shouldStrikeThru ? "neutral.500" : "inherit",
            fontWeight: "inherit",
            children: Caml_option.some(shouldStrikeThru ? Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.unsuppressedAmount)) : Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount)))
          });
    }
    return JsxRuntime.jsxs("tr", {
                children: [
                  JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Typography, {
                              level: "inherit",
                              noWrap: true,
                              sx: {
                                fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(bl.lineAmount) <= 0.0 ? "italic" : "normal",
                                textDecoration: shouldStrikeThru ? "line-through" : "none"
                              },
                              textColor: shouldStrikeThru ? "neutral.500" : "inherit",
                              fontWeight: "inherit",
                              children: Caml_option.some(shouldStrikeThru ? Belt_Option.getWithDefault(bl.unsuppressedDescription, bl.billingLineDescription) : bl.billingLineDescription)
                            })
                      }),
                  JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "flex-end",
                              sx: {
                                width: "100%"
                              },
                              children: Caml_option.some(displayedAmount)
                            })
                      }),
                  JsxRuntime.jsx(BDInvoiceDetails$TableLine$1, {
                        shouldRender: shouldRender,
                        generatePreviewFromDiff: props.generatePreviewFromDiff,
                        billPayRec: props.billPayRec,
                        bl: bl
                      })
                ]
              });
  }
  var pl = line._0;
  var url = pl.paymentMethodImage;
  var tmp;
  if (shouldRender) {
    var match$2 = Caml_obj.equal(pl.pmtMethod, "CREDIT_CARD");
    var match$3 = CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) > 0.0;
    var match$4 = CS_Decimal$Util.Decimal10_2.toFloat(pl.refundableAmt) > 0.0 || CS_Decimal$Util.Decimal10_2.toFloat(pl.refundableFeeAmt) > 0.0;
    tmp = JsxRuntime.jsx("td", {
          children: JsxRuntime.jsx(Stack, {
                direction: "row",
                justifyContent: "flex-end",
                children: Caml_option.some(JsxRuntime.jsxs(Dropdown, {
                          children: [
                            JsxRuntime.jsx(MenuButton, {
                                  children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                                  size: "md",
                                  slotProps: {
                                    root: {
                                      color: "neutral"
                                    }
                                  },
                                  slots: {
                                    root: IconButton
                                  }
                                }),
                            JsxRuntime.jsxs(Menu, {
                                  children: [
                                    openRefundModal !== undefined && match$2 && match$3 && match$4 ? JsxRuntime.jsx(MenuItem, {
                                            children: "Log refund",
                                            onClick: (function (param) {
                                                openRefundModal();
                                              })
                                          }) : null,
                                    moveSelectedPayment !== undefined ? JsxRuntime.jsx(MenuItem, {
                                            children: "Move payment",
                                            onClick: (function (param) {
                                                moveSelectedPayment(pl);
                                              })
                                          }) : null
                                  ],
                                  size: "sm",
                                  sx: {
                                    zIndex: "modal"
                                  }
                                })
                          ]
                        }))
              })
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            noWrap: true,
                            sx: {
                              fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                            },
                            textColor: "inherit",
                            fontWeight: "inherit",
                            children: Caml_option.some(pl.shouldRenderStatus ? Common$Thick.PmtLifecycle.toString(pl.lifecycleStatus) : pl.date)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            noWrap: true,
                            sx: {
                              fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                            },
                            textColor: "inherit",
                            fontWeight: "inherit",
                            children: Caml_option.some(pl.payer)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            alignItems: "center",
                            children: [
                              url !== undefined ? JsxRuntime.jsx(Stack, {
                                      direction: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      sx: {
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        pr: "4px"
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx("img", {
                                                style: {
                                                  width: "100%"
                                                },
                                                alt: "payment method",
                                                src: url
                                              }))
                                    }) : null,
                              JsxRuntime.jsx(Typography, {
                                    level: "inherit",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"
                                    },
                                    textColor: "inherit",
                                    fontWeight: "inherit",
                                    children: Caml_option.some(pl.paymentMethod)
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            justifyContent: "flex-end",
                            sx: {
                              width: "100%"
                            },
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "inherit",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) <= 0.0 ? "italic" : "normal"
                                    },
                                    textColor: "inherit",
                                    fontWeight: "inherit",
                                    children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(pl.amount)))
                                  }),
                              Caml_obj.equal(pl.pmtMethod, "CREDIT_CARD") && Caml_obj.notequal(pl.refundableAmt, pl.amount) ? (
                                  CS_Decimal$Util.Decimal10_2.toFloat(pl.amount) > 0.0 ? (
                                      CS_Decimal$Util.Decimal10_2.toFloat(pl.refundableAmt) > 0.0 ? JsxRuntime.jsx(Tooltip, {
                                              children: JsxRuntime.jsx(Replay, {}),
                                              arrow: true,
                                              placement: "top",
                                              size: "sm",
                                              sx: {
                                                justifySelf: "flex-start",
                                                cursor: "pointer"
                                              },
                                              title: "Partially refunded payment"
                                            }) : JsxRuntime.jsx(Tooltip, {
                                              children: JsxRuntime.jsx(ReplayCircleFilled, {}),
                                              arrow: true,
                                              placement: "top",
                                              size: "sm",
                                              sx: {
                                                justifySelf: "flex-start",
                                                cursor: "pointer"
                                              },
                                              title: "Fully refunded payment"
                                            })
                                    ) : JsxRuntime.jsx(Tooltip, {
                                          children: JsxRuntime.jsx(KeyboardReturn, {}),
                                          arrow: true,
                                          placement: "top",
                                          size: "sm",
                                          sx: {
                                            justifySelf: "flex-start",
                                            cursor: "pointer"
                                          },
                                          title: "Credit card refund"
                                        })
                                ) : null
                            ]
                          })
                    }),
                tmp
              ]
            });
}

var TableLine = {
  make: BDInvoiceDetails$TableLine
};

function BDInvoiceDetails$InvoiceDetails(props) {
  var openRecordCheckModal = props.openRecordCheckModal;
  var brandFamily = props.brandFamily;
  var currentUserEmail = props.currentUserEmail;
  var versionSuppressions = props.versionSuppressions;
  var versionTimestamp = props.versionTimestamp;
  var generatePreviewFromDiff = props.generatePreviewFromDiff;
  var versionUrl = props.versionUrl;
  var canManage = props.canManage;
  var snapshot = props.snapshot;
  var isActive = props.isActive;
  var content = props.content;
  var shouldRender = isActive && !snapshot && canManage;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var lines = diffStruct.addManualBillingLines;
  var passthruId = "add-" + (
    lines !== undefined ? String(Belt_MapString.size(Caml_option.valFromOption(lines))) : "0"
  );
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setSelectedPaymentLine = match$1[1];
  var selectedPaymentLine = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setSelectedPmtToMove = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setMovePaymentModalOpen = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setRefundModalOpen = match$4[1];
  var moveSelectedPayment = function (pl) {
    setSelectedPaymentLine(function (param) {
          return pl;
        });
    setSelectedPmtToMove(function (param) {
          return Caml_option.some(pl.publicPmtId);
        });
    setMovePaymentModalOpen(function (param) {
          return true;
        });
  };
  var billingColumns = [
    {
      heading: "Description",
      width: "8rem"
    },
    {
      heading: "Amount",
      width: "5rem"
    },
    {
      heading: "Actions",
      width: "1rem"
    }
  ];
  var paymentColumns = [
    {
      heading: "Date",
      width: "3rem"
    },
    {
      heading: "Payer",
      width: "3rem"
    },
    {
      heading: "Payment Method",
      width: "4rem"
    },
    {
      heading: "Amount",
      width: "3rem"
    },
    {
      heading: "Actions",
      width: "2rem"
    }
  ];
  var match$5 = shouldRender ? [
      billingColumns,
      paymentColumns
    ] : [
      Belt_Array.keep(billingColumns, (function (column) {
              return column.heading !== "Edit";
            })),
      Belt_Array.keep(paymentColumns, (function (column) {
              return column.heading !== "Actions";
            }))
    ];
  var zeroed = CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00");
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 4,
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "h4",
                                      component: "h4",
                                      children: "Booking Charges"
                                    }),
                                shouldRender ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          JsxRuntime.jsx(BDButton$Thick.make, {
                                                endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                                          fontSize: "sm",
                                                          sx: {
                                                            color: "text.secondary"
                                                          }
                                                        })),
                                                onClick: (function (param) {
                                                    setModalOpen(function (param) {
                                                          return true;
                                                        });
                                                  }),
                                                text: "Add new charge"
                                              }),
                                          JsxRuntime.jsx(AddManualBillingLineModal$Thick.make, {
                                                open_: match[0],
                                                onClose: (function () {
                                                    setModalOpen(function (param) {
                                                          return false;
                                                        });
                                                  }),
                                                billPayCurrent: content,
                                                versionUrl: versionUrl,
                                                passthruId: passthruId,
                                                generatePreviewFromDiff: generatePreviewFromDiff
                                              })
                                        ]
                                      }) : null
                              ]
                            }),
                        content.billing.billingLines.length !== 0 ? JsxRuntime.jsx(BDBillPayTable$Thick.make, {
                                columns: match$5[0],
                                totalDescription: "Total Billed",
                                total: content.billing.billingLineSubtotal,
                                pendingDescription: "Pending Billed",
                                pendingTotal: zeroed,
                                isActive: isActive,
                                snapshot: snapshot,
                                canManage: canManage,
                                children: Belt_Array.mapWithIndex(content.billing.billingLines, (function (i, line) {
                                        return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                                    line: {
                                                      TAG: "Billing",
                                                      _0: line
                                                    },
                                                    shouldRender: shouldRender,
                                                    billPayRec: content,
                                                    versionUrl: versionUrl,
                                                    generatePreviewFromDiff: generatePreviewFromDiff,
                                                    versionTimestamp: versionTimestamp,
                                                    versionSuppressions: versionSuppressions
                                                  }, String(i));
                                      }))
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  height: "7rem"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "title-lg",
                                          component: "p",
                                          textColor: "neutral.500",
                                          children: Caml_option.some(isActive && !snapshot ? "No charges have been added yet." : "No charges added.")
                                        }))
                              })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "h4",
                                      component: "h4",
                                      children: "Payments"
                                    }),
                                shouldRender && currentUserEmail !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                spacing: 1,
                                                children: [
                                                  JsxRuntime.jsx(BDButton$Thick.make, {
                                                        endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                                                  fontSize: "sm",
                                                                  sx: {
                                                                    color: "text.secondary"
                                                                  }
                                                                })),
                                                        onClick: (function (param) {
                                                            if (openRecordCheckModal !== undefined) {
                                                              return openRecordCheckModal();
                                                            }
                                                            
                                                          }),
                                                        text: "Log check payment",
                                                        disabled: Belt_Option.isNone(openRecordCheckModal)
                                                      }),
                                                  JsxRuntime.jsx(BDButton$Thick.make, {
                                                        endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                                                  fontSize: "sm",
                                                                  sx: {
                                                                    color: "text.secondary"
                                                                  }
                                                                })),
                                                        onClick: (function (param) {
                                                            setRefundModalOpen(function (param) {
                                                                  return true;
                                                                });
                                                          }),
                                                        text: "Log refund"
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsx(BDRefundModal$Thick.make, {
                                                open_: match$4[0],
                                                onClose: (function () {
                                                    setRefundModalOpen(function (param) {
                                                          return false;
                                                        });
                                                    setSelectedPaymentLine(function (param) {
                                                          
                                                        });
                                                  }),
                                                currentUserEmail: Caml_option.valFromOption(currentUserEmail),
                                                prefill: selectedPaymentLine,
                                                clearPrefill: (function () {
                                                    setSelectedPaymentLine(function (param) {
                                                          
                                                        });
                                                  }),
                                                brandFamily: brandFamily,
                                                billPayCurrent: content,
                                                versionUrl: versionUrl,
                                                generatePreviewFromDiff: generatePreviewFromDiff
                                              })
                                        ]
                                      }) : null
                              ]
                            }),
                        content.payment.paymentLines.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsx(BDBillPayTable$Thick.make, {
                                        columns: match$5[1],
                                        totalDescription: "Total Paid",
                                        total: content.payment.completedPaymentSubtotal,
                                        pendingDescription: "Pending",
                                        pendingTotal: content.arePendingPayments ? content.payment.pendingPaymentSubtotal : zeroed,
                                        isActive: isActive,
                                        snapshot: snapshot,
                                        canManage: canManage,
                                        children: Belt_Array.mapWithIndex(content.payment.paymentLines, (function (i, line) {
                                                return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                                            line: {
                                                              TAG: "Payment",
                                                              _0: line
                                                            },
                                                            shouldRender: shouldRender,
                                                            billPayRec: content,
                                                            versionUrl: versionUrl,
                                                            generatePreviewFromDiff: generatePreviewFromDiff,
                                                            versionTimestamp: versionTimestamp,
                                                            versionSuppressions: versionSuppressions,
                                                            openRefundModal: (function () {
                                                                setSelectedPaymentLine(function (param) {
                                                                      return line;
                                                                    });
                                                                setRefundModalOpen(function (param) {
                                                                      return true;
                                                                    });
                                                              }),
                                                            moveSelectedPayment: moveSelectedPayment
                                                          }, String(i));
                                              }))
                                      }),
                                  JsxRuntime.jsx(BDMovePaymentModal$Thick.make, {
                                        open_: match$3[0],
                                        onClose: (function () {
                                            setMovePaymentModalOpen(function (param) {
                                                  return false;
                                                });
                                          }),
                                        pmtToMove: match$2[0],
                                        pmtLine: selectedPaymentLine,
                                        brandFamily: brandFamily,
                                        billPayCurrent: content,
                                        versionUrl: versionUrl,
                                        generatePreviewFromDiff: generatePreviewFromDiff
                                      })
                                ]
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  height: "7rem"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "title-lg",
                                          component: "p",
                                          textColor: "neutral.500",
                                          children: Caml_option.some(isActive && !snapshot ? "No payments have been made yet." : "No payments made.")
                                        }))
                              }),
                        JsxRuntime.jsx(Sheet, {
                              sx: {
                                border: "1px solid",
                                borderColor: "divider",
                                borderRadius: "4px",
                                p: 1,
                                my: 2
                              },
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "flex-start",
                                        children: [
                                          JsxRuntime.jsx(Check, {
                                                sx: {
                                                  color: "#037E3A",
                                                  pr: 1
                                                }
                                              }),
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "column",
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "title-md",
                                                        textColor: "#037E3A",
                                                        children: "Remaining Unpaid Balance"
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "title-md",
                                                        textColor: "text.secondary",
                                                        children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(content.totalAmount)))
                                                      }),
                                                  content.arePendingPayments ? JsxRuntime.jsx(Typography, {
                                                          level: "title-sm",
                                                          sx: {
                                                            fontStyle: "italic"
                                                          },
                                                          textColor: "text.secondary",
                                                          fontWeight: "normal",
                                                          children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(content.totalAmountIncludingPending)) + " after pending payments")
                                                        }) : null
                                                ]
                                              })
                                        ]
                                      }))
                            })
                      ]
                    })
              ]
            });
}

var InvoiceDetails = {
  make: BDInvoiceDetails$InvoiceDetails
};

function BDInvoiceDetails(props) {
  var billPayRec = props.billPayRec;
  if (billPayRec !== undefined) {
    return JsxRuntime.jsx(BDInvoiceDetails$InvoiceDetails, {
                content: billPayRec,
                isActive: props.isActive,
                snapshot: props.snapshot,
                canManage: props.canManage,
                versionUrl: props.versionUrl,
                generatePreviewFromDiff: props.generatePreviewFromDiff,
                versionTimestamp: props.versionTimestamp,
                versionSuppressions: props.versionSuppressions,
                currentUserEmail: props.currentUserEmail,
                brandFamily: props.brandFamily,
                openRecordCheckModal: props.openRecordCheckModal
              });
  } else {
    return JsxRuntime.jsx(Stack, {
                direction: "column",
                justifyContent: "center",
                alignItems: "center",
                sx: {
                  height: "7rem"
                },
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "title-lg",
                          component: "p",
                          textColor: "neutral.500",
                          children: "No billing & payment information available."
                        }))
              });
  }
}

var make = BDInvoiceDetails;

exports.TableLine = TableLine;
exports.InvoiceDetails = InvoiceDetails;
exports.make = make;
/* react Not a pure module */
