// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"versionComment":{"c":"Util.CustomScalars.NonemptyString.Exn"},"bookingVersionNumber":{"b":""},"bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function lifecycleStatus_decode($$enum) {
  if ($$enum === "CANCELED" || $$enum === "STAGED" || $$enum === "DRAFT" || $$enum === "ACTIVE" || $$enum === "HISTORY") {
    return $$enum;
  }
  
}

function lifecycleStatus_fromString(str) {
  return lifecycleStatus_decode(str);
}

var Utils = {
  lifecycleStatus_decode: lifecycleStatus_decode,
  lifecycleStatus_fromString: lifecycleStatus_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BDHero_version",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lifecycleStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingVersionNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "versionComment",
      "storageKey": null
    }
  ],
  "type": "BookingVersion",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
