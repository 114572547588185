// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var InviteUserSubmit$Thick = require("./mutations/InviteUserSubmit.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var BrandPermProtected$Thick = require("../wrappers/BrandPermProtected.bs.js");
var InviteUserForm_query_graphql$Thick = require("../../__generated__/InviteUserForm_query_graphql.bs.js");

var convertFragment = InviteUserForm_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(InviteUserForm_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, InviteUserForm_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(InviteUserForm_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment_brandfamRole_decode = InviteUserForm_query_graphql$Thick.Utils.brandfamRole_decode;

var QueryFragment_brandfamRole_fromString = InviteUserForm_query_graphql$Thick.Utils.brandfamRole_fromString;

var QueryFragment = {
  brandfamRole_decode: QueryFragment_brandfamRole_decode,
  brandfamRole_fromString: QueryFragment_brandfamRole_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function InviteUserForm(props) {
  var onClose = props.onClose;
  var fragment = use(props.fragment);
  var match = fragment.roles;
  var rolesList = match !== undefined ? Belt_List.toArray(Belt_Array.reduce(match.nodes, /* [] */0, (function (acc, node) {
                return Belt_List.add(acc, {
                            brandfamRole: Common$Thick.BrandfamRole.toInput(node.brandfamRole),
                            description: node.description
                          });
              }))) : [];
  var match$1 = fragment.voyages;
  var voyagesList = match$1 !== undefined ? Belt_List.toArray(Belt_Array.reduce(match$1.nodes, /* [] */0, (function (acc, node) {
                return Belt_List.add(acc, {
                            voyageSlug: node.voyageSlug
                          });
              }))) : [];
  var match$2 = InviteUserSubmit$Thick.useMutation(props.brandFamily, props.refreshTeam, Belt_Array.slice(rolesList, 0, 1), [], onClose);
  var onSubmit = match$2.onSubmit;
  var setError = match$2.setError;
  var error = match$2.error;
  var setUserVoyages = match$2.setUserVoyages;
  var setUserRoles = match$2.setUserRoles;
  var userRoles = match$2.userRoles;
  var setUserEmail = match$2.setUserEmail;
  var userEmail = match$2.userEmail;
  var clearAll = function () {
    setUserEmail(function (param) {
          return "";
        });
    setUserRoles(function (param) {
          return Belt_Array.slice(rolesList, 0, 1);
        });
    setUserVoyages(function (param) {
          return [];
        });
  };
  console.log(userRoles);
  var m = CS_Emails$Util.Email.fromPrimitive(userEmail);
  var tmp;
  tmp = m.TAG === "Ok" ? null : m._0;
  var match$3 = userRoles.length;
  var match$4 = userRoles.length;
  var match$5 = Belt_Result.isOk(CS_Emails$Util.Email.fromPrimitive(userEmail));
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: (function () {
                  onClose();
                  clearAll();
                  setError(function (param) {
                        
                      });
                }),
              title: "Invite User",
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        textColor: "text.tertiary",
                                        children: "Invite a user to your brand family. They will receive an email with a link to create an account."
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        component: "h3",
                                        children: "User Information"
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        component: "form",
                                        spacing: 2,
                                        onSubmit: onSubmit,
                                        children: [
                                          JsxRuntime.jsxs(FormControl, {
                                                error: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(userEmail)),
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Email"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "email",
                                                        required: true,
                                                        value: userEmail,
                                                        onChange: (function (e) {
                                                            var val = e.currentTarget.value;
                                                            setUserEmail(function (param) {
                                                                  return val;
                                                                });
                                                          })
                                                      }),
                                                  JsxRuntime.jsx(FormHelperText, {
                                                        children: Caml_option.some(tmp)
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsxs(BrandPermProtected$Thick.make, {
                                                fragmentRefs: props.permFrag,
                                                neededPerm: "MANAGE_USER",
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        component: "h3",
                                                        children: "Permissions"
                                                      }),
                                                  JsxRuntime.jsxs(FormControl, {
                                                        error: match$3 === 0,
                                                        children: [
                                                          JsxRuntime.jsx(FormLabel, {
                                                                children: "Roles"
                                                              }),
                                                          JsxRuntime.jsx(Joy$Util.JoyAuto.Multi.make, {
                                                                options: rolesList,
                                                                getOptionLabel: (function (node) {
                                                                    if (node !== undefined) {
                                                                      return Common$Thick.BrandfamRole.toString(node.brandfamRole);
                                                                    } else {
                                                                      return "";
                                                                    }
                                                                  }),
                                                                id: "brandfam-roles",
                                                                isOptionEqualToValue: (function (option, value) {
                                                                    if (option !== undefined && value !== undefined) {
                                                                      return option.brandfamRole === value.brandfamRole;
                                                                    } else {
                                                                      return false;
                                                                    }
                                                                  }),
                                                                onChange: (function (param, value) {
                                                                    if (value !== undefined) {
                                                                      return setUserRoles(function (param) {
                                                                                  return value;
                                                                                });
                                                                    }
                                                                    
                                                                  }),
                                                                required: true,
                                                                value: userRoles
                                                              }),
                                                          JsxRuntime.jsx(FormHelperText, {
                                                                children: Caml_option.some(match$4 !== 0 ? null : "User must be invited with at least one role")
                                                              })
                                                        ]
                                                      }),
                                                  JsxRuntime.jsxs(FormControl, {
                                                        children: [
                                                          JsxRuntime.jsx(FormLabel, {
                                                                children: "Voyages"
                                                              }),
                                                          JsxRuntime.jsx(Joy$Util.JoyAuto.Multi.make, {
                                                                options: voyagesList,
                                                                getOptionLabel: (function (node) {
                                                                    if (node !== undefined) {
                                                                      return CS_Slugs$Util.VoyageSlug.toPrimitive(node.voyageSlug);
                                                                    } else {
                                                                      return "";
                                                                    }
                                                                  }),
                                                                id: "voyages",
                                                                isOptionEqualToValue: (function (option, value) {
                                                                    if (option !== undefined && value !== undefined) {
                                                                      return Caml_obj.equal(option.voyageSlug, value.voyageSlug);
                                                                    } else {
                                                                      return false;
                                                                    }
                                                                  }),
                                                                onChange: (function (param, value) {
                                                                    if (value !== undefined) {
                                                                      return setUserVoyages(function (param) {
                                                                                  return value;
                                                                                });
                                                                    }
                                                                    
                                                                  }),
                                                                value: match$2.userVoyages
                                                              })
                                                        ]
                                                      })
                                                ]
                                              }),
                                          error !== undefined ? JsxRuntime.jsx(Typography, {
                                                  color: "danger",
                                                  children: Caml_option.some(error)
                                                }) : null
                                        ]
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                component: "form",
                                onSubmit: onSubmit,
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          loading: match$2.isMutating,
                                          type: "submit",
                                          disabled: match$5 && error === undefined ? userRoles.length === 0 : true,
                                          children: "Submit"
                                        }))
                              }))
                    })
              ]
            });
}

var make = InviteUserForm;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* Joy-Util Not a pure module */
