// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick = require("../../../__generated__/ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql.bs.js");

var convertVariables = ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ChangeCabinCapacitySubmit_ChangeCabinCapacityMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var ChangeCabinCapacityMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(onBrandFamily, onVoyage, forCabinNumbers, refetch, setSelectedCabins, onClose, setError) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var match$2 = React.useState(function () {
        return false;
      });
  var match$3 = React.useState(function () {
        return null;
      });
  var capacityMin = match$3[0];
  var match$4 = React.useState(function () {
        return null;
      });
  var capacityMax = match$4[0];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    var maybeNewSalesMax = Belt_Option.flatMap((capacityMax == null) ? undefined : Caml_option.some(capacityMax), Belt_Int.fromString);
    var maybeNewSalesMin = Belt_Option.flatMap((capacityMin == null) ? undefined : Caml_option.some(capacityMin), Belt_Int.fromString);
    mutate({
          input: {
            onBrandFamily: onBrandFamily,
            onVoyage: onVoyage,
            forCabinNumbers: forCabinNumbers,
            maybeNewSalesMin: maybeNewSalesMin,
            maybeNewSalesMax: maybeNewSalesMax
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.changeCabinCapacity;
              if (match !== undefined) {
                var match$1 = match.boolean;
                if (match$1 !== undefined && match$1) {
                  console.log("Successfully changed cabin capacity");
                  refetch();
                  onClose();
                  setSelectedCabins(function (param) {
                        return Belt_Set.make(Common$Thick.CabinCmp);
                      });
                  setError(function (param) {
                        
                      });
                  return ;
                }
                
              }
              console.log("Failed to change cabin capacity");
              setError(function (param) {
                    return "Failed to change cabin capacity";
                  });
              return ;
            }
            if (err !== undefined) {
              var error = Belt_Array.reduce(err, "", (function (carry, obj) {
                      if (carry === "") {
                        return obj.message;
                      } else {
                        return carry + ", " + obj.message;
                      }
                    }));
              console.log(error);
              setError(function (param) {
                    return "Error changing cabin capacity";
                  });
              return ;
            }
            console.log("Unknown error changing cabin capacity");
          }), (function (err) {
            console.log(err);
            setError(function (param) {
                  return "Error changing cabin capacity";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          areChangingMin: match$1[0],
          setAreChangingMin: match$1[1],
          areChangingMax: match$2[0],
          setAreChangingMax: match$2[1],
          capacityMin: capacityMin,
          setCapacityMin: match$3[1],
          capacityMax: capacityMax,
          setCapacityMax: match$4[1],
          onSubmit: onSubmit
        };
}

exports.ChangeCabinCapacityMutation = ChangeCabinCapacityMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
