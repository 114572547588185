// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var Textarea = require("@mui/joy/Textarea").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var AddBookingComment$Thick = require("./mutations/AddBookingComment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Send = require("@mui/icons-material/Send").default;
var BDComments_version_graphql$Thick = require("../../__generated__/BDComments_version_graphql.bs.js");
var BDCommentsFragmentRefetch_graphql$Thick = require("../../__generated__/BDCommentsFragmentRefetch_graphql.bs.js");

var convertFragment = BDComments_version_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDComments_version_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDComments_version_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDComments_version_graphql$Thick.node, convertFragment, fRef);
}

var makeRefetchVariables = BDCommentsFragmentRefetch_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = BDCommentsFragmentRefetch_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(BDComments_version_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var CommentsFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function BDComments(props) {
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var refetch$1 = function (param) {
    return refetch(makeRefetchVariables(undefined), "store-and-network", undefined);
  };
  var match$1 = React.useState(function () {
        return AddBookingComment$Thick.Utils.empty;
      });
  var setAdd = match$1[1];
  var add = match$1[0];
  var success = function (param) {
    setAdd(function (param) {
          return {
                  commentBody: undefined
                };
        });
    refetch$1();
  };
  var match$2 = AddBookingComment$Thick.useMutation(props.brandFamily, props.voyage, props.bookingId, props.bookingVersionNumber, success);
  var addBookingComment = match$2.addBookingComment;
  var setError = match$2.setError;
  var error = match$2.error;
  var onSubmit = function (e) {
    e.preventDefault();
    var record = AddBookingComment$Thick.Utils.newBookingCommentIntermToRecord(add);
    if (record.TAG === "Ok") {
      addBookingComment(record._0);
      return ;
    }
    console.error(record._0);
    setError(function (param) {
          return "Please enter a comment.";
        });
  };
  var val = add.commentBody;
  return JsxRuntime.jsx(BDContentBlock$Thick.make, {
              title: "Comments",
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    spacing: 1,
                    children: [
                      JsxRuntime.jsx(Typography, {
                            level: "body-xs",
                            sx: {
                              fontStyle: "italic"
                            },
                            children: "Comments are not visible to customers"
                          }),
                      JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            component: "form",
                            spacing: 1,
                            sx: {
                              width: "100%"
                            },
                            onSubmit: onSubmit,
                            children: [
                              JsxRuntime.jsx(FormControl, {
                                    error: val !== undefined ? Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val)) : true,
                                    children: Caml_option.some(JsxRuntime.jsx(Textarea, {
                                              value: Belt_Option.getWithDefault(add.commentBody, ""),
                                              onChange: (function (e) {
                                                  var val = e.currentTarget.value;
                                                  if (val === "") {
                                                    return setAdd(function (param) {
                                                                return {
                                                                        commentBody: undefined
                                                                      };
                                                              });
                                                  } else {
                                                    return setAdd(function (param) {
                                                                return {
                                                                        commentBody: val
                                                                      };
                                                              });
                                                  }
                                                }),
                                              maxRows: 3,
                                              minRows: 3,
                                              size: "sm",
                                              sx: {
                                                width: "400px"
                                              }
                                            }))
                                  }),
                              JsxRuntime.jsx(Button, {
                                    loading: match$2.isMutating,
                                    type: "submit",
                                    startDecorator: Caml_option.some(JsxRuntime.jsx(Send, {})),
                                    disabled: Belt_Result.isError(AddBookingComment$Thick.Utils.newBookingCommentIntermToRecord(add)),
                                    children: ""
                                  })
                            ]
                          }),
                      error !== undefined ? JsxRuntime.jsx(Typography, {
                              color: "danger",
                              children: Caml_option.some(error)
                            }) : null,
                      JsxRuntime.jsx(Stack, {
                            direction: "column",
                            spacing: 1,
                            children: Caml_option.some(Belt_Array.map(match[0].booking_comment.nodes, (function (node) {
                                        var bfuser = node.brand_family_user;
                                        return JsxRuntime.jsxs(Stack, {
                                                    direction: "column",
                                                    spacing: 0,
                                                    children: [
                                                      JsxRuntime.jsxs(Stack, {
                                                            direction: "row",
                                                            spacing: 1,
                                                            children: [
                                                              JsxRuntime.jsx(Typography, {
                                                                    level: "body-xs",
                                                                    children: Caml_option.some(bfuser !== undefined ? CS_Emails$Util.Email.toString(bfuser.email) : null)
                                                                  }),
                                                              JsxRuntime.jsx(Tooltip, {
                                                                    children: JsxRuntime.jsx(Typography, {
                                                                          level: "body-xs",
                                                                          sx: {
                                                                            cursor: "pointer"
                                                                          },
                                                                          children: Caml_option.some(DateFns.formatDistanceToNow(CS_Make$Util.Dateable.Plain.toDate(node.createdAt)) + " ago")
                                                                        }),
                                                                    arrow: true,
                                                                    placement: "top",
                                                                    size: "sm",
                                                                    title: DateFns.format(CS_Make$Util.Dateable.Plain.toDate(node.createdAt), "LLL do, yyyy h:mmaaa")
                                                                  })
                                                            ]
                                                          }),
                                                      JsxRuntime.jsx(Typography, {
                                                            level: "body-sm",
                                                            children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(node.commentBody))
                                                          })
                                                    ]
                                                  });
                                      })))
                          })
                    ]
                  })
            });
}

var make = BDComments;

exports.CommentsFragment = CommentsFragment;
exports.make = make;
/* react Not a pure module */
