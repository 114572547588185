// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var CS_Make$Util = require("./CS_Make.bs.js");

function check(numericStr, nPlaces) {
  var numRe = new RegExp("^\\d+$");
  var isNeg = numericStr.startsWith("-");
  var numericStr$1 = isNeg ? numericStr.substring(1, numericStr.length) : numericStr;
  var multiplier = Belt_Option.getExn(Belt_Int.fromString("1" + "0".repeat(nPlaces)));
  var match = numericStr$1.length >= 3;
  var match$1 = numericStr$1.split(".");
  if (!match) {
    return {
            TAG: "Error",
            _0: "No decimal point or too many points or string too short"
          };
  }
  if (match$1.length !== 2) {
    return {
            TAG: "Error",
            _0: "No decimal point or too many points or string too short"
          };
  }
  var front = match$1[0];
  var back = match$1[1];
  var match$2 = numRe.test(front);
  var match$3 = numRe.test(back);
  if (match$2 && match$3) {
    if (back.length === nPlaces) {
      return {
              TAG: "Ok",
              _0: Math.imul(Math.imul(isNeg ? -1 : 1, Belt_Option.getExn(Belt_Int.fromString(front))), multiplier) + Math.imul(isNeg ? -1 : 1, Belt_Option.getExn(Belt_Int.fromString(back))) | 0
            };
    } else {
      return {
              TAG: "Error",
              _0: "Not a Decimal number with " + String(nPlaces) + " places"
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "Decimal number is not made up of just numbers and points"
          };
  }
}

function check$1(str) {
  return check(str, 2);
}

function toFloat(i) {
  return i / Math.pow(10, 2);
}

var Decimal10_2 = CS_Make$Util.Decimable.MakeWithRefinements({
      check: check$1,
      toFloat: toFloat
    });

exports.check = check;
exports.Decimal10_2 = Decimal10_2;
/* Decimal10_2 Not a pure module */
