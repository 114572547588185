// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var BDButton$Thick = require("./atoms/BDButton.bs.js");
var $$Option = require("@mui/joy/Option").default;
var DraftUtils$Thick = require("./drafts/DraftUtils.bs.js");
var Textarea = require("@mui/joy/Textarea").default;
var BookingDiff$Thick = require("../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDPreferences_version_graphql$Thick = require("../../__generated__/BDPreferences_version_graphql.bs.js");

var convertFragment = BDPreferences_version_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDPreferences_version_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDPreferences_version_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDPreferences_version_graphql$Thick.node, convertFragment, fRef);
}

var VersionFragment_elevatorProximity_decode = BDPreferences_version_graphql$Thick.Utils.elevatorProximity_decode;

var VersionFragment_elevatorProximity_fromString = BDPreferences_version_graphql$Thick.Utils.elevatorProximity_fromString;

var VersionFragment_longitudinality_decode = BDPreferences_version_graphql$Thick.Utils.longitudinality_decode;

var VersionFragment_longitudinality_fromString = BDPreferences_version_graphql$Thick.Utils.longitudinality_fromString;

var VersionFragment = {
  elevatorProximity_decode: VersionFragment_elevatorProximity_decode,
  elevatorProximity_fromString: VersionFragment_elevatorProximity_fromString,
  longitudinality_decode: VersionFragment_longitudinality_decode,
  longitudinality_fromString: VersionFragment_longitudinality_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BDPreferences$Preferences(props) {
  var versionUrl = props.versionUrl;
  var diffStruct = props.diffStruct;
  var draftPreferences = props.draftPreferences;
  var versionPreferences = props.preferences;
  var match = React.useState(function () {
        return versionPreferences;
      });
  var setPref = match[1];
  var pref = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var loading = match$1[0];
  var canEdit = !props.snapshot && props.isActive && props.canManage;
  React.useEffect((function () {
          var initialPrefs = draftPreferences !== undefined ? draftPreferences : versionPreferences;
          setPref(function (param) {
                return initialPrefs;
              });
        }), [
        draftPreferences,
        versionPreferences
      ]);
  var onChange = function (pref) {
    var applyDiff = BookingDiff$Thick.Alter.updateBookingPreferences(diffStruct, pref, versionPreferences);
    DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
  };
  var onChangeDebounceTimerRef = React.useRef(null);
  var debouncedOnChange = function (newPrefs) {
    var timer = onChangeDebounceTimerRef.current;
    if (!(timer == null)) {
      clearTimeout(timer);
    }
    setLoading(function (param) {
          return true;
        });
    onChangeDebounceTimerRef.current = setTimeout((function () {
            onChange(newPrefs);
            setLoading(function (param) {
                  return false;
                });
          }), 1000);
  };
  React.useEffect((function () {
          Belt_Option.forEach(Caml_option.nullable_to_opt(onChangeDebounceTimerRef.current), (function (prim) {
                  clearTimeout(prim);
                }));
        }), []);
  var actions = JsxRuntime.jsx(Stack, {
        sx: {
          visibility: !canEdit || BookingDiff$Thick.UnnestedDiff.arePreferenceRecordsSame(pref, versionPreferences) ? "hidden" : "visible"
        },
        children: Caml_option.some(JsxRuntime.jsx(BDButton$Thick.make, {
                  onClick: (function (param) {
                      var newrecord = Caml_obj.obj_dup(diffStruct);
                      newrecord.updateBookingPreferences = undefined;
                      setPref(function (param) {
                            return versionPreferences;
                          });
                      DraftUtils$Thick.addToDraft(newrecord, versionUrl);
                    }),
                  text: "Revert Changes",
                  type_: "primary",
                  loading: loading,
                  disabled: BookingDiff$Thick.UnnestedDiff.arePreferenceRecordsSame(pref, versionPreferences) || loading,
                  size: "md"
                }))
      });
  var pref$1 = pref.cabinLongitudePref;
  var tmp;
  if (pref$1 !== undefined) {
    if (pref$1 === "AFT" || pref$1 === "FORE" || pref$1 === "MID") {
      switch (pref$1) {
        case "FORE" :
            tmp = "fore";
            break;
        case "AFT" :
            tmp = "aft";
            break;
        case "MID" :
            tmp = "mid";
            break;
        
      }
    } else {
      tmp = undefined;
    }
  } else {
    tmp = "none";
  }
  var pref$2 = pref.cabinElevatorPref;
  var tmp$1;
  tmp$1 = pref$2 !== undefined ? (
      pref$2 === "NEARELEVATORS" || pref$2 === "FARELEVATORS" ? (
          pref$2 === "NEARELEVATORS" ? "nearelevators" : "farelevators"
        ) : undefined
    ) : "none";
  var pref$3 = pref.earlyDinnerSeatingPref;
  var pref$4 = pref.bedsTogetherPref;
  return JsxRuntime.jsx(BDContentBlock$Thick.make, {
              title: "Preferences",
              actions: Caml_option.some(actions),
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    spacing: 2,
                    children: [
                      JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 1,
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    fontWeight: "400",
                                    children: "Cabin Ship Placement"
                                  }),
                              JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                                    children: [
                                      JsxRuntime.jsx($$Option, {
                                            value: "fore",
                                            children: "I'd like a stateroom towards the front of the ship"
                                          }),
                                      JsxRuntime.jsx($$Option, {
                                            value: "mid",
                                            children: "I'd like a stateroom towards the middle of the ship"
                                          }),
                                      JsxRuntime.jsx($$Option, {
                                            value: "aft",
                                            children: "I'd like a stateroom towards the back of the ship"
                                          }),
                                      JsxRuntime.jsx($$Option, {
                                            value: "none",
                                            children: "No preference"
                                          })
                                    ],
                                    disabled: !canEdit,
                                    onChange: (function (param, val) {
                                        var newPrefs;
                                        var exit = 0;
                                        if (val !== undefined) {
                                          var val$1 = Caml_option.valFromOption(val);
                                          if (val$1 !== undefined) {
                                            var tmp;
                                            switch (val$1) {
                                              case "aft" :
                                                  tmp = "AFT";
                                                  break;
                                              case "fore" :
                                                  tmp = "FORE";
                                                  break;
                                              case "mid" :
                                                  tmp = "MID";
                                                  break;
                                              default:
                                                tmp = undefined;
                                            }
                                            newPrefs = {
                                              bedsTogetherPref: pref.bedsTogetherPref,
                                              cabinLongitudePref: tmp,
                                              cabinElevatorPref: pref.cabinElevatorPref,
                                              cabinPlacementRequest: pref.cabinPlacementRequest,
                                              earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                              generalPref: pref.generalPref
                                            };
                                          } else {
                                            exit = 1;
                                          }
                                        } else {
                                          exit = 1;
                                        }
                                        if (exit === 1) {
                                          newPrefs = {
                                            bedsTogetherPref: pref.bedsTogetherPref,
                                            cabinLongitudePref: undefined,
                                            cabinElevatorPref: pref.cabinElevatorPref,
                                            cabinPlacementRequest: pref.cabinPlacementRequest,
                                            earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                            generalPref: pref.generalPref
                                          };
                                        }
                                        setPref(function (param) {
                                              return newPrefs;
                                            });
                                        onChange(newPrefs);
                                      }),
                                    size: "sm",
                                    value: tmp
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 1,
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    fontWeight: "400",
                                    children: "Cabin Elevator Placement"
                                  }),
                              JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                                    children: [
                                      JsxRuntime.jsx($$Option, {
                                            value: "nearelevators",
                                            children: "Try to place my stateroom near the elevators"
                                          }),
                                      JsxRuntime.jsx($$Option, {
                                            value: "farelevators",
                                            children: "Try to place my stateroom far from the elevators"
                                          }),
                                      JsxRuntime.jsx($$Option, {
                                            value: "none",
                                            children: "No preference"
                                          })
                                    ],
                                    disabled: !canEdit,
                                    onChange: (function (param, val) {
                                        var newPrefs;
                                        var exit = 0;
                                        if (val !== undefined) {
                                          var val$1 = Caml_option.valFromOption(val);
                                          if (val$1 !== undefined) {
                                            var tmp;
                                            switch (val$1) {
                                              case "farelevators" :
                                                  tmp = "FARELEVATORS";
                                                  break;
                                              case "nearelevators" :
                                                  tmp = "NEARELEVATORS";
                                                  break;
                                              default:
                                                tmp = undefined;
                                            }
                                            newPrefs = {
                                              bedsTogetherPref: pref.bedsTogetherPref,
                                              cabinLongitudePref: pref.cabinLongitudePref,
                                              cabinElevatorPref: tmp,
                                              cabinPlacementRequest: pref.cabinPlacementRequest,
                                              earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                              generalPref: pref.generalPref
                                            };
                                          } else {
                                            exit = 1;
                                          }
                                        } else {
                                          exit = 1;
                                        }
                                        if (exit === 1) {
                                          newPrefs = {
                                            bedsTogetherPref: pref.bedsTogetherPref,
                                            cabinLongitudePref: pref.cabinLongitudePref,
                                            cabinElevatorPref: undefined,
                                            cabinPlacementRequest: pref.cabinPlacementRequest,
                                            earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                            generalPref: pref.generalPref
                                          };
                                        }
                                        setPref(function (param) {
                                              return newPrefs;
                                            });
                                        onChange(newPrefs);
                                      }),
                                    size: "sm",
                                    value: tmp$1
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 1,
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    fontWeight: "400",
                                    children: "Additional Stateroom Placement Requests"
                                  }),
                              JsxRuntime.jsx(Textarea, {
                                    value: Belt_Option.getWithDefault(Belt_Option.map(pref.cabinPlacementRequest, CS_NonemptyStrings$Util.NonEmptyString.toString), ""),
                                    disabled: !canEdit,
                                    onChange: (function (e) {
                                        var val = e.currentTarget.value;
                                        var val$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val);
                                        var tmp;
                                        tmp = val$1.TAG === "Ok" ? Caml_option.some(val$1._0) : undefined;
                                        var newPrefs_bedsTogetherPref = pref.bedsTogetherPref;
                                        var newPrefs_cabinLongitudePref = pref.cabinLongitudePref;
                                        var newPrefs_cabinElevatorPref = pref.cabinElevatorPref;
                                        var newPrefs_earlyDinnerSeatingPref = pref.earlyDinnerSeatingPref;
                                        var newPrefs_generalPref = pref.generalPref;
                                        var newPrefs = {
                                          bedsTogetherPref: newPrefs_bedsTogetherPref,
                                          cabinLongitudePref: newPrefs_cabinLongitudePref,
                                          cabinElevatorPref: newPrefs_cabinElevatorPref,
                                          cabinPlacementRequest: tmp,
                                          earlyDinnerSeatingPref: newPrefs_earlyDinnerSeatingPref,
                                          generalPref: newPrefs_generalPref
                                        };
                                        setPref(function (param) {
                                              return newPrefs;
                                            });
                                        debouncedOnChange(newPrefs);
                                      }),
                                    maxRows: 3,
                                    minRows: 3,
                                    size: "sm"
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 1,
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    fontWeight: "400",
                                    children: "Bed Placement"
                                  }),
                              JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                                    children: [
                                      JsxRuntime.jsx($$Option, {
                                            value: "true",
                                            children: "Join the beds together"
                                          }),
                                      JsxRuntime.jsx($$Option, {
                                            value: "false",
                                            children: "Keep the beds separate"
                                          }),
                                      JsxRuntime.jsx($$Option, {
                                            value: "none",
                                            children: "No preference"
                                          })
                                    ],
                                    disabled: !canEdit,
                                    onChange: (function (param, val) {
                                        var newPrefs;
                                        var exit = 0;
                                        if (val !== undefined) {
                                          var val$1 = Caml_option.valFromOption(val);
                                          if (val$1 !== undefined) {
                                            var tmp;
                                            switch (val$1) {
                                              case "false" :
                                                  tmp = false;
                                                  break;
                                              case "true" :
                                                  tmp = true;
                                                  break;
                                              default:
                                                tmp = undefined;
                                            }
                                            newPrefs = {
                                              bedsTogetherPref: pref.bedsTogetherPref,
                                              cabinLongitudePref: pref.cabinLongitudePref,
                                              cabinElevatorPref: pref.cabinElevatorPref,
                                              cabinPlacementRequest: pref.cabinPlacementRequest,
                                              earlyDinnerSeatingPref: tmp,
                                              generalPref: pref.generalPref
                                            };
                                          } else {
                                            exit = 1;
                                          }
                                        } else {
                                          exit = 1;
                                        }
                                        if (exit === 1) {
                                          newPrefs = {
                                            bedsTogetherPref: pref.bedsTogetherPref,
                                            cabinLongitudePref: pref.cabinLongitudePref,
                                            cabinElevatorPref: pref.cabinElevatorPref,
                                            cabinPlacementRequest: pref.cabinPlacementRequest,
                                            earlyDinnerSeatingPref: undefined,
                                            generalPref: pref.generalPref
                                          };
                                        }
                                        setPref(function (param) {
                                              return newPrefs;
                                            });
                                        onChange(newPrefs);
                                      }),
                                    size: "sm",
                                    value: pref$3 !== undefined ? (
                                        pref$3 ? "true" : "false"
                                      ) : "none"
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 1,
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    fontWeight: "400",
                                    children: "Dinner Seating"
                                  }),
                              JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                                    children: [
                                      JsxRuntime.jsx($$Option, {
                                            value: "true",
                                            children: "Red Team (Early Dinner/Late Concert), thanks!"
                                          }),
                                      JsxRuntime.jsx($$Option, {
                                            value: "false",
                                            children: "Gold Team (Late Dinner/Early Concert), thanks!"
                                          }),
                                      JsxRuntime.jsx($$Option, {
                                            value: "none",
                                            children: "No preference"
                                          })
                                    ],
                                    disabled: !canEdit,
                                    onChange: (function (param, val) {
                                        var newPrefs;
                                        var exit = 0;
                                        if (val !== undefined) {
                                          var val$1 = Caml_option.valFromOption(val);
                                          if (val$1 !== undefined) {
                                            var tmp;
                                            switch (val$1) {
                                              case "false" :
                                                  tmp = false;
                                                  break;
                                              case "true" :
                                                  tmp = true;
                                                  break;
                                              default:
                                                tmp = undefined;
                                            }
                                            newPrefs = {
                                              bedsTogetherPref: tmp,
                                              cabinLongitudePref: pref.cabinLongitudePref,
                                              cabinElevatorPref: pref.cabinElevatorPref,
                                              cabinPlacementRequest: pref.cabinPlacementRequest,
                                              earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                              generalPref: pref.generalPref
                                            };
                                          } else {
                                            exit = 1;
                                          }
                                        } else {
                                          exit = 1;
                                        }
                                        if (exit === 1) {
                                          newPrefs = {
                                            bedsTogetherPref: undefined,
                                            cabinLongitudePref: pref.cabinLongitudePref,
                                            cabinElevatorPref: pref.cabinElevatorPref,
                                            cabinPlacementRequest: pref.cabinPlacementRequest,
                                            earlyDinnerSeatingPref: pref.earlyDinnerSeatingPref,
                                            generalPref: pref.generalPref
                                          };
                                        }
                                        setPref(function (param) {
                                              return newPrefs;
                                            });
                                        onChange(newPrefs);
                                      }),
                                    size: "sm",
                                    value: pref$4 !== undefined ? (
                                        pref$4 ? "true" : "false"
                                      ) : "none"
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 1,
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    fontWeight: "400",
                                    children: "Any Other Requests?"
                                  }),
                              JsxRuntime.jsx(Textarea, {
                                    value: Belt_Option.getWithDefault(Belt_Option.map(pref.generalPref, CS_NonemptyStrings$Util.NonEmptyString.toString), ""),
                                    disabled: !canEdit,
                                    onChange: (function (e) {
                                        var val = e.currentTarget.value;
                                        var val$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val);
                                        var tmp;
                                        tmp = val$1.TAG === "Ok" ? Caml_option.some(val$1._0) : undefined;
                                        var newPrefs_bedsTogetherPref = pref.bedsTogetherPref;
                                        var newPrefs_cabinLongitudePref = pref.cabinLongitudePref;
                                        var newPrefs_cabinElevatorPref = pref.cabinElevatorPref;
                                        var newPrefs_cabinPlacementRequest = pref.cabinPlacementRequest;
                                        var newPrefs_earlyDinnerSeatingPref = pref.earlyDinnerSeatingPref;
                                        var newPrefs = {
                                          bedsTogetherPref: newPrefs_bedsTogetherPref,
                                          cabinLongitudePref: newPrefs_cabinLongitudePref,
                                          cabinElevatorPref: newPrefs_cabinElevatorPref,
                                          cabinPlacementRequest: newPrefs_cabinPlacementRequest,
                                          earlyDinnerSeatingPref: newPrefs_earlyDinnerSeatingPref,
                                          generalPref: tmp
                                        };
                                        setPref(function (param) {
                                              return newPrefs;
                                            });
                                        debouncedOnChange(newPrefs);
                                      }),
                                    maxRows: 3,
                                    minRows: 3,
                                    size: "sm"
                                  })
                            ]
                          })
                    ]
                  })
            });
}

var Preferences = {
  make: BDPreferences$Preferences
};

function BDPreferences(props) {
  var diffStruct = props.diffStruct;
  var data = use(props.fragmentRefs);
  var match = React.useState(function () {
        
      });
  var setDraftPreferences = match[1];
  var match$1 = data.preferences;
  var match$2 = data.preferences;
  var match$3 = data.preferences;
  var match$4 = data.preferences;
  var match$5 = data.preferences;
  var match$6 = data.preferences;
  var preferences_bedsTogetherPref = match$1 !== undefined ? match$1.bedsTogetherPref : undefined;
  var preferences_cabinLongitudePref = match$2 !== undefined ? match$2.cabinLongitudePref : undefined;
  var preferences_cabinElevatorPref = match$3 !== undefined ? match$3.cabinElevatorPref : undefined;
  var preferences_cabinPlacementRequest = match$4 !== undefined ? match$4.cabinPlacementRequest : undefined;
  var preferences_earlyDinnerSeatingPref = match$5 !== undefined ? match$5.earlyDinnerSeatingPref : undefined;
  var preferences_generalPref = match$6 !== undefined ? match$6.generalPref : undefined;
  var preferences = {
    bedsTogetherPref: preferences_bedsTogetherPref,
    cabinLongitudePref: preferences_cabinLongitudePref,
    cabinElevatorPref: preferences_cabinElevatorPref,
    cabinPlacementRequest: preferences_cabinPlacementRequest,
    earlyDinnerSeatingPref: preferences_earlyDinnerSeatingPref,
    generalPref: preferences_generalPref
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  React.useEffect((function () {
          var update = diffStruct.updateBookingPreferences;
          if (update !== undefined) {
            setDraftPreferences(function (param) {
                  return update;
                });
          } else {
            setDraftPreferences(function (param) {
                  
                });
          }
        }), [
        url.search,
        diffStruct.updateBookingPreferences
      ]);
  return JsxRuntime.jsx(BDPreferences$Preferences, {
              preferences: preferences,
              draftPreferences: match[0],
              billPayRec: props.billPayRec,
              snapshot: props.snapshot,
              isActive: props.isActive,
              canManage: props.canManage,
              diffStruct: diffStruct,
              generatePreviewFromDiff: props.generatePreviewFromDiff,
              versionUrl: props.versionUrl
            });
}

var make = BDPreferences;

exports.VersionFragment = VersionFragment;
exports.Preferences = Preferences;
exports.make = make;
/* react Not a pure module */
