// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CreateNewAccountSubmit$Thick = require("./mutations/CreateNewAccountSubmit.bs.js");

function CreateNewAccountForm(props) {
  var brandFamily = props.brandFamily;
  var onClose = props.onClose;
  var match = CreateNewAccountSubmit$Thick.useMutation(brandFamily, props.refresh, onClose);
  var onSubmit = match.onSubmit;
  var setError = match.setError;
  var error = match.error;
  var setLastName = match.setLastName;
  var lastName = match.lastName;
  var setFirstName = match.setFirstName;
  var firstName = match.firstName;
  var setEmail = match.setEmail;
  var email = match.email;
  var m = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(firstName);
  var tmp;
  tmp = m.TAG === "Ok" ? null : m._0;
  var m$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(lastName);
  var tmp$1;
  tmp$1 = m$1.TAG === "Ok" ? null : m$1._0;
  var m$2 = CS_Emails$Util.Email.fromPrimitive(email);
  var tmp$2;
  tmp$2 = m$2.TAG === "Ok" ? null : m$2._0;
  var match$1 = Belt_Result.isOk(CS_Emails$Util.Email.fromPrimitive(email));
  var match$2 = Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(firstName));
  var match$3 = Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(lastName));
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: (function () {
                  onClose();
                  setError(function (param) {
                        
                      });
                }),
              title: "Create a New Account",
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        textColor: "text.tertiary",
                                        children: Caml_option.some("Create a new " + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + " customer account and send them an email invitation to claim it.")
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        component: "h3",
                                        children: "User Information"
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        component: "form",
                                        spacing: 1,
                                        onSubmit: onSubmit,
                                        children: [
                                          JsxRuntime.jsxs(FormControl, {
                                                error: Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(firstName)),
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "First Name"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        required: true,
                                                        value: firstName,
                                                        onChange: (function (e) {
                                                            var val = e.currentTarget.value;
                                                            setFirstName(function (param) {
                                                                  return val;
                                                                });
                                                          })
                                                      }),
                                                  JsxRuntime.jsx(FormHelperText, {
                                                        children: Caml_option.some(tmp)
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsxs(FormControl, {
                                                error: Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(lastName)),
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Last Name"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        required: true,
                                                        value: lastName,
                                                        onChange: (function (e) {
                                                            var val = e.currentTarget.value;
                                                            setLastName(function (param) {
                                                                  return val;
                                                                });
                                                          })
                                                      }),
                                                  JsxRuntime.jsx(FormHelperText, {
                                                        children: Caml_option.some(tmp$1)
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsxs(FormControl, {
                                                error: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Email"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "email",
                                                        required: true,
                                                        value: email,
                                                        onChange: (function (e) {
                                                            var val = e.currentTarget.value;
                                                            setEmail(function (param) {
                                                                  return val;
                                                                });
                                                          })
                                                      }),
                                                  JsxRuntime.jsx(FormHelperText, {
                                                        children: Caml_option.some(tmp$2)
                                                      })
                                                ]
                                              }),
                                          error !== undefined ? JsxRuntime.jsx(Typography, {
                                                  color: "danger",
                                                  children: Caml_option.some(error)
                                                }) : null
                                        ]
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                component: "form",
                                onSubmit: onSubmit,
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          loading: match.isMutating,
                                          type: "submit",
                                          disabled: match$1 && match$2 && match$3 ? false : true,
                                          children: "Create Account"
                                        }))
                              }))
                    })
              ]
            });
}

var make = CreateNewAccountForm;

exports.make = make;
/* Joy-Util Not a pure module */
