// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var CS_Make$Util = require("./CS_Make.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var CS_ISO3166_1$Util = require("./CS_ISO3166_1.bs.js");

function preproc(str) {
  var rmchr = function (orig, chr) {
    return orig.split(chr).join("");
  };
  var keepFirstSpaceOnly = function (orig) {
    var firstSpace = orig.indexOf(" ");
    if (firstSpace === -1) {
      return orig;
    } else {
      return orig.substring(0, firstSpace) + " " + rmchr(orig.slice(firstSpace + 1 | 0), " ");
    }
  };
  return rmchr(rmchr(rmchr(rmchr(keepFirstSpaceOnly(str), "("), ")"), "-"), ".");
}

function splitValid(str) {
  var a = str.split(" ");
  var front = Belt_Array.getExn(Belt_Array.getExn(a, 0).split("+"), 1);
  var back = Belt_Array.getExn(a, 1);
  return [
          front,
          back
        ];
}

function check(str) {
  var regex = /^\+\d{1,4} \d{5,15}$/g;
  var isNANP = /^\+1 \d+$/g;
  var isValidNANP = /^\+1 \d{10}$/g;
  var match = regex.test(str);
  var match$1 = isNANP.test(str);
  var match$2 = isValidNANP.test(str);
  if (!match) {
    return "BadFmt";
  }
  if (match$1) {
    
  }
  var match$3 = splitValid(str);
  var found = CS_ISO3166_1$Util.Bindings.findFromNumber(match$3[0], match$3[1]);
  if (found !== undefined) {
    return ;
  } else {
    return "BadCountryCode";
  }
}

var struct = S$RescriptSchema.refine(S$RescriptSchema.transform(S$RescriptSchema.$$String.min(S$RescriptSchema.string, 7, undefined), (function (param) {
            return {
                    p: (function (s) {
                        return preproc(s);
                      }),
                    s: (function (s) {
                        return s;
                      })
                  };
          })), (function (s) {
        return function (v) {
          var match = check(v);
          if (match === undefined) {
            return ;
          }
          switch (match) {
            case "BadFmt" :
                return s.fail("The phone number has invalid characters", undefined);
            case "BadNANP" :
                return s.fail("Phone numbers in country code +1 have ten digits", undefined);
            case "BadCountryCode" :
                return ;
            
          }
        };
      }));

function toPrimitive(a) {
  return a;
}

function primitiveToEl(prim) {
  return prim;
}

function primitiveToJson(prim) {
  return prim;
}

function meta(prim) {
  var match = splitValid(prim);
  return Belt_Option.getExn(CS_ISO3166_1$Util.Bindings.findFromNumber(match[0], match[1]));
}

var Phone = CS_Make$Util.Stringable.MakeFromMetaStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String,
      meta: meta
    });

exports.preproc = preproc;
exports.splitValid = splitValid;
exports.check = check;
exports.struct = struct;
exports.Phone = Phone;
/* struct Not a pure module */
