// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Menu = require("@mui/joy/Menu").default;
var Button = require("@mui/joy/Button").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var ButtonGroup = require("@mui/joy/ButtonGroup").default;
var ArrowDropDown = require("@mui/icons-material/ArrowDropDown").default;

function WorkflowApproveSplitButton(props) {
  var setSelectedAction = props.setSelectedAction;
  var selectedAction = props.selectedAction;
  var userActions = props.userActions;
  var allActions = props.allActions;
  var match = React.useState(function () {
        return false;
      });
  var setMenuIsOpen = match[1];
  var menuIsOpen = match[0];
  var actionRef = React.useRef(null);
  var anchorRef = React.useRef(null);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(ButtonGroup, {
                      ref: anchorRef,
                      color: "success",
                      variant: "solid",
                      size: "sm",
                      disabled: !Belt_Array.some(allActions, (function (action) {
                              return Belt_Set.has(userActions, action);
                            })),
                      children: [
                        JsxRuntime.jsx(Button, {
                              onClick: props.handleClick,
                              children: Caml_option.some(Common$Thick.WorkflowAction.toString(Common$Thick.WorkflowAction.toInput(selectedAction)))
                            }),
                        JsxRuntime.jsx(IconButton, {
                              onClick: (function (param) {
                                  var action = actionRef.current;
                                  if (!(action == null)) {
                                    return action();
                                  }
                                  
                                }),
                              "aria-label": "select merge strategy",
                              "aria-controls": menuIsOpen ? "split-button-menu" : "",
                              "aria-expanded": menuIsOpen ? "true" : "",
                              "aria-haspopup": "menu",
                              onMouseDown: (function (param) {
                                  actionRef.current = (function () {
                                      setMenuIsOpen(function (param) {
                                            return !menuIsOpen;
                                          });
                                    });
                                }),
                              onKeyDown: (function (param) {
                                  actionRef.current = (function () {
                                      setMenuIsOpen(function (param) {
                                            return !menuIsOpen;
                                          });
                                    });
                                }),
                              children: Caml_option.some(JsxRuntime.jsx(ArrowDropDown, {}))
                            })
                      ],
                      "aria-label": "split button"
                    }),
                JsxRuntime.jsx(Menu, {
                      anchorEl: Caml_option.some(anchorRef.current),
                      children: Caml_option.some(Belt_Array.map(allActions, (function (action) {
                                  return JsxRuntime.jsx(MenuItem, {
                                              children: Caml_option.some(Common$Thick.WorkflowAction.toString(Common$Thick.WorkflowAction.toInput(action))),
                                              disabled: !Belt_Set.has(userActions, action),
                                              selected: action === selectedAction,
                                              onClick: (function (param) {
                                                  setSelectedAction(function (param) {
                                                        return action;
                                                      });
                                                  setMenuIsOpen(function (param) {
                                                        return false;
                                                      });
                                                })
                                            }, action);
                                }))),
                      onClose: (function (param) {
                          setMenuIsOpen(function (param) {
                                return false;
                              });
                        }),
                      open: menuIsOpen
                    })
              ]
            });
}

var make = WorkflowApproveSplitButton;

exports.make = make;
/* react Not a pure module */
