// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Chip = require("@mui/joy/Chip").default;
var Menu = require("@mui/joy/Menu").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var PreviewTable$Thick = require("./PreviewTable.bs.js");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;

function DiffComparison$InvoicePreviewTab(props) {
  var setTab = props.setTab;
  var preview = props.preview;
  var activeTab = props.activeTab;
  var tab = props.tab;
  var match = tab - activeTab | 0;
  var match$1 = tab === activeTab;
  return JsxRuntime.jsx(Joy$Util.Grid.make, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        justifyContent: "space-between",
                        sx: {
                          height: "100%"
                        },
                        children: [
                          JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  mb: 1
                                },
                                onClick: (function (param) {
                                    setTab(function (param) {
                                          return tab;
                                        });
                                  }),
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        sx: {
                                          height: "10px"
                                        }
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        sx: {
                                          px: 2,
                                          borderRadius: "4px",
                                          "&:hover": {
                                            backgroundColor: tab === activeTab ? "" : "background.level2",
                                            cursor: "pointer"
                                          }
                                        },
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-sm",
                                                sx: {
                                                  color: tab === activeTab ? "text.primary" : "neutral.500",
                                                  fontWeight: "bold"
                                                },
                                                children: Caml_option.some(preview ? "Preview" : "Current")
                                              }),
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                sx: {
                                                  color: tab === activeTab ? "text.primary" : "neutral.500"
                                                },
                                                children: Caml_option.some(CS_NonemptyStrings$Util.BookingId.toPrimitive(props.bookingId))
                                              })
                                        ]
                                      })
                                ]
                              }),
                          JsxRuntime.jsx(Stack, {
                                sx: {
                                  height: "6px",
                                  backgroundColor: activeTab === 1 ? "neutral.300" : "primary.300"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                          sx: {
                                            height: "100%",
                                            backgroundColor: match !== 0 ? "background.surface" : (
                                                preview ? "primary.300" : "neutral.300"
                                              ),
                                            borderBottomLeftRadius: (tab - activeTab | 0) === 1 ? "5000px" : "0",
                                            borderBottomRightRadius: (tab - activeTab | 0) === -1 ? "5000px" : "0"
                                          }
                                        }))
                              })
                        ]
                      })),
              sx: {
                backgroundColor: match$1 ? (
                    preview ? "primary.300" : "neutral.300"
                  ) : "transparent",
                borderTopRightRadius: "6px",
                borderTopLeftRadius: "6px"
              },
              xs: props.gridCols
            });
}

var InvoicePreviewTab = {
  make: DiffComparison$InvoicePreviewTab
};

function DiffComparison(props) {
  var setTab = props.setTab;
  var tab = props.tab;
  var billPayCurrent = props.billPayCurrent;
  var billPayNew = props.billPayNew;
  var match = React.useState(function () {
        var x = Belt_Array.get(billPayNew.other, 0);
        if (x !== undefined) {
          return x;
        }
        
      });
  var setThirdTab = match[1];
  var thirdTab = match[0];
  var num = billPayNew.other.length;
  var match$1 = billPayNew.other.length > 1;
  var match$2 = billPayNew.other.length;
  var tmp;
  var exit = 0;
  switch (tab) {
    case 1 :
        tmp = "Current invoice for " + CS_NonemptyStrings$Util.BookingId.toPrimitive(billPayCurrent.bookingId);
        break;
    case 2 :
        exit = 1;
        break;
    case 3 :
        if (thirdTab !== undefined) {
          tmp = "Preview invoice for " + CS_NonemptyStrings$Util.BookingId.toPrimitive(thirdTab.bookingId);
        } else {
          exit = 1;
        }
        break;
    default:
      tmp = "";
  }
  if (exit === 1) {
    tmp = "Preview invoice for " + CS_NonemptyStrings$Util.BookingId.toPrimitive(billPayNew.current.bookingId);
  }
  var tmp$1;
  var exit$1 = 0;
  switch (tab) {
    case 1 :
        tmp$1 = JsxRuntime.jsx(PreviewTable$Thick.make, {
              isActive: false,
              snapshot: false,
              canManage: false,
              billPayRec: billPayCurrent
            });
        break;
    case 2 :
        exit$1 = 1;
        break;
    case 3 :
        if (thirdTab !== undefined) {
          tmp$1 = JsxRuntime.jsx(PreviewTable$Thick.make, {
                isActive: true,
                snapshot: false,
                canManage: false,
                billPayRec: thirdTab
              });
        } else {
          exit$1 = 1;
        }
        break;
    default:
      tmp$1 = null;
  }
  if (exit$1 === 1) {
    tmp$1 = JsxRuntime.jsx(PreviewTable$Thick.make, {
          isActive: true,
          snapshot: false,
          canManage: false,
          billPayRec: billPayNew.current
        });
  }
  return JsxRuntime.jsxs(Stack, {
              sx: {
                rowGap: 0
              },
              children: [
                num !== 0 ? JsxRuntime.jsx(Typography, {
                        level: "title-sm",
                        sx: {
                          color: "danger.400"
                        },
                        children: Caml_option.some("Warning: This change affects " + String(num) + " other booking" + (
                              num > 1 ? "s" : ""
                            ) + ".")
                      }) : null,
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      sx: {
                        mt: 2
                      },
                      children: [
                        JsxRuntime.jsxs(Joy$Util.Grid.make, {
                              children: [
                                JsxRuntime.jsx(DiffComparison$InvoicePreviewTab, {
                                      tab: 1,
                                      activeTab: tab,
                                      bookingId: billPayCurrent.bookingId,
                                      gridCols: Belt_Option.isSome(thirdTab) ? 4 : 6,
                                      preview: false,
                                      setTab: setTab
                                    }),
                                JsxRuntime.jsx(DiffComparison$InvoicePreviewTab, {
                                      tab: 2,
                                      activeTab: tab,
                                      bookingId: billPayCurrent.bookingId,
                                      gridCols: Belt_Option.isSome(thirdTab) ? 4 : 6,
                                      preview: true,
                                      setTab: setTab
                                    }),
                                thirdTab !== undefined ? JsxRuntime.jsx(DiffComparison$InvoicePreviewTab, {
                                        tab: 3,
                                        activeTab: tab,
                                        bookingId: thirdTab.bookingId,
                                        gridCols: 4,
                                        preview: true,
                                        setTab: setTab
                                      }) : null
                              ],
                              container: true,
                              sx: {
                                flex: 1
                              }
                            }),
                        thirdTab !== undefined && match$1 ? JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                justifyContent: "space-between",
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        direction: "row",
                                        sx: {
                                          p: 1,
                                          mt: "6px"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsxs(Dropdown, {
                                                  children: [
                                                    JsxRuntime.jsx(MenuButton, {
                                                          children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                                                          size: "md",
                                                          slotProps: {
                                                            root: {
                                                              color: "neutral"
                                                            }
                                                          },
                                                          slots: {
                                                            root: IconButton
                                                          },
                                                          sx: {
                                                            borderRadius: "100%",
                                                            "&:hover": {
                                                              backgroundColor: "background.level2"
                                                            }
                                                          }
                                                        }),
                                                    JsxRuntime.jsx(Menu, {
                                                          children: Caml_option.some(Belt_Array.keepMap(billPayNew.other, (function (v) {
                                                                      if (Caml_obj.equal(v.bookingId, thirdTab.bookingId)) {
                                                                        return ;
                                                                      } else {
                                                                        return Caml_option.some(JsxRuntime.jsx(MenuItem, {
                                                                                        children: Caml_option.some(CS_NonemptyStrings$Util.BookingId.toPrimitive(v.bookingId)),
                                                                                        onClick: (function (param) {
                                                                                            setThirdTab(function (param) {
                                                                                                  return v;
                                                                                                });
                                                                                            setTab(function (param) {
                                                                                                  return 3;
                                                                                                });
                                                                                          })
                                                                                      }));
                                                                      }
                                                                    }))),
                                                          size: "sm",
                                                          sx: {
                                                            zIndex: "modal"
                                                          }
                                                        })
                                                  ]
                                                }))
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        sx: {
                                          height: "6px",
                                          backgroundColor: "primary.300"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                  sx: {
                                                    height: "100%",
                                                    backgroundColor: "background.surface",
                                                    borderBottomLeftRadius: tab === 3 ? "5000px" : "0"
                                                  }
                                                }))
                                      })
                                ]
                              }) : null
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      spacing: 1,
                      sx: {
                        borderRadius: "4px",
                        borderTopLeftRadius: tab === 1 ? "0" : "4px",
                        borderTopRightRadius: match$2 !== 0 ? (
                            match$2 !== 1 || tab !== 3 ? "4px" : "0"
                          ) : (
                            tab !== 2 ? "4px" : "0"
                          ),
                        p: 2,
                        backgroundColor: tab === 1 ? "neutral.300" : "primary.300"
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                                sx: {
                                  backgroundColor: "background.surface",
                                  borderRadius: "4px",
                                  border: "2px solid",
                                  borderColor: tab === 1 ? "neutral.400" : "primary.400",
                                  p: 2
                                },
                                children: [
                                  JsxRuntime.jsx(Chip, {
                                        children: Caml_option.some(tmp),
                                        color: tab === 1 ? "neutral" : "primary",
                                        size: "sm",
                                        variant: "solid",
                                        sx: {
                                          borderRadius: "xs",
                                          marginBottom: "1rem"
                                        }
                                      }),
                                  tmp$1
                                ]
                              }))
                    })
              ]
            });
}

var make = DiffComparison;

exports.InvoicePreviewTab = InvoicePreviewTab;
exports.make = make;
/* react Not a pure module */
