// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var fragmentConverter = {};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

function signonStatus_decode($$enum) {
  if ($$enum === "INVITED" || $$enum === "PENDING_2FA" || $$enum === "CLEAR_JWT" || $$enum === "ANONYMOUS" || $$enum === "SIGNED_ON") {
    return $$enum;
  }
  
}

function signonStatus_fromString(str) {
  return signonStatus_decode(str);
}

var Utils = {
  signonStatus_decode: signonStatus_decode,
  signonStatus_fromString: signonStatus_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardPage_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserSignonStatus",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
