// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick = require("../../../__generated__/ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql.bs.js");

var convertVariables = ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ChangeCabinSegmentSubmit_ChangeCabinSegmentMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var ChangeCabinSegmentMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(onBrandFamily, onVoyage, forCabinNumbers, refetch, setSelectedCabins, onClose, setError) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return null;
      });
  var newSegment = match$1[0];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    var newSegment$1 = (newSegment == null) ? undefined : Caml_option.some(newSegment);
    Belt_Option.map(newSegment$1, (function (newSegment) {
            return mutate({
                        input: {
                          onBrandFamily: onBrandFamily,
                          onVoyage: onVoyage,
                          forCabinNumbers: forCabinNumbers,
                          newSegment: newSegment
                        }
                      }, undefined, undefined, undefined, (function (resp, err) {
                          if (err === undefined) {
                            var match = resp.changeCabinSegment;
                            if (match !== undefined) {
                              var match$1 = match.boolean;
                              if (match$1 !== undefined && match$1) {
                                console.log("Successfully changed cabin segment");
                                refetch();
                                onClose();
                                setSelectedCabins(function (param) {
                                      return Belt_Set.make(Common$Thick.CabinCmp);
                                    });
                                setError(function (param) {
                                      
                                    });
                                return ;
                              }
                              
                            }
                            console.log("Failed to change cabin segment");
                            setError(function (param) {
                                  return "Failed to change cabin segment";
                                });
                            return ;
                          }
                          if (err !== undefined) {
                            var error = Belt_Array.reduce(err, "", (function (carry, obj) {
                                    if (carry === "") {
                                      return obj.message;
                                    } else {
                                      return carry + ", " + obj.message;
                                    }
                                  }));
                            console.log(error);
                            setError(function (param) {
                                  return "Error changing cabin segment";
                                });
                            return ;
                          }
                          console.log("Unknown error changing cabin segment");
                        }), (function (err) {
                          console.log(err);
                          setError(function (param) {
                                return "Error changing cabin capacity";
                              });
                        }), undefined);
          }));
  };
  return {
          isMutating: match[1],
          newSegment: newSegment,
          setNewSegment: match$1[1],
          onSubmit: onSubmit
        };
}

exports.ChangeCabinSegmentMutation = ChangeCabinSegmentMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
