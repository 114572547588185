// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Checkbox = require("@mui/joy/Checkbox").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var KeyboardArrowDown = require("@mui/icons-material/KeyboardArrowDown").default;

function VoyageMiniCard(props) {
  var __sliderOpen = props.sliderOpen;
  var __asSlider = props.asSlider;
  var __isSelected = props.isSelected;
  var onSelect = props.onSelect;
  var voyage = props.voyage;
  var isSelected = __isSelected !== undefined ? __isSelected : false;
  var asSlider = __asSlider !== undefined ? __asSlider : false;
  var sliderOpen = __sliderOpen !== undefined ? __sliderOpen : false;
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              justifyContent: "space-between",
              spacing: 1,
              sx: {
                py: 1,
                px: {
                  xs: asSlider ? 0 : 1,
                  md: asSlider ? 0 : 1
                },
                border: "1px solid",
                borderColor: isSelected ? "primary.500" : "neutral.300",
                borderRadius: asSlider ? "12px" : "4px",
                borderTopRightRadius: {
                  xs: asSlider ? "0px" : "4px",
                  md: "4px"
                },
                borderBottomRightRadius: {
                  xs: asSlider ? "0px" : "4px",
                  md: "4px"
                },
                flex: 1,
                cursor: {
                  xs: "pointer",
                  md: asSlider || !Belt_Option.isSome(onSelect) ? "default" : "pointer"
                },
                backgroundColor: asSlider ? "background.surface" : "transparent"
              },
              onClick: onSelect !== undefined ? onSelect : (function () {
                    
                  }),
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: asSlider ? 0 : 1,
                      sx: {
                        overflow: "hidden"
                      },
                      children: [
                        asSlider ? JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                sx: {
                                  height: "100%",
                                  pr: {
                                    xs: 0,
                                    md: 1
                                  }
                                },
                                children: Caml_option.some(JsxRuntime.jsx(KeyboardArrowDown, {
                                          fontSize: "md",
                                          sx: {
                                            transform: sliderOpen ? "rotate(-90deg)" : "rotate(90deg)",
                                            display: {
                                              xs: "flex",
                                              md: "none"
                                            }
                                          }
                                        }))
                              }) : null,
                        JsxRuntime.jsx("img", {
                              style: {
                                height: asSlider ? "100%" : "66px",
                                minHeight: asSlider ? "56px" : "66px",
                                width: asSlider ? "56px" : "70px",
                                borderRadius: asSlider ? "8px" : "4px",
                                objectFit: "cover",
                                objectPosition: "center"
                              },
                              alt: voyage.image.alt,
                              src: voyage.image.src
                            }),
                        JsxRuntime.jsxs(Stack, {
                              direction: "column",
                              justifyContent: "center",
                              sx: {
                                height: "100%",
                                overflow: "hidden",
                                px: asSlider ? 2 : 1
                              },
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "body-sm",
                                      noWrap: !asSlider,
                                      sx: {
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        mb: "0.25rem"
                                      },
                                      fontWeight: "900",
                                      children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(voyage.voyageName))
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      level: "body-xs",
                                      noWrap: !asSlider,
                                      sx: {
                                        color: "neutral.400",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                      },
                                      textTransform: "uppercase",
                                      children: Caml_option.some(DateOnly$Util.prettyFormatDateRange(voyage.voyStartDate, voyage.voyEndDate))
                                    })
                              ]
                            })
                      ]
                    }),
                onSelect !== undefined ? JsxRuntime.jsx(Checkbox, {
                        checked: isSelected,
                        size: "sm"
                      }) : null
              ]
            });
}

var make = VoyageMiniCard;

exports.make = make;
/* DateOnly-Util Not a pure module */
