// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var Checkbox = require("@mui/joy/Checkbox").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var ChangeCabinCapacitySubmit$Thick = require("./mutations/ChangeCabinCapacitySubmit.bs.js");

function ChangeCabinCapacityForm(props) {
  var selectedCabins = props.selectedCabins;
  var capacityOptions = [
    1,
    2,
    3,
    4
  ];
  var numCabins = Belt_Set.size(selectedCabins);
  var match = ChangeCabinCapacitySubmit$Thick.useMutation(props.brandFamily, CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(props.voyage), Belt_Array.map(Belt_Set.toArray(selectedCabins), (function (cabin) {
              return Caml_option.some(cabin);
            })), props.refetch, props.setSelectedCabins, props.onClose, props.setError);
  var setCapacityMax = match.setCapacityMax;
  var capacityMax = match.capacityMax;
  var setCapacityMin = match.setCapacityMin;
  var capacityMin = match.capacityMin;
  var setAreChangingMax = match.setAreChangingMax;
  var areChangingMax = match.areChangingMax;
  var setAreChangingMin = match.setAreChangingMin;
  var areChangingMin = match.areChangingMin;
  return JsxRuntime.jsx(Stack, {
              component: "form",
              onSubmit: match.onSubmit,
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        spacing: 2,
                        children: [
                          JsxRuntime.jsx(Typography, {
                                textColor: "text.tertiary",
                                children: Caml_option.some("Specify new minimum and/or maximum berths for " + String(numCabins) + " selected cabin" + (
                                      numCabins > 1 ? "s" : ""
                                    ) + ".")
                              }),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                alignItems: "center",
                                spacing: 1,
                                children: [
                                  JsxRuntime.jsx(Checkbox, {
                                        checked: areChangingMin,
                                        label: "Change Min Capacity",
                                        onChange: (function (param) {
                                            var newVal = !areChangingMin;
                                            setCapacityMin(function (param) {
                                                  return null;
                                                });
                                            setAreChangingMin(function (param) {
                                                  return newVal;
                                                });
                                          })
                                      }),
                                  JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                        children: Caml_option.some(Belt_Array.map(capacityOptions, (function (num) {
                                                    return JsxRuntime.jsx($$Option, {
                                                                value: String(num),
                                                                children: Caml_option.some(String(num))
                                                              });
                                                  }))),
                                        disabled: !areChangingMin,
                                        onChange: (function (param, val) {
                                            if (val !== undefined) {
                                              var val$1 = Caml_option.valFromOption(val);
                                              if (val$1 !== undefined) {
                                                return setCapacityMin(function (param) {
                                                            return val$1;
                                                          });
                                              }
                                              
                                            }
                                            setCapacityMin(function (param) {
                                                  return null;
                                                });
                                          }),
                                        placeholder: "",
                                        size: "sm",
                                        value: !(capacityMin == null) ? capacityMin : undefined
                                      })
                                ]
                              }),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                alignItems: "center",
                                spacing: 1,
                                children: [
                                  JsxRuntime.jsx(Checkbox, {
                                        checked: areChangingMax,
                                        label: "Change Max Capacity",
                                        onChange: (function (param) {
                                            var newVal = !areChangingMax;
                                            setCapacityMax(function (param) {
                                                  return null;
                                                });
                                            setAreChangingMax(function (param) {
                                                  return newVal;
                                                });
                                          })
                                      }),
                                  JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                        children: Caml_option.some(Belt_Array.map(capacityOptions, (function (num) {
                                                    return JsxRuntime.jsx($$Option, {
                                                                value: String(num),
                                                                children: Caml_option.some(String(num))
                                                              });
                                                  }))),
                                        disabled: !areChangingMax,
                                        onChange: (function (param, val) {
                                            if (val !== undefined) {
                                              var val$1 = Caml_option.valFromOption(val);
                                              if (val$1 !== undefined) {
                                                return setCapacityMax(function (param) {
                                                            return val$1;
                                                          });
                                              }
                                              
                                            }
                                            setCapacityMax(function (param) {
                                                  return null;
                                                });
                                          }),
                                        placeholder: "",
                                        size: "sm",
                                        value: !(capacityMax == null) ? capacityMax : undefined
                                      })
                                ]
                              }),
                          JsxRuntime.jsx(Button, {
                                loading: match.isMutating,
                                type: "submit",
                                disabled: areChangingMin ? (
                                    areChangingMax ? (capacityMin == null) || (capacityMax == null) : (capacityMin == null)
                                  ) : (
                                    areChangingMax ? (capacityMax == null) : true
                                  ),
                                children: Caml_option.some("Apply to " + String(numCabins) + " selected cabin" + (
                                      numCabins > 1 ? "s" : ""
                                    ))
                              })
                        ]
                      }))
            });
}

var make = ChangeCabinCapacityForm;

exports.make = make;
/* Joy-Util Not a pure module */
