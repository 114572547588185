// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var AppLink$Thick = require("../atoms/AppLink.bs.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var AccountMenu$Thick = require("./AccountMenu.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var NavBarQuery_graphql$Thick = require("../../../__generated__/NavBarQuery_graphql.bs.js");
var NavBar_profile_graphql$Thick = require("../../../__generated__/NavBar_profile_graphql.bs.js");

var convertFragment = NavBar_profile_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(NavBar_profile_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NavBar_profile_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(NavBar_profile_graphql$Thick.node, convertFragment, fRef);
}

var ProfileFragment_signonStatus_decode = NavBar_profile_graphql$Thick.Utils.signonStatus_decode;

var ProfileFragment_signonStatus_fromString = NavBar_profile_graphql$Thick.Utils.signonStatus_fromString;

var ProfileFragment = {
  signonStatus_decode: ProfileFragment_signonStatus_decode,
  signonStatus_fromString: ProfileFragment_signonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertVariables = NavBarQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = NavBarQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = NavBarQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, NavBarQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, NavBarQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(NavBarQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(NavBarQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(NavBarQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(NavBarQuery_graphql$Thick.node, convertVariables);

var CanQuery_brandFamilyPermission_decode = NavBarQuery_graphql$Thick.Utils.brandFamilyPermission_decode;

var CanQuery_brandFamilyPermission_fromString = NavBarQuery_graphql$Thick.Utils.brandFamilyPermission_fromString;

var CanQuery = {
  brandFamilyPermission_decode: CanQuery_brandFamilyPermission_decode,
  brandFamilyPermission_fromString: CanQuery_brandFamilyPermission_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$1,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function NavBar(props) {
  var brandFamily = props.brandFamily;
  var profile = use(props.profile);
  var match = React.useState(function () {
        
      });
  var setBfQuery = match[1];
  React.useEffect((function () {
          if (brandFamily !== undefined) {
            $$fetch(RelayEnv$Thick.environment, {
                  brandFamily: Caml_option.valFromOption(brandFamily)
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var res$1 = res._0;
                      return setBfQuery(function (param) {
                                  return res$1;
                                });
                    }
                    console.log(res._0);
                    setBfQuery(function (param) {
                          
                        });
                  }), undefined, undefined);
          }
          
        }), []);
  var match$1 = profile.currentUserSignonStatus;
  var match$2 = profile.currentUserEmail;
  var tmp;
  var exit = 0;
  if ((match$1 === "INVITED" || match$1 === "PENDING_2FA" || match$1 === "CLEAR_JWT" || match$1 === "ANONYMOUS" || match$1 === "SIGNED_ON") && match$1 === "SIGNED_ON" && match$2 !== undefined) {
    tmp = JsxRuntime.jsx(AccountMenu$Thick.make, {
          query: profile.fragmentRefs,
          brandFamily: brandFamily,
          bfQuery: match[0]
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Box, {
          children: Caml_option.some(JsxRuntime.jsx(AppLink$Thick.make, {
                    to: "/login",
                    children: JsxRuntime.jsx(Typography, {
                          children: "Login"
                        })
                  }))
        });
  }
  return JsxRuntime.jsx(Box, {
              children: Caml_option.some(JsxRuntime.jsxs(Box, {
                        children: [
                          JsxRuntime.jsx(Box, {}),
                          JsxRuntime.jsxs(Box, {
                                children: [
                                  tmp,
                                  JsxRuntime.jsx(Box, {
                                        sx: {
                                          mx: 2
                                        }
                                      })
                                ],
                                sx: {
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center"
                                }
                              })
                        ],
                        sx: {
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          p: 1
                        }
                      })),
              sx: {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "background.body",
                borderBottom: 1,
                borderColor: "neutral.300"
              }
            });
}

var make = NavBar;

exports.ProfileFragment = ProfileFragment;
exports.CanQuery = CanQuery;
exports.make = make;
/* use Not a pure module */
