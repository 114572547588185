// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var BDButton$Thick = require("../atoms/BDButton.bs.js");
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var Add = require("@mui/icons-material/Add").default;

function AddBerthBtn(props) {
  var __disabled = props.disabled;
  var disabled = __disabled !== undefined ? __disabled : false;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, (function () {
          
        }));
  var clearPreviewFields = match.clearPreviewFields;
  var setApplyDiff = match.setApplyDiff;
  var generatePreview = match.generatePreview;
  var addBerth = function (param) {
    var applyDiff = BookingDiff$Thick.Alter.addSingleBerth(diffStruct);
    setApplyDiff(function (param) {
          return applyDiff;
        });
    generatePreview(applyDiff);
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(BDButton$Thick.make, {
                      endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                fontSize: "sm",
                                sx: {
                                  color: "text.secondary"
                                }
                              })),
                      onClick: addBerth,
                      text: "Add Berth",
                      loading: match.loading,
                      disabled: disabled
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match.billPayNew,
                      applyDiff: match.applyDiff,
                      title: "Add Berth",
                      description: "You are proposing to add a berth to this booking. Please carefully review the changes below.",
                      onClose: (function () {
                          clearPreviewFields();
                        }),
                      open_: match.previewModalOpen,
                      versionUrl: props.versionUrl,
                      error: match.error
                    })
              ]
            });
}

var make = AddBerthBtn;

exports.make = make;
/* BDButton-Thick Not a pure module */
