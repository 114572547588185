// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var $$Option = require("@mui/joy/Option").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var ListItemContent = require("@mui/joy/ListItemContent").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;

function PhoneNumberInput(props) {
  var __error = props.error;
  var __disabled = props.disabled;
  var onUpdatePhoneNumber = props.onUpdatePhoneNumber;
  var onUpdateCountryCode = props.onUpdateCountryCode;
  var disabled = __disabled !== undefined ? __disabled : false;
  var error = __error !== undefined ? __error : false;
  var countryOptions = Belt_Array.keepMap(props.countryOptions, (function (v) {
          var match = v.callingCode;
          if (match !== undefined) {
            return v;
          }
          
        }));
  var startDecorator = JsxRuntime.jsxs(JsxRuntime.Fragment, {
        children: [
          JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                children: Caml_option.some(Belt_Array.map(countryOptions, (function (option) {
                            var emoji = option.emoji;
                            return JsxRuntime.jsxs($$Option, {
                                        value: Caml_option.some(option.callingCode),
                                        children: [
                                          emoji !== undefined ? JsxRuntime.jsx(ListItemDecorator, {
                                                  children: Caml_option.some(emoji)
                                                }) : null,
                                          JsxRuntime.jsxs(ListItemContent, {
                                                children: [
                                                  option.country,
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-xs",
                                                        children: Caml_option.some(option.alpha3 + " (" + Belt_Option.getWithDefault(option.callingCode, "No code") + ")")
                                                      })
                                                ],
                                                sx: {
                                                  fontSize: "sm"
                                                }
                                              })
                                        ],
                                        label: Belt_Option.getWithDefault(option.callingCode, option.alpha3),
                                        sx: {
                                          maxWidth: "calc(100vw - 32px)",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis"
                                        }
                                      }, option.alpha3);
                          }))),
                color: "neutral",
                disabled: disabled,
                onChange: (function (param, v) {
                    if (v === undefined) {
                      return ;
                    }
                    var countryCode = Caml_option.valFromOption(v);
                    if (countryCode !== undefined) {
                      return onUpdateCountryCode(countryCode);
                    }
                    
                  }),
                slotProps: {
                  listbox: {
                    sx: {
                      zIndex: "modal"
                    }
                  },
                  root: {
                    sx: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0
                    }
                  },
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                },
                value: props.countryCode,
                variant: disabled ? "solid" : "soft"
              }),
          JsxRuntime.jsx(Divider, {
                orientation: "vertical"
              })
        ]
      });
  return JsxRuntime.jsx(Joy$Util.Input.make, {
              type_: "tel",
              disabled: disabled,
              error: error,
              value: Belt_Option.getWithDefault(props.phoneNumber, ""),
              onChange: (function (e) {
                  var val = e.currentTarget.value;
                  if (val === "") {
                    return onUpdatePhoneNumber(undefined);
                  } else {
                    return onUpdatePhoneNumber(val);
                  }
                }),
              startDecorator: Caml_option.some(startDecorator),
              slotProps: {
                root: {
                  id: "phone-number",
                  sx: {
                    pl: 0
                  }
                }
              }
            });
}

var make = PhoneNumberInput;

exports.make = make;
/* Joy-Util Not a pure module */
