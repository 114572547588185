// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

function makeRefetchVariables(bookingId, brandFamily, voyage) {
  return {
          bookingId: bookingId,
          brandFamily: brandFamily,
          voyage: voyage
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"voyage":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"brandFamily":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.serialize,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bookingId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandFamily"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyage"
},
v3 = {
  "kind": "Variable",
  "name": "brandFamily",
  "variableName": "brandFamily"
},
v4 = {
  "kind": "Variable",
  "name": "bookingId",
  "variableName": "bookingId"
},
v5 = [
  (v3/*: any*/)
],
v6 = {
  "kind": "Variable",
  "name": "brandFamilySlug",
  "variableName": "brandFamily"
},
v7 = [
  {
    "fields": [
      (v6/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandFamilySlug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LatestBookingVersionRedirectRouteQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "voyage",
            "variableName": "voyage"
          }
        ],
        "kind": "FragmentSpread",
        "name": "VoyageProtectedRoute_query"
      },
      {
        "args": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "LatestBookingVersionRedirect_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DashboardLayout_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "OrgProtectedRoute_query"
      },
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "BrandProtectedRoute_query"
      },
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "BrandPermProtected_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LatestBookingVersionRedirectRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "BrandFamilyVoyagesConnection",
        "kind": "LinkedField",
        "name": "brandFamilyVoyages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BrandFamilyVoyage",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voyageSlug",
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "forBrandFamily",
            "variableName": "brandFamily"
          },
          {
            "kind": "Variable",
            "name": "forVoyage",
            "variableName": "voyage"
          },
          {
            "kind": "Literal",
            "name": "withPerm",
            "value": "VIEW_VOYAGE_META"
          }
        ],
        "kind": "ScalarField",
        "name": "currentUserCanForVoyage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "ObjectValue",
            "name": "condition"
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "PRIMARY_KEY_DESC"
          }
        ],
        "concreteType": "BookingVersionsConnection",
        "kind": "LinkedField",
        "name": "bookingVersions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingVersion",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bookingVersionNumber",
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserSignonStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserEmail",
        "storageKey": null
      },
      {
        "alias": "allBrandFamilies",
        "args": null,
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandFamilyRoles",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "forBrandFamilySlug",
            "variableName": "brandFamily"
          }
        ],
        "kind": "ScalarField",
        "name": "currentUserCanForBrand",
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "856ad547c886ddf50352ff7cec1d9a5a",
    "id": null,
    "metadata": {},
    "name": "LatestBookingVersionRedirectRouteQuery",
    "operationKind": "query",
    "text": "query LatestBookingVersionRedirectRouteQuery(\n  $brandFamily: BrandFamilySlug!\n  $bookingId: BookingId!\n  $voyage: VoyageSlug!\n) {\n  ...VoyageProtectedRoute_query_1ODBMq\n  ...LatestBookingVersionRedirect_query_fxu5T\n  ...DashboardLayout_query\n  ...OrgProtectedRoute_query\n  ...BrandProtectedRoute_query_2PeJLC\n  ...BrandPermProtected_query_2PeJLC\n}\n\nfragment AccountMenu_bfselector on CurrentUserProfile {\n  brandFamilySlug\n}\n\nfragment AccountMenu_query on Query {\n  currentUserEmail\n  allBrandFamilies: currentUserProfiles {\n    nodes {\n      ...AccountMenu_bfselector\n    }\n  }\n}\n\nfragment BrandPermProtected_query_2PeJLC on Query {\n  currentUserCanForBrand(forBrandFamilySlug: $brandFamily)\n  currentUserSignonStatus\n}\n\nfragment BrandProtectedRoute_query_2PeJLC on Query {\n  currentUserSignonStatus\n  currentUserProfiles(condition: {brandFamilySlug: $brandFamily}) {\n    nodes {\n      brandFamilySlug\n      brandFamilyRoles\n    }\n  }\n}\n\nfragment DashboardLayout_query on Query {\n  currentUserSignonStatus\n  currentUserEmail\n  ...NavBar_profile\n  ...StandardLayout_query\n}\n\nfragment LatestBookingVersionRedirect_query_fxu5T on Query {\n  bookingVersions(condition: {brandFamilySlug: $brandFamily, bookingId: $bookingId}, orderBy: PRIMARY_KEY_DESC) {\n    nodes {\n      bookingVersionNumber\n      id\n    }\n  }\n}\n\nfragment NavBar_profile on Query {\n  currentUserSignonStatus\n  currentUserEmail\n  ...AccountMenu_query\n}\n\nfragment OrgProtectedRoute_query on Query {\n  currentUserSignonStatus\n}\n\nfragment StandardLayout_query on Query {\n  currentUserEmail\n  currentUserSignonStatus\n  ...NavBar_profile\n}\n\nfragment VoyageProtectedRoute_query_1ODBMq on Query {\n  brandFamilyVoyages(condition: {brandFamilySlug: $brandFamily}) {\n    nodes {\n      voyageSlug\n      id\n    }\n  }\n  currentUserCanForVoyage(forVoyage: $voyage, forBrandFamily: $brandFamily, withPerm: VIEW_VOYAGE_META)\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
