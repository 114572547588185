// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var URL$Util = require("util/src/URL.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Link = require("@mui/joy/Link").default;
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function AppLink(props) {
  var __underline = props.underline;
  var __color = props.color;
  var onClick = props.onClick;
  var href = props.to;
  var color = __color !== undefined ? __color : "primary";
  var underline = __underline !== undefined ? __underline : "hover";
  var match = URL$Util.parse(Belt_Option.getWithDefault(href, ""));
  var isExternal = match !== undefined ? match.host !== "localhost" : false;
  var isTargetBlank = Belt_Option.getWithDefault(props.forceTargetBlank, false);
  return JsxRuntime.jsx(Link, {
              children: Caml_option.some(props.children),
              color: color,
              underline: underline,
              onClick: (function (e) {
                  if (onClick !== undefined) {
                    onClick(e);
                    return ;
                  } else if (isExternal || isTargetBlank || href === undefined) {
                    return ;
                  } else {
                    e.preventDefault();
                    RescriptReactRouter.push(href);
                    return ;
                  }
                }),
              href: Belt_Option.getWithDefault(href, ""),
              target: isExternal || isTargetBlank ? "_blank" : ""
            });
}

var make = AppLink;

exports.make = make;
/* @mui/joy/Link Not a pure module */
