// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Stack = require("@mui/joy/Stack").default;
var BDButton$Thick = require("./atoms/BDButton.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RelayEnv$Thick = require("../../RelayEnv.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var BookingDiff$Thick = require("../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var ChangeBCModal$Thick = require("./drafts/ChangeBCModal.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var BDContentBlock$Thick = require("./molecules/BDContentBlock.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDBillingContactQuery_graphql$Thick = require("../../__generated__/BDBillingContactQuery_graphql.bs.js");
var BDBillingContact_version_graphql$Thick = require("../../__generated__/BDBillingContact_version_graphql.bs.js");
var ContentCopyOutlined = require("@mui/icons-material/ContentCopyOutlined").default;
var PublishedWithChanges = require("@mui/icons-material/PublishedWithChanges").default;

var convertFragment = BDBillingContact_version_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDBillingContact_version_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDBillingContact_version_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDBillingContact_version_graphql$Thick.node, convertFragment, fRef);
}

var VersionFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertVariables = BDBillingContactQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BDBillingContactQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BDBillingContactQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, BDBillingContactQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BDBillingContactQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BDBillingContactQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BDBillingContactQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BDBillingContactQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BDBillingContactQuery_graphql$Thick.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$1,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BDBillingContact$BillingContact$DataStack(props) {
  var value = props.value;
  var label = props.label;
  var match = React.useState(function () {
        return false;
      });
  var setCopied = match[1];
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      noWrap: true,
                      sx: {
                        color: "neutral.500"
                      },
                      fontWeight: "normal",
                      children: Caml_option.some(label)
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "body-sm",
                              noWrap: true,
                              sx: {
                                color: value !== undefined ? "text.primary" : "neutral.plainDisabledColor"
                              },
                              fontWeight: "normal",
                              children: Caml_option.some(value !== undefined ? value : "No " + label.toLowerCase() + " provided")
                            }),
                        JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(ContentCopyOutlined, {
                                    fontSize: "sm",
                                    onClick: (function (param) {
                                        if (value !== undefined) {
                                          Common$Thick.copyToClipboard(value, setCopied);
                                          return ;
                                        }
                                        
                                      })
                                  }),
                              arrow: true,
                              size: "sm",
                              sx: {
                                cursor: "pointer"
                              },
                              title: match[0] ? "Copied!" : "Copy to clipboard"
                            })
                      ]
                    })
              ]
            });
}

var DataStack = {
  make: BDBillingContact$BillingContact$DataStack
};

function BDBillingContact$BillingContact(props) {
  var bcDraft = props.bcDraft;
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var content = bcDraft !== undefined ? bcDraft : props.content;
  var actions = props.isActive && !(props.snapshot || !props.canManage) ? JsxRuntime.jsx(BDButton$Thick.make, {
          endDecorator: Caml_option.some(JsxRuntime.jsx(PublishedWithChanges, {
                    fontSize: "sm",
                    sx: {
                      color: "text.secondary"
                    }
                  })),
          onClick: (function (param) {
              setModalOpen(function (param) {
                    return true;
                  });
            }),
          text: "Change"
        }) : null;
  return JsxRuntime.jsxs(BDContentBlock$Thick.make, {
              title: "Billing Contact",
              actions: Caml_option.some(actions),
              children: [
                JsxRuntime.jsxs(Joy$Util.Grid.make, {
                      children: [
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsx(BDBillingContact$BillingContact$DataStack, {
                                        label: "Contact Name",
                                        value: content.fullName
                                      })),
                              xs: 4
                            }),
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsx(BDBillingContact$BillingContact$DataStack, {
                                        label: "Loyalty #",
                                        value: content.loyaltyNumber
                                      })),
                              xs: 3
                            }),
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsx(BDBillingContact$BillingContact$DataStack, {
                                        label: "Email",
                                        value: content.email
                                      })),
                              xs: 5
                            })
                      ],
                      container: true
                    }),
                JsxRuntime.jsx(ChangeBCModal$Thick.make, {
                      open_: match[0],
                      onClose: (function () {
                          setModalOpen(function (param) {
                                return false;
                              });
                        }),
                      billPayCurrent: props.billPayRec,
                      versionUrl: props.versionUrl,
                      currentAcctId: content.accountId,
                      brandFamily: props.brandFamily,
                      generatePreviewFromDiff: props.generatePreviewFromDiff
                    })
              ]
            });
}

var BillingContact = {
  DataStack: DataStack,
  make: BDBillingContact$BillingContact
};

function BDBillingContact(props) {
  var brandFamily = props.brandFamily;
  var data = use(props.fragmentRefs);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var billingContact = data.billingContact;
  var billingContact$1 = data.billingContact;
  var billingContact$2 = data.billingContact;
  var match = data.billingContact;
  var tmp;
  if (match !== undefined) {
    var email = match.email;
    tmp = email !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) : undefined;
  } else {
    tmp = undefined;
  }
  var account_accountId = billingContact !== undefined ? CS_NonemptyStrings$Util.AccountId.toString(billingContact.accountId) : undefined;
  var account_fullName = billingContact$1 !== undefined ? billingContact$1.fullName : undefined;
  var account_loyaltyNumber = billingContact$2 !== undefined ? CS_NonemptyStrings$Util.LoyaltyNumber.toString(billingContact$2.loyaltyNumber) : undefined;
  var account = {
    accountId: account_accountId,
    fullName: account_fullName,
    loyaltyNumber: account_loyaltyNumber,
    email: tmp
  };
  var match$1 = React.useState(function () {
        
      });
  var setBcDraft = match$1[1];
  React.useEffect((function () {
          var acctId = diffStruct.changeBillingContact;
          if (acctId !== undefined) {
            $$fetch(RelayEnv$Thick.environment, {
                  accountId: Caml_option.valFromOption(acctId),
                  brandFamily: brandFamily
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var match = res._0.bfcustUsers;
                      if (match !== undefined) {
                        var match$1 = match.nodes;
                        if (match$1.length !== 1) {
                          console.log("nothing doing");
                        } else {
                          var account = match$1[0];
                          var email = account.email;
                          var acct_accountId = CS_NonemptyStrings$Util.AccountId.toString(account.accountId);
                          var acct_fullName = account.fullName;
                          var acct_loyaltyNumber = CS_NonemptyStrings$Util.LoyaltyNumber.toString(account.loyaltyNumber);
                          var acct_email = email !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) : undefined;
                          var acct = {
                            accountId: acct_accountId,
                            fullName: acct_fullName,
                            loyaltyNumber: acct_loyaltyNumber,
                            email: acct_email
                          };
                          setBcDraft(function (param) {
                                return acct;
                              });
                        }
                      } else {
                        console.log("nothing doing");
                      }
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined);
          } else {
            setBcDraft(function (param) {
                  
                });
          }
        }), [url.search]);
  return JsxRuntime.jsx(BDBillingContact$BillingContact, {
              content: account,
              snapshot: props.snapshot,
              isActive: props.isActive,
              canManage: props.canManage,
              versionUrl: props.versionUrl,
              billPayRec: props.billPayRec,
              brandFamily: brandFamily,
              generatePreviewFromDiff: props.generatePreviewFromDiff,
              bcDraft: match$1[0]
            });
}

var make = BDBillingContact;

exports.VersionFragment = VersionFragment;
exports.Query = Query;
exports.BillingContact = BillingContact;
exports.make = make;
/* use Not a pure module */
