// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Js_promise2 = require("rescript/lib/js/js_promise2.js");
var ReactRelay = require("react-relay");
var RelayRuntime = require("relay-runtime");
var RescriptRelay_Internal = require("./RescriptRelay_Internal.bs.js");

function useQuery(convertVariables, node, convertResponse) {
  return function (variables, fetchPolicy, fetchKey, networkCacheConfig) {
    var __x = ReactRelay.useLazyLoadQuery(node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(convertVariables(variables)), {
          fetchKey: fetchKey,
          fetchPolicy: fetchPolicy,
          networkCacheConfig: networkCacheConfig
        });
    return RescriptRelay_Internal.internal_useConvertedValue(convertResponse, __x);
  };
}

function useLoader(convertVariables, node, mkQueryRef) {
  return function () {
    var match = ReactRelay.useQueryLoader(node);
    var loadQueryFn = match[1];
    var loadQuery = React.useMemo((function () {
            return function (variables, fetchPolicy, networkCacheConfig) {
              loadQueryFn(convertVariables(variables), {
                    fetchPolicy: fetchPolicy,
                    networkCacheConfig: networkCacheConfig
                  });
            };
          }), [loadQueryFn]);
    return [
            mkQueryRef(Caml_option.nullable_to_opt(match[0])),
            loadQuery,
            match[2]
          ];
  };
}

function usePreloaded(node, convertResponse, mkQueryRef) {
  return function (queryRef) {
    var __x = ReactRelay.usePreloadedQuery(node, mkQueryRef(queryRef));
    return RescriptRelay_Internal.internal_useConvertedValue(convertResponse, __x);
  };
}

function $$fetch(node, convertResponse, convertVariables) {
  return function (environment, variables, onResult, networkCacheConfig, fetchPolicy) {
    ReactRelay.fetchQuery(environment, node, convertVariables(variables), {
            networkCacheConfig: networkCacheConfig,
            fetchPolicy: fetchPolicy
          }).subscribe({
          next: (function (res) {
              onResult({
                    TAG: "Ok",
                    _0: convertResponse(res)
                  });
            }),
          error: (function (err) {
              onResult({
                    TAG: "Error",
                    _0: err
                  });
            })
        });
  };
}

function fetchPromised(node, convertResponse, convertVariables) {
  return function (environment, variables, networkCacheConfig, fetchPolicy) {
    return Js_promise2.then(ReactRelay.fetchQuery(environment, node, convertVariables(variables), {
                      networkCacheConfig: networkCacheConfig,
                      fetchPolicy: fetchPolicy
                    }).toPromise(), (function (res) {
                  return Promise.resolve(convertResponse(res));
                }));
  };
}

function retain(node, convertVariables) {
  return function (environment, variables) {
    return environment.retain(RelayRuntime.createOperationDescriptor(node, convertVariables(variables)));
  };
}

function commitLocalPayload(node, convertVariables, convertWrapRawResponse) {
  return function (environment, variables, payload) {
    environment.commitPayload(RelayRuntime.createOperationDescriptor(node, convertVariables(variables)), convertWrapRawResponse(payload));
  };
}

exports.useQuery = useQuery;
exports.useLoader = useLoader;
exports.usePreloaded = usePreloaded;
exports.$$fetch = $$fetch;
exports.fetchPromised = fetchPromised;
exports.retain = retain;
exports.commitLocalPayload = commitLocalPayload;
/* react Not a pure module */
