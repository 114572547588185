// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

function BDCancelReservationModal(props) {
  var bookingId = props.bookingId;
  var onClose = props.onClose;
  var match = React.useState(function () {
        return "";
      });
  var setReason = match[1];
  var reason = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match$1 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, onClose);
  var clearPreviewFields = match$1.clearPreviewFields;
  var setApplyDiff = match$1.setApplyDiff;
  var setError = match$1.setError;
  var error = match$1.error;
  var setLoading = match$1.setLoading;
  var generatePreview = match$1.generatePreview;
  var clearAll = function () {
    clearPreviewFields();
    setLoading(function (param) {
          return false;
        });
    setReason(function (param) {
          return "";
        });
    setError(function (param) {
          
        });
  };
  var cancel = function () {
    clearAll();
    onClose();
  };
  var onSubmit = function (e) {
    e.preventDefault();
    var reason$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(reason);
    if (reason$1.TAG !== "Ok") {
      return setError(function (param) {
                  return "Please enter a reason for cancellation.";
                });
    }
    var applyDiff = BookingDiff$Thick.Alter.updateCancellationReason(diffStruct, Caml_option.some(reason$1._0));
    setApplyDiff(function (param) {
          return applyDiff;
        });
    generatePreview(applyDiff);
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Dialog$Thick.make, {
                      open_: props.open_,
                      onClose: cancel,
                      title: "Cancel booking " + CS_NonemptyStrings$Util.BookingId.toString(bookingId),
                      children: [
                        JsxRuntime.jsxs(DialogContent, {
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "title-md",
                                      children: "Please enter a reason for cancellation below."
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      spacing: 2,
                                      sx: {
                                        py: 1
                                      },
                                      children: [
                                        JsxRuntime.jsx(FormControl, {
                                              error: Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(reason)),
                                              children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        required: true,
                                                        value: reason,
                                                        onChange: (function (e) {
                                                            var val = e.currentTarget.value;
                                                            setReason(function (param) {
                                                                  return val;
                                                                });
                                                          })
                                                      }))
                                            }),
                                        error !== undefined ? JsxRuntime.jsx(Typography, {
                                                color: "danger",
                                                children: Caml_option.some(error)
                                              }) : null
                                      ]
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(Divider, {}),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: {
                                          xs: "column-reverse",
                                          sm: "row"
                                        },
                                        component: "form",
                                        justifyContent: "space-between",
                                        spacing: 1,
                                        sx: {
                                          width: "100%"
                                        },
                                        onSubmit: onSubmit,
                                        children: [
                                          JsxRuntime.jsx(Button, {
                                                onClick: cancel,
                                                variant: "outlined",
                                                color: "neutral",
                                                children: "Cancel"
                                              }),
                                          JsxRuntime.jsx(Button, {
                                                loading: match$1.loading,
                                                type: "submit",
                                                disabled: Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(reason)),
                                                children: "Add to draft"
                                              })
                                        ]
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$1.billPayNew,
                      applyDiff: match$1.applyDiff,
                      title: "Cancel reservation",
                      description: "You are proposing to cancel booking " + CS_NonemptyStrings$Util.BookingId.toString(bookingId) + ".",
                      onClose: (function () {
                          clearAll();
                        }),
                      open_: match$1.previewModalOpen,
                      versionUrl: props.versionUrl,
                      error: error
                    })
              ]
            });
}

var make = BDCancelReservationModal;

exports.make = make;
/* react Not a pure module */
