// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var ResendNewPaxInvitation$Thick = require("./mutations/ResendNewPaxInvitation.bs.js");
var ResendBfCustUserTransfer$Thick = require("./mutations/ResendBfCustUserTransfer.bs.js");

function ResendInvitationForm(props) {
  var targetUserType = props.targetUserType;
  var invitee = props.invitee;
  var brandFamily = props.brandFamily;
  var accountId = props.accountId;
  var refresh = props.refresh;
  var onClose = props.onClose;
  var match = targetUserType === "newpax" ? ResendNewPaxInvitation$Thick.useMutation(refresh, accountId, brandFamily, onClose) : ResendBfCustUserTransfer$Thick.useMutation(refresh, accountId, brandFamily, onClose);
  var setError = match.setError;
  var error = match.error;
  var match$1 = targetUserType === "newpax" ? [
      "Resend a New Passenger Invitation",
      "Are you sure you want to resend the invitation to " + invitee + "?"
    ] : [
      "Resend a Transfer Invitation",
      "Are you sure you want to resend the invitation to " + invitee + " to take over the account \"" + props.fullName + "?\""
    ];
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: (function () {
                  onClose();
                  setError(function (param) {
                        
                      });
                }),
              title: match$1[0],
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        textColor: "text.tertiary",
                                        children: Caml_option.some(match$1[1])
                                      }),
                                  error !== undefined ? JsxRuntime.jsx(Typography, {
                                          color: "danger",
                                          children: Caml_option.some(error)
                                        }) : null
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                component: "form",
                                onSubmit: match.onSubmit,
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          loading: match.isMutating,
                                          type: "submit",
                                          color: "danger",
                                          children: "Resend invitation"
                                        }))
                              }))
                    })
              ]
            });
}

var make = ResendInvitationForm;

exports.make = make;
/* Dialog-Thick Not a pure module */
