// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick = require("../../../__generated__/InviteUserSubmit_InviteToBrandFamilyMutation_graphql.bs.js");

var convertVariables = InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var InviteToBrandFamilyMutation_brandfamRole_decode = InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.Utils.brandfamRole_decode;

var InviteToBrandFamilyMutation_brandfamRole_fromString = InviteUserSubmit_InviteToBrandFamilyMutation_graphql$Thick.Utils.brandfamRole_fromString;

var InviteToBrandFamilyMutation = {
  brandfamRole_decode: InviteToBrandFamilyMutation_brandfamRole_decode,
  brandfamRole_fromString: InviteToBrandFamilyMutation_brandfamRole_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(brandFamily, refreshTeam, initialRoles, initialVoyages, onClose) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setUserEmail = match$1[1];
  var userEmail = match$1[0];
  var match$2 = React.useState(function () {
        return initialRoles;
      });
  var setUserRoles = match$2[1];
  var userRoles = match$2[0];
  var match$3 = React.useState(function () {
        return initialVoyages;
      });
  var setUserVoyages = match$3[1];
  var userVoyages = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setError = match$4[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    var roleRec = Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(userRoles, (function (x) {
                    return x;
                  })), 0), {
          brandfamRole: "INVENTORY_MANAGER",
          description: ""
        });
    var inviteeRole = roleRec.brandfamRole;
    var additionalInviteeRoles = Belt_Array.map(Belt_Array.keepMap(userRoles, (function (x) {
                return x;
              })), (function (role) {
            return role.brandfamRole;
          }));
    var toVoyages = Belt_Array.map(Belt_Array.keepMap(userVoyages, (function (x) {
                return x;
              })), (function (voyage) {
            return Caml_option.some(voyage.voyageSlug);
          }));
    mutate({
          input: {
            toBrandFamilySlug: brandFamily,
            toVoyages: toVoyages,
            inviteeEmail: CS_Emails$Util.Email.Exn.fromPrimitive(userEmail),
            inviteeRole: inviteeRole,
            additionalInviteeRoles: additionalInviteeRoles
          }
        }, undefined, undefined, undefined, (function (param, err) {
            if (err === undefined) {
              console.log("Successfully invited user");
              refreshTeam();
              onClose();
              setUserEmail(function (param) {
                    return "";
                  });
              setUserRoles(function (param) {
                    return initialRoles;
                  });
              return setUserVoyages(function (param) {
                          return initialVoyages;
                        });
            } else {
              console.log("Failed to invite user");
              setError(function (param) {
                    return "Failed to invite user";
                  });
              return ;
            }
          }), (function (param) {
            console.log("Error inviting user");
            setError(function (param) {
                  return "Error inviting user";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          userEmail: userEmail,
          setUserEmail: setUserEmail,
          userRoles: userRoles,
          setUserRoles: setUserRoles,
          userVoyages: userVoyages,
          setUserVoyages: setUserVoyages,
          error: match$4[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

exports.InviteToBrandFamilyMutation = InviteToBrandFamilyMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
