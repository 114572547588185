// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var DiffList$Thick = require("./DiffList.bs.js");
var Button = require("@mui/joy/Button").default;
var ApplyDraft$Thick = require("./mutations/ApplyDraft.bs.js");
var Textarea = require("@mui/joy/Textarea").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

function ApplyDraftModal(props) {
  var onClose = props.onClose;
  var applyArgs = props.applyArgs;
  var match = React.useState(function () {
        return "";
      });
  var setVersionComment = match[1];
  var match$1 = ApplyDraft$Thick.useMutation(applyArgs, props.redirect, match[0]);
  var error = match$1.error;
  return JsxRuntime.jsx(Dialog$Thick.make, {
              open_: props.open_,
              onClose: onClose,
              title: "Apply Draft",
              children: JsxRuntime.jsxs(DialogContent, {
                    children: [
                      JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 2,
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    level: "title-md",
                                    children: Caml_option.some("You are about to apply your draft and create a new booking version for booking " + CS_NonemptyStrings$Util.BookingId.toString(applyArgs.bookingId) + ". The changes are as follows:")
                                  }),
                              JsxRuntime.jsx(DiffList$Thick.make, {
                                    paxes: props.paxes,
                                    diffStruct: props.diffStruct,
                                    versionBilling: props.versionBilling,
                                    size: "md"
                                  }),
                              JsxRuntime.jsx(Typography, {
                                    level: "title-md",
                                    children: "Do you want to apply these changes?"
                                  }),
                              JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    fontWeight: "400",
                                    children: "Version comment:"
                                  }),
                              JsxRuntime.jsx(Textarea, {
                                    onChange: (function (e) {
                                        var val = e.currentTarget.value;
                                        setVersionComment(function (param) {
                                              return val;
                                            });
                                      }),
                                    maxRows: 3,
                                    minRows: 3,
                                    size: "sm"
                                  })
                            ]
                          }),
                      error !== undefined ? JsxRuntime.jsx(Typography, {
                              color: "danger",
                              children: Caml_option.some(error)
                            }) : null,
                      JsxRuntime.jsxs(DialogActions, {
                            children: [
                              JsxRuntime.jsx(Button, {
                                    loading: match$1.isMutating,
                                    type: "submit",
                                    children: "Apply"
                                  }),
                              JsxRuntime.jsx(Button, {
                                    onClick: onClose,
                                    variant: "outlined",
                                    children: "Keep Editing"
                                  })
                            ]
                          })
                    ],
                    component: "form",
                    onSubmit: match$1.onSubmit
                  })
            });
}

var make = ApplyDraftModal;

exports.make = make;
/* react Not a pure module */
