// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");

function set(key, val) {
  localStorage.setItem(key, val);
}

function get(key) {
  return Caml_option.null_to_opt(localStorage.getItem(key));
}

function $$delete(key) {
  localStorage.removeItem(key);
}

function setSelectedBrand(brand) {
  set("grp-bf-selected", brand);
}

function getSelectedBrand() {
  return Caml_option.null_to_opt(localStorage.getItem("grp-bf-selected"));
}

function setSelectedVoyage(voyage) {
  set("grp-voyage-selected", voyage);
}

function getSelectedVoyage() {
  return Caml_option.null_to_opt(localStorage.getItem("grp-voyage-selected"));
}

var Local = {
  set: set,
  get: get,
  $$delete: $$delete,
  setSelectedBrand: setSelectedBrand,
  getSelectedBrand: getSelectedBrand,
  setSelectedVoyage: setSelectedVoyage,
  getSelectedVoyage: getSelectedVoyage
};

function set$1(key, val) {
  sessionStorage.setItem(key, val);
}

function get$1(key) {
  return Caml_option.null_to_opt(sessionStorage.getItem(key));
}

function $$delete$1(key) {
  sessionStorage.removeItem(key);
}

var Session = {
  set: set$1,
  get: get$1,
  $$delete: $$delete$1
};

exports.Local = Local;
exports.Session = Session;
/* No side effect */
