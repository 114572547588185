// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;

function AccountAccessSection(props) {
  var openModal = props.openModal;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              sx: {
                width: "100%",
                py: 2
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h4",
                      component: "h2",
                      sx: {
                        mb: 2
                      },
                      children: "Account access"
                    }),
                JsxRuntime.jsxs(Joy$Util.Grid.make, {
                      children: [
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                        children: [
                                          JsxRuntime.jsx(FormLabel, {
                                                children: "Email"
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Input.make, {
                                                type_: "email",
                                                fullWidth: true,
                                                disabled: true,
                                                value: props.currentUserEmail
                                              }),
                                          JsxRuntime.jsx(FormHelperText, {
                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          sx: {
                                                            textDecoration: "underline",
                                                            "&:hover": {
                                                              cursor: "pointer"
                                                            }
                                                          },
                                                          children: "Change email?",
                                                          onClick: (function (param) {
                                                              openModal("email");
                                                            })
                                                        }))
                                              })
                                        ]
                                      })),
                              xs: 12,
                              md: 6
                            }),
                        JsxRuntime.jsx(Joy$Util.Grid.make, {
                              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                        children: [
                                          JsxRuntime.jsx(FormLabel, {
                                                children: "Password"
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Input.make, {
                                                type_: "password",
                                                fullWidth: true,
                                                disabled: true,
                                                value: "*********"
                                              }),
                                          JsxRuntime.jsx(FormHelperText, {
                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          sx: {
                                                            textDecoration: "underline",
                                                            "&:hover": {
                                                              cursor: "pointer"
                                                            }
                                                          },
                                                          children: "Change password?",
                                                          onClick: (function (param) {
                                                              openModal("password");
                                                            })
                                                        }))
                                              })
                                        ]
                                      })),
                              xs: 12,
                              md: 6
                            })
                      ],
                      container: true,
                      spacing: {
                        TAG: "Fixed",
                        _0: 1
                      },
                      sx: {
                        width: "100%"
                      }
                    })
              ]
            });
}

var make = AccountAccessSection;

exports.make = make;
/* Joy-Util Not a pure module */
