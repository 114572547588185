// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function BDButton(props) {
  var __size = props.size;
  var __disabled = props.disabled;
  var __loading = props.loading;
  var __type_ = props.type_;
  var __endDecorator = props.endDecorator;
  var endDecorator = __endDecorator !== undefined ? Caml_option.valFromOption(__endDecorator) : null;
  var type_ = __type_ !== undefined ? __type_ : "primary";
  var loading = __loading !== undefined ? __loading : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var size = __size !== undefined ? __size : "md";
  return JsxRuntime.jsx(Button, {
              loading: loading,
              onClick: props.onClick,
              endDecorator: Caml_option.some(JsxRuntime.jsx(Box, {
                        children: Caml_option.some(endDecorator),
                        sx: {
                          color: loading ? "background.surface" : "text.primary",
                          position: "relative",
                          top: "1px"
                        }
                      })),
              size: size,
              disabled: disabled,
              sx: {
                boxShadow: type_ === "primary" ? "1px 1px 2px 0px #E5E5E5" : "none",
                border: type_ === "primary" ? "1px solid" : "none",
                borderColor: "#100D0C4D",
                borderRadius: "2px",
                backgroundColor: "background.surface",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingInline: "0.5rem",
                opacity: disabled ? "0.5" : "1",
                "&:hover": {
                  backgroundColor: type_ === "primary" ? "#E5E5E5" : "#F5F5F5"
                },
                "& .MuiButton-endDecorator": {
                  marginLeft: Caml_obj.equal(endDecorator, null) ? "0rem" : "0.35rem"
                }
              },
              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                        level: "body-sm",
                        sx: {
                          color: loading ? "transparent" : "text.primary"
                        },
                        fontWeight: "400",
                        children: Caml_option.some(props.text)
                      }))
            });
}

var make = BDButton;

exports.make = make;
/* @mui/joy/Box Not a pure module */
