// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

function makeRefetchVariables(brandFamily) {
  return {
          brandFamily: brandFamily
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"brandFamily":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandFamily"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "brandFamily",
    "variableName": "brandFamily"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandFamilySlug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandFamilyRouteQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DashboardLayout_query"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "BrandFamilyPage_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "OrgProtectedRoute_query"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "BrandProtectedRoute_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrandFamilyRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserSignonStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserEmail",
        "storageKey": null
      },
      {
        "alias": "allBrandFamilies",
        "args": null,
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "forBrandFamilySlug",
            "variableName": "brandFamily"
          }
        ],
        "kind": "ScalarField",
        "name": "currentUserCanForBrand",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "brandFamilySlug",
                "variableName": "brandFamily"
              }
            ],
            "kind": "ObjectValue",
            "name": "condition"
          }
        ],
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brandFamilyRoles",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00fc6982be0e1b987751b26f5c87fd5b",
    "id": null,
    "metadata": {},
    "name": "BrandFamilyRouteQuery",
    "operationKind": "query",
    "text": "query BrandFamilyRouteQuery(\n  $brandFamily: BrandFamilySlug!\n) {\n  ...DashboardLayout_query\n  ...BrandFamilyPage_query_2PeJLC\n  ...OrgProtectedRoute_query\n  ...BrandProtectedRoute_query_2PeJLC\n}\n\nfragment AccountMenu_bfselector on CurrentUserProfile {\n  brandFamilySlug\n}\n\nfragment AccountMenu_query on Query {\n  currentUserEmail\n  allBrandFamilies: currentUserProfiles {\n    nodes {\n      ...AccountMenu_bfselector\n    }\n  }\n}\n\nfragment BrandFamilyPage_query_2PeJLC on Query {\n  currentUserEmail\n  currentUserCanForBrand(forBrandFamilySlug: $brandFamily)\n}\n\nfragment BrandProtectedRoute_query_2PeJLC on Query {\n  currentUserSignonStatus\n  currentUserProfiles(condition: {brandFamilySlug: $brandFamily}) {\n    nodes {\n      brandFamilySlug\n      brandFamilyRoles\n    }\n  }\n}\n\nfragment DashboardLayout_query on Query {\n  currentUserSignonStatus\n  currentUserEmail\n  ...NavBar_profile\n  ...StandardLayout_query\n}\n\nfragment NavBar_profile on Query {\n  currentUserSignonStatus\n  currentUserEmail\n  ...AccountMenu_query\n}\n\nfragment OrgProtectedRoute_query on Query {\n  currentUserSignonStatus\n}\n\nfragment StandardLayout_query on Query {\n  currentUserEmail\n  currentUserSignonStatus\n  ...NavBar_profile\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
