// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BrandPermProtected$Thick = require("../components/wrappers/BrandPermProtected.bs.js");
var CustomerAccountsPage$Thick = require("../pages/CustomerAccountsPage.bs.js");
var CustomerAccountsRouteQuery_graphql$Thick = require("../__generated__/CustomerAccountsRouteQuery_graphql.bs.js");

var convertVariables = CustomerAccountsRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = CustomerAccountsRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = CustomerAccountsRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, CustomerAccountsRouteQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, CustomerAccountsRouteQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(CustomerAccountsRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(CustomerAccountsRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(CustomerAccountsRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(CustomerAccountsRouteQuery_graphql$Thick.node, convertVariables);

var Query_accountStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_billingKind_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_bookingSessionPassengerType_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var Query_bookingSessionPassengerType_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var Query_brandfamRole_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.brandfamRole_decode;

var Query_brandfamRole_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.brandfamRole_fromString;

var Query_custSignonStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_invitationType_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.invitationType_decode;

var Query_invitationType_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.invitationType_fromString;

var Query_lifecycleStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_longitudinality_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_paxDataStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_signonStatus_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.signonStatus_decode;

var Query_signonStatus_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.signonStatus_fromString;

var Query_subtotalCategory_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query_versionProvenance_decode = CustomerAccountsRouteQuery_graphql$Thick.Utils.versionProvenance_decode;

var Query_versionProvenance_fromString = CustomerAccountsRouteQuery_graphql$Thick.Utils.versionProvenance_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  bookingSessionPassengerType_decode: Query_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: Query_bookingSessionPassengerType_fromString,
  brandfamRole_decode: Query_brandfamRole_decode,
  brandfamRole_fromString: Query_brandfamRole_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  invitationType_decode: Query_invitationType_decode,
  invitationType_fromString: Query_invitationType_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  signonStatus_decode: Query_signonStatus_decode,
  signonStatus_fromString: Query_signonStatus_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  versionProvenance_decode: Query_versionProvenance_decode,
  versionProvenance_fromString: Query_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function CustomerAccountsRoute(props) {
  var brandFamily = props.brandFamily;
  var filter_brandFamilySlug = {
    equalTo: CS_Slugs$Util.BrandFamilySlug.toString(brandFamily)
  };
  var filter_email = {
    notIn: []
  };
  var filter_accountStatus = {
    in: [
      "UNCLAIMED",
      "CLAIMED",
      "INVITED",
      "TRANSFERRING"
    ]
  };
  var filter = {
    brandFamilySlug: filter_brandFamilySlug,
    email: filter_email,
    accountStatus: filter_accountStatus
  };
  var data = use({
        brandFamily: brandFamily,
        filter: filter
      }, undefined, undefined, undefined);
  var fragmentRefs = data.fragmentRefs;
  return JsxRuntime.jsx(DashboardLayout$Thick.make, {
              fragmentRefs: fragmentRefs,
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                              fragmentRefs: fragmentRefs,
                              children: JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                    fragmentRefs: fragmentRefs,
                                    neededPerm: "VIEW_CUSTOMER_DETAILS",
                                    children: JsxRuntime.jsx(CustomerAccountsPage$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          brandFamily: brandFamily,
                                          filter: filter
                                        }),
                                    isRoute: true,
                                    redirect: "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily)
                                  })
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
                  }),
              brandFamily: Caml_option.some(brandFamily)
            });
}

var make = CustomerAccountsRoute;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
