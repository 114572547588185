// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Avatar = require("@mui/joy/Avatar").default;
var JsxRuntime = require("react/jsx-runtime");

function BoxAvatar(props) {
  var __variant = props.variant;
  var srcSet = props.srcSet;
  var src = props.src;
  var alt = props.alt;
  var __size = props.size;
  var __color = props.color;
  var __type_ = props.type_;
  var onClick = props.onClick;
  var children = props.children;
  var type_ = __type_ !== undefined ? __type_ : "square";
  var color = __color !== undefined ? __color : "primary";
  var size = __size !== undefined ? __size : "md";
  var variant = __variant !== undefined ? __variant : "soft";
  return JsxRuntime.jsx(Avatar, {
              children: Caml_option.some(children !== undefined ? Caml_option.valFromOption(children) : null),
              onClick: onClick !== undefined ? onClick : (function (param) {
                    
                  }),
              color: color,
              size: size,
              alt: alt !== undefined ? alt : "",
              src: src !== undefined ? src : "",
              srcSet: srcSet !== undefined ? srcSet : "",
              sx: {
                borderRadius: type_ === "square" ? "sm" : ""
              },
              variant: variant
            });
}

var make = BoxAvatar;

exports.make = make;
/* @mui/joy/Avatar Not a pure module */
