// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

function makeRefetchVariables(accountId, brandFamily) {
  return {
          accountId: accountId,
          brandFamily: brandFamily
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"brandFamily":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"accountId":{"c":"Util.CustomScalars.AccountId.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.serialize,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"bfcustUsers_nodes_passenger_record_nodes_booking_version_voyage_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"bfcustUsers_nodes_passenger_record_nodes_booking_version_voyage_voyageName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"bfcustUsers_nodes_passenger_record_nodes_booking_version_voyage_voyStartDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"bfcustUsers_nodes_passenger_record_nodes_booking_version_voyage_voyEndDate":{"c":"Util.CustomScalars.SQLDate.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.serialize,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"bfcustUsers_nodes_passenger_record_nodes_booking_version_voyage_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"bfcustUsers_nodes_passenger_record_nodes_booking_version_voyage_voyageName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"bfcustUsers_nodes_passenger_record_nodes_booking_version_voyage_voyStartDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"bfcustUsers_nodes_passenger_record_nodes_booking_version_voyage_voyEndDate":{"c":"Util.CustomScalars.SQLDate.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "brandFamily"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "brandFamilySlug",
        "variableName": "brandFamily"
      }
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "booking_version": {
        "lifecycleStatus": {
          "equalTo": "ACTIVE"
        }
      }
    }
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageSlug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyStartDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyEndDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchBfCustUserVoyagesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BfcustUsersConnection",
        "kind": "LinkedField",
        "name": "bfcustUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BfcustUser",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "PassengersConnection",
                "kind": "LinkedField",
                "name": "passenger_record",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Passenger",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BookingVersion",
                        "kind": "LinkedField",
                        "name": "booking_version",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BrandFamilyVoyage",
                            "kind": "LinkedField",
                            "name": "voyage",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "passenger_record(filter:{\"booking_version\":{\"lifecycleStatus\":{\"equalTo\":\"ACTIVE\"}}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FetchBfCustUserVoyagesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "BfcustUsersConnection",
        "kind": "LinkedField",
        "name": "bfcustUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BfcustUser",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "PassengersConnection",
                "kind": "LinkedField",
                "name": "passenger_record",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Passenger",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BookingVersion",
                        "kind": "LinkedField",
                        "name": "booking_version",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BrandFamilyVoyage",
                            "kind": "LinkedField",
                            "name": "voyage",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "passenger_record(filter:{\"booking_version\":{\"lifecycleStatus\":{\"equalTo\":\"ACTIVE\"}}})"
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c01ec5cbef9b646504f8277725cf6205",
    "id": null,
    "metadata": {},
    "name": "FetchBfCustUserVoyagesQuery",
    "operationKind": "query",
    "text": "query FetchBfCustUserVoyagesQuery(\n  $brandFamily: BrandFamilySlug!\n  $accountId: AccountId!\n) {\n  bfcustUsers(condition: {accountId: $accountId, brandFamilySlug: $brandFamily}) {\n    nodes {\n      passenger_record(filter: {booking_version: {lifecycleStatus: {equalTo: ACTIVE}}}) {\n        nodes {\n          booking_version {\n            voyage {\n              voyageSlug\n              voyageName\n              voyStartDate\n              voyEndDate\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
