// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var ResendNewPaxInvitation$Thick = require("../../customers/mutations/ResendNewPaxInvitation.bs.js");

function ResendNewPaxInvitationModal(props) {
  var onClose = props.onClose;
  var match = ResendNewPaxInvitation$Thick.useMutation(props.refetch, props.accountId, props.brandFamily, onClose);
  var setError = match.setError;
  var error = match.error;
  var description = "Are you sure you want to resend the invitation to " + Belt_Option.getWithDefault(props.invitee, "this passenger") + "?";
  return JsxRuntime.jsx(Dialog$Thick.make, {
              open_: props.open_,
              onClose: (function () {
                  onClose();
                  setError(function (param) {
                        
                      });
                }),
              title: "Resend a New Passenger Invitation",
              children: JsxRuntime.jsx(DialogContent, {
                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                              spacing: 2,
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      textColor: "text.tertiary",
                                      children: Caml_option.some(description)
                                    }),
                                JsxRuntime.jsx(Stack, {
                                      component: "form",
                                      spacing: 1,
                                      onSubmit: match.onSubmit,
                                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                loading: match.isMutating,
                                                type: "submit",
                                                color: "danger",
                                                children: "Resend invitation"
                                              }))
                                    }),
                                error !== undefined ? JsxRuntime.jsx(Typography, {
                                        color: "danger",
                                        children: Caml_option.some(error)
                                      }) : null
                              ]
                            }))
                  })
            });
}

var make = ResendNewPaxInvitationModal;

exports.make = make;
/* Dialog-Thick Not a pure module */
