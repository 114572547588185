// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var DateFns$Util = require("util/src/DateFns.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var FetchBfCustUserVoyagesQuery_graphql$Thick = require("../../../__generated__/FetchBfCustUserVoyagesQuery_graphql.bs.js");

var convertVariables = FetchBfCustUserVoyagesQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = FetchBfCustUserVoyagesQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = FetchBfCustUserVoyagesQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, FetchBfCustUserVoyagesQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, FetchBfCustUserVoyagesQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(FetchBfCustUserVoyagesQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(FetchBfCustUserVoyagesQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(FetchBfCustUserVoyagesQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(FetchBfCustUserVoyagesQuery_graphql$Thick.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function useQuery(brandFamily, voyage) {
  var match = React.useState(function () {
        return "NotAsked";
      });
  var setVoyOpt = match[1];
  var fetchVoyages = function (acctId) {
    setVoyOpt(function (param) {
          return "Loading";
        });
    $$fetch(RelayEnv$Thick.environment, {
          accountId: acctId,
          brandFamily: brandFamily
        }, (function (res) {
            if (res.TAG === "Ok") {
              var match = res._0.bfcustUsers;
              if (match === undefined) {
                return ;
              }
              var match$1 = match.nodes;
              if (match$1.length !== 1) {
                return ;
              }
              var node = match$1[0];
              var voyages = Belt_Array.keepMap(node.passenger_record.nodes, (function (pax) {
                      var match = pax.booking_version;
                      if (match === undefined) {
                        return ;
                      }
                      var match$1 = match.voyage;
                      if (match$1 === undefined) {
                        return ;
                      }
                      var voyageSlug = match$1.voyageSlug;
                      var voyStartDate = match$1.voyStartDate;
                      if ((
                          Belt_Option.isSome(voyage) ? voyage !== Caml_option.some(voyageSlug) : true
                        ) && !DateFns.isBefore(Belt_Result.getExn(DateFns$Util.parseISO(CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive(voyStartDate))), new Date())) {
                        return {
                                voyageSlug: voyageSlug,
                                voyageName: Belt_Option.getWithDefault(match$1.voyageName, CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive(CS_Slugs$Util.VoyageSlug.toString(voyageSlug))),
                                voyStartDate: voyStartDate,
                                voyEndDate: match$1.voyEndDate,
                                image: {
                                  alt: "Voyage Image",
                                  src: "https://via.placeholder.com/150"
                                }
                              };
                      }
                      
                    }));
              return setVoyOpt(function (param) {
                          return {
                                  TAG: "Success",
                                  _0: voyages
                                };
                        });
            }
            console.log(res._0);
            setVoyOpt(function (param) {
                  return {
                          TAG: "Failure",
                          _0: "Couldn't fetch user voyages."
                        };
                });
          }), undefined, undefined);
  };
  return {
          voyOpt: match[0],
          fetchVoyages: fetchVoyages
        };
}

exports.Query = Query;
exports.useQuery = useQuery;
/* use Not a pure module */
