// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick = require("../../../__generated__/ChangePasswordSubmit_ChangePasswordMutation_graphql.bs.js");

var convertVariables = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ChangePasswordSubmit_ChangePasswordMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var ChangePasswordMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(setModalType) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setNewPass = match$1[1];
  var newPass = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setConfirmPass = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var onSubmit = function (e, pass) {
    e.preventDefault();
    setError(function (param) {
          
        });
    mutate({
          input: {
            allegedCurrentPass: pass,
            newPass: newPass
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (resp, err) {
            if (err !== undefined) {
              return setError(function (param) {
                          return "An error occurred! Please try again.";
                        });
            }
            var match = resp.changePassword;
            var match$1 = match.boolean;
            if (match$1 !== undefined) {
              if (match$1) {
                setNewPass(function (param) {
                      return "";
                    });
                setConfirmPass(function (param) {
                      return "";
                    });
                setModalType(function (param) {
                      return "";
                    });
                Common$Thick.cookieLogout(Caml_option.some((function () {
                            RescriptReactRouter.push("/login?to=/account");
                          })), undefined, undefined);
                return ;
              } else {
                console.log("error");
                return setError(function (param) {
                            return "An error occurred! Please try again.";
                          });
              }
            }
            
          }), (function (param) {
            setError(function (param) {
                  return "An error occurred! Please try again.";
                });
          }), undefined);
  };
  return {
          isMutating: match[1],
          newPass: newPass,
          setNewPass: setNewPass,
          confirmPass: match$2[0],
          setConfirmPass: setConfirmPass,
          error: match$3[0],
          onSubmit: onSubmit
        };
}

exports.ChangePasswordMutation = ChangePasswordMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
