// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CS_Make$Util = require("./CS_Make.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");

function check(str) {
  return str.replace(/^\s+|\s+$/gm, "") !== "";
}

var struct = S$RescriptSchema.refine(S$RescriptSchema.string, (function (s) {
        return function (value) {
          if (!check(value)) {
            return s.fail("Must not be empty or entirely whitespace", undefined);
          }
          
        };
      }));

function toPrimitive(a) {
  return a;
}

function primitiveToEl(prim) {
  return prim;
}

function primitiveToJson(prim) {
  return prim;
}

var FromStruct = {
  toPrimitive: toPrimitive,
  primitiveToEl: primitiveToEl,
  primitiveToJson: primitiveToJson,
  struct: struct,
  QueryVar: undefined
};

var NonEmptyString = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var CabinNumber = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var DeckNumber = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var AccountId = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var BookingId = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var LoyaltyNumber = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var PostalCode = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var Phone = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var PmtTransactionId = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var ImageSetSlug = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var $$String;

exports.$$String = $$String;
exports.struct = struct;
exports.FromStruct = FromStruct;
exports.NonEmptyString = NonEmptyString;
exports.CabinNumber = CabinNumber;
exports.DeckNumber = DeckNumber;
exports.AccountId = AccountId;
exports.BookingId = BookingId;
exports.LoyaltyNumber = LoyaltyNumber;
exports.PostalCode = PostalCode;
exports.Phone = Phone;
exports.PmtTransactionId = PmtTransactionId;
exports.ImageSetSlug = ImageSetSlug;
/* struct Not a pure module */
