// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

var Obj = {};

var Type = {};

var $$Promise = {};

var Re = {};

var $$Object = {};

var $$Set = {};

function unique(array) {
  return Array.from(new Set(array));
}

function has(array, idx) {
  return array[idx];
}

function isArray(prim) {
  return Array.isArray(prim);
}

var $$Array = {
  unique: unique,
  has: has,
  isArray: isArray
};

function raiseAny(any) {
  throw any;
}

var Exn = {
  raiseAny: raiseAny,
  raiseError: raiseAny
};

function plus(int1, int2) {
  return int1 + int2;
}

var Int = {
  plus: plus
};

function has$1(dict, key) {
  return dict[key];
}

function deleteInPlace(dict, key) {
  Js_dict.unsafeDeleteKey(dict, key);
}

var mapValues = ((dict, fn)=>{
      var key,newDict = {};
      for (key in dict) {
        newDict[key] = fn(dict[key])
      }
      return newDict
    });

var every = ((dict, fn)=>{
      for (var key in dict) {
        if (!fn(dict[key])) {
          return false
        }
      }
      return true
    });

var Dict = {
  has: has$1,
  deleteInPlace: deleteInPlace,
  mapValues: mapValues,
  every: every
};

var Float = {};

var Bool = {};

function unsafeToString(bigInt) {
  return bigInt + "n";
}

var $$BigInt = {
  unsafeToString: unsafeToString
};

function make2(ctxVarName1, ctxVarValue1, ctxVarName2, ctxVarValue2, inlinedFunction) {
  return new Function(ctxVarName1, ctxVarName2, "return " + inlinedFunction)(ctxVarValue1, ctxVarValue2);
}

var $$Function$1 = {
  make2: make2
};

var $$Symbol = {};

function stringify(any) {
  if (any === (void 0)) {
    return "undefined";
  } else {
    return JSON.stringify(any);
  }
}

function fromString(string) {
  return JSON.stringify(string);
}

var Value = {
  stringify: stringify,
  fromString: fromString
};

function toRescript($$float) {
  return $$float.toString() + (
          $$float % 1 === 0 ? "." : ""
        );
}

var Float$1 = {
  toRescript: toRescript
};

var Inlined = {
  Value: Value,
  Float: Float$1
};

var Stdlib = {
  Type: Type,
  $$Promise: $$Promise,
  Re: Re,
  $$Object: $$Object,
  $$Set: $$Set,
  $$Array: $$Array,
  Exn: Exn,
  Int: Int,
  Dict: Dict,
  Float: Float,
  Bool: Bool,
  $$BigInt: $$BigInt,
  $$Function: $$Function$1,
  $$Symbol: $$Symbol,
  Inlined: Inlined
};

function classify(value) {
  var typeOfValue = typeof value;
  if (typeOfValue === "symbol") {
    return {
            TAG: "Symbol",
            _0: value
          };
  } else if (typeOfValue === "boolean") {
    return {
            TAG: "Boolean",
            _0: value
          };
  } else if (typeOfValue === "string") {
    return {
            TAG: "String",
            _0: value
          };
  } else if (typeOfValue === "function") {
    return {
            TAG: "Function",
            _0: value
          };
  } else if (typeOfValue === "object") {
    if (value === null) {
      return "Null";
    } else if (Array.isArray(value)) {
      return {
              TAG: "Array",
              _0: value.map(function (i) {
                    return classify(i);
                  })
            };
    } else if (value.constructor === Object) {
      return {
              TAG: "Dict",
              _0: mapValues(value, classify)
            };
    } else {
      return {
              TAG: "Object",
              _0: value
            };
    }
  } else if (typeOfValue === "undefined") {
    return "Undefined";
  } else if (typeOfValue === "number") {
    if (Number.isNaN(value)) {
      return "NaN";
    } else {
      return {
              TAG: "Number",
              _0: value
            };
    }
  } else {
    return {
            TAG: "BigInt",
            _0: value
          };
  }
}

function value(literal) {
  if (typeof literal !== "object") {
    switch (literal) {
      case "Null" :
          return null;
      case "Undefined" :
          return undefined;
      case "NaN" :
          return NaN;
      
    }
  } else {
    switch (literal.TAG) {
      case "Array" :
          return literal._0.map(value);
      case "Dict" :
          return mapValues(literal._0, value);
      default:
        return literal._0;
    }
  }
}

function isJsonable(literal) {
  if (typeof literal !== "object") {
    if (literal === "Null") {
      return true;
    } else {
      return false;
    }
  }
  switch (literal.TAG) {
    case "String" :
    case "Number" :
    case "Boolean" :
        return true;
    case "Array" :
        return literal._0.every(isJsonable);
    case "Dict" :
        return every(literal._0, isJsonable);
    default:
      return false;
  }
}

function toText(literal) {
  if (typeof literal !== "object") {
    switch (literal) {
      case "Null" :
          return "null";
      case "Undefined" :
          return "undefined";
      case "NaN" :
          return "NaN";
      
    }
  } else {
    switch (literal.TAG) {
      case "String" :
          return JSON.stringify(literal._0);
      case "Number" :
      case "Boolean" :
          return literal._0;
      case "BigInt" :
          return literal._0 + "n";
      case "Symbol" :
          return literal._0.toString();
      case "Array" :
          return "[" + literal._0.map(toText).join(", ") + "]";
      case "Dict" :
          var v = literal._0;
          return "{" + Object.keys(v).map(function (key) {
                        return JSON.stringify(key) + ": " + toText(v[key]);
                      }).join(", ") + "}";
      case "Function" :
          return "[object Function]";
      case "Object" :
          return Object.prototype.toString.call(literal._0);
      
    }
  }
}

var Literal = {
  classify: classify,
  value: value,
  isJsonable: isJsonable,
  toText: toText
};

function toArray(path) {
  if (path === "") {
    return [];
  } else {
    return JSON.parse(path.split("\"][\"").join("\",\""));
  }
}

function fromInlinedLocation(inlinedLocation) {
  return "[" + inlinedLocation + "]";
}

function fromLocation($$location) {
  return "[" + JSON.stringify($$location) + "]";
}

function fromArray(array) {
  var len = array.length;
  if (len !== 1) {
    if (len !== 0) {
      return "[" + array.map(fromString).join("][") + "]";
    } else {
      return "";
    }
  }
  var $$location = array[0];
  return "[" + JSON.stringify($$location) + "]";
}

function concat(path, concatedPath) {
  return path + concatedPath;
}

var Path = {
  toArray: toArray,
  fromInlinedLocation: fromInlinedLocation,
  fromLocation: fromLocation,
  fromArray: fromArray,
  concat: concat
};

var symbol = Symbol("rescript-schema");

var Raised = /* @__PURE__ */Caml_exceptions.create("S_Core-RescriptSchema.Raised");

function unsafeGetVariantPayload(variant) {
  return variant._0;
}

function unsafeGetErrorPayload(variant) {
  return variant._1;
}

class RescriptSchemaError extends Error {
      constructor(code, operation, path) {
        super();
        this.operation = operation;
        this.code = code;
        this.path = path;
        this.s = symbol;
        this.RE_EXN_ID = Raised;
        this._1 = this;
        this.Error = this;
        this.name = "RescriptSchemaError";
      }
      get message() {
        return message(this);
      }
      get reason() {
        return reason(this);
      }
    }
;

function getOrRethrow(exn) {
  if ((exn&&exn.s===symbol)) {
    return exn;
  }
  throw (exn&&exn.RE_EXN_ID==='JsError') ? exn._1 : exn;
}

function raise(code, operation, path) {
  throw new RescriptSchemaError(code, operation, path);
}

function prependLocationOrRethrow(exn, $$location) {
  var error = getOrRethrow(exn);
  var path = "[" + JSON.stringify($$location) + "]" + error.path;
  throw new RescriptSchemaError(error.code, error.operation, path);
}

function panic(message) {
  throw new Error("[rescript-schema] " + message);
}

var InternalError = {
  getOrRethrow: getOrRethrow,
  raise: raise,
  prependLocationOrRethrow: prependLocationOrRethrow,
  panic: panic
};

function make(selfSchema, path, operation) {
  return {
          schema: selfSchema,
          fail: (function (message, customPathOpt) {
              var customPath = customPathOpt !== undefined ? customPathOpt : "";
              throw new RescriptSchemaError({
                        TAG: "OperationFailed",
                        _0: message
                      }, operation, path + customPath);
            }),
          failWithError: (function (error) {
              throw new RescriptSchemaError(error.code, operation, path + error.path);
            })
        };
}

var EffectCtx = {
  make: make
};

function classify$1(schema) {
  return schema.t;
}

function make$1(prim) {
  return prim;
}

function embed(b, value) {
  return "e[" + (b.e.push(value) - 1) + "]";
}

function scope(b, fn) {
  var prevVarsAllocation = b.l;
  var prevCode = b.c;
  b.l = "";
  b.c = "";
  var resultCode = fn(b);
  var varsAllocation = b.l;
  var code = varsAllocation === "" ? b.c : "let " + varsAllocation + ";" + b.c;
  b.l = prevVarsAllocation;
  b.c = prevCode;
  return code + resultCode;
}

function varWithoutAllocation(b) {
  var newCounter = b.v + 1;
  b.v = newCounter;
  var v = "v" + newCounter;
  b.s.add(v);
  return v;
}

function $$var(b) {
  var v = varWithoutAllocation(b);
  b.l = b.l === "" ? v : b.l + "," + v;
  return v;
}

function useInput(b) {
  return b.i;
}

function toVar(b, val) {
  if (b.s.has(val)) {
    return val;
  }
  var $$var$1 = $$var(b);
  b.c = b.c + ($$var$1 + "=" + val + ";");
  return $$var$1;
}

function useInputVar(b) {
  return toVar(b, b.i);
}

function isInternalError(_b, $$var) {
  return $$var + "&&" + $$var + ".s===s";
}

function transform(b, input, isAsync, operation) {
  if (b.a === true) {
    var prevCode = b.c;
    b.c = "";
    var inputVar = varWithoutAllocation(b);
    var operationOutputVar = operation(b, inputVar);
    var outputVar = $$var(b);
    b.c = prevCode + (outputVar + "=()=>" + input + "().then(" + inputVar + "=>{" + b.c + "return " + operationOutputVar + (
        isAsync ? "()" : ""
      ) + "});");
    return outputVar;
  }
  if (!isAsync) {
    return operation(b, input);
  }
  b.a = true;
  var outputVar$1 = $$var(b);
  b.c = b.c + (outputVar$1 + "=" + operation(b, input) + ";");
  return outputVar$1;
}

function embedSyncOperation(b, input, fn) {
  return transform(b, input, false, (function (b, input) {
                return "e[" + (b.e.push(fn) - 1) + "](" + input + ")";
              }));
}

function embedAsyncOperation(b, input, fn) {
  return transform(b, input, true, (function (b, input) {
                return "e[" + (b.e.push(fn) - 1) + "](" + input + ")";
              }));
}

function raiseWithArg(b, path, fn, arg) {
  return "e[" + (b.e.push(function (arg) {
                var code = fn(arg);
                throw new RescriptSchemaError(code, b.o, path);
              }) - 1) + "](" + arg + ")";
}

function fail(b, message, path) {
  return "e[" + (b.e.push(function () {
                throw new RescriptSchemaError({
                          TAG: "OperationFailed",
                          _0: message
                        }, b.o, path);
              }) - 1) + "]()";
}

function invalidOperation(b, path, description) {
  throw new RescriptSchemaError({
            TAG: "InvalidOperation",
            description: description
          }, b.o, path);
}

function withCatch(b, $$catch, fn) {
  var prevCode = b.c;
  b.c = "";
  var errorVar = varWithoutAllocation(b);
  var maybeResolveVar = $$catch(b, errorVar);
  var catchCode = "if(" + (errorVar + "&&" + errorVar + ".s===s") + "){" + b.c;
  b.c = "";
  var fnOutput = fn(b);
  var isAsync = b.a;
  var isInlined = !b.s.has(fnOutput);
  var outputVar = isAsync || isInlined ? $$var(b) : fnOutput;
  var catchCode$1 = maybeResolveVar !== undefined ? (function (catchLocation) {
        return catchCode + (
                catchLocation === 1 ? "return Promise.resolve(" + maybeResolveVar + ")" : (
                    catchLocation === 2 ? "return " + maybeResolveVar : (
                        isAsync ? outputVar + "=()=>Promise.resolve(" + maybeResolveVar + ")" : outputVar + "=" + maybeResolveVar
                      )
                  )
              ) + ("}else{throw " + errorVar + "}");
      }) : (function (param) {
        return catchCode + "}throw " + errorVar;
      });
  b.c = prevCode + ("try{" + b.c + (
      isAsync ? outputVar + "=()=>{try{return " + fnOutput + "().catch(" + errorVar + "=>{" + catchCode$1(2) + "})}catch(" + errorVar + "){" + catchCode$1(1) + "}};" : (
          isInlined ? outputVar + "=" + fnOutput : ""
        )
    ) + "}catch(" + errorVar + "){" + catchCode$1(0) + "}");
  return outputVar;
}

function withPathPrepend(b, path, maybeDynamicLocationVar, fn) {
  if (path === "" && maybeDynamicLocationVar === undefined) {
    return fn(b, path);
  }
  try {
    return withCatch(b, (function (b, errorVar) {
                  b.c = errorVar + ".path=" + JSON.stringify(path) + "+" + (
                    maybeDynamicLocationVar !== undefined ? "'[\"'+" + maybeDynamicLocationVar + "+'\"]'+" : ""
                  ) + errorVar + ".path";
                }), (function (b) {
                  return fn(b, "");
                }));
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    if (error.RE_EXN_ID === Raised) {
      var error$1 = error._1;
      throw new RescriptSchemaError(error$1.code, error$1.operation, path + "[]" + error$1.path);
    }
    throw error;
  }
}

function typeFilterCode(b, typeFilter, schema, inputVar, path) {
  return "if(" + typeFilter(inputVar) + "){" + raiseWithArg(b, path, (function (input) {
                return {
                        TAG: "InvalidType",
                        expected: schema,
                        received: input
                      };
              }), inputVar) + "}";
}

function use(b, schema, input, path) {
  var isParentAsync = b.a;
  var isParsing = b.o === "Parsing";
  b.i = input;
  b.a = false;
  var output = (
      isParsing ? schema.p : schema.s
    )(b, schema, path);
  if (isParsing) {
    schema.i = b.a;
    b.a = isParentAsync || b.a;
  }
  return output;
}

function useWithTypeFilter(b, schema, input, path) {
  var typeFilter = schema.f;
  var input$1;
  if (typeFilter !== undefined) {
    var inputVar = toVar(b, input);
    b.c = b.c + typeFilterCode(b, typeFilter, schema, inputVar, path);
    input$1 = inputVar;
  } else {
    input$1 = input;
  }
  return use(b, schema, input$1, path);
}

function withBuildErrorInline(b, fn) {
  try {
    return fn();
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    var error = getOrRethrow(exn);
    b.c = "throw " + ("e[" + (b.e.push(error) - 1) + "]") + ";";
    return b.i;
  }
}

var Ctx = {
  embed: embed,
  scope: scope,
  varWithoutAllocation: varWithoutAllocation,
  $$var: $$var,
  useInput: useInput,
  toVar: toVar,
  useInputVar: useInputVar,
  isInternalError: isInternalError,
  transform: transform,
  embedSyncOperation: embedSyncOperation,
  embedAsyncOperation: embedAsyncOperation,
  raiseWithArg: raiseWithArg,
  fail: fail,
  invalidOperation: invalidOperation,
  withCatch: withCatch,
  withPathPrepend: withPathPrepend,
  typeFilterCode: typeFilterCode,
  use: use,
  useWithTypeFilter: useWithTypeFilter,
  withBuildErrorInline: withBuildErrorInline
};

function noop(b, param, param$1) {
  return b.i;
}

function noopOperation(i) {
  return i;
}

function build(builder, schema, operation) {
  var b = {
    a: false,
    c: "",
    o: operation,
    v: -1,
    s: new Set(["i"]),
    l: "",
    i: "i",
    e: []
  };
  var output = builder(b, schema, "");
  if (operation === "Parsing") {
    var typeFilter = schema.f;
    if (typeFilter !== undefined) {
      b.c = typeFilterCode(b, typeFilter, schema, "i", "") + b.c;
    }
    schema.i = b.a;
  }
  if (b.c === "" && output === "i") {
    return noopOperation;
  }
  var inlinedFunction = "i=>{" + (
    b.l === "" ? "" : "let " + b.l + ";"
  ) + b.c + "return " + output + "}";
  return new Function("e", "s", "return " + inlinedFunction)(b.e, symbol);
}

var Builder = {
  make: make$1,
  Ctx: Ctx,
  noop: noop,
  noopOperation: noopOperation,
  build: build
};

function loop(_schema) {
  while(true) {
    var schema = _schema;
    var literal = schema.t;
    if (typeof literal !== "object") {
      throw symbol;
    }
    switch (literal.TAG) {
      case "Literal" :
          return literal._0;
      case "Object" :
          return {
                  TAG: "Dict",
                  _0: mapValues(literal.fields, loop)
                };
      case "Tuple" :
          return {
                  TAG: "Array",
                  _0: literal._0.map(function (a) {
                        return loop(a);
                      })
                };
      case "Union" :
          _schema = literal._0[0];
          continue ;
      default:
        throw symbol;
    }
  };
}

function toLiteral(schema) {
  try {
    return loop(schema);
  }
  catch (raw_jsExn){
    var jsExn = Caml_js_exceptions.internalToOCamlException(raw_jsExn);
    if (jsExn.RE_EXN_ID === Js_exn.$$Error) {
      var jsExn$1 = jsExn._1;
      if (jsExn$1 === symbol) {
        return ;
      }
      throw jsExn$1;
    }
    throw jsExn;
  }
}

function isAsyncParse(schema) {
  var v = schema.i;
  if (typeof v === "boolean") {
    return v;
  }
  try {
    build(schema.p, schema, "Parsing");
    return schema.i;
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    getOrRethrow(exn);
    return false;
  }
}

function validateJsonableSchema(_schema, rootSchema, _isRootOpt) {
  while(true) {
    var isRootOpt = _isRootOpt;
    var schema = _schema;
    var isRoot = isRootOpt !== undefined ? isRootOpt : false;
    if (!(isRoot || rootSchema !== schema)) {
      return ;
    }
    var childrenSchemas = schema.t;
    var exit = 0;
    if (typeof childrenSchemas !== "object") {
      if (childrenSchemas !== "Unknown") {
        return ;
      }
      exit = 2;
    } else {
      switch (childrenSchemas.TAG) {
        case "Literal" :
            if (isJsonable(childrenSchemas._0)) {
              return ;
            }
            exit = 2;
            break;
        case "Option" :
            exit = 2;
            break;
        case "Object" :
            var fieldNames = childrenSchemas.fieldNames;
            var fields = childrenSchemas.fields;
            for(var idx = 0 ,idx_finish = fieldNames.length; idx < idx_finish; ++idx){
              var fieldName = fieldNames[idx];
              var fieldSchema = fields[fieldName];
              try {
                var s = fieldSchema.t;
                var tmp;
                tmp = typeof s !== "object" || s.TAG !== "Option" ? fieldSchema : s._0;
                validateJsonableSchema(tmp, rootSchema, undefined);
              }
              catch (raw_exn){
                var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                prependLocationOrRethrow(exn, fieldName);
              }
            }
            return ;
        case "Tuple" :
            childrenSchemas._0.forEach(function (schema, i) {
                  try {
                    return validateJsonableSchema(schema, rootSchema, undefined);
                  }
                  catch (raw_exn){
                    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
                    return prependLocationOrRethrow(exn, i.toString());
                  }
                });
            return ;
        case "Union" :
            childrenSchemas._0.forEach(function (schema) {
                  validateJsonableSchema(schema, rootSchema, undefined);
                });
            return ;
        case "Null" :
        case "Array" :
        case "Dict" :
            exit = 1;
            break;
        
      }
    }
    switch (exit) {
      case 1 :
          _isRootOpt = undefined;
          _schema = childrenSchemas._0;
          continue ;
      case 2 :
          throw new RescriptSchemaError({
                    TAG: "InvalidJsonStruct",
                    _0: schema
                  }, "Serializing", "");
      
    }
  };
}

function make$2(name, tagged, metadataMap, parseOperationBuilder, serializeOperationBuilder, maybeTypeFilter) {
  return {
          t: tagged,
          n: name,
          p: parseOperationBuilder,
          s: serializeOperationBuilder,
          f: maybeTypeFilter,
          i: 0,
          m: metadataMap
        };
}

function makeWithNoopSerializer(name, tagged, metadataMap, parseOperationBuilder, maybeTypeFilter) {
  return {
          t: tagged,
          n: name,
          p: parseOperationBuilder,
          s: noop,
          f: maybeTypeFilter,
          i: 0,
          m: metadataMap
        };
}

function unexpectedAsync(param) {
  throw new RescriptSchemaError("UnexpectedAsync", "Parsing", "");
}

function make$3(label, init) {
  return function (i, s) {
    try {
      return s[label](i);
    }
    catch (exn){
      if (s[label]) {
        throw exn;
      }
      var o = init(s);
      s[label] = o;
      return o(i);
    }
  };
}

var Operation = {
  unexpectedAsync: unexpectedAsync,
  make: make$3
};

function init(schema) {
  var operation = build(schema.p, schema, "Parsing");
  var isAsync = schema.i;
  if (isAsync) {
    return unexpectedAsync;
  } else {
    return operation;
  }
}

function parseAnyOrRaiseWith(i, s) {
  try {
    return s["op"](i);
  }
  catch (exn){
    if (s["op"]) {
      throw exn;
    }
    var o = init(s);
    s["op"] = o;
    return o(i);
  }
}

function parseAnyWith(any, schema) {
  try {
    return {
            TAG: "Ok",
            _0: parseAnyOrRaiseWith(any, schema)
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    return {
            TAG: "Error",
            _0: getOrRethrow(exn)
          };
  }
}

function asyncPrepareOk(value) {
  return {
          TAG: "Ok",
          _0: value
        };
}

function asyncPrepareError(jsExn) {
  return {
          TAG: "Error",
          _0: getOrRethrow(jsExn)
        };
}

function init$1(schema) {
  var operation = build(schema.p, schema, "Parsing");
  var isAsync = schema.i;
  if (isAsync) {
    return operation;
  } else {
    return function (input) {
      var syncValue = operation(input);
      return function () {
        return Promise.resolve(syncValue);
      };
    };
  }
}

function internalParseAsyncWith(i, s) {
  try {
    return s["opa"](i);
  }
  catch (exn){
    if (s["opa"]) {
      throw exn;
    }
    var o = init$1(s);
    s["opa"] = o;
    return o(i);
  }
}

function parseAnyAsyncWith(any, schema) {
  try {
    return internalParseAsyncWith(any, schema)().then(asyncPrepareOk, asyncPrepareError);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    return Promise.resolve({
                TAG: "Error",
                _0: getOrRethrow(exn)
              });
  }
}

function parseAnyAsyncInStepsWith(any, schema) {
  try {
    var asyncFn = internalParseAsyncWith(any, schema);
    return {
            TAG: "Ok",
            _0: (function () {
                return asyncFn().then(asyncPrepareOk, asyncPrepareError);
              })
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    return {
            TAG: "Error",
            _0: getOrRethrow(exn)
          };
  }
}

function init$2(schema) {
  validateJsonableSchema(schema, schema, true);
  return build(schema.s, schema, "Serializing");
}

function serializeOrRaiseWith(i, s) {
  try {
    return s["osj"](i);
  }
  catch (exn){
    if (s["osj"]) {
      throw exn;
    }
    var o = init$2(s);
    s["osj"] = o;
    return o(i);
  }
}

function serializeWith(value, schema) {
  try {
    return {
            TAG: "Ok",
            _0: serializeOrRaiseWith(value, schema)
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    return {
            TAG: "Error",
            _0: getOrRethrow(exn)
          };
  }
}

function init$3(schema) {
  return build(schema.s, schema, "Serializing");
}

function serializeToUnknownOrRaiseWith(i, s) {
  try {
    return s["os"](i);
  }
  catch (exn){
    if (s["os"]) {
      throw exn;
    }
    var o = init$3(s);
    s["os"] = o;
    return o(i);
  }
}

function serializeToUnknownWith(value, schema) {
  try {
    return {
            TAG: "Ok",
            _0: serializeToUnknownOrRaiseWith(value, schema)
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    return {
            TAG: "Error",
            _0: getOrRethrow(exn)
          };
  }
}

function serializeToJsonStringWith(value, schema, spaceOpt) {
  var space = spaceOpt !== undefined ? spaceOpt : 0;
  var json = serializeWith(value, schema);
  if (json.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: JSON.stringify(json._0, null, space)
          };
  } else {
    return json;
  }
}

function parseJsonStringWith(json, schema) {
  var json$1;
  try {
    json$1 = {
      TAG: "Ok",
      _0: JSON.parse(json)
    };
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    if (error.RE_EXN_ID === Js_exn.$$Error) {
      json$1 = {
        TAG: "Error",
        _0: new RescriptSchemaError({
              TAG: "OperationFailed",
              _0: error._1.message
            }, "Parsing", "")
      };
    } else {
      throw error;
    }
  }
  if (json$1.TAG === "Ok") {
    return parseAnyWith(json$1._0, schema);
  } else {
    return json$1;
  }
}

function make$4(namespace, name) {
  return namespace + ":" + name;
}

var Id = {
  make: make$4
};

var empty = {};

function set(map, id, metadata) {
  if (map === empty) {
    return ({[id]:metadata});
  }
  var copy = Object.assign({}, map);
  copy[id] = metadata;
  return copy;
}

var $$Map = {
  empty: empty,
  set: set
};

function get(schema, id) {
  return schema.m[id];
}

function set$1(schema, id, metadata) {
  var metadataMap = set(schema.m, id, metadata);
  return {
          t: schema.t,
          n: schema.n,
          p: schema.p,
          s: schema.s,
          f: schema.f,
          i: 0,
          m: metadataMap
        };
}

var Metadata = {
  Id: Id,
  $$Map: $$Map,
  get: get,
  set: set$1
};

function recursive(fn) {
  var placeholder = {
    m: empty
  };
  var schema = fn(placeholder);
  Object.assign(placeholder, schema);
  var builder = placeholder.p;
  placeholder.p = (function (b, selfSchema, path) {
      var input = b.i;
      selfSchema.p = noop;
      var ctx = {
        a: false,
        c: "",
        o: "Parsing",
        v: -1,
        s: new Set(["i"]),
        l: "",
        i: "i",
        e: []
      };
      builder(ctx, selfSchema, path);
      var isAsync = ctx.a;
      selfSchema.p = (function (b, selfSchema, param) {
          var input = b.i;
          if (isAsync) {
            return embedAsyncOperation(b, input, (function (input) {
                          return internalParseAsyncWith(input, selfSchema);
                        }));
          } else {
            return embedSyncOperation(b, input, (function (input) {
                          return parseAnyOrRaiseWith(input, selfSchema);
                        }));
          }
        });
      var operation = build(builder, selfSchema, "Parsing");
      if (isAsync) {
        selfSchema["opa"] = operation;
      } else {
        selfSchema["op"] = operation;
      }
      selfSchema.p = builder;
      return withPathPrepend(b, path, undefined, (function (b, param) {
                    if (isAsync) {
                      return embedAsyncOperation(b, input, operation);
                    } else {
                      return embedSyncOperation(b, input, operation);
                    }
                  }));
    });
  var builder$1 = placeholder.s;
  placeholder.s = (function (b, selfSchema, path) {
      var input = b.i;
      selfSchema.s = (function (b, selfSchema, param) {
          var input = b.i;
          return embedSyncOperation(b, input, (function (input) {
                        return serializeToUnknownOrRaiseWith(input, selfSchema);
                      }));
        });
      var operation = build(builder$1, selfSchema, "Serializing");
      selfSchema["os"] = operation;
      selfSchema.s = builder$1;
      return withPathPrepend(b, path, undefined, (function (b, param) {
                    return embedSyncOperation(b, input, operation);
                  }));
    });
  return placeholder;
}

function setName(schema, name) {
  return {
          t: schema.t,
          n: (function () {
              return name;
            }),
          p: schema.p,
          s: schema.s,
          f: schema.f,
          i: 0,
          m: schema.m
        };
}

function primitiveName() {
  return this.t;
}

function containerName() {
  var tagged = this.t;
  return tagged.TAG + "(" + tagged._0.n() + ")";
}

function internalRefine(schema, refiner) {
  return {
          t: schema.t,
          n: schema.n,
          p: (function (b, selfSchema, path) {
              var input = b.i;
              return transform(b, use(b, schema, input, path), false, (function (b, input) {
                            var inputVar = toVar(b, input);
                            b.c = b.c + refiner(b, inputVar, selfSchema, path);
                            return inputVar;
                          }));
            }),
          s: (function (b, selfSchema, path) {
              var input = b.i;
              return use(b, schema, transform(b, input, false, (function (b, input) {
                                var inputVar = toVar(b, input);
                                b.c = b.c + refiner(b, inputVar, selfSchema, path);
                                return inputVar;
                              })), path);
            }),
          f: schema.f,
          i: 0,
          m: schema.m
        };
}

function refine(schema, refiner) {
  return internalRefine(schema, (function (b, inputVar, selfSchema, path) {
                var value = refiner(make(selfSchema, path, b.o));
                return "e[" + (b.e.push(value) - 1) + "](" + inputVar + ");";
              }));
}

function addRefinement(schema, metadataId, refinement, refiner) {
  var refinements = schema.m[metadataId];
  return internalRefine(set$1(schema, metadataId, refinements !== undefined ? refinements.concat(refinement) : [refinement]), refiner);
}

function transform$1(schema, transformer) {
  return {
          t: schema.t,
          n: schema.n,
          p: (function (b, selfSchema, path) {
              var input = b.i;
              var input$1 = use(b, schema, input, path);
              var match = transformer(make(selfSchema, path, b.o));
              var parser = match.p;
              if (parser !== undefined) {
                if (match.a !== undefined) {
                  return invalidOperation(b, path, "The S.transform doesn't allow parser and asyncParser at the same time. Remove parser in favor of asyncParser.");
                } else {
                  return embedSyncOperation(b, input$1, parser);
                }
              }
              var asyncParser = match.a;
              if (asyncParser !== undefined) {
                return embedAsyncOperation(b, input$1, asyncParser);
              } else if (match.s !== undefined) {
                return invalidOperation(b, path, "The S.transform parser is missing");
              } else {
                return input$1;
              }
            }),
          s: (function (b, selfSchema, path) {
              var input = b.i;
              var match = transformer(make(selfSchema, path, b.o));
              var serializer = match.s;
              if (serializer !== undefined) {
                return use(b, schema, embedSyncOperation(b, input, serializer), path);
              } else if (match.a !== undefined || match.p !== undefined) {
                return invalidOperation(b, path, "The S.transform serializer is missing");
              } else {
                return use(b, schema, input, path);
              }
            }),
          f: schema.f,
          i: 0,
          m: schema.m
        };
}

function preprocess(schema, transformer) {
  var unionSchemas = schema.t;
  if (typeof unionSchemas === "object" && unionSchemas.TAG === "Union") {
    return {
            t: {
              TAG: "Union",
              _0: unionSchemas._0.map(function (unionSchema) {
                    return preprocess(unionSchema, transformer);
                  })
            },
            n: schema.n,
            p: schema.p,
            s: schema.s,
            f: schema.f,
            i: 0,
            m: schema.m
          };
  }
  return {
          t: schema.t,
          n: schema.n,
          p: (function (b, selfSchema, path) {
              var input = b.i;
              var match = transformer(make(selfSchema, path, b.o));
              var parser = match.p;
              if (parser !== undefined) {
                if (match.a !== undefined) {
                  return invalidOperation(b, path, "The S.preprocess doesn't allow parser and asyncParser at the same time. Remove parser in favor of asyncParser.");
                }
                var operationResultVar = $$var(b);
                b.c = b.c + (operationResultVar + "=" + embedSyncOperation(b, input, parser) + ";");
                return useWithTypeFilter(b, schema, operationResultVar, path);
              }
              var asyncParser = match.a;
              if (asyncParser === undefined) {
                return useWithTypeFilter(b, schema, input, path);
              }
              var parseResultVar = embedAsyncOperation(b, input, asyncParser);
              var outputVar = $$var(b);
              var asyncResultVar = varWithoutAllocation(b);
              b.c = b.c + (outputVar + "=()=>" + parseResultVar + "().then(" + asyncResultVar + "=>{" + scope(b, (function (b) {
                        var schemaOutputVar = useWithTypeFilter(b, schema, asyncResultVar, path);
                        var isAsync = schema.i;
                        return "return " + (
                                isAsync ? schemaOutputVar + "()" : schemaOutputVar
                              );
                      })) + "});");
              return outputVar;
            }),
          s: (function (b, selfSchema, path) {
              var input = b.i;
              var input$1 = use(b, schema, input, path);
              var match = transformer(make(selfSchema, path, b.o));
              var serializer = match.s;
              if (serializer !== undefined) {
                return embedSyncOperation(b, input$1, serializer);
              } else {
                return input$1;
              }
            }),
          f: undefined,
          i: 0,
          m: schema.m
        };
}

function custom(name, definer) {
  return {
          t: "Unknown",
          n: (function () {
              return name;
            }),
          p: (function (b, selfSchema, path) {
              var input = b.i;
              var match = definer(make(selfSchema, path, b.o));
              var parser = match.p;
              if (parser !== undefined) {
                if (match.a !== undefined) {
                  return invalidOperation(b, path, "The S.custom doesn't allow parser and asyncParser at the same time. Remove parser in favor of asyncParser.");
                } else {
                  return embedSyncOperation(b, input, parser);
                }
              }
              var asyncParser = match.a;
              if (asyncParser !== undefined) {
                return embedAsyncOperation(b, input, asyncParser);
              } else if (match.s !== undefined) {
                return invalidOperation(b, path, "The S.custom parser is missing");
              } else {
                return input;
              }
            }),
          s: (function (b, selfSchema, path) {
              var input = b.i;
              var match = definer(make(selfSchema, path, b.o));
              var serializer = match.s;
              if (serializer !== undefined) {
                return embedSyncOperation(b, input, serializer);
              } else if (match.a !== undefined || match.p !== undefined) {
                return invalidOperation(b, path, "The S.custom serializer is missing");
              } else {
                return input;
              }
            }),
          f: undefined,
          i: 0,
          m: empty
        };
}

function literalCheckBuilder(b, value, inputVar) {
  if (Number.isNaN(value)) {
    return "Number.isNaN(" + inputVar + ")";
  }
  if (value === null) {
    return inputVar + "===null";
  }
  if (value === (void 0)) {
    return inputVar + "===void 0";
  }
  var check = inputVar + "===" + ("e[" + (b.e.push(value) - 1) + "]");
  if (Array.isArray(value)) {
    return "(" + check + "||Array.isArray(" + inputVar + ")&&" + inputVar + ".length===" + value.length + (
            value.length > 0 ? "&&" + value.map(function (item, idx) {
                      return literalCheckBuilder(b, item, inputVar + "[" + idx + "]");
                    }).join("&&") : ""
          ) + ")";
  }
  if (!(value&&value.constructor===Object)) {
    return check;
  }
  var keys = Object.keys(value);
  var numberOfKeys = keys.length;
  return "(" + check + "||" + inputVar + "&&" + inputVar + ".constructor===Object&&Object.keys(" + inputVar + ").length===" + numberOfKeys + (
          numberOfKeys > 0 ? "&&" + keys.map(function (key) {
                    return literalCheckBuilder(b, value[key], inputVar + "[" + JSON.stringify(key) + "]");
                  }).join("&&") : ""
        ) + ")";
}

function literal(value) {
  var literal$1 = classify(value);
  var operationBuilder = function (b, param, path) {
    var inputVar = toVar(b, b.i);
    b.c = b.c + (literalCheckBuilder(b, value, inputVar) + "||" + raiseWithArg(b, path, (function (input) {
              return {
                      TAG: "InvalidLiteral",
                      expected: literal$1,
                      received: input
                    };
            }), inputVar) + ";");
    return inputVar;
  };
  return {
          t: {
            TAG: "Literal",
            _0: literal$1
          },
          n: (function () {
              return "Literal(" + toText(literal$1) + ")";
            }),
          p: operationBuilder,
          s: operationBuilder,
          f: undefined,
          i: 0,
          m: empty
        };
}

var unit = literal((void 0));

function toKindWithSet(definition, embededSet) {
  if (embededSet.has(definition)) {
    return 2;
  } else if (typeof definition === "object" && definition !== null) {
    return 0;
  } else {
    return 1;
  }
}

function toKindWithValue(definition, embeded) {
  if (embeded === definition) {
    return 2;
  } else if (typeof definition === "object" && definition !== null) {
    return 0;
  } else {
    return 1;
  }
}

function toConstant(prim) {
  return prim;
}

function toEmbeded(prim) {
  return prim;
}

function toNode(prim) {
  return prim;
}

var Definition = {
  toKindWithSet: toKindWithSet,
  toKindWithValue: toKindWithValue,
  toConstant: toConstant,
  toEmbeded: toEmbeded,
  toNode: toNode
};

function factory(schema, definer) {
  return {
          t: schema.t,
          n: schema.n,
          p: (function (b, param, path) {
              var input = b.i;
              return embedSyncOperation(b, use(b, schema, input, path), definer);
            }),
          s: (function (b, selfSchema, path) {
              var inputVar = toVar(b, b.i);
              var definition = definer(symbol);
              var definitionToOutput = function (definition, outputPath) {
                var kind = symbol === definition ? 2 : (
                    typeof definition === "object" && definition !== null ? 0 : 1
                  );
                switch (kind) {
                  case 0 :
                      var keys = Object.keys(definition);
                      var maybeOutputRef = 0;
                      for(var idx = 0 ,idx_finish = keys.length; idx < idx_finish; ++idx){
                        var key = keys[idx];
                        var definition$1 = definition[key];
                        var maybeOutput = definitionToOutput(definition$1, outputPath + ("[" + JSON.stringify(key) + "]"));
                        var match = maybeOutputRef;
                        if (typeof match !== "string") {
                          if (match === 0) {
                            maybeOutputRef = maybeOutput;
                          }
                          
                        } else if (!(typeof maybeOutput !== "string" && maybeOutput === 0)) {
                          maybeOutputRef = 1;
                        }
                        
                      }
                      return maybeOutputRef;
                  case 1 :
                      var constantVar = $$var(b);
                      b.c = b.c + (constantVar + "=" + inputVar + outputPath + ";if(" + constantVar + "!==" + ("e[" + (b.e.push(definition) - 1) + "]") + "){" + raiseWithArg(b, path + outputPath, (function (input) {
                                return {
                                        TAG: "InvalidLiteral",
                                        expected: classify(definition),
                                        received: input
                                      };
                              }), constantVar) + "}");
                      return 0;
                  case 2 :
                      return inputVar + outputPath;
                  
                }
              };
              var output = definitionToOutput(definition, "");
              if (typeof output === "string") {
                return use(b, schema, output, path);
              }
              if (output !== 0) {
                return invalidOperation(b, path, "Can't create serializer. The S.variant's value is registered multiple times. Use S.transform instead");
              }
              var literal = toLiteral(selfSchema);
              if (literal === undefined) {
                return invalidOperation(b, path, "Can't create serializer. The S.variant's value is not registered and not a literal. Use S.transform instead");
              }
              var value$1 = value(literal);
              return use(b, schema, "e[" + (b.e.push(value$1) - 1) + "]", path);
            }),
          f: schema.f,
          i: 0,
          m: schema.m
        };
}

var Variant = {
  factory: factory
};

var defaultMetadataId = "rescript-schema:Option.default";

function $$default(schema) {
  return schema.m[defaultMetadataId];
}

function parseOperationBuilder(b, selfSchema, path) {
  var inputVar = toVar(b, b.i);
  var outputVar = $$var(b);
  var isNull = (selfSchema.t.TAG === "Null");
  var childSchema = selfSchema.t._0;
  var ifCode = scope(b, (function (b) {
          return outputVar + "=" + use(b, childSchema, inputVar, path);
        }));
  var isAsync = childSchema.i;
  b.c = b.c + ("if(" + inputVar + "!==" + (
      isNull ? "null" : "void 0"
    ) + "){" + ifCode + "}else{" + outputVar + "=" + (
      isAsync ? "()=>Promise.resolve(void 0)" : "void 0"
    ) + "}");
  return outputVar;
}

function serializeOperationBuilder(b, selfSchema, path) {
  var inputVar = toVar(b, b.i);
  var outputVar = $$var(b);
  var isNull = (selfSchema.t.TAG === "Null");
  var childSchema = selfSchema.t._0;
  b.c = b.c + ("if(" + inputVar + "!==void 0){" + scope(b, (function (b) {
            var value = Caml_option.valFromOption;
            return outputVar + "=" + use(b, childSchema, "e[" + (b.e.push(value) - 1) + "](" + inputVar + ")", path);
          })) + "}else{" + outputVar + "=" + (
      isNull ? "null" : "void 0"
    ) + "}");
  return outputVar;
}

function maybeTypeFilter(schema, inlinedNoneValue) {
  var typeFilter = schema.f;
  if (typeFilter !== undefined) {
    return (function (inputVar) {
              return inputVar + "!==" + inlinedNoneValue + "&&(" + typeFilter(inputVar) + ")";
            });
  }
  
}

function factory$1(schema) {
  return {
          t: {
            TAG: "Option",
            _0: schema
          },
          n: containerName,
          p: parseOperationBuilder,
          s: serializeOperationBuilder,
          f: maybeTypeFilter(schema, "void 0"),
          i: 0,
          m: empty
        };
}

function getWithDefault(schema, $$default) {
  return {
          t: schema.t,
          n: schema.n,
          p: (function (b, param, path) {
              var input = b.i;
              return transform(b, use(b, schema, input, path), false, (function (b, input) {
                            var tmp;
                            tmp = $$default.TAG === "Value" ? "e[" + (b.e.push($$default._0) - 1) + "]" : "e[" + (b.e.push($$default._0) - 1) + "]()";
                            return input + "===void 0?" + tmp + ":" + input;
                          }));
            }),
          s: schema.s,
          f: schema.f,
          i: 0,
          m: set(schema.m, defaultMetadataId, $$default)
        };
}

function getOr(schema, defalutValue) {
  return getWithDefault(schema, {
              TAG: "Value",
              _0: defalutValue
            });
}

function getOrWith(schema, defalutCb) {
  return getWithDefault(schema, {
              TAG: "Callback",
              _0: defalutCb
            });
}

var $$Option = {
  defaultMetadataId: defaultMetadataId,
  $$default: $$default,
  parseOperationBuilder: parseOperationBuilder,
  serializeOperationBuilder: serializeOperationBuilder,
  maybeTypeFilter: maybeTypeFilter,
  factory: factory$1,
  getWithDefault: getWithDefault,
  getOr: getOr,
  getOrWith: getOrWith
};

function factory$2(schema) {
  return {
          t: {
            TAG: "Null",
            _0: schema
          },
          n: containerName,
          p: parseOperationBuilder,
          s: serializeOperationBuilder,
          f: maybeTypeFilter(schema, "null"),
          i: 0,
          m: empty
        };
}

var Null = {
  factory: factory$2
};

function nullable(schema) {
  return factory$1(factory$2(schema));
}

function typeFilter(inputVar) {
  return "!" + inputVar + "||" + inputVar + ".constructor!==Object";
}

function noopRefinement(_b, param, param$1, param$2) {
  
}

function makeParseOperationBuilder(itemDefinitions, itemDefinitionsSet, definition, inputRefinement, unknownKeysRefinement) {
  return function (b, selfSchema, path) {
    var inputVar = toVar(b, b.i);
    var registeredDefinitions = new Set();
    var asyncOutputVars = [];
    inputRefinement(b, selfSchema, inputVar, path);
    var prevCode = b.c;
    b.c = "";
    unknownKeysRefinement(b, selfSchema, inputVar, path);
    var unknownKeysRefinementCode = b.c;
    b.c = "";
    var definitionToOutput = function (definition, outputPath) {
      var kind = toKindWithSet(definition, itemDefinitionsSet);
      switch (kind) {
        case 0 :
            var isArray = Array.isArray(definition);
            var keys = Object.keys(definition);
            var codeRef = isArray ? "[" : "{";
            for(var idx = 0 ,idx_finish = keys.length; idx < idx_finish; ++idx){
              var key = keys[idx];
              var definition$1 = definition[key];
              var output = definitionToOutput(definition$1, outputPath + ("[" + JSON.stringify(key) + "]"));
              codeRef = codeRef + (
                isArray ? output : JSON.stringify(key) + ":" + output
              ) + ",";
            }
            return codeRef + (
                    isArray ? "]" : "}"
                  );
        case 1 :
            return "e[" + (b.e.push(definition) - 1) + "]";
        case 2 :
            registeredDefinitions.add(definition);
            var inputPath = definition.p;
            var schema = definition.s;
            var fieldOuputVar = useWithTypeFilter(b, schema, inputVar + inputPath, path + inputPath);
            var isAsyncField = schema.i;
            if (isAsyncField) {
              asyncOutputVars.push(fieldOuputVar);
            }
            return fieldOuputVar;
        
      }
    };
    var syncOutput = definitionToOutput(definition, "");
    var registeredFieldsCode = b.c;
    b.c = "";
    for(var idx = 0 ,idx_finish = itemDefinitions.length; idx < idx_finish; ++idx){
      var itemDefinition = itemDefinitions[idx];
      if (!registeredDefinitions.has(itemDefinition)) {
        var inputPath = itemDefinition.p;
        var schema = itemDefinition.s;
        var fieldOuputVar = useWithTypeFilter(b, schema, inputVar + inputPath, path + inputPath);
        var isAsyncField = schema.i;
        if (isAsyncField) {
          asyncOutputVars.push(fieldOuputVar);
        }
        
      }
      
    }
    var unregisteredFieldsCode = b.c;
    b.c = prevCode + unregisteredFieldsCode + registeredFieldsCode + unknownKeysRefinementCode;
    if (asyncOutputVars.length === 0) {
      return syncOutput;
    }
    var outputVar = $$var(b);
    b.c = b.c + (outputVar + "=()=>Promise.all([" + asyncOutputVars.map(function (asyncOutputVar) {
              return asyncOutputVar + "()";
            }).join(",") + "]).then(([" + asyncOutputVars.toString() + "])=>(" + syncOutput + "));");
    return outputVar;
  };
}

function make$5() {
  var fields = {};
  var fieldNames = [];
  var itemDefinitionsSet = new Set();
  var field = function (fieldName, schema) {
    var inlinedInputLocation = JSON.stringify(fieldName);
    if (fields[fieldName]) {
      throw new Error("[rescript-schema] " + ("The field " + inlinedInputLocation + " is defined multiple times. If you want to duplicate the field, use S.transform instead."));
    }
    var itemDefinition_p = "[" + inlinedInputLocation + "]";
    var itemDefinition = {
      s: schema,
      l: inlinedInputLocation,
      p: itemDefinition_p
    };
    fields[fieldName] = schema;
    fieldNames.push(fieldName);
    itemDefinitionsSet.add(itemDefinition);
    return itemDefinition;
  };
  var tag = function (tag$1, asValue) {
    field(tag$1, literal(asValue));
  };
  var fieldOr = function (fieldName, schema, or) {
    return field(fieldName, getOr(factory$1(schema), or));
  };
  return {
          n: fieldNames,
          h: fields,
          d: itemDefinitionsSet,
          field: field,
          fieldOr: fieldOr,
          tag: tag,
          f: field,
          o: fieldOr,
          t: tag
        };
}

var Ctx$1 = {
  make: make$5
};

function factory$3(definer) {
  var fields = {};
  var fieldNames = [];
  var itemDefinitionsSet = new Set();
  var field = function (fieldName, schema) {
    var inlinedInputLocation = JSON.stringify(fieldName);
    if (fields[fieldName]) {
      throw new Error("[rescript-schema] " + ("The field " + inlinedInputLocation + " is defined multiple times. If you want to duplicate the field, use S.transform instead."));
    }
    var itemDefinition_p = "[" + inlinedInputLocation + "]";
    var itemDefinition = {
      s: schema,
      l: inlinedInputLocation,
      p: itemDefinition_p
    };
    fields[fieldName] = schema;
    fieldNames.push(fieldName);
    itemDefinitionsSet.add(itemDefinition);
    return itemDefinition;
  };
  var tag = function (tag$1, asValue) {
    field(tag$1, literal(asValue));
  };
  var fieldOr = function (fieldName, schema, or) {
    return field(fieldName, getOr(factory$1(schema), or));
  };
  var ctx = {
    n: fieldNames,
    h: fields,
    d: itemDefinitionsSet,
    field: field,
    fieldOr: fieldOr,
    tag: tag,
    f: field,
    o: fieldOr,
    t: tag
  };
  var definition = definer(ctx);
  var itemDefinitionsSet$1 = itemDefinitionsSet;
  var fields$1 = fields;
  var fieldNames$1 = fieldNames;
  var itemDefinitions = Array.from(itemDefinitionsSet$1);
  return {
          t: {
            TAG: "Object",
            fields: fields$1,
            fieldNames: fieldNames$1,
            unknownKeys: "Strip"
          },
          n: (function () {
              return "Object({" + fieldNames$1.map(function (fieldName) {
                            var fieldSchema = fields$1[fieldName];
                            return JSON.stringify(fieldName) + ": " + fieldSchema.n();
                          }).join(", ") + "})";
            }),
          p: makeParseOperationBuilder(itemDefinitions, itemDefinitionsSet$1, definition, noopRefinement, (function (b, selfSchema, inputVar, path) {
                  var withUnknownKeysRefinement = selfSchema.t.unknownKeys === "Strict";
                  if (!withUnknownKeysRefinement) {
                    return ;
                  }
                  if (itemDefinitions.length !== 0) {
                    var keyVar = $$var(b);
                    b.c = b.c + ("for(" + keyVar + " in " + inputVar + "){if(");
                    for(var idx = 0 ,idx_finish = itemDefinitions.length; idx < idx_finish; ++idx){
                      var itemDefinition = itemDefinitions[idx];
                      if (idx !== 0) {
                        b.c = b.c + "&&";
                      }
                      b.c = b.c + (keyVar + "!==" + itemDefinition.l);
                    }
                    b.c = b.c + ("){" + raiseWithArg(b, path, (function (exccessFieldName) {
                              return {
                                      TAG: "ExcessField",
                                      _0: exccessFieldName
                                    };
                            }), keyVar) + "}}");
                    return ;
                  }
                  var keyVar$1 = $$var(b);
                  b.c = b.c + ("for(" + keyVar$1 + " in " + inputVar + "){" + raiseWithArg(b, path, (function (exccessFieldName) {
                            return {
                                    TAG: "ExcessField",
                                    _0: exccessFieldName
                                  };
                          }), keyVar$1) + "}");
                })),
          s: (function (b, param, path) {
              var inputVar = toVar(b, b.i);
              var fieldsCodeRef = {
                contents: ""
              };
              var registeredDefinitions = new Set();
              var prevCode = b.c;
              b.c = "";
              var definitionToOutput = function (definition, outputPath) {
                var kind = toKindWithSet(definition, itemDefinitionsSet$1);
                switch (kind) {
                  case 0 :
                      var keys = Object.keys(definition);
                      for(var idx = 0 ,idx_finish = keys.length; idx < idx_finish; ++idx){
                        var key = keys[idx];
                        var definition$1 = definition[key];
                        definitionToOutput(definition$1, outputPath + ("[" + JSON.stringify(key) + "]"));
                      }
                      return ;
                  case 1 :
                      b.c = "if(" + inputVar + outputPath + "!==" + ("e[" + (b.e.push(definition) - 1) + "]") + "){" + raiseWithArg(b, path + outputPath, (function (input) {
                              return {
                                      TAG: "InvalidLiteral",
                                      expected: classify(definition),
                                      received: input
                                    };
                            }), inputVar + outputPath) + "}" + b.c;
                      return ;
                  case 2 :
                      if (registeredDefinitions.has(definition)) {
                        return invalidOperation(b, path, "The field " + definition.l + " is registered multiple times. If you want to duplicate the field, use S.transform instead");
                      } else {
                        registeredDefinitions.add(definition);
                        fieldsCodeRef.contents = fieldsCodeRef.contents + (definition.l + ":" + use(b, definition.s, inputVar + outputPath, path + outputPath) + ",");
                        return ;
                      }
                  
                }
              };
              definitionToOutput(definition, "");
              b.c = prevCode + b.c;
              for(var idx = 0 ,idx_finish = itemDefinitions.length; idx < idx_finish; ++idx){
                var itemDefinition = itemDefinitions[idx];
                if (!registeredDefinitions.has(itemDefinition)) {
                  var inlinedInputLocation = itemDefinition.l;
                  var literal = toLiteral(itemDefinition.s);
                  if (literal !== undefined) {
                    var value$1 = value(literal);
                    fieldsCodeRef.contents = fieldsCodeRef.contents + (inlinedInputLocation + ":" + ("e[" + (b.e.push(value$1) - 1) + "]") + ",");
                  } else {
                    invalidOperation(b, path, "Can't create serializer. The " + inlinedInputLocation + " field is not registered and not a literal. Use S.transform instead");
                  }
                }
                
              }
              return "{" + fieldsCodeRef.contents + "}";
            }),
          f: typeFilter,
          i: 0,
          m: empty
        };
}

function strip(schema) {
  var match = schema.t;
  if (typeof match !== "object" || !(match.TAG === "Object" && match.unknownKeys !== "Strip")) {
    return schema;
  } else {
    return {
            t: {
              TAG: "Object",
              fields: match.fields,
              fieldNames: match.fieldNames,
              unknownKeys: "Strip"
            },
            n: schema.n,
            p: schema.p,
            s: schema.s,
            f: schema.f,
            i: 0,
            m: schema.m
          };
  }
}

function strict(schema) {
  var match = schema.t;
  if (typeof match !== "object" || !(match.TAG === "Object" && match.unknownKeys === "Strip")) {
    return schema;
  } else {
    return {
            t: {
              TAG: "Object",
              fields: match.fields,
              fieldNames: match.fieldNames,
              unknownKeys: "Strict"
            },
            n: schema.n,
            p: schema.p,
            s: schema.s,
            f: schema.f,
            i: 0,
            m: schema.m
          };
  }
}

var $$Object$1 = {
  typeFilter: typeFilter,
  noopRefinement: noopRefinement,
  makeParseOperationBuilder: makeParseOperationBuilder,
  Ctx: Ctx$1,
  factory: factory$3,
  strip: strip,
  strict: strict
};

function builder(b, selfSchema, path) {
  var input = b.i;
  b.c = b.c + raiseWithArg(b, path, (function (input) {
          return {
                  TAG: "InvalidType",
                  expected: selfSchema,
                  received: input
                };
        }), input) + ";";
  return input;
}

var schema = {
  t: "Never",
  n: primitiveName,
  p: builder,
  s: builder,
  f: undefined,
  i: 0,
  m: empty
};

var Never = {
  builder: builder,
  schema: schema
};

var schema$1 = {
  t: "Unknown",
  n: primitiveName,
  p: noop,
  s: noop,
  f: undefined,
  i: false,
  m: empty
};

var Unknown = {
  schema: schema$1
};

var metadataId = "rescript-schema:String.refinements";

var Refinement = {
  metadataId: metadataId
};

function refinements(schema) {
  var m = schema.m[metadataId];
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

var cuidRegex = /^c[^\s-]{8,}$/i;

var uuidRegex = /^([a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[a-f0-9]{4}-[a-f0-9]{12}|00000000-0000-0000-0000-000000000000)$/i;

var emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\])|(\[IPv6:(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))\])|([A-Za-z0-9]([A-Za-z0-9-]*[A-Za-z0-9])*(\.[A-Za-z]{2,})+))$/;

var datetimeRe = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;

function typeFilter$1(inputVar) {
  return "typeof " + inputVar + "!==\"string\"";
}

var schema$2 = makeWithNoopSerializer(primitiveName, "String", empty, noop, typeFilter$1);

function min(schema, length, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "String must be " + length + " or more characters long";
  return addRefinement(schema, metadataId, {
              kind: {
                TAG: "Min",
                length: length
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length<" + ("e[" + (b.e.push(length) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function max(schema, length, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "String must be " + length + " or fewer characters long";
  return addRefinement(schema, metadataId, {
              kind: {
                TAG: "Max",
                length: length
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length>" + ("e[" + (b.e.push(length) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function length(schema, length$1, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "String must be exactly " + length$1 + " characters long";
  return addRefinement(schema, metadataId, {
              kind: {
                TAG: "Length",
                length: length$1
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length!==" + ("e[" + (b.e.push(length$1) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function email(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid email address";
  return addRefinement(schema, metadataId, {
              kind: "Email",
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(!" + ("e[" + (b.e.push(emailRegex) - 1) + "]") + ".test(" + inputVar + ")){" + fail(b, message, path) + "}";
              }));
}

function uuid(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid UUID";
  return addRefinement(schema, metadataId, {
              kind: "Uuid",
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(!" + ("e[" + (b.e.push(uuidRegex) - 1) + "]") + ".test(" + inputVar + ")){" + fail(b, message, path) + "}";
              }));
}

function cuid(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid CUID";
  return addRefinement(schema, metadataId, {
              kind: "Cuid",
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(!" + ("e[" + (b.e.push(cuidRegex) - 1) + "]") + ".test(" + inputVar + ")){" + fail(b, message, path) + "}";
              }));
}

function url(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid url";
  return addRefinement(schema, metadataId, {
              kind: "Url",
              message: message
            }, (function (b, inputVar, param, path) {
                return "try{new URL(" + inputVar + ")}catch(_){" + fail(b, message, path) + "}";
              }));
}

function pattern(schema, re, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid";
  return addRefinement(schema, metadataId, {
              kind: {
                TAG: "Pattern",
                re: re
              },
              message: message
            }, (function (b, inputVar, param, path) {
                var reVar = $$var(b);
                return reVar + "=" + ("e[" + (b.e.push(re) - 1) + "]") + ";" + reVar + ".lastIndex=0;if(!" + reVar + ".test(" + inputVar + ")){" + fail(b, message, path) + "}";
              }));
}

function datetime(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid datetime string! Must be UTC";
  var refinement = {
    kind: "Datetime",
    message: message
  };
  var refinements = schema.m[metadataId];
  return transform$1(set$1(schema, metadataId, refinements !== undefined ? refinements.concat(refinement) : [refinement]), (function (s) {
                return {
                        p: (function (string) {
                            if (!datetimeRe.test(string)) {
                              s.fail(message, undefined);
                            }
                            return new Date(string);
                          }),
                        s: (function (date) {
                            return date.toISOString();
                          })
                      };
              }));
}

function trim(schema) {
  var transformer = function (string) {
    return string.trim();
  };
  return transform$1(schema, (function (param) {
                return {
                        p: transformer,
                        s: transformer
                      };
              }));
}

var $$String = {
  Refinement: Refinement,
  refinements: refinements,
  cuidRegex: cuidRegex,
  uuidRegex: uuidRegex,
  emailRegex: emailRegex,
  datetimeRe: datetimeRe,
  typeFilter: typeFilter$1,
  schema: schema$2,
  min: min,
  max: max,
  length: length,
  email: email,
  uuid: uuid,
  cuid: cuid,
  url: url,
  pattern: pattern,
  datetime: datetime,
  trim: trim
};

function factory$4(schema, spaceOpt) {
  var space = spaceOpt !== undefined ? spaceOpt : 0;
  try {
    validateJsonableSchema(schema, schema, true);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    getOrRethrow(exn);
    var message = "The schema " + schema.n() + " passed to S.jsonString is not compatible with JSON";
    throw new Error("[rescript-schema] " + message);
  }
  return {
          t: "String",
          n: primitiveName,
          p: (function (b, param, path) {
              var input = b.i;
              var jsonVar = $$var(b);
              b.c = b.c + ("try{" + jsonVar + "=JSON.parse(" + input + ")}catch(t){" + raiseWithArg(b, path, (function (message) {
                        return {
                                TAG: "OperationFailed",
                                _0: message
                              };
                      }), "t.message") + "}");
              return useWithTypeFilter(b, schema, jsonVar, path);
            }),
          s: (function (b, param, path) {
              var input = b.i;
              return "JSON.stringify(" + use(b, schema, input, path) + (
                      space > 0 ? ",null," + space : ""
                    ) + ")";
            }),
          f: typeFilter$1,
          i: 0,
          m: empty
        };
}

var JsonString = {
  factory: factory$4
};

function typeFilter$2(inputVar) {
  return "typeof " + inputVar + "!==\"boolean\"";
}

var schema$3 = makeWithNoopSerializer(primitiveName, "Bool", empty, noop, typeFilter$2);

var Bool$1 = {
  typeFilter: typeFilter$2,
  schema: schema$3
};

var metadataId$1 = "rescript-schema:Int.refinements";

var Refinement$1 = {
  metadataId: metadataId$1
};

function refinements$1(schema) {
  var m = schema.m[metadataId$1];
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

function typeFilter$3(inputVar) {
  return "typeof " + inputVar + "!==\"number\"||" + inputVar + ">2147483647||" + inputVar + "<-2147483648||" + inputVar + "%1!==0";
}

var schema$4 = makeWithNoopSerializer(primitiveName, "Int", empty, noop, typeFilter$3);

function min$1(schema, minValue, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be greater than or equal to " + minValue;
  return addRefinement(schema, metadataId$1, {
              kind: {
                TAG: "Min",
                value: minValue
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + "<" + ("e[" + (b.e.push(minValue) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function max$1(schema, maxValue, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be lower than or equal to " + maxValue;
  return addRefinement(schema, metadataId$1, {
              kind: {
                TAG: "Max",
                value: maxValue
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ">" + ("e[" + (b.e.push(maxValue) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function port(schema, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Invalid port";
  return addRefinement(schema, metadataId$1, {
              kind: "Port",
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + "<1||" + inputVar + ">65535){" + fail(b, message, path) + "}";
              }));
}

var Int$1 = {
  Refinement: Refinement$1,
  refinements: refinements$1,
  typeFilter: typeFilter$3,
  schema: schema$4,
  min: min$1,
  max: max$1,
  port: port
};

var metadataId$2 = "rescript-schema:Float.refinements";

var Refinement$2 = {
  metadataId: metadataId$2
};

function refinements$2(schema) {
  var m = schema.m[metadataId$2];
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

function typeFilter$4(inputVar) {
  return "typeof " + inputVar + "!==\"number\"||Number.isNaN(" + inputVar + ")";
}

var schema$5 = makeWithNoopSerializer(primitiveName, "Float", empty, noop, typeFilter$4);

function min$2(schema, minValue, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be greater than or equal to " + minValue;
  return addRefinement(schema, metadataId$2, {
              kind: {
                TAG: "Min",
                value: minValue
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + "<" + ("e[" + (b.e.push(minValue) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function max$2(schema, maxValue, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Number must be lower than or equal to " + maxValue;
  return addRefinement(schema, metadataId$2, {
              kind: {
                TAG: "Max",
                value: maxValue
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ">" + ("e[" + (b.e.push(maxValue) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

var Float$2 = {
  Refinement: Refinement$2,
  refinements: refinements$2,
  typeFilter: typeFilter$4,
  schema: schema$5,
  min: min$2,
  max: max$2
};

var metadataId$3 = "rescript-schema:Array.refinements";

var Refinement$3 = {
  metadataId: metadataId$3
};

function refinements$3(schema) {
  var m = schema.m[metadataId$3];
  if (m !== undefined) {
    return m;
  } else {
    return [];
  }
}

function typeFilter$5(inputVar) {
  return "!Array.isArray(" + inputVar + ")";
}

function factory$5(schema) {
  return {
          t: {
            TAG: "Array",
            _0: schema
          },
          n: containerName,
          p: (function (b, param, path) {
              var inputVar = toVar(b, b.i);
              var iteratorVar = varWithoutAllocation(b);
              var outputVar = $$var(b);
              b.c = b.c + (outputVar + "=[];for(let " + iteratorVar + "=0;" + iteratorVar + "<" + inputVar + ".length;++" + iteratorVar + "){" + scope(b, (function (b) {
                        var itemOutputVar = withPathPrepend(b, path, iteratorVar, (function (b, path) {
                                return useWithTypeFilter(b, schema, inputVar + "[" + iteratorVar + "]", path);
                              }));
                        return outputVar + ".push(" + itemOutputVar + ")";
                      })) + "}");
              var isAsync = schema.i;
              if (!isAsync) {
                return outputVar;
              }
              var asyncOutputVar = $$var(b);
              b.c = b.c + (asyncOutputVar + "=()=>Promise.all(" + outputVar + ".map(t=>t()));");
              return asyncOutputVar;
            }),
          s: (function (b, param, path) {
              if (schema.s === noop) {
                return b.i;
              }
              var inputVar = toVar(b, b.i);
              var iteratorVar = varWithoutAllocation(b);
              var outputVar = $$var(b);
              b.c = b.c + (outputVar + "=[];for(let " + iteratorVar + "=0;" + iteratorVar + "<" + inputVar + ".length;++" + iteratorVar + "){" + scope(b, (function (b) {
                        var itemOutputVar = withPathPrepend(b, path, iteratorVar, (function (b, path) {
                                return use(b, schema, inputVar + "[" + iteratorVar + "]", path);
                              }));
                        return outputVar + ".push(" + itemOutputVar + ")";
                      })) + "}");
              return outputVar;
            }),
          f: typeFilter$5,
          i: 0,
          m: empty
        };
}

function min$3(schema, length, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Array must be " + length + " or more items long";
  return addRefinement(schema, metadataId$3, {
              kind: {
                TAG: "Min",
                length: length
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length<" + ("e[" + (b.e.push(length) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function max$3(schema, length, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Array must be " + length + " or fewer items long";
  return addRefinement(schema, metadataId$3, {
              kind: {
                TAG: "Max",
                length: length
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length>" + ("e[" + (b.e.push(length) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

function length$1(schema, length$2, maybeMessage) {
  var message = maybeMessage !== undefined ? maybeMessage : "Array must be exactly " + length$2 + " items long";
  return addRefinement(schema, metadataId$3, {
              kind: {
                TAG: "Length",
                length: length$2
              },
              message: message
            }, (function (b, inputVar, param, path) {
                return "if(" + inputVar + ".length!==" + ("e[" + (b.e.push(length$2) - 1) + "]") + "){" + fail(b, message, path) + "}";
              }));
}

var $$Array$1 = {
  Refinement: Refinement$3,
  refinements: refinements$3,
  typeFilter: typeFilter$5,
  factory: factory$5,
  min: min$3,
  max: max$3,
  length: length$1
};

function factory$6(schema) {
  return {
          t: {
            TAG: "Dict",
            _0: schema
          },
          n: containerName,
          p: (function (b, param, path) {
              var inputVar = toVar(b, b.i);
              var keyVar = varWithoutAllocation(b);
              var outputVar = $$var(b);
              b.c = b.c + (outputVar + "={};for(let " + keyVar + " in " + inputVar + "){" + scope(b, (function (b) {
                        var itemOutputVar = withPathPrepend(b, path, keyVar, (function (b, path) {
                                return useWithTypeFilter(b, schema, inputVar + "[" + keyVar + "]", path);
                              }));
                        return outputVar + "[" + keyVar + "]=" + itemOutputVar;
                      })) + "}");
              var isAsync = schema.i;
              if (!isAsync) {
                return outputVar;
              }
              var resolveVar = varWithoutAllocation(b);
              var rejectVar = varWithoutAllocation(b);
              var asyncParseResultVar = varWithoutAllocation(b);
              var counterVar = varWithoutAllocation(b);
              var asyncOutputVar = $$var(b);
              b.c = b.c + (asyncOutputVar + "=()=>new Promise((" + resolveVar + "," + rejectVar + ")=>{let " + counterVar + "=Object.keys(" + outputVar + ").length;for(let " + keyVar + " in " + outputVar + "){" + outputVar + "[" + keyVar + "]().then(" + asyncParseResultVar + "=>{" + outputVar + "[" + keyVar + "]=" + asyncParseResultVar + ";if(" + counterVar + "--===1){" + resolveVar + "(" + outputVar + ")}}," + rejectVar + ")}});");
              return asyncOutputVar;
            }),
          s: (function (b, param, path) {
              if (schema.s === noop) {
                return b.i;
              }
              var inputVar = toVar(b, b.i);
              var keyVar = varWithoutAllocation(b);
              var outputVar = $$var(b);
              b.c = b.c + (outputVar + "={};for(let " + keyVar + " in " + inputVar + "){" + scope(b, (function (b) {
                        var itemOutputVar = withPathPrepend(b, path, keyVar, (function (b, path) {
                                return use(b, schema, inputVar + "[" + keyVar + "]", path);
                              }));
                        return outputVar + "[" + keyVar + "]=" + itemOutputVar;
                      })) + "}");
              return outputVar;
            }),
          f: typeFilter,
          i: 0,
          m: empty
        };
}

var Dict$1 = {
  factory: factory$6
};

function make$6() {
  var schemas = [];
  var itemDefinitionsSet = new Set();
  var item = function (idx, schema) {
    var inlinedInputLocation = "\"" + idx + "\"";
    if (schemas[idx]) {
      throw new Error("[rescript-schema] " + ("The item " + inlinedInputLocation + " is defined multiple times. If you want to duplicate the item, use S.transform instead."));
    }
    var itemDefinition_p = "[" + inlinedInputLocation + "]";
    var itemDefinition = {
      s: schema,
      l: inlinedInputLocation,
      p: itemDefinition_p
    };
    schemas[idx] = schema;
    itemDefinitionsSet.add(itemDefinition);
    return itemDefinition;
  };
  var tag = function (idx, asValue) {
    item(idx, literal(asValue));
  };
  return {
          s: schemas,
          d: itemDefinitionsSet,
          item: item,
          tag: tag,
          i: item,
          t: tag
        };
}

var Ctx$2 = {
  make: make$6
};

function factory$7(definer) {
  var schemas = [];
  var itemDefinitionsSet = new Set();
  var item = function (idx, schema) {
    var inlinedInputLocation = "\"" + idx + "\"";
    if (schemas[idx]) {
      throw new Error("[rescript-schema] " + ("The item " + inlinedInputLocation + " is defined multiple times. If you want to duplicate the item, use S.transform instead."));
    }
    var itemDefinition_p = "[" + inlinedInputLocation + "]";
    var itemDefinition = {
      s: schema,
      l: inlinedInputLocation,
      p: itemDefinition_p
    };
    schemas[idx] = schema;
    itemDefinitionsSet.add(itemDefinition);
    return itemDefinition;
  };
  var tag = function (idx, asValue) {
    item(idx, literal(asValue));
  };
  var ctx = {
    s: schemas,
    d: itemDefinitionsSet,
    item: item,
    tag: tag,
    i: item,
    t: tag
  };
  var definition = definer(ctx);
  var itemDefinitionsSet$1 = itemDefinitionsSet;
  var schemas$1 = schemas;
  var length = schemas$1.length;
  for(var idx = 0; idx < length; ++idx){
    if (!schemas$1[idx]) {
      var inlinedInputLocation = "\"" + idx + "\"";
      var itemDefinition_p = "[" + inlinedInputLocation + "]";
      var itemDefinition = {
        s: unit,
        l: inlinedInputLocation,
        p: itemDefinition_p
      };
      schemas$1[idx] = unit;
      itemDefinitionsSet$1.add(itemDefinition);
    }
    
  }
  var itemDefinitions = Array.from(itemDefinitionsSet$1);
  return {
          t: {
            TAG: "Tuple",
            _0: schemas$1
          },
          n: (function () {
              return "Tuple(" + schemas$1.map(function (s) {
                            return s.n();
                          }).join(", ") + ")";
            }),
          p: makeParseOperationBuilder(itemDefinitions, itemDefinitionsSet$1, definition, (function (b, param, inputVar, path) {
                  b.c = b.c + ("if(" + inputVar + ".length!==" + length + "){" + raiseWithArg(b, path, (function (numberOfInputItems) {
                            return {
                                    TAG: "InvalidTupleSize",
                                    expected: length,
                                    received: numberOfInputItems
                                  };
                          }), inputVar + ".length") + "}");
                }), noopRefinement),
          s: (function (b, param, path) {
              var inputVar = toVar(b, b.i);
              var outputVar = $$var(b);
              var registeredDefinitions = new Set();
              b.c = b.c + (outputVar + "=[];");
              var prevCode = b.c;
              b.c = "";
              var definitionToOutput = function (definition, outputPath) {
                var kind = toKindWithSet(definition, itemDefinitionsSet$1);
                switch (kind) {
                  case 0 :
                      var keys = Object.keys(definition);
                      for(var idx = 0 ,idx_finish = keys.length; idx < idx_finish; ++idx){
                        var key = keys[idx];
                        var definition$1 = definition[key];
                        definitionToOutput(definition$1, outputPath + ("[" + JSON.stringify(key) + "]"));
                      }
                      return ;
                  case 1 :
                      b.c = "if(" + inputVar + outputPath + "!==" + ("e[" + (b.e.push(definition) - 1) + "]") + "){" + raiseWithArg(b, path + outputPath, (function (input) {
                              return {
                                      TAG: "InvalidLiteral",
                                      expected: classify(definition),
                                      received: input
                                    };
                            }), inputVar + outputPath) + "}" + b.c;
                      return ;
                  case 2 :
                      if (registeredDefinitions.has(definition)) {
                        return invalidOperation(b, path, "The item " + definition.l + " is registered multiple times. If you want to duplicate the item, use S.transform instead");
                      }
                      registeredDefinitions.add(definition);
                      var fieldOuputVar = use(b, definition.s, inputVar + outputPath, path + outputPath);
                      b.c = b.c + (outputVar + definition.p + "=" + fieldOuputVar + ";");
                      return ;
                  
                }
              };
              definitionToOutput(definition, "");
              b.c = prevCode + b.c;
              for(var idx = 0 ,idx_finish = itemDefinitions.length; idx < idx_finish; ++idx){
                var itemDefinition = itemDefinitions[idx];
                if (!registeredDefinitions.has(itemDefinition)) {
                  var literal = toLiteral(itemDefinition.s);
                  if (literal !== undefined) {
                    var value$1 = value(literal);
                    b.c = b.c + (outputVar + itemDefinition.p + "=" + ("e[" + (b.e.push(value$1) - 1) + "]") + ";");
                  } else {
                    invalidOperation(b, path, "Can't create serializer. The " + itemDefinition.l + " item is not registered and not a literal. Use S.transform instead");
                  }
                }
                
              }
              return outputVar;
            }),
          f: typeFilter$5,
          i: 0,
          m: empty
        };
}

var Tuple = {
  Ctx: Ctx$2,
  factory: factory$7
};

function factory$8(schemas) {
  if (schemas.length < 2) {
    throw new Error("[rescript-schema] A Union schema factory require at least two schemas.");
  }
  return {
          t: {
            TAG: "Union",
            _0: schemas
          },
          n: (function () {
              return "Union(" + schemas.map(function (s) {
                            return s.n();
                          }).join(", ") + ")";
            }),
          p: (function (b, selfSchema, path) {
              var inputVar = toVar(b, b.i);
              var schemas = selfSchema.t._0;
              var isAsyncRef = false;
              var itemsCode = [];
              var itemsOutputVar = [];
              var prevCode = b.c;
              for(var idx = 0 ,idx_finish = schemas.length; idx < idx_finish; ++idx){
                var schema = schemas[idx];
                b.c = "";
                var itemOutputVar = withBuildErrorInline(b, (function () {
                        return useWithTypeFilter(b, schema, inputVar, "");
                      }));
                var isAsyncItem = schema.i;
                if (isAsyncItem) {
                  isAsyncRef = true;
                }
                itemsOutputVar.push(itemOutputVar);
                itemsCode.push(b.c);
              }
              b.c = prevCode;
              var isAsync = isAsyncRef;
              var outputVar = $$var(b);
              var codeEndRef = "";
              var errorCodeRef = "";
              for(var idx$1 = 0 ,idx_finish$1 = schemas.length; idx$1 < idx_finish$1; ++idx$1){
                var schema$1 = schemas[idx$1];
                var code = itemsCode[idx$1];
                var itemOutputVar$1 = itemsOutputVar[idx$1];
                var isAsyncItem$1 = schema$1.i;
                var errorVar = varWithoutAllocation(b);
                var errorCode = isAsync ? (
                    isAsyncItem$1 ? errorVar + "===" + itemOutputVar$1 + "?" + errorVar + "():" : ""
                  ) + ("Promise.reject(" + errorVar + ")") : errorVar;
                errorCodeRef = idx$1 === 0 ? errorCode : errorCodeRef + "," + errorCode;
                b.c = b.c + ("try{" + code + (
                    isAsyncItem$1 ? "throw " + itemOutputVar$1 : (
                        isAsync ? outputVar + "=()=>Promise.resolve(" + itemOutputVar$1 + ")" : outputVar + "=" + itemOutputVar$1
                      )
                  ) + "}catch(" + errorVar + "){if(" + (errorVar + "&&" + errorVar + ".s===s") + (
                    isAsyncItem$1 ? "||" + errorVar + "===" + itemOutputVar$1 : ""
                  ) + "){");
                codeEndRef = "}else{throw " + errorVar + "}}" + codeEndRef;
              }
              if (isAsync) {
                b.c = b.c + (outputVar + "=()=>Promise.any([" + errorCodeRef + "]).catch(t=>{" + raiseWithArg(b, path, (function (internalErrors) {
                          return {
                                  TAG: "InvalidUnion",
                                  _0: internalErrors
                                };
                        }), "t.errors") + "})") + codeEndRef;
                return outputVar;
              } else {
                b.c = b.c + raiseWithArg(b, path, (function (internalErrors) {
                        return {
                                TAG: "InvalidUnion",
                                _0: internalErrors
                              };
                      }), "[" + errorCodeRef + "]") + codeEndRef;
                return outputVar;
              }
            }),
          s: (function (b, selfSchema, path) {
              var inputVar = toVar(b, b.i);
              var schemas = selfSchema.t._0;
              var outputVar = $$var(b);
              var codeEndRef = "";
              var errorVarsRef = "";
              for(var idx = 0 ,idx_finish = schemas.length; idx < idx_finish; ++idx){
                var itemSchema = schemas[idx];
                var errorVar = varWithoutAllocation(b);
                errorVarsRef = errorVarsRef + errorVar + ",";
                b.c = b.c + ("try{" + scope(b, (function(itemSchema){
                      return function (b) {
                        var itemOutput = withBuildErrorInline(b, (function () {
                                return use(b, itemSchema, inputVar, "");
                              }));
                        var typeFilter = itemSchema.f;
                        var itemOutput$1;
                        if (typeFilter !== undefined) {
                          var itemOutputVar = toVar(b, itemOutput);
                          b.c = b.c + typeFilterCode(b, typeFilter, itemSchema, itemOutputVar, "");
                          itemOutput$1 = itemOutputVar;
                        } else {
                          itemOutput$1 = itemOutput;
                        }
                        return outputVar + "=" + itemOutput$1;
                      }
                      }(itemSchema))) + "}catch(" + errorVar + "){if(" + (errorVar + "&&" + errorVar + ".s===s") + "){");
                codeEndRef = "}else{throw " + errorVar + "}}" + codeEndRef;
              }
              b.c = b.c + raiseWithArg(b, path, (function (internalErrors) {
                      return {
                              TAG: "InvalidUnion",
                              _0: internalErrors
                            };
                    }), "[" + errorVarsRef + "]") + codeEndRef;
              return outputVar;
            }),
          f: undefined,
          i: 0,
          m: empty
        };
}

var Union = {
  factory: factory$8
};

function list(schema) {
  return transform$1(factory$5(schema), (function (param) {
                return {
                        p: Belt_List.fromArray,
                        s: Belt_List.toArray
                      };
              }));
}

var json = makeWithNoopSerializer(primitiveName, "JSON", empty, (function (b, selfSchema, path) {
        var parse = function (input, pathOpt) {
          var path$1 = pathOpt !== undefined ? pathOpt : path;
          var match = typeof input;
          if (match === "string" || match === "boolean") {
            return input;
          }
          if (match === "object") {
            if (input === null) {
              return input;
            }
            if (Array.isArray(input)) {
              var output = [];
              for(var idx = 0 ,idx_finish = input.length; idx < idx_finish; ++idx){
                var inputItem = input[idx];
                var $$location = idx.toString();
                output.push(parse(inputItem, path$1 + ("[" + JSON.stringify($$location) + "]")));
              }
              return output;
            }
            var keys = Object.keys(input);
            var output$1 = {};
            for(var idx$1 = 0 ,idx_finish$1 = keys.length; idx$1 < idx_finish$1; ++idx$1){
              var key = keys[idx$1];
              var field = input[key];
              output$1[key] = parse(field, path$1 + ("[" + JSON.stringify(key) + "]"));
            }
            return output$1;
          }
          if (match === "number") {
            if (!Number.isNaN(input)) {
              return input;
            }
            throw new RescriptSchemaError({
                      TAG: "InvalidType",
                      expected: selfSchema,
                      received: input
                    }, "Parsing", path$1);
          }
          throw new RescriptSchemaError({
                    TAG: "InvalidType",
                    expected: selfSchema,
                    received: input
                  }, "Parsing", path$1);
        };
        var input = b.i;
        return "e[" + (b.e.push(parse) - 1) + "](" + input + ")";
      }), undefined);

function $$catch(schema, getFallbackValue) {
  return {
          t: schema.t,
          n: schema.n,
          p: (function (b, selfSchema, path) {
              var inputVar = toVar(b, b.i);
              return withCatch(b, (function (b, errorVar) {
                            return "e[" + (b.e.push(function (input, internalError) {
                                          return getFallbackValue({
                                                      e: internalError,
                                                      i: input,
                                                      s: selfSchema,
                                                      f: (function (message, customPathOpt) {
                                                          var customPath = customPathOpt !== undefined ? customPathOpt : "";
                                                          throw new RescriptSchemaError({
                                                                    TAG: "OperationFailed",
                                                                    _0: message
                                                                  }, b.o, path + customPath);
                                                        }),
                                                      w: (function (error) {
                                                          throw new RescriptSchemaError(error.code, b.o, path + error.path);
                                                        })
                                                    });
                                        }) - 1) + "](" + inputVar + "," + errorVar + ")";
                          }), (function (b) {
                            return useWithTypeFilter(b, schema, inputVar, path);
                          }));
            }),
          s: schema.s,
          f: undefined,
          i: 0,
          m: schema.m
        };
}

var deprecationMetadataId = "rescript-schema:deprecation";

function deprecate(schema, message) {
  return set$1(schema, deprecationMetadataId, message);
}

function deprecation(schema) {
  return schema.m[deprecationMetadataId];
}

var descriptionMetadataId = "rescript-schema:description";

function describe(schema, description) {
  return set$1(schema, descriptionMetadataId, description);
}

function description(schema) {
  return schema.m[descriptionMetadataId];
}

function definitionToSchema(definition, embededSet) {
  var kind = toKindWithSet(definition, embededSet);
  switch (kind) {
    case 0 :
        if (Array.isArray(definition)) {
          return factory$7(function (s) {
                      for(var idx = 0 ,idx_finish = definition.length; idx < idx_finish; ++idx){
                        var definition$1 = definition[idx];
                        definition[idx] = s.i(idx, definitionToSchema(definition$1, embededSet));
                      }
                      return definition;
                    });
        } else {
          return factory$3(function (s) {
                      var keys = Object.keys(definition);
                      for(var idx = 0 ,idx_finish = keys.length; idx < idx_finish; ++idx){
                        var key = keys[idx];
                        var definition$1 = definition[key];
                        definition[key] = s.f(key, definitionToSchema(definition$1, embededSet));
                      }
                      return definition;
                    });
        }
    case 1 :
        return literal(definition);
    case 2 :
        return definition;
    
  }
}

function factory$9(definer) {
  var embededSet = new Set();
  var matches = function (schema) {
    embededSet.add(schema);
    return schema;
  };
  var ctx = {
    matches: matches
  };
  var definition = definer(ctx);
  return definitionToSchema(definition, embededSet);
}

var Schema = {
  definitionToSchema: definitionToSchema,
  factory: factory$9
};

var $$class = RescriptSchemaError;

function make$7(prim0, prim1, prim2) {
  return new RescriptSchemaError(prim0, prim1, prim2);
}

function raise$1(error) {
  throw error;
}

function reason(error, nestedLevelOpt) {
  var nestedLevel = nestedLevelOpt !== undefined ? nestedLevelOpt : 0;
  var reason$1 = error.code;
  if (typeof reason$1 !== "object") {
    return "Encountered unexpected asynchronous transform or refine. Use S.parseAsyncWith instead of S.parseWith";
  }
  switch (reason$1.TAG) {
    case "OperationFailed" :
        return reason$1._0;
    case "InvalidOperation" :
        return reason$1.description;
    case "InvalidType" :
        return "Expected " + reason$1.expected.n() + ", received " + toText(classify(reason$1.received));
    case "InvalidLiteral" :
        return "Expected " + toText(reason$1.expected) + ", received " + toText(classify(reason$1.received));
    case "InvalidTupleSize" :
        return "Expected Tuple with " + reason$1.expected + " items, received " + reason$1.received;
    case "ExcessField" :
        return "Encountered disallowed excess key " + JSON.stringify(reason$1._0) + " on an object. Use Deprecated to ignore a specific field, or S.Object.strip to ignore excess keys completely";
    case "InvalidUnion" :
        var lineBreak = "\n" + " ".repeat((nestedLevel << 1));
        var array = reason$1._0.map(function (error) {
              var reason$2 = reason(error, nestedLevel + 1);
              var nonEmptyPath = error.path;
              var $$location = nonEmptyPath === "" ? "" : "Failed at " + nonEmptyPath + ". ";
              return "- " + $$location + reason$2;
            });
        var reasons = Array.from(new Set(array));
        return "Invalid union with following errors" + lineBreak + reasons.join(lineBreak);
    case "InvalidJsonStruct" :
        return "The schema " + reason$1._0.n() + " is not compatible with JSON";
    
  }
}

function message(error) {
  var match = error.operation;
  var operation;
  operation = match === "Parsing" ? "parsing" : "serializing";
  var nonEmptyPath = error.path;
  var pathText = nonEmptyPath === "" ? "root" : nonEmptyPath;
  return "Failed " + operation + " at " + pathText + ". Reason: " + reason(error);
}

var $$Error$1 = {
  $$class: $$class,
  make: make$7,
  raise: raise$1,
  reason: reason,
  message: message
};

function internalInline(schema, maybeVariant, param) {
  var metadataMap = Object.assign({}, schema.m);
  var literal = schema.t;
  var inlinedSchema;
  if (typeof literal !== "object") {
    switch (literal) {
      case "Never" :
          inlinedSchema = "S.never";
          break;
      case "Unknown" :
          inlinedSchema = "S.unknown";
          break;
      case "String" :
          inlinedSchema = "S.string";
          break;
      case "Int" :
          inlinedSchema = "S.int";
          break;
      case "Float" :
          inlinedSchema = "S.float";
          break;
      case "Bool" :
          inlinedSchema = "S.bool";
          break;
      case "JSON" :
          inlinedSchema = "S.json";
          break;
      
    }
  } else {
    switch (literal.TAG) {
      case "Literal" :
          inlinedSchema = "S.literal(%raw(\`" + toText(literal._0) + "\`))";
          break;
      case "Option" :
          inlinedSchema = "S.option(" + internalInline(literal._0, undefined, undefined) + ")";
          break;
      case "Null" :
          inlinedSchema = "S.null(" + internalInline(literal._0, undefined, undefined) + ")";
          break;
      case "Array" :
          inlinedSchema = "S.array(" + internalInline(literal._0, undefined, undefined) + ")";
          break;
      case "Object" :
          var fieldNames = literal.fieldNames;
          var fields = literal.fields;
          inlinedSchema = fieldNames.length !== 0 ? "S.object(s =>\n  {\n    " + fieldNames.map(function (fieldName) {
                    return JSON.stringify(fieldName) + ": s.field(" + JSON.stringify(fieldName) + ", " + internalInline(fields[fieldName], undefined, undefined) + ")";
                  }).join(",\n    ") + ",\n  }\n)" : "S.object(_ => ())";
          break;
      case "Tuple" :
          var tupleSchemas = literal._0;
          var exit = 0;
          var len = tupleSchemas.length;
          if (len >= 4) {
            exit = 1;
          } else {
            switch (len) {
              case 0 :
                  exit = 1;
                  break;
              case 1 :
                  var s1 = tupleSchemas[0];
                  inlinedSchema = "S.tuple1(" + internalInline(s1, undefined, undefined) + ")";
                  break;
              case 2 :
                  var s1$1 = tupleSchemas[0];
                  var s2 = tupleSchemas[1];
                  inlinedSchema = "S.tuple2(" + internalInline(s1$1, undefined, undefined) + ", " + internalInline(s2, undefined, undefined) + ")";
                  break;
              case 3 :
                  var s1$2 = tupleSchemas[0];
                  var s2$1 = tupleSchemas[1];
                  var s3 = tupleSchemas[2];
                  inlinedSchema = "S.tuple3(" + internalInline(s1$2, undefined, undefined) + ", " + internalInline(s2$1, undefined, undefined) + ", " + internalInline(s3, undefined, undefined) + ")";
                  break;
              
            }
          }
          if (exit === 1) {
            inlinedSchema = "S.tuple(s => (" + tupleSchemas.map(function (s, idx) {
                    return "s.item(" + idx + ", " + internalInline(s, undefined, undefined) + ")";
                  }).join(", ") + "))";
          }
          break;
      case "Union" :
          var variantNamesCounter = {};
          inlinedSchema = "S.union([" + literal._0.map(function (s) {
                  var variantName = s.n();
                  var n = Js_dict.get(variantNamesCounter, variantName);
                  var numberOfVariantNames = n !== undefined ? n : 0;
                  variantNamesCounter[variantName] = numberOfVariantNames + 1;
                  var variantName$1 = numberOfVariantNames !== 0 ? variantName + (numberOfVariantNames + 1) : variantName;
                  var inlinedVariant = "#" + JSON.stringify(variantName$1);
                  return internalInline(s, inlinedVariant, undefined);
                }).join(", ") + "])";
          break;
      case "Dict" :
          inlinedSchema = "S.dict(" + internalInline(literal._0, undefined, undefined) + ")";
          break;
      
    }
  }
  var $$default = schema.m[defaultMetadataId];
  var inlinedSchema$1;
  if ($$default !== undefined) {
    Js_dict.unsafeDeleteKey(metadataMap, defaultMetadataId);
    if ($$default.TAG === "Value") {
      var defaultValue = $$default._0;
      inlinedSchema$1 = inlinedSchema + ("->S.Option.getOr(%raw(\`" + (
          defaultValue === (void 0) ? "undefined" : JSON.stringify(defaultValue)
        ) + "\`))");
    } else {
      var any = $$default._0();
      inlinedSchema$1 = inlinedSchema + ("->S.Option.getOrWith(() => %raw(\`" + (
          any === (void 0) ? "undefined" : JSON.stringify(any)
        ) + "\`))");
    }
  } else {
    inlinedSchema$1 = inlinedSchema;
  }
  var message = deprecation(schema);
  var inlinedSchema$2 = message !== undefined ? (Js_dict.unsafeDeleteKey(metadataMap, deprecationMetadataId), inlinedSchema$1 + ("->S.deprecate(" + JSON.stringify(message) + ")")) : inlinedSchema$1;
  var message$1 = description(schema);
  var inlinedSchema$3 = message$1 !== undefined ? (Js_dict.unsafeDeleteKey(metadataMap, descriptionMetadataId), inlinedSchema$2 + ("->S.describe(" + (
          message$1 === (void 0) ? "undefined" : JSON.stringify(message$1)
        ) + ")")) : inlinedSchema$2;
  var match = schema.t;
  var inlinedSchema$4;
  inlinedSchema$4 = typeof match !== "object" || !(match.TAG === "Object" && match.unknownKeys !== "Strip") ? inlinedSchema$3 : inlinedSchema$3 + "->S.Object.strict";
  var match$1 = schema.t;
  var inlinedSchema$5;
  var exit$1 = 0;
  if (typeof match$1 !== "object") {
    switch (match$1) {
      case "String" :
          exit$1 = 1;
          break;
      case "Int" :
          var refinements$4 = refinements$1(schema);
          if (refinements$4.length !== 0) {
            Js_dict.unsafeDeleteKey(metadataMap, metadataId$1);
            inlinedSchema$5 = inlinedSchema$4 + refinements$4.map(function (refinement) {
                    var match = refinement.kind;
                    if (typeof match !== "object") {
                      return "->S.Int.port(~message=" + JSON.stringify(refinement.message) + ")";
                    } else if (match.TAG === "Min") {
                      return "->S.Int.min(" + match.value + ", ~message=" + JSON.stringify(refinement.message) + ")";
                    } else {
                      return "->S.Int.max(" + match.value + ", ~message=" + JSON.stringify(refinement.message) + ")";
                    }
                  }).join("");
          } else {
            inlinedSchema$5 = inlinedSchema$4;
          }
          break;
      case "Float" :
          var refinements$5 = refinements$2(schema);
          if (refinements$5.length !== 0) {
            Js_dict.unsafeDeleteKey(metadataMap, metadataId$2);
            inlinedSchema$5 = inlinedSchema$4 + refinements$5.map(function (refinement) {
                    var match = refinement.kind;
                    if (match.TAG === "Min") {
                      var value = match.value;
                      return "->S.Float.min(" + (value.toString() + (
                                value % 1 === 0 ? "." : ""
                              )) + ", ~message=" + JSON.stringify(refinement.message) + ")";
                    }
                    var value$1 = match.value;
                    return "->S.Float.max(" + (value$1.toString() + (
                              value$1 % 1 === 0 ? "." : ""
                            )) + ", ~message=" + JSON.stringify(refinement.message) + ")";
                  }).join("");
          } else {
            inlinedSchema$5 = inlinedSchema$4;
          }
          break;
      default:
        inlinedSchema$5 = inlinedSchema$4;
    }
  } else {
    switch (match$1.TAG) {
      case "Literal" :
          var tmp = match$1._0;
          if (typeof tmp !== "object" || tmp.TAG !== "String") {
            inlinedSchema$5 = inlinedSchema$4;
          } else {
            exit$1 = 1;
          }
          break;
      case "Array" :
          var refinements$6 = refinements$3(schema);
          if (refinements$6.length !== 0) {
            Js_dict.unsafeDeleteKey(metadataMap, metadataId$3);
            inlinedSchema$5 = inlinedSchema$4 + refinements$6.map(function (refinement) {
                    var match = refinement.kind;
                    switch (match.TAG) {
                      case "Min" :
                          return "->S.Array.min(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                      case "Max" :
                          return "->S.Array.max(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                      case "Length" :
                          return "->S.Array.length(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                      
                    }
                  }).join("");
          } else {
            inlinedSchema$5 = inlinedSchema$4;
          }
          break;
      default:
        inlinedSchema$5 = inlinedSchema$4;
    }
  }
  if (exit$1 === 1) {
    var refinements$7 = refinements(schema);
    if (refinements$7.length !== 0) {
      Js_dict.unsafeDeleteKey(metadataMap, metadataId);
      inlinedSchema$5 = inlinedSchema$4 + refinements$7.map(function (refinement) {
              var match = refinement.kind;
              if (typeof match !== "object") {
                switch (match) {
                  case "Email" :
                      return "->S.String.email(~message=" + JSON.stringify(refinement.message) + ")";
                  case "Uuid" :
                      return "->S.String.uuid(~message=" + JSON.stringify(refinement.message) + ")";
                  case "Cuid" :
                      return "->S.String.cuid(~message=" + JSON.stringify(refinement.message) + ")";
                  case "Url" :
                      return "->S.String.url(~message=" + JSON.stringify(refinement.message) + ")";
                  case "Datetime" :
                      return "->S.String.datetime(~message=" + JSON.stringify(refinement.message) + ")";
                  
                }
              } else {
                switch (match.TAG) {
                  case "Min" :
                      return "->S.String.min(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                  case "Max" :
                      return "->S.String.max(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                  case "Length" :
                      return "->S.String.length(" + match.length + ", ~message=" + JSON.stringify(refinement.message) + ")";
                  case "Pattern" :
                      return "->S.String.pattern(%re(" + JSON.stringify(match.re.toString()) + "), ~message=" + JSON.stringify(refinement.message) + ")";
                  
                }
              }
            }).join("");
    } else {
      inlinedSchema$5 = inlinedSchema$4;
    }
  }
  var inlinedSchema$6 = Object.keys(metadataMap).length !== 0 ? "{\n  let s = " + inlinedSchema$5 + "\n  let _ = %raw(\`s.m = " + JSON.stringify(metadataMap) + "\`)\n  s\n}" : inlinedSchema$5;
  if (maybeVariant !== undefined) {
    return inlinedSchema$6 + ("->S.variant(v => " + maybeVariant + "(v))");
  } else {
    return inlinedSchema$6;
  }
}

function inline(schema) {
  if (false) {
    var v = (void 0);
    if (v !== undefined) {
      Caml_option.valFromOption(v);
    }
    
  }
  return internalInline(schema, undefined, undefined);
}

function tuple1(v0) {
  return factory$7(function (s) {
              return s.i(0, v0);
            });
}

function tuple2(v0, v1) {
  return factory$7(function (s) {
              return [
                      s.i(0, v0),
                      s.i(1, v1)
                    ];
            });
}

function tuple3(v0, v1, v2) {
  return factory$7(function (s) {
              return [
                      s.i(0, v0),
                      s.i(1, v1),
                      s.i(2, v2)
                    ];
            });
}

function toJsResult(result) {
  if (result.TAG === "Ok") {
    return {
            success: true,
            value: result._0
          };
  } else {
    return {
            success: false,
            error: result._0
          };
  }
}

function js_parse(schema, data) {
  try {
    return {
            success: true,
            value: parseAnyOrRaiseWith(data, schema)
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    return {
            success: false,
            error: getOrRethrow(exn)
          };
  }
}

function js_parseOrThrow(schema, data) {
  return parseAnyOrRaiseWith(data, schema);
}

function js_parseAsync(schema, data) {
  return parseAnyAsyncWith(data, schema).then(toJsResult);
}

function js_serialize(schema, value) {
  try {
    return {
            success: true,
            value: serializeToUnknownOrRaiseWith(value, schema)
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    return {
            success: false,
            error: getOrRethrow(exn)
          };
  }
}

function js_serializeOrThrow(schema, value) {
  return serializeToUnknownOrRaiseWith(value, schema);
}

function js_transform(schema, maybeParser, maybeSerializer) {
  return transform$1(schema, (function (s) {
                return {
                        p: maybeParser !== undefined ? (function (v) {
                              return maybeParser(v, s);
                            }) : undefined,
                        s: maybeSerializer !== undefined ? (function (v) {
                              return maybeSerializer(v, s);
                            }) : undefined
                      };
              }));
}

function js_refine(schema, refiner) {
  return refine(schema, (function (s) {
                return function (v) {
                  refiner(v, s);
                };
              }));
}

function noop$1(a) {
  return a;
}

function js_asyncParserRefine(schema, refine) {
  return transform$1(schema, (function (s) {
                return {
                        a: (function (v) {
                            return function () {
                              return refine(v, s).then(function () {
                                          return v;
                                        });
                            };
                          }),
                        s: noop$1
                      };
              }));
}

function js_optional(schema, maybeOr) {
  var schema$1 = factory$1(schema);
  if (maybeOr === undefined) {
    return schema$1;
  }
  var or = Caml_option.valFromOption(maybeOr);
  if (typeof or === "function") {
    return getOrWith(schema$1, or);
  } else {
    return getOr(schema$1, or);
  }
}

function js_tuple(definer) {
  if (typeof definer === "function") {
    return factory$7(definer);
  } else {
    return factory$7(function (s) {
                return definer.map(function (schema, idx) {
                            return s.i(idx, schema);
                          });
              });
  }
}

function js_custom(name, maybeParser, maybeSerializer, param) {
  return custom(name, (function (s) {
                return {
                        p: maybeParser !== undefined ? (function (v) {
                              return maybeParser(v, s);
                            }) : undefined,
                        s: maybeSerializer !== undefined ? (function (v) {
                              return maybeSerializer(v, s);
                            }) : undefined
                      };
              }));
}

function js_object(definer) {
  if (typeof definer === "function") {
    return factory$3(definer);
  } else {
    return factory$3(function (s) {
                var definition = {};
                var fieldNames = Object.keys(definer);
                for(var idx = 0 ,idx_finish = fieldNames.length; idx < idx_finish; ++idx){
                  var fieldName = fieldNames[idx];
                  var schema = definer[fieldName];
                  definition[fieldName] = s.f(fieldName, schema);
                }
                return definition;
              });
  }
}

function js_merge(s1, s2) {
  var match = s1.t;
  if (typeof match === "object" && match.TAG === "Object") {
    var s1FieldNames = match.fieldNames;
    var s1Fields = match.fields;
    var match$1 = s2.t;
    if (typeof match$1 === "object" && match$1.TAG === "Object") {
      var s2FieldNames = match$1.fieldNames;
      var s2Fields = match$1.fields;
      var fieldNames = [];
      var fields = {};
      for(var idx = 0 ,idx_finish = s1FieldNames.length; idx < idx_finish; ++idx){
        var fieldName = s1FieldNames[idx];
        fieldNames.push(fieldName);
        fields[fieldName] = s1Fields[fieldName];
      }
      for(var idx$1 = 0 ,idx_finish$1 = s2FieldNames.length; idx$1 < idx_finish$1; ++idx$1){
        var fieldName$1 = s2FieldNames[idx$1];
        if (fields[fieldName$1]) {
          var message = "The field " + JSON.stringify(fieldName$1) + " is defined multiple times.";
          throw new Error("[rescript-schema] " + message);
        }
        fieldNames.push(fieldName$1);
        fields[fieldName$1] = s2Fields[fieldName$1];
      }
      return {
              t: {
                TAG: "Object",
                fields: fields,
                fieldNames: fieldNames,
                unknownKeys: match$1.unknownKeys
              },
              n: (function () {
                  return s1.n() + " & " + s2.n();
                }),
              p: (function (b, param, path) {
                  var inputVar = toVar(b, b.i);
                  var s1Result = use(b, s1, inputVar, path);
                  var s2Result = use(b, s2, inputVar, path);
                  return "Object.assign(" + s1Result + ", " + s2Result + ")";
                }),
              s: (function (b, param, path) {
                  return invalidOperation(b, path, "The S.merge serializing is not supported yet");
                }),
              f: typeFilter,
              i: 0,
              m: empty
            };
    }
    
  }
  throw new Error("[rescript-schema] The merge supports only Object schemas.");
}

function js_name(prim) {
  return prim.n();
}

var B;

var parseWith = parseAnyWith;

var parseOrRaiseWith = parseAnyOrRaiseWith;

var parseAsyncWith = parseAnyAsyncWith;

var parseAsyncInStepsWith = parseAnyAsyncInStepsWith;

var schema$6 = factory$9;

var object = factory$3;

var never = schema;

var unknown = schema$1;

var string = schema$2;

var bool = schema$3;

var $$int = schema$4;

var $$float = schema$5;

var $$null = factory$2;

var option = factory$1;

var array = factory$5;

var dict = factory$6;

var variant = factory;

var tuple = factory$7;

var union = factory$8;

var jsonString = factory$4;

exports.Obj = Obj;
exports.Stdlib = Stdlib;
exports.Literal = Literal;
exports.Path = Path;
exports.symbol = symbol;
exports.Raised = Raised;
exports.unsafeGetVariantPayload = unsafeGetVariantPayload;
exports.unsafeGetErrorPayload = unsafeGetErrorPayload;
exports.InternalError = InternalError;
exports.EffectCtx = EffectCtx;
exports.classify = classify$1;
exports.Builder = Builder;
exports.B = B;
exports.toLiteral = toLiteral;
exports.isAsyncParse = isAsyncParse;
exports.validateJsonableSchema = validateJsonableSchema;
exports.make = make$2;
exports.makeWithNoopSerializer = makeWithNoopSerializer;
exports.Operation = Operation;
exports.parseAnyOrRaiseWith = parseAnyOrRaiseWith;
exports.parseAnyWith = parseAnyWith;
exports.parseWith = parseWith;
exports.parseOrRaiseWith = parseOrRaiseWith;
exports.asyncPrepareOk = asyncPrepareOk;
exports.asyncPrepareError = asyncPrepareError;
exports.internalParseAsyncWith = internalParseAsyncWith;
exports.parseAnyAsyncWith = parseAnyAsyncWith;
exports.parseAsyncWith = parseAsyncWith;
exports.parseAnyAsyncInStepsWith = parseAnyAsyncInStepsWith;
exports.parseAsyncInStepsWith = parseAsyncInStepsWith;
exports.serializeOrRaiseWith = serializeOrRaiseWith;
exports.serializeWith = serializeWith;
exports.serializeToUnknownOrRaiseWith = serializeToUnknownOrRaiseWith;
exports.serializeToUnknownWith = serializeToUnknownWith;
exports.serializeToJsonStringWith = serializeToJsonStringWith;
exports.parseJsonStringWith = parseJsonStringWith;
exports.Metadata = Metadata;
exports.recursive = recursive;
exports.setName = setName;
exports.primitiveName = primitiveName;
exports.containerName = containerName;
exports.internalRefine = internalRefine;
exports.refine = refine;
exports.addRefinement = addRefinement;
exports.transform = transform$1;
exports.preprocess = preprocess;
exports.custom = custom;
exports.literalCheckBuilder = literalCheckBuilder;
exports.literal = literal;
exports.unit = unit;
exports.Definition = Definition;
exports.Variant = Variant;
exports.$$Option = $$Option;
exports.Null = Null;
exports.nullable = nullable;
exports.$$Object = $$Object$1;
exports.Never = Never;
exports.Unknown = Unknown;
exports.$$String = $$String;
exports.JsonString = JsonString;
exports.Bool = Bool$1;
exports.Int = Int$1;
exports.Float = Float$2;
exports.$$Array = $$Array$1;
exports.Dict = Dict$1;
exports.Tuple = Tuple;
exports.Union = Union;
exports.list = list;
exports.json = json;
exports.$$catch = $$catch;
exports.deprecationMetadataId = deprecationMetadataId;
exports.deprecate = deprecate;
exports.deprecation = deprecation;
exports.descriptionMetadataId = descriptionMetadataId;
exports.describe = describe;
exports.description = description;
exports.Schema = Schema;
exports.schema = schema$6;
exports.$$Error = $$Error$1;
exports.inline = inline;
exports.object = object;
exports.never = never;
exports.unknown = unknown;
exports.string = string;
exports.bool = bool;
exports.$$int = $$int;
exports.$$float = $$float;
exports.$$null = $$null;
exports.option = option;
exports.array = array;
exports.dict = dict;
exports.variant = variant;
exports.tuple = tuple;
exports.tuple1 = tuple1;
exports.tuple2 = tuple2;
exports.tuple3 = tuple3;
exports.union = union;
exports.jsonString = jsonString;
exports.toJsResult = toJsResult;
exports.js_parse = js_parse;
exports.js_parseOrThrow = js_parseOrThrow;
exports.js_parseAsync = js_parseAsync;
exports.js_serialize = js_serialize;
exports.js_serializeOrThrow = js_serializeOrThrow;
exports.js_transform = js_transform;
exports.js_refine = js_refine;
exports.noop = noop$1;
exports.js_asyncParserRefine = js_asyncParserRefine;
exports.js_optional = js_optional;
exports.js_tuple = js_tuple;
exports.js_custom = js_custom;
exports.js_object = js_object;
exports.js_merge = js_merge;
exports.js_name = js_name;
/* symbol Not a pure module */
