// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Reporting$Util = require("util/src/Reporting.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function VoyageDashboardPage(props) {
  var url = process.env.GRAPHILE_URL;
  var useDashboardQuery = function (query) {
    return Reporting$Util.Graphql.useTable(url, query, false);
  };
  var renderTable = function (tab, withHeadersOpt, divAfterOpt, firstColOpt) {
    var withHeaders = withHeadersOpt !== undefined ? withHeadersOpt : false;
    var divAfter = divAfterOpt !== undefined ? divAfterOpt : [];
    var firstCol = firstColOpt !== undefined ? firstColOpt : true;
    if (typeof tab !== "object") {
      return null;
    }
    if (tab.TAG !== "Success") {
      return "There was an error loading the query";
    }
    var tab$1 = tab._0;
    if (tab$1 !== undefined) {
      return JsxRuntime.jsxs("table", {
                  children: [
                    withHeaders ? JsxRuntime.jsx("thead", {
                            children: JsxRuntime.jsx("tr", {
                                  children: Belt_Array.mapWithIndex(tab$1.headers, (function (ind, v) {
                                          return JsxRuntime.jsx("th", {
                                                      children: v,
                                                      style: ind === 0 && firstCol ? ({
                                                            border: "1px solid #ccc",
                                                            borderRight: "5px double #ccc",
                                                            fontSize: "14px",
                                                            padding: "10px",
                                                            textAlign: "right"
                                                          }) : (
                                                          divAfter.includes(ind) ? ({
                                                                border: "1px solid #ccc",
                                                                borderRight: "5px double #ccc",
                                                                fontSize: "14px",
                                                                padding: "10px",
                                                                textAlign: "left"
                                                              }) : ({
                                                                border: "1px solid #ccc",
                                                                fontSize: "14px",
                                                                padding: "10px",
                                                                textAlign: "left"
                                                              })
                                                        )
                                                    });
                                        })),
                                  style: {
                                    background: "#3498db",
                                    color: "white",
                                    fontWeight: "bold"
                                  }
                                })
                          }) : null,
                    JsxRuntime.jsx("tbody", {
                          children: Belt_Array.mapWithIndex(tab$1.rows, (function (rowInd, row) {
                                  return JsxRuntime.jsx("tr", {
                                              children: Belt_Array.mapWithIndex(tab$1.headers, (function (ind, hdr) {
                                                      return JsxRuntime.jsx("td", {
                                                                  children: Reporting$Util.getHeader(row, hdr),
                                                                  style: ind === 0 && firstCol ? ({
                                                                        border: "1px solid #ccc",
                                                                        borderRight: "5px double #777",
                                                                        fontSize: "14px",
                                                                        fontWeight: "800",
                                                                        padding: "10px",
                                                                        textAlign: "right"
                                                                      }) : (
                                                                      divAfter.includes(ind) ? ({
                                                                            border: "1px solid #ccc",
                                                                            borderRight: "5px double #777",
                                                                            fontSize: "14px",
                                                                            padding: "10px",
                                                                            textAlign: "left"
                                                                          }) : ({
                                                                            border: "1px solid #ccc",
                                                                            fontSize: "14px",
                                                                            padding: "10px",
                                                                            textAlign: "left"
                                                                          })
                                                                    )
                                                                });
                                                    })),
                                              style: rowInd % 2 === 0 ? ({
                                                    background: "white"
                                                  }) : ({
                                                    background: "#eee"
                                                  })
                                            });
                                }))
                        })
                  ],
                  style: {
                    borderCollapse: "collapse"
                  }
                });
    } else {
      return "No Rows ";
    }
  };
  var allBookingsByStatus = useDashboardQuery("\n{\n  query {\n      reportVoyBookingsRollup(\n    _voyageSlug: \"2024\"\n    filterKey: NONE\n    rollupKey: LIFECYCLE_STATUS\n  ) {\n    nodes {\n      status: rollupValue\n      totalBookings: numBookings\n      totalBerths\n    }\n  }\n  }\n}\n");
  var completedPaymentsByPmtMethod = useDashboardQuery("\n{\n  query {\n      reportVoyPaymentRollup(\n    _voyageSlug: \"2024\"\n    filterKey: COMPLETED_PAYMENTS\n    rollupKey: PMT_METHOD\n  ) {\n    nodes {\n      paymentMethod: rollupValue\n      incomingTotal: netIncoming\n      outgoingTotal: netOutgoing\n      processorFees: netBfFees\n\n    }\n  }\n  }\n}\n");
  var allPaxByUpperLower = useDashboardQuery("\n{\n  query {\n      reportVoyPaxRollup(\n    _voyageSlug: \"2024\"\n    filterKey: ACTIVE_BOOKINGS\n    rollupKey: UPPER_LOWER\n  ) {\n    nodes {\n      berthType: rollupValue\n      nonTBAPax: numPax\n\n    }\n  }\n  }\n}\n");
  var pendingPaymentsByPmtMethod = useDashboardQuery("\n{\n  query {\n      reportVoyPaymentRollup(\n    _voyageSlug: \"2024\"\n    filterKey: PENDING_PAYMENTS\n    rollupKey: PMT_METHOD\n  ) {\n    nodes {\n      paymentMethod: rollupValue\n      incomingTotal: netIncoming\n      outgoingTotal: netOutgoing\n      processorFees: netBfFees\n\n    }\n  }\n  }\n}\n");
  var netbillings = useDashboardQuery("\n{\n  query {\n      reportVoyBillingsRollup(\n    _voyageSlug: \"2024\"\n    filterKey: NONE\n    rollupKey: NONE\n  ) {\n    nodes {\n      netBillings: totalBilled\n      netBaseFare: totalBaseFares\n      netDiscounts: totalDynamicPricing\n      netFees: totalChangeFee\n      suppressedFees: totalSuppressed\n      netManualBilling: totalManual\n    }\n  }\n  }\n}\n");
  var fareClassSales = useDashboardQuery("\n{\n  query {\n      reportVoyBookingsRollup(\n    _voyageSlug: \"2024\"\n    filterKey: ACTIVE_BOOKINGS\n    rollupKey: FARE_CLASS_SLUG\n  ) {\n    nodes {\n      fareClass:rollupValue\n      activeBookings:numBookings\n      activeBerths:totalBerths\n      netBillings\n    }\n  }\n  }\n}\n");
  var fareClassSalesCanceled = useDashboardQuery("\n{\n  query {\n      reportVoyBookingsRollup(\n    _voyageSlug: \"2024\"\n    filterKey: CANCELED_BOOKINGS\n    rollupKey: FARE_CLASS_SLUG\n  ) {\n    nodes {\n      fareClass:rollupValue\n      canceledBookings:numBookings\n      canceledBerths:totalBerths\n      netBillings\n    }\n  }\n  }\n}\n");
  var inventoryByClass = useDashboardQuery("\n{\n  query {\n      reportVoyInventoryRollup(\n    _voyageSlug: \"2024\"\n    filterKey: NONE\n    rollupKey: CLASS\n  ) {\n    nodes {\n      class:rollupValue\n      booked:totalBookedCabins\n      available:totalAvailCabins\n      total:totalCabins\n      retailBooked:retailBookedCabins\n      retailAvail:retailAvailCabins\n      waitlist:waitlistTotalCabins\n      accessisbleHold:accessibleHoldTotalCabins\n      privateBooked:privateBookedCabins\n      privateAvail:privateAvailCabins\n    }\n  }\n  }\n}\n");
  var inventoryByCategory = useDashboardQuery("\n{\n  query {\n      reportVoyInventoryRollup(\n    _voyageSlug: \"2024\"\n    filterKey: NONE\n    rollupKey: CATEGORY\n  ) {\n    nodes {\n      class:rollupValue\n      booked:totalBookedCabins\n      available:totalAvailCabins\n      total:totalCabins\n      retailBooked:retailBookedCabins\n      retailAvail:retailAvailCabins\n      waitlist:waitlistTotalCabins\n      accessisbleHold:accessibleHoldTotalCabins\n      privateBooked:privateBookedCabins\n      privateAvail:privateAvailCabins\n    }\n  }\n  }\n}\n");
  var discounts = useDashboardQuery("\n{\n  query {\n      reportVoyBillingsRollup(\n    _voyageSlug: \"2024\"\n    filterKey: DYNAMIC_PRICING\n    rollupKey: FARE_RULE_SLUG\n  ) {\n    nodes {\n      type: rollupValue\n      netBillings: totalBilled\n      count:numBillings\n    }\n  }\n  }\n}\n");
  var fees = useDashboardQuery("\n{\n  query {\n      reportVoyBillingsRollup(\n    _voyageSlug: \"2024\"\n    filterKey: CHANGE_FEES\n    rollupKey: FARE_RULE_SLUG\n  ) {\n    nodes {\n      type: rollupValue\n      netBillings: totalBilled\n      count:numBillings\n    }\n  }\n  }\n}\n");
  var dinnerSeating = useDashboardQuery("\n{\n  query {\n      reportVoyBookingsRollup(\n    _voyageSlug: \"2024\"\n    filterKey: ACTIVE_BOOKINGS\n    rollupKey: PREFER_EARLY_DINNER\n  ) {\n    nodes {\n      preference: rollupValue\n      bookings: numBookings\n      berths:totalBerths\n    }\n  }\n  }\n}\n");
  var custAcquisition = useDashboardQuery("\n{\n  query {\n      reportVoyPaxRollup(\n    _voyageSlug: \"2024\"\n    filterKey: ACTIVE_BOOKINGS\n    rollupKey: ACQUISITION_SLUG\n  ) {\n    nodes {\n      channel: rollupValue\n      count: numPax\n      newPax: numFirstTime\n      returners: numReturning\n\n    }\n  }\n  }\n}\n");
  var loyalty = useDashboardQuery("\n{\n  query {\n      reportVoyPaxRollup(\n    _voyageSlug: \"2024\"\n    filterKey: ACTIVE_BOOKINGS\n    rollupKey: LOYALTY_TIER_SLUG\n  ) {\n    nodes {\n      channel: rollupValue\n      count: numPax\n\n    }\n  }\n  }\n}\n");
  return JsxRuntime.jsxs(Stack, {
              spacing: 1,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h1",
                      children: Caml_option.some("Welcome to " + CS_Slugs$Util.VoyageSlug.toString(props.voyage) + ": a " + CS_Slugs$Util.BrandFamilySlug.toString(props.brandFamily) + " voyage!")
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: "Booking Totals"
                            }),
                        renderTable(allBookingsByStatus, true, undefined, undefined),
                        JsxRuntime.jsx("br", {}),
                        renderTable(allPaxByUpperLower, true, undefined, undefined),
                        JsxRuntime.jsx("h3", {
                              children: "Completed Payments"
                            }),
                        renderTable(completedPaymentsByPmtMethod, true, undefined, undefined),
                        JsxRuntime.jsx("h3", {
                              children: "Pending Payments"
                            }),
                        renderTable(pendingPaymentsByPmtMethod, true, undefined, undefined),
                        JsxRuntime.jsx("h3", {
                              children: "Billings"
                            }),
                        renderTable(netbillings, true, undefined, false),
                        JsxRuntime.jsx("h3", {
                              children: "Fare Class Breakdown"
                            }),
                        renderTable(fareClassSales, true, undefined, undefined),
                        JsxRuntime.jsx("br", {}),
                        renderTable(fareClassSalesCanceled, true, undefined, undefined),
                        JsxRuntime.jsx("h3", {
                              children: "Inventory by Class"
                            }),
                        renderTable(inventoryByClass, true, [
                              3,
                              5,
                              6,
                              7
                            ], undefined),
                        JsxRuntime.jsx("h3", {
                              children: "Inventory by Category"
                            }),
                        renderTable(inventoryByCategory, true, [
                              3,
                              5,
                              6,
                              7
                            ], undefined),
                        JsxRuntime.jsx("h3", {
                              children: "Discounts"
                            }),
                        renderTable(discounts, true, undefined, undefined),
                        JsxRuntime.jsx("h3", {
                              children: "Fees"
                            }),
                        renderTable(fees, true, undefined, undefined),
                        JsxRuntime.jsx("h3", {
                              children: "Dinner Seating Preferences"
                            }),
                        renderTable(dinnerSeating, true, undefined, undefined),
                        JsxRuntime.jsx("h3", {
                              children: "Customer Acquisition"
                            }),
                        renderTable(custAcquisition, true, undefined, undefined),
                        JsxRuntime.jsx("h3", {
                              children: "Passengers by Loyalty Tier"
                            }),
                        renderTable(loyalty, true, undefined, undefined)
                      ]
                    })
              ]
            });
}

var make = VoyageDashboardPage;

exports.make = make;
/* CS_Slugs-Util Not a pure module */
