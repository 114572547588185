// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Storage$Util = require("util/src/Storage.bs.js");
var List = require("@mui/joy/List").default;
var Menu = require("@mui/joy/Menu").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var BoxAvatar$Thick = require("../atoms/BoxAvatar.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var ListItem = require("@mui/joy/ListItem").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ListDivider = require("@mui/joy/ListDivider").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var AccountMenu_query_graphql$Thick = require("../../../__generated__/AccountMenu_query_graphql.bs.js");
var AccountMenu_bfselector_graphql$Thick = require("../../../__generated__/AccountMenu_bfselector_graphql.bs.js");

var convertFragment = AccountMenu_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AccountMenu_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AccountMenu_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(AccountMenu_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = AccountMenu_bfselector_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(AccountMenu_bfselector_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AccountMenu_bfselector_graphql$Thick.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(AccountMenu_bfselector_graphql$Thick.node, convertFragment$1, fRef);
}

var BFFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function AccountMenu$BFSelector$MenuItem(props) {
  var selected = props.selected;
  var fragment = use$1(props.fragment);
  var selected$1;
  if (selected !== undefined) {
    var familyName = fragment.brandFamilySlug;
    selected$1 = familyName !== undefined ? Caml_obj.equal(Caml_option.valFromOption(selected), Caml_option.valFromOption(familyName)) : false;
  } else {
    selected$1 = false;
  }
  var name = fragment.brandFamilySlug;
  if (name === undefined) {
    return null;
  }
  var name$1 = Caml_option.valFromOption(name);
  return JsxRuntime.jsx(MenuItem, {
              children: Caml_option.some(CS_Slugs$Util.BrandFamilySlug.toString(name$1)),
              selected: selected$1,
              onClick: (function (param) {
                  Storage$Util.Local.setSelectedBrand(CS_Slugs$Util.BrandFamilySlug.toString(name$1));
                  RescriptReactRouter.push("/" + CS_Slugs$Util.BrandFamilySlug.toString(name$1));
                })
            });
}

var MenuItem$1 = {
  make: AccountMenu$BFSelector$MenuItem
};

var BFSelector = {
  BFFragment: BFFragment,
  MenuItem: MenuItem$1
};

function AccountMenu(props) {
  var bfQuery = props.bfQuery;
  var brandFamily = props.brandFamily;
  var query = use(props.query);
  var email = query.currentUserEmail;
  var email$1 = email !== undefined ? email : "";
  var initial = Js_string.charAt(0, email$1).toUpperCase();
  var logout = function (param) {
    Common$Thick.cookieLogout(Caml_option.some((function () {
                ((window.location.reload()));
              })), undefined, undefined);
  };
  var name = Storage$Util.Local.getSelectedBrand();
  var display = name !== undefined ? name : "Welcome";
  var bfMenu;
  if (bfQuery !== undefined && brandFamily !== undefined) {
    var name$1 = Caml_option.valFromOption(brandFamily);
    var perms = Belt_Set.fromArray(Belt_Array.keepMap(Belt_Option.getWithDefault(bfQuery.currentUserCanForBrand, []), (function (perm) {
                if (perm !== undefined) {
                  return perm;
                }
                
              })), Common$Thick.PermCmp);
    bfMenu = JsxRuntime.jsx(JsxRuntime.Fragment, {
          children: Caml_option.some(JsxRuntime.jsxs(List, {
                    children: [
                      JsxRuntime.jsx(ListItem, {
                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                      level: "body-xs",
                                      textTransform: "uppercase",
                                      id: "account-menu-brand-family",
                                      children: Caml_option.some(CS_Slugs$Util.BrandFamilySlug.toString(name$1))
                                    })),
                            sticky: true
                          }),
                      Belt_Set.has(perms, "VIEW_ALL_USERS") ? JsxRuntime.jsx(MenuItem, {
                              children: "Team",
                              onClick: (function (param) {
                                  RescriptReactRouter.push("/" + CS_Slugs$Util.BrandFamilySlug.toString(name$1) + "/team");
                                })
                            }) : null,
                      JsxRuntime.jsx(ListDivider, {})
                    ]
                  }))
        });
  } else {
    bfMenu = null;
  }
  var match = query.allBrandFamilies;
  var tmp;
  if (match !== undefined) {
    var arr = Belt_Array.map(match.nodes, (function (family) {
            return JsxRuntime.jsx(AccountMenu$BFSelector$MenuItem, {
                        fragment: family.fragmentRefs,
                        selected: brandFamily
                      }, family.__id);
          }));
    tmp = JsxRuntime.jsx(JsxRuntime.Fragment, {
          children: Caml_option.some(JsxRuntime.jsxs(List, {
                    children: [
                      JsxRuntime.jsx(ListItem, {
                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                      level: "body-xs",
                                      textTransform: "uppercase",
                                      id: "account-menu-email",
                                      children: "Brand Families"
                                    })),
                            sticky: true
                          }),
                      arr,
                      JsxRuntime.jsx(ListDivider, {})
                    ]
                  }))
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(Dropdown, {
              children: [
                JsxRuntime.jsx(MenuButton, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                alignItems: "start",
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-sm",
                                        children: Caml_option.some(email$1)
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-xs",
                                        children: Caml_option.some(display)
                                      })
                                ]
                              })),
                      startDecorator: Caml_option.some(JsxRuntime.jsx(BoxAvatar$Thick.make, {
                                children: Caml_option.some(initial),
                                color: "success"
                              }))
                    }),
                JsxRuntime.jsxs(Menu, {
                      children: [
                        tmp,
                        bfMenu,
                        JsxRuntime.jsxs(List, {
                              children: [
                                JsxRuntime.jsx(ListItem, {
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                textTransform: "uppercase",
                                                id: "account-menu-email",
                                                children: Caml_option.some(email$1)
                                              })),
                                      sticky: true
                                    }),
                                JsxRuntime.jsx(MenuItem, {
                                      children: "Manage Account",
                                      onClick: (function (param) {
                                          RescriptReactRouter.push("/account");
                                        })
                                    }),
                                JsxRuntime.jsx(MenuItem, {
                                      children: "Logout",
                                      onClick: logout
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var make = AccountMenu;

exports.QueryFragment = QueryFragment;
exports.BFSelector = BFSelector;
exports.make = make;
/* Common-Thick Not a pure module */
