// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("date-fns");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var CS_Make$Util = require("./CS_Make.bs.js");
var DateOnly$Util = require("../DateOnly.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");

function check(v) {
  var match = v.split("-");
  if (match.length !== 3) {
    return {
            TAG: "Error",
            _0: "SQL style dates are YYYY-MM-DD"
          };
  } else {
    return {
            TAG: "Ok",
            _0: v
          };
  }
}

var Dateable = CS_Make$Util.Dateable.MakeWithRefinements({
      check: check
    });

function toPrimitive(v) {
  return DateFns.formatISO(DateOnly$Util.toDate(v), {
              representation: "date"
            });
}

function primitiveToEl(prim) {
  return prim;
}

function primitiveToJson(prim) {
  return prim;
}

var struct = S$RescriptSchema.transform(Dateable.schema, (function (s) {
        return {
                p: (function (v) {
                    return DateOnly$Util.fromDate(Dateable.toDate(v));
                  }),
                s: (function (dt) {
                    return Belt_Result.getExn(Dateable.fromPrimitive(toPrimitive(dt)));
                  })
              };
      }));

var include = CS_Make$Util.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

var DateOnly = include;

var DateAndTime;

exports.DateOnly = DateOnly;
exports.DateAndTime = DateAndTime;
/* Dateable Not a pure module */
