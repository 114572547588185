// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Stack = require("@mui/joy/Stack").default;
var $$Option = require("@mui/joy/Option").default;
var ReactState$Util = require("util/src/ReactState.bs.js");
var DraftUtils$Thick = require("../../booking-details/drafts/DraftUtils.bs.js");
var Checkbox = require("@mui/joy/Checkbox").default;
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var VoyageMiniCard$Thick = require("../molecules/VoyageMiniCard.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var PhoneNumberInput$Thick = require("../molecules/PhoneNumberInput.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ListItemContent = require("@mui/joy/ListItemContent").default;
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var PreviewChangesQuery_graphql$Thick = require("../../../__generated__/PreviewChangesQuery_graphql.bs.js");
var PersonOutlined = require("@mui/icons-material/PersonOutlined").default;
var ArticleOutlined = require("@mui/icons-material/ArticleOutlined").default;
var UpdateAccountAndPaxData_query_graphql$Thick = require("../../../__generated__/UpdateAccountAndPaxData_query_graphql.bs.js");
var UpdateAccountAndPaxData_UpdateBfcustAccountDataMutation_graphql$Thick = require("../../../__generated__/UpdateAccountAndPaxData_UpdateBfcustAccountDataMutation_graphql.bs.js");

var convertVariables = UpdateAccountAndPaxData_UpdateBfcustAccountDataMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = UpdateAccountAndPaxData_UpdateBfcustAccountDataMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = UpdateAccountAndPaxData_UpdateBfcustAccountDataMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, UpdateAccountAndPaxData_UpdateBfcustAccountDataMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, UpdateAccountAndPaxData_UpdateBfcustAccountDataMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var UpdateBfcustAccountDataMutation_docGender_decode = UpdateAccountAndPaxData_UpdateBfcustAccountDataMutation_graphql$Thick.Utils.docGender_decode;

var UpdateBfcustAccountDataMutation_docGender_fromString = UpdateAccountAndPaxData_UpdateBfcustAccountDataMutation_graphql$Thick.Utils.docGender_fromString;

var UpdateBfcustAccountDataMutation = {
  docGender_decode: UpdateBfcustAccountDataMutation_docGender_decode,
  docGender_fromString: UpdateBfcustAccountDataMutation_docGender_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(refetch, onClose) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var updateAccountData = function (input) {
    mutate({
          input: input
        }, undefined, undefined, undefined, (function (res, err) {
            if (err !== undefined) {
              return setError(function (param) {
                          return "Could not update account data at this time.";
                        });
            }
            var match = res.updateBfcustAccountData;
            if (match !== undefined) {
              var match$1 = match.boolean;
              if (match$1 !== undefined && match$1) {
                refetch();
                return onClose();
              }
              
            }
            setError(function (param) {
                  return "Could not update account data at this time.";
                });
          }), (function (err) {
            setError(function (param) {
                  return "Failed to update account data";
                });
          }), undefined);
  };
  return {
          error: match$1[0],
          setError: setError,
          isMutating: match[1],
          updateAccountData: updateAccountData
        };
}

var empty_updateOtherVoyages = [];

var empty = {
  accountId: undefined,
  custodianAccountId: undefined,
  updateOtherVoyages: empty_updateOtherVoyages,
  updateUserAccount: false,
  preferredFirstName: undefined,
  preferredLastName: undefined,
  phone: undefined,
  phoneCountryCode: "+1",
  residenceCountry: undefined,
  postalCode: undefined,
  halMarinerNumber: undefined,
  docFirstName: undefined,
  docLastName: undefined,
  docBirthdate: undefined,
  docGender: undefined,
  docCitizenship: undefined
};

function paxToUpdate(p) {
  return {
          accountId: p.accountId,
          custodianAccountId: p.custodianAccountId,
          updateOtherVoyages: [],
          updateUserAccount: false,
          preferredFirstName: p.firstName,
          preferredLastName: p.lastName,
          phone: undefined,
          phoneCountryCode: "+1",
          residenceCountry: undefined,
          postalCode: undefined,
          halMarinerNumber: p.halMarinerNumber,
          docFirstName: p.legalDocumentFirstName,
          docLastName: p.legalDocumentLastName,
          docBirthdate: p.legalDocumentBirthdate,
          docGender: p.legalDocumentGender,
          docCitizenship: p.legalDocumentCitizenship
        };
}

function userToUpdate(u) {
  var p = Belt_Option.map(u.phone, CS_Phone$Util.Phone.meta);
  var phoneCountryCode = p !== undefined ? Belt_Option.getWithDefault(p.callingCode, "+1") : "+1";
  var p$1 = u.phone;
  var phone;
  if (p$1 !== undefined) {
    var match = CS_Phone$Util.Phone.toString(Caml_option.valFromOption(p$1)).split(" ");
    var len = match.length;
    if (len >= 3) {
      phone = undefined;
    } else {
      switch (len) {
        case 0 :
        case 1 :
            phone = undefined;
            break;
        case 2 :
            phone = match[1];
            break;
        
      }
    }
  } else {
    phone = undefined;
  }
  return {
          accountId: Caml_option.some(u.accountId),
          custodianAccountId: u.custodianAccountId,
          updateOtherVoyages: [],
          updateUserAccount: true,
          preferredFirstName: CS_NonemptyStrings$Util.NonEmptyString.toString(u.firstName),
          preferredLastName: Belt_Option.map(u.lastName, CS_NonemptyStrings$Util.NonEmptyString.toString),
          phone: phone,
          phoneCountryCode: phoneCountryCode,
          residenceCountry: u.residenceCountry,
          postalCode: Belt_Option.map(u.postalCode, CS_NonemptyStrings$Util.PostalCode.toString),
          halMarinerNumber: Belt_Option.map(u.halMarinerNumber, CS_LoyaltyNumbers$Util.HalMarinerNumber.toString),
          docFirstName: Belt_Option.map(u.legalDocumentFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString),
          docLastName: Belt_Option.map(u.legalDocumentLastName, CS_NonemptyStrings$Util.NonEmptyString.toString),
          docBirthdate: u.legalDocumentBirthdate,
          docGender: u.legalDocumentGender,
          docCitizenship: u.legalDocumentCitizenship
        };
}

function toOptionalArg(a, fn) {
  var match = Belt_Option.map(a, fn);
  if (match !== undefined && match.TAG === "Ok") {
    return Caml_option.some(match._0);
  }
  
}

function toAccountDataUpdate(u) {
  var phoneNum = Belt_Option.map(u.phone, (function (p) {
          return u.phoneCountryCode + " " + p;
        }));
  console.log(phoneNum);
  var match = u.accountId;
  var match$1 = Belt_Option.map(u.preferredFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$2 = Belt_Option.map(u.preferredLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$3 = Belt_Option.map(phoneNum, CS_Phone$Util.Phone.fromPrimitive);
  var match$4 = Belt_Option.map(u.postalCode, CS_NonemptyStrings$Util.PostalCode.fromPrimitive);
  var match$5 = Belt_Option.map(u.halMarinerNumber, CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive);
  var match$6 = Belt_Option.map(u.docFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$7 = Belt_Option.map(u.docLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid account data fields"
          };
  }
  if (match$1 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid account data fields"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid account data fields"
          };
  }
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  var exit$3 = 0;
  var exit$4 = 0;
  if (match$2 !== undefined) {
    if (match$2.TAG !== "Ok") {
      return {
              TAG: "Error",
              _0: "Invalid user fields! Please review!"
            };
    }
    exit$4 = 6;
  } else {
    exit$4 = 6;
  }
  if (exit$4 === 6) {
    if (match$3 !== undefined) {
      if (match$3.TAG !== "Ok") {
        return {
                TAG: "Error",
                _0: "Invalid user fields! Please review!"
              };
      }
      exit$3 = 5;
    } else {
      exit$3 = 5;
    }
  }
  if (exit$3 === 5) {
    if (match$4 !== undefined) {
      if (match$4.TAG !== "Ok") {
        return {
                TAG: "Error",
                _0: "Invalid user fields! Please review!"
              };
      }
      exit$2 = 4;
    } else {
      exit$2 = 4;
    }
  }
  if (exit$2 === 4) {
    if (match$5 !== undefined) {
      if (match$5.TAG !== "Ok") {
        return {
                TAG: "Error",
                _0: "Invalid user fields! Please review!"
              };
      }
      exit$1 = 3;
    } else {
      exit$1 = 3;
    }
  }
  if (exit$1 === 3) {
    if (match$6 !== undefined) {
      if (match$6.TAG !== "Ok") {
        return {
                TAG: "Error",
                _0: "Invalid user fields! Please review!"
              };
      }
      exit = 2;
    } else {
      exit = 2;
    }
  }
  if (exit === 2 && match$7 !== undefined && match$7.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid user fields! Please review!"
          };
  }
  var _preferredLastName = toOptionalArg(u.preferredLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var _phone = toOptionalArg(Belt_Option.map(u.phone, (function (p) {
              return u.phoneCountryCode + " " + p;
            })), CS_Phone$Util.Phone.fromPrimitive);
  var _postalCode = toOptionalArg(u.postalCode, CS_NonemptyStrings$Util.PostalCode.fromPrimitive);
  var _docFirstName = toOptionalArg(u.docFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var _docLastName = toOptionalArg(u.docLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var _residenceCountry = u.residenceCountry;
  var _halMarinerNumber = toOptionalArg(u.halMarinerNumber, CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive);
  var _docGender = Belt_Option.flatMap(u.docGender, PreviewChangesQuery_graphql$Thick.Utils.docGender_decode);
  var _docBirthdate = u.docBirthdate;
  var _docCitizenship = u.docCitizenship;
  return {
          TAG: "Ok",
          _0: {
            _accountId: Caml_option.valFromOption(match),
            _updateProfile: true,
            _preferredFirstName: Caml_option.some(match$1._0),
            _preferredLastName: _preferredLastName,
            _halMarinerNumber: _halMarinerNumber,
            _phone: _phone,
            _residenceCountry: _residenceCountry,
            _postalCode: _postalCode,
            _docFirstName: _docFirstName,
            _docLastName: _docLastName,
            _docBirthdate: _docBirthdate,
            _docGender: _docGender,
            _docCitizenship: _docCitizenship
          }
        };
}

function updateToNewConnected(u) {
  var match = Belt_Option.map(u.docFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$1 = Belt_Option.map(u.docLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$2 = u.docBirthdate;
  var match$3 = u.docGender;
  var match$4 = u.docCitizenship;
  var match$5 = Belt_Option.map(u.preferredFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$6 = Belt_Option.map(u.preferredLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$7 = Belt_Option.map(u.halMarinerNumber, CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive);
  var match$8 = u.custodianAccountId;
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$1 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$2 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$3 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$4 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$5 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$5.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$7 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$7.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$8 === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid legal document fields"
          };
  }
  if (match$6 !== undefined && match$6.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid preferred last name"
          };
  }
  var tmp;
  tmp = match$6 !== undefined && match$6.TAG === "Ok" ? Caml_option.some(match$6._0) : undefined;
  return {
          TAG: "Ok",
          _0: {
            custodianAccountId: Caml_option.valFromOption(match$8),
            docFirstName: match._0,
            docLastName: match$1._0,
            docGender: match$3,
            docBirthdate: Caml_option.valFromOption(match$2),
            docCitizenship: Caml_option.valFromOption(match$4),
            halMarinerNumber: Caml_option.some(match$7._0),
            preferredFirstName: Caml_option.some(match$5._0),
            preferredLastName: tmp
          }
        };
}

function updateToPaxPatch(u) {
  var match = u.accountId;
  var match$1 = Belt_Option.map(u.docFirstName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$2 = Belt_Option.map(u.docLastName, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  var match$3 = u.docGender;
  var match$4 = u.docBirthdate;
  var match$5 = u.docCitizenship;
  var match$6 = u.updateUserAccount;
  var match$7 = u.updateOtherVoyages;
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: "Invalid patch fields"
          };
  }
  if (match$1 !== undefined) {
    if (match$1.TAG !== "Ok") {
      return {
              TAG: "Error",
              _0: "Invalid legal document first name"
            };
    }
    if (match$2 === undefined) {
      return {
              TAG: "Error",
              _0: "Invalid patch fields"
            };
    }
    if (match$2.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                accountId: Caml_option.valFromOption(match),
                pDocFirstName: Caml_option.some(match$1._0),
                pDocLastName: Caml_option.some(match$2._0),
                pDocBirthdate: match$4,
                pDocGender: match$3,
                pDocCitizenship: match$5,
                updateUserAccount: match$6,
                updateOtherVoyages: match$7
              }
            };
    }
    
  }
  if (match$2 !== undefined && match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "Invalid legal document last name"
          };
  } else {
    return {
            TAG: "Error",
            _0: "Invalid patch fields"
          };
  }
}

var Util = {
  paxToUpdate: paxToUpdate,
  userToUpdate: userToUpdate,
  toOptionalArg: toOptionalArg,
  toAccountDataUpdate: toAccountDataUpdate,
  updateToNewConnected: updateToNewConnected,
  updateToPaxPatch: updateToPaxPatch
};

var convertFragment = UpdateAccountAndPaxData_query_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(UpdateAccountAndPaxData_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, UpdateAccountAndPaxData_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(UpdateAccountAndPaxData_query_graphql$Thick.node, convertFragment, fRef);
}

var CountryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use$1,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function UpdateAccountAndPaxData$Form$FormWrapper(props) {
  var showTitleSection = props.showTitleSection;
  var __variant = props.variant;
  var subtitle = props.subtitle;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var tmp;
  tmp = variant === "Neutral" ? "transparent" : "neutral.200";
  var tmp$1;
  tmp$1 = variant === "Neutral" ? 0 : 4;
  var tmp$2;
  tmp$2 = variant === "Neutral" ? 1 : 2;
  var tmp$3;
  tmp$3 = variant === "Neutral" ? 1 : 2;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      sx: {
                        mb: showTitleSection ? 2 : 0
                      },
                      children: [
                        showTitleSection ? JsxRuntime.jsx(Typography, {
                                startDecorator: Caml_option.some(props.startDecorator),
                                level: props.titleSize === "md" ? "title-md" : "h4",
                                component: "h2",
                                children: Caml_option.some(props.title)
                              }) : null,
                        subtitle !== undefined ? JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                sx: {
                                  py: 1,
                                  color: "neutral.500"
                                },
                                children: Caml_option.some(subtitle)
                              }) : null
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      sx: {
                        backgroundColor: tmp,
                        p: tmp$1,
                        borderRadius: "12px"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Grid.make, {
                                children: Caml_option.some(props.children),
                                container: true,
                                spacing: {
                                  TAG: "Fixed",
                                  _0: 1
                                },
                                rowSpacing: tmp$2,
                                columnSpacing: tmp$3
                              }))
                    })
              ]
            });
}

var FormWrapper = {
  make: UpdateAccountAndPaxData$Form$FormWrapper
};

function UpdateAccountAndPaxData$Form$Travel(props) {
  var __forceValidate = props.forceValidate;
  var __noErrors = props.noErrors;
  var maxBirthdate = props.maxBirthdate;
  var __showTitleSection = props.showTitleSection;
  var __disabled = props.disabled;
  var __variant = props.variant;
  var setUpdate = props.setUpdate;
  var update = props.update;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Travel Documentation";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var variant = __variant !== undefined ? __variant : "Neutral";
  var disabled = __disabled !== undefined ? __disabled : false;
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  var forceValidate = __forceValidate !== undefined ? __forceValidate : false;
  var countryData = use$1(props.fragmentRefs);
  var match = countryData.countries;
  var countryOptions = match !== undefined ? Belt_Array.map(match.nodes, (function (c) {
            return CS_ISO3166_1$Util.Alpha3.meta(c.alpha3);
          })) : [];
  var match$1 = React.useState(function () {
        return false;
      });
  var setDateFocused = match$1[1];
  var dateFocused = match$1[0];
  return JsxRuntime.jsxs(UpdateAccountAndPaxData$Form$FormWrapper, {
              startDecorator: JsxRuntime.jsx(ArticleOutlined, {}),
              title: title,
              titleSize: titleSize,
              subtitle: props.subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: [
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    return Belt_Option.getWithDefault(update.docFirstName, "");
                                  }),
                                set: (function (update, val) {
                                    return {
                                            accountId: update.accountId,
                                            custodianAccountId: update.custodianAccountId,
                                            updateOtherVoyages: update.updateOtherVoyages,
                                            updateUserAccount: update.updateUserAccount,
                                            preferredFirstName: update.preferredFirstName,
                                            preferredLastName: update.preferredLastName,
                                            phone: update.phone,
                                            phoneCountryCode: update.phoneCountryCode,
                                            residenceCountry: update.residenceCountry,
                                            postalCode: update.postalCode,
                                            halMarinerNumber: update.halMarinerNumber,
                                            docFirstName: val,
                                            docLastName: update.docLastName,
                                            docBirthdate: update.docBirthdate,
                                            docGender: update.docGender,
                                            docCitizenship: update.docCitizenship
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (v) {
                                    if (noErrors) {
                                      return true;
                                    } else {
                                      return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                                    }
                                  }),
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "First Name on Travel Documents"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        placeholder: "First Name on Travel Documents",
                                                        disabled: disabled,
                                                        required: true,
                                                        variant: tmp,
                                                        value: param.get,
                                                        onChange: param.onChange.withCurrentTarget
                                                      })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    return Belt_Option.getWithDefault(update.docLastName, "");
                                  }),
                                set: (function (update, val) {
                                    return {
                                            accountId: update.accountId,
                                            custodianAccountId: update.custodianAccountId,
                                            updateOtherVoyages: update.updateOtherVoyages,
                                            updateUserAccount: update.updateUserAccount,
                                            preferredFirstName: update.preferredFirstName,
                                            preferredLastName: update.preferredLastName,
                                            phone: update.phone,
                                            phoneCountryCode: update.phoneCountryCode,
                                            residenceCountry: update.residenceCountry,
                                            postalCode: update.postalCode,
                                            halMarinerNumber: update.halMarinerNumber,
                                            docFirstName: update.docFirstName,
                                            docLastName: val,
                                            docBirthdate: update.docBirthdate,
                                            docGender: update.docGender,
                                            docCitizenship: update.docCitizenship
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (v) {
                                    if (noErrors) {
                                      return true;
                                    } else {
                                      return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                                    }
                                  }),
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Last Name on Travel Documents"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        placeholder: "Last Name on Travel Documents",
                                                        disabled: disabled,
                                                        required: true,
                                                        variant: tmp,
                                                        value: param.get,
                                                        onChange: param.onChange.withCurrentTarget
                                                      })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    return update.docGender;
                                  }),
                                set: (function (update, val) {
                                    return {
                                            accountId: update.accountId,
                                            custodianAccountId: update.custodianAccountId,
                                            updateOtherVoyages: update.updateOtherVoyages,
                                            updateUserAccount: update.updateUserAccount,
                                            preferredFirstName: update.preferredFirstName,
                                            preferredLastName: update.preferredLastName,
                                            phone: update.phone,
                                            phoneCountryCode: update.phoneCountryCode,
                                            residenceCountry: update.residenceCountry,
                                            postalCode: update.postalCode,
                                            halMarinerNumber: update.halMarinerNumber,
                                            docFirstName: update.docFirstName,
                                            docLastName: update.docLastName,
                                            docBirthdate: update.docBirthdate,
                                            docGender: val,
                                            docCitizenship: update.docCitizenship
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (v) {
                                    if (noErrors) {
                                      return true;
                                    } else {
                                      return Belt_Option.isSome(v);
                                    }
                                  }),
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Gender on Travel Documents"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                        children: Caml_option.some(Belt_Array.mapWithIndex(DraftUtils$Thick.Pax.genderOptions, (function (i, g) {
                                                                    return JsxRuntime.jsx($$Option, {
                                                                                value: g,
                                                                                children: Caml_option.some(DraftUtils$Thick.Pax.genderToText(g))
                                                                              }, String(i));
                                                                  }))),
                                                        disabled: disabled,
                                                        onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                        placeholder: "Gender on Travel Documents",
                                                        slotProps: {
                                                          listbox: {
                                                            sx: {
                                                              zIndex: "modal"
                                                            }
                                                          }
                                                        },
                                                        value: param.get,
                                                        variant: tmp
                                                      })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    return update.docCitizenship;
                                  }),
                                set: (function (update, val) {
                                    return {
                                            accountId: update.accountId,
                                            custodianAccountId: update.custodianAccountId,
                                            updateOtherVoyages: update.updateOtherVoyages,
                                            updateUserAccount: update.updateUserAccount,
                                            preferredFirstName: update.preferredFirstName,
                                            preferredLastName: update.preferredLastName,
                                            phone: update.phone,
                                            phoneCountryCode: update.phoneCountryCode,
                                            residenceCountry: update.residenceCountry,
                                            postalCode: update.postalCode,
                                            halMarinerNumber: update.halMarinerNumber,
                                            docFirstName: update.docFirstName,
                                            docLastName: update.docLastName,
                                            docBirthdate: update.docBirthdate,
                                            docGender: update.docGender,
                                            docCitizenship: val
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (v) {
                                    if (noErrors) {
                                      return true;
                                    } else {
                                      return Belt_Option.isSome(v);
                                    }
                                  }),
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Citizenship on Travel Documents"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                        children: Caml_option.some(Belt_Array.map(countryOptions, (function (option) {
                                                                    var emoji = option.emoji;
                                                                    return JsxRuntime.jsxs($$Option, {
                                                                                value: option.alpha3,
                                                                                children: [
                                                                                  emoji !== undefined ? JsxRuntime.jsx(ListItemDecorator, {
                                                                                          children: Caml_option.some(emoji)
                                                                                        }) : null,
                                                                                  JsxRuntime.jsxs(ListItemContent, {
                                                                                        children: [
                                                                                          option.country,
                                                                                          JsxRuntime.jsx(Typography, {
                                                                                                level: "body-xs",
                                                                                                children: Caml_option.some(option.alpha3 + " (" + Belt_Option.getWithDefault(option.callingCode, "No code") + ")")
                                                                                              })
                                                                                        ],
                                                                                        sx: {
                                                                                          fontSize: "sm"
                                                                                        }
                                                                                      })
                                                                                ],
                                                                                label: option.alpha3,
                                                                                sx: {
                                                                                  maxWidth: "calc(100vw - 32px)",
                                                                                  overflow: "hidden",
                                                                                  textOverflow: "ellipsis"
                                                                                }
                                                                              }, option.alpha3);
                                                                  }))),
                                                        disabled: disabled,
                                                        onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                        placeholder: "Citizenship on Travel Documents",
                                                        slotProps: {
                                                          listbox: {
                                                            sx: {
                                                              zIndex: "modal"
                                                            }
                                                          }
                                                        },
                                                        sx: {
                                                          overflow: "hidden",
                                                          textOverflow: "ellipsis"
                                                        },
                                                        value: param.get,
                                                        variant: tmp
                                                      })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    return Belt_Option.getWithDefault(Belt_Option.map(update.docBirthdate, CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive), "");
                                  }),
                                set: (function (update, val) {
                                    var d = CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(val);
                                    if (d.TAG === "Ok") {
                                      return {
                                              accountId: update.accountId,
                                              custodianAccountId: update.custodianAccountId,
                                              updateOtherVoyages: update.updateOtherVoyages,
                                              updateUserAccount: update.updateUserAccount,
                                              preferredFirstName: update.preferredFirstName,
                                              preferredLastName: update.preferredLastName,
                                              phone: update.phone,
                                              phoneCountryCode: update.phoneCountryCode,
                                              residenceCountry: update.residenceCountry,
                                              postalCode: update.postalCode,
                                              halMarinerNumber: update.halMarinerNumber,
                                              docFirstName: update.docFirstName,
                                              docLastName: update.docLastName,
                                              docBirthdate: Caml_option.some(d._0),
                                              docGender: update.docGender,
                                              docCitizenship: update.docCitizenship
                                            };
                                    } else {
                                      return update;
                                    }
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (v) {
                                    if (noErrors) {
                                      return true;
                                    }
                                    var match = CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive(v);
                                    if (match.TAG === "Ok" && maxBirthdate !== undefined) {
                                      return DateFns.isBefore(DateOnly$Util.toDate(match._0), CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(maxBirthdate)));
                                    } else {
                                      return false;
                                    }
                                  }),
                                children: (function (param) {
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Birthdate on Travel Documents"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: dateFocused || !Belt_Option.isNone(update.docBirthdate) ? "date" : "text",
                                                        placeholder: "Birthdate on Travel Documents",
                                                        disabled: disabled,
                                                        variant: "outlined",
                                                        value: param.get,
                                                        onChange: param.onChange.withCurrentTarget,
                                                        onFocus: (function (param) {
                                                            setDateFocused(function (param) {
                                                                  return true;
                                                                });
                                                          }),
                                                        onBlur: (function (param) {
                                                            setDateFocused(function (param) {
                                                                  return false;
                                                                });
                                                          })
                                                      }),
                                                  Belt_Option.isSome(maxBirthdate) ? JsxRuntime.jsx(FormHelperText, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-xs",
                                                                    children: "Primary passengers must be at least 21 at time of sailing."
                                                                  }))
                                                        }) : null
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    })
              ]
            });
}

var Travel = {
  make: UpdateAccountAndPaxData$Form$Travel
};

function UpdateAccountAndPaxData$Form$Personal(props) {
  var __forceValidate = props.forceValidate;
  var __noErrors = props.noErrors;
  var __namesOnly = props.namesOnly;
  var __noHelperText = props.noHelperText;
  var __disabled = props.disabled;
  var __variant = props.variant;
  var setUpdate = props.setUpdate;
  var update = props.update;
  var __showTitleSection = props.showTitleSection;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var title = __title !== undefined ? __title : "My Profile";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var disabled = __disabled !== undefined ? __disabled : false;
  var noHelperText = __noHelperText !== undefined ? __noHelperText : false;
  var namesOnly = __namesOnly !== undefined ? __namesOnly : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  var forceValidate = __forceValidate !== undefined ? __forceValidate : false;
  var countryData = use$1(props.fragmentRefs);
  var match = countryData.countries;
  var countryOptions = match !== undefined ? Belt_Array.map(match.nodes, (function (c) {
            return CS_ISO3166_1$Util.Alpha3.meta(c.alpha3);
          })) : [];
  return JsxRuntime.jsxs(UpdateAccountAndPaxData$Form$FormWrapper, {
              startDecorator: JsxRuntime.jsx(PersonOutlined, {}),
              title: title,
              titleSize: titleSize,
              subtitle: props.subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: [
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    return Belt_Option.getWithDefault(update.preferredFirstName, "");
                                  }),
                                set: (function (update, val) {
                                    return {
                                            accountId: update.accountId,
                                            custodianAccountId: update.custodianAccountId,
                                            updateOtherVoyages: update.updateOtherVoyages,
                                            updateUserAccount: update.updateUserAccount,
                                            preferredFirstName: val,
                                            preferredLastName: update.preferredLastName,
                                            phone: update.phone,
                                            phoneCountryCode: update.phoneCountryCode,
                                            residenceCountry: update.residenceCountry,
                                            postalCode: update.postalCode,
                                            halMarinerNumber: update.halMarinerNumber,
                                            docFirstName: update.docFirstName,
                                            docLastName: update.docLastName,
                                            docBirthdate: update.docBirthdate,
                                            docGender: update.docGender,
                                            docCitizenship: update.docCitizenship
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (v) {
                                    if (noErrors) {
                                      return true;
                                    } else {
                                      return Belt_Result.isOk(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(v));
                                    }
                                  }),
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Preferred First Name"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        placeholder: "Preferred First Name",
                                                        disabled: disabled,
                                                        variant: tmp,
                                                        value: param.get,
                                                        onChange: param.onChange.withCurrentTarget
                                                      }),
                                                  noHelperText ? null : JsxRuntime.jsx(FormHelperText, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-sm",
                                                                    children: "Will display in the platform"
                                                                  }))
                                                        })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    return Belt_Option.getWithDefault(update.preferredLastName, "");
                                  }),
                                set: (function (update, val) {
                                    return {
                                            accountId: update.accountId,
                                            custodianAccountId: update.custodianAccountId,
                                            updateOtherVoyages: update.updateOtherVoyages,
                                            updateUserAccount: update.updateUserAccount,
                                            preferredFirstName: update.preferredFirstName,
                                            preferredLastName: val,
                                            phone: update.phone,
                                            phoneCountryCode: update.phoneCountryCode,
                                            residenceCountry: update.residenceCountry,
                                            postalCode: update.postalCode,
                                            halMarinerNumber: update.halMarinerNumber,
                                            docFirstName: update.docFirstName,
                                            docLastName: update.docLastName,
                                            docBirthdate: update.docBirthdate,
                                            docGender: update.docGender,
                                            docCitizenship: update.docCitizenship
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (param) {
                                    return true;
                                  }),
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "Preferred Last Name (optional)"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        placeholder: "Preferred Last Name",
                                                        disabled: disabled,
                                                        variant: tmp,
                                                        value: param.get,
                                                        onChange: param.onChange.withCurrentTarget
                                                      }),
                                                  noHelperText ? null : JsxRuntime.jsx(FormHelperText, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-sm",
                                                                    children: "Will display in the platform"
                                                                  }))
                                                        })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                state: [
                                  update,
                                  setUpdate
                                ],
                                get: (function (update) {
                                    return Belt_Option.getWithDefault(update.halMarinerNumber, "");
                                  }),
                                set: (function (update, val) {
                                    return {
                                            accountId: update.accountId,
                                            custodianAccountId: update.custodianAccountId,
                                            updateOtherVoyages: update.updateOtherVoyages,
                                            updateUserAccount: update.updateUserAccount,
                                            preferredFirstName: update.preferredFirstName,
                                            preferredLastName: update.preferredLastName,
                                            phone: update.phone,
                                            phoneCountryCode: update.phoneCountryCode,
                                            residenceCountry: update.residenceCountry,
                                            postalCode: update.postalCode,
                                            halMarinerNumber: val,
                                            docFirstName: update.docFirstName,
                                            docLastName: update.docLastName,
                                            docBirthdate: update.docBirthdate,
                                            docGender: update.docGender,
                                            docCitizenship: update.docCitizenship
                                          };
                                  }),
                                forceValidate: forceValidate,
                                isValid: (function (param) {
                                    return true;
                                  }),
                                children: (function (param) {
                                    var tmp;
                                    tmp = variant === "Neutral" ? "outlined" : "plain";
                                    return JsxRuntime.jsxs(FormControl, {
                                                error: param.error,
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "HAL Mariner Number (optional)"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "text",
                                                        placeholder: "HAL Mariner Number",
                                                        disabled: disabled,
                                                        variant: tmp,
                                                        value: param.get,
                                                        onChange: param.onChange.withCurrentTarget
                                                      }),
                                                  noHelperText ? null : JsxRuntime.jsx(FormHelperText, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-sm",
                                                                    children: "Will display in the platform"
                                                                  }))
                                                        })
                                                ]
                                              });
                                  })
                              })),
                      xs: 12,
                      md: 6
                    }),
                namesOnly ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                          state: [
                                            update,
                                            setUpdate
                                          ],
                                          get: (function (update) {
                                              return [
                                                      update.phoneCountryCode,
                                                      update.phone
                                                    ];
                                            }),
                                          set: (function (update, param) {
                                              return {
                                                      accountId: update.accountId,
                                                      custodianAccountId: update.custodianAccountId,
                                                      updateOtherVoyages: update.updateOtherVoyages,
                                                      updateUserAccount: update.updateUserAccount,
                                                      preferredFirstName: update.preferredFirstName,
                                                      preferredLastName: update.preferredLastName,
                                                      phone: param[1],
                                                      phoneCountryCode: param[0],
                                                      residenceCountry: update.residenceCountry,
                                                      postalCode: update.postalCode,
                                                      halMarinerNumber: update.halMarinerNumber,
                                                      docFirstName: update.docFirstName,
                                                      docLastName: update.docLastName,
                                                      docBirthdate: update.docBirthdate,
                                                      docGender: update.docGender,
                                                      docCitizenship: update.docCitizenship
                                                    };
                                            }),
                                          forceValidate: forceValidate,
                                          isValid: (function (param) {
                                              if (noErrors) {
                                                return true;
                                              } else {
                                                return Belt_Result.isOk(CS_Phone$Util.Phone.fromPrimitive(param[0] + " " + Belt_Option.getWithDefault(param[1], "")));
                                              }
                                            }),
                                          children: (function (param) {
                                              var set = param.set;
                                              var match = param.get;
                                              return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                          children: [
                                                            JsxRuntime.jsx(FormLabel, {
                                                                  children: "Phone Number (optional)"
                                                                }),
                                                            JsxRuntime.jsx(PhoneNumberInput$Thick.make, {
                                                                  countryOptions: Belt_Array.map(countryOptions, (function (v) {
                                                                          return v;
                                                                        })),
                                                                  countryCode: match[0],
                                                                  phoneNumber: match[1],
                                                                  onUpdateCountryCode: (function (phoneCountryCode) {
                                                                      set(function (param) {
                                                                            return [
                                                                                    phoneCountryCode,
                                                                                    param[1]
                                                                                  ];
                                                                          });
                                                                    }),
                                                                  onUpdatePhoneNumber: (function (phone) {
                                                                      set(function (param) {
                                                                            return [
                                                                                    param[0],
                                                                                    phone
                                                                                  ];
                                                                          });
                                                                    }),
                                                                  disabled: disabled
                                                                }),
                                                            JsxRuntime.jsx(FormHelperText, {
                                                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                            level: "body-sm",
                                                                            children: ""
                                                                          }))
                                                                })
                                                          ]
                                                        });
                                            })
                                        })),
                                xs: 12,
                                md: 6
                              }),
                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                          state: [
                                            update,
                                            setUpdate
                                          ],
                                          get: (function (update) {
                                              return Belt_Option.getWithDefault(update.postalCode, "");
                                            }),
                                          set: (function (update, val) {
                                              return {
                                                      accountId: update.accountId,
                                                      custodianAccountId: update.custodianAccountId,
                                                      updateOtherVoyages: update.updateOtherVoyages,
                                                      updateUserAccount: update.updateUserAccount,
                                                      preferredFirstName: update.preferredFirstName,
                                                      preferredLastName: update.preferredLastName,
                                                      phone: update.phone,
                                                      phoneCountryCode: update.phoneCountryCode,
                                                      residenceCountry: update.residenceCountry,
                                                      postalCode: val,
                                                      halMarinerNumber: update.halMarinerNumber,
                                                      docFirstName: update.docFirstName,
                                                      docLastName: update.docLastName,
                                                      docBirthdate: update.docBirthdate,
                                                      docGender: update.docGender,
                                                      docCitizenship: update.docCitizenship
                                                    };
                                            }),
                                          forceValidate: forceValidate,
                                          isValid: (function (v) {
                                              if (noErrors) {
                                                return true;
                                              } else {
                                                return Belt_Result.isOk(CS_NonemptyStrings$Util.PostalCode.fromPrimitive(v));
                                              }
                                            }),
                                          children: (function (param) {
                                              var tmp;
                                              tmp = variant === "Neutral" ? "outlined" : "plain";
                                              return JsxRuntime.jsxs(FormControl, {
                                                          error: param.error,
                                                          children: [
                                                            JsxRuntime.jsx(FormLabel, {
                                                                  children: "Postal Code (optional)"
                                                                }),
                                                            JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                  type_: "text",
                                                                  placeholder: "Postal Code",
                                                                  disabled: disabled,
                                                                  variant: tmp,
                                                                  value: param.get,
                                                                  onChange: param.onChange.withCurrentTarget,
                                                                  pattern: "^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$"
                                                                })
                                                          ]
                                                        });
                                            })
                                        })),
                                xs: 12,
                                md: 6
                              }),
                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                          state: [
                                            update,
                                            setUpdate
                                          ],
                                          get: (function (update) {
                                              return update.residenceCountry;
                                            }),
                                          set: (function (update, val) {
                                              return {
                                                      accountId: update.accountId,
                                                      custodianAccountId: update.custodianAccountId,
                                                      updateOtherVoyages: update.updateOtherVoyages,
                                                      updateUserAccount: update.updateUserAccount,
                                                      preferredFirstName: update.preferredFirstName,
                                                      preferredLastName: update.preferredLastName,
                                                      phone: update.phone,
                                                      phoneCountryCode: update.phoneCountryCode,
                                                      residenceCountry: val,
                                                      postalCode: update.postalCode,
                                                      halMarinerNumber: update.halMarinerNumber,
                                                      docFirstName: update.docFirstName,
                                                      docLastName: update.docLastName,
                                                      docBirthdate: update.docBirthdate,
                                                      docGender: update.docGender,
                                                      docCitizenship: update.docCitizenship
                                                    };
                                            }),
                                          forceValidate: forceValidate,
                                          isValid: (function (v) {
                                              if (noErrors) {
                                                return true;
                                              } else {
                                                return Belt_Option.isSome(v);
                                              }
                                            }),
                                          children: (function (param) {
                                              var tmp;
                                              tmp = variant === "Neutral" ? "outlined" : "plain";
                                              return JsxRuntime.jsxs(FormControl, {
                                                          error: param.error,
                                                          children: [
                                                            JsxRuntime.jsx(FormLabel, {
                                                                  children: "Country of Residence (optional)"
                                                                }),
                                                            JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                                  children: Caml_option.some(Belt_Array.map(countryOptions, (function (option) {
                                                                              var emoji = option.emoji;
                                                                              return JsxRuntime.jsxs($$Option, {
                                                                                          value: option.alpha3,
                                                                                          children: [
                                                                                            emoji !== undefined ? JsxRuntime.jsx(ListItemDecorator, {
                                                                                                    children: Caml_option.some(emoji)
                                                                                                  }) : null,
                                                                                            JsxRuntime.jsxs(ListItemContent, {
                                                                                                  children: [
                                                                                                    option.country,
                                                                                                    JsxRuntime.jsx(Typography, {
                                                                                                          level: "body-xs",
                                                                                                          children: Caml_option.some(option.alpha3 + " (" + Belt_Option.getWithDefault(option.callingCode, "No code") + ")")
                                                                                                        })
                                                                                                  ],
                                                                                                  sx: {
                                                                                                    fontSize: "sm"
                                                                                                  }
                                                                                                })
                                                                                          ],
                                                                                          label: option.alpha3,
                                                                                          sx: {
                                                                                            maxWidth: "calc(100vw - 32px)",
                                                                                            overflow: "hidden",
                                                                                            textOverflow: "ellipsis"
                                                                                          }
                                                                                        }, option.alpha3);
                                                                            }))),
                                                                  disabled: disabled,
                                                                  onChange: param.onChange.withDoubleWrappedJoyOpt,
                                                                  placeholder: "Country of Residence",
                                                                  slotProps: {
                                                                    listbox: {
                                                                      sx: {
                                                                        zIndex: "modal"
                                                                      }
                                                                    }
                                                                  },
                                                                  value: param.get,
                                                                  variant: tmp
                                                                })
                                                          ]
                                                        });
                                            })
                                        })),
                                xs: 12,
                                md: 6
                              }),
                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                children: Caml_option.some(JsxRuntime.jsx(ReactState$Util.Wrap.WithUnsyncedShadowState.make, {
                                          state: [
                                            update,
                                            setUpdate
                                          ],
                                          get: (function (update) {
                                              return Belt_Option.getWithDefault(update.halMarinerNumber, "");
                                            }),
                                          set: (function (update, val) {
                                              return {
                                                      accountId: update.accountId,
                                                      custodianAccountId: update.custodianAccountId,
                                                      updateOtherVoyages: update.updateOtherVoyages,
                                                      updateUserAccount: update.updateUserAccount,
                                                      preferredFirstName: update.preferredFirstName,
                                                      preferredLastName: update.preferredLastName,
                                                      phone: update.phone,
                                                      phoneCountryCode: update.phoneCountryCode,
                                                      residenceCountry: update.residenceCountry,
                                                      postalCode: update.postalCode,
                                                      halMarinerNumber: val,
                                                      docFirstName: update.docFirstName,
                                                      docLastName: update.docLastName,
                                                      docBirthdate: update.docBirthdate,
                                                      docGender: update.docGender,
                                                      docCitizenship: update.docCitizenship
                                                    };
                                            }),
                                          forceValidate: forceValidate,
                                          isValid: (function (v) {
                                              if (noErrors) {
                                                return true;
                                              } else {
                                                return Belt_Result.isOk(CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive(v));
                                              }
                                            }),
                                          children: (function (param) {
                                              var tmp;
                                              tmp = variant === "Neutral" ? "outlined" : "plain";
                                              return JsxRuntime.jsxs(FormControl, {
                                                          error: param.error,
                                                          children: [
                                                            JsxRuntime.jsx(FormLabel, {
                                                                  children: "HAL Mariner Number (optional)"
                                                                }),
                                                            JsxRuntime.jsx(Joy$Util.Input.make, {
                                                                  type_: "text",
                                                                  placeholder: "HAL Mariner Number",
                                                                  disabled: disabled,
                                                                  variant: tmp,
                                                                  value: param.get,
                                                                  onChange: param.onChange.withCurrentTarget,
                                                                  pattern: "^[0-9]{0,12}$"
                                                                })
                                                          ]
                                                        });
                                            })
                                        })),
                                xs: 12,
                                md: 6
                              })
                        ]
                      })
              ]
            });
}

var Personal = {
  make: UpdateAccountAndPaxData$Form$Personal
};

function UpdateAccountAndPaxData$Form$Voyages(props) {
  var voyageOptions = props.voyageOptions;
  var setShowVoyageOptions = props.setShowVoyageOptions;
  var showVoyageOptions = props.showVoyageOptions;
  var setUpdate = props.setUpdate;
  var update = props.update;
  var isVoyageSelected = function (s, u) {
    return Belt_Array.some(u.updateOtherVoyages, (function (voy) {
                  return voy === s;
                }));
  };
  var match = voyageOptions.length !== 0;
  if (props.display && match) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        alignItems: "center",
                        spacing: 1,
                        sx: {
                          cursor: "pointer"
                        },
                        onClick: (function (param) {
                            setShowVoyageOptions(function (param) {
                                  return !showVoyageOptions;
                                });
                          }),
                        children: [
                          JsxRuntime.jsx(Checkbox, {
                                checked: showVoyageOptions,
                                size: "sm"
                              }),
                          JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                children: "Apply changes to bookings"
                              })
                        ]
                      }),
                  showVoyageOptions ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                          children: Caml_option.some(Belt_Array.mapWithIndex(voyageOptions, (function (i, v) {
                                      return JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(VoyageMiniCard$Thick.make, {
                                                            voyage: v,
                                                            onSelect: (function () {
                                                                var s = v.voyageSlug;
                                                                if (isVoyageSelected(s, update)) {
                                                                  return setUpdate(function (param) {
                                                                              return {
                                                                                      accountId: update.accountId,
                                                                                      custodianAccountId: update.custodianAccountId,
                                                                                      updateOtherVoyages: Belt_Array.keep(update.updateOtherVoyages, (function (voy) {
                                                                                              return voy !== s;
                                                                                            })),
                                                                                      updateUserAccount: update.updateUserAccount,
                                                                                      preferredFirstName: update.preferredFirstName,
                                                                                      preferredLastName: update.preferredLastName,
                                                                                      phone: update.phone,
                                                                                      phoneCountryCode: update.phoneCountryCode,
                                                                                      residenceCountry: update.residenceCountry,
                                                                                      postalCode: update.postalCode,
                                                                                      halMarinerNumber: update.halMarinerNumber,
                                                                                      docFirstName: update.docFirstName,
                                                                                      docLastName: update.docLastName,
                                                                                      docBirthdate: update.docBirthdate,
                                                                                      docGender: update.docGender,
                                                                                      docCitizenship: update.docCitizenship
                                                                                    };
                                                                            });
                                                                } else {
                                                                  return setUpdate(function (param) {
                                                                              return {
                                                                                      accountId: update.accountId,
                                                                                      custodianAccountId: update.custodianAccountId,
                                                                                      updateOtherVoyages: Belt_Array.concat(update.updateOtherVoyages, [s]),
                                                                                      updateUserAccount: update.updateUserAccount,
                                                                                      preferredFirstName: update.preferredFirstName,
                                                                                      preferredLastName: update.preferredLastName,
                                                                                      phone: update.phone,
                                                                                      phoneCountryCode: update.phoneCountryCode,
                                                                                      residenceCountry: update.residenceCountry,
                                                                                      postalCode: update.postalCode,
                                                                                      halMarinerNumber: update.halMarinerNumber,
                                                                                      docFirstName: update.docFirstName,
                                                                                      docLastName: update.docLastName,
                                                                                      docBirthdate: update.docBirthdate,
                                                                                      docGender: update.docGender,
                                                                                      docCitizenship: update.docCitizenship
                                                                                    };
                                                                            });
                                                                }
                                                              }),
                                                            isSelected: isVoyageSelected(v.voyageSlug, update)
                                                          })),
                                                  xs: 12,
                                                  md: 6
                                                }, String(i));
                                    }))),
                          container: true,
                          rowSpacing: 1,
                          columnSpacing: 1
                        }) : null
                ]
              });
  } else {
    return null;
  }
}

var Voyages = {
  make: UpdateAccountAndPaxData$Form$Voyages
};

var Form = {
  CountryFragment: CountryFragment,
  FormWrapper: FormWrapper,
  Travel: Travel,
  Personal: Personal,
  Voyages: Voyages
};

exports.UpdateBfcustAccountDataMutation = UpdateBfcustAccountDataMutation;
exports.useMutation = useMutation;
exports.empty = empty;
exports.Util = Util;
exports.Form = Form;
/* commitMutation Not a pure module */
