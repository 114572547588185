// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

function makeRefetchVariables(brandFamily) {
  return {
          brandFamily: brandFamily
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"brandFamily":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandFamily"
  }
],
v1 = {
  "kind": "Variable",
  "name": "brandFamilySlug",
  "variableName": "brandFamily"
},
v2 = {
  "kind": "Literal",
  "name": "workflowT",
  "value": "request_brandfam_invite"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BrandFamilyWorkflow",
    "kind": "LinkedField",
    "name": "nodes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrandFamilyGrantWorkflow",
        "kind": "LinkedField",
        "name": "brandFamilyGrantWorkflows",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workflowInstance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inviteeEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "toVoyages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "asRoles",
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserCan",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BFGrantWorkflowsQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "brandFamily",
            "variableName": "brandFamily"
          }
        ],
        "kind": "FragmentSpread",
        "name": "BFGrantWorkflows_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BFGrantWorkflowsQuery",
    "selections": [
      {
        "alias": "actionableBFGrants",
        "args": [
          {
            "fields": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "isOpenStatus",
                "value": true
              },
              (v2/*: any*/)
            ],
            "kind": "ObjectValue",
            "name": "condition"
          }
        ],
        "concreteType": "BrandFamilyWorkflowsConnection",
        "kind": "LinkedField",
        "name": "brandFamilyWorkflows",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": "viewableBFGrants",
        "args": [
          {
            "fields": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "isOpenStatus",
                "value": false
              },
              (v2/*: any*/)
            ],
            "kind": "ObjectValue",
            "name": "condition"
          }
        ],
        "concreteType": "BrandFamilyWorkflowsConnection",
        "kind": "LinkedField",
        "name": "brandFamilyWorkflows",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aad2a108e335e260f89f4ce0dd9c522b",
    "id": null,
    "metadata": {},
    "name": "BFGrantWorkflowsQuery",
    "operationKind": "query",
    "text": "query BFGrantWorkflowsQuery(\n  $brandFamily: BrandFamilySlug!\n) {\n  ...BFGrantWorkflows_query_2PeJLC\n}\n\nfragment BFGrantWorkflowView_workflow on BrandFamilyGrantWorkflow {\n  workflowInstance\n  inviteeEmail\n  toVoyages\n  asRoles\n}\n\nfragment BFGrantWorkflows_query_2PeJLC on Query {\n  actionableBFGrants: brandFamilyWorkflows(condition: {brandFamilySlug: $brandFamily, isOpenStatus: true, workflowT: \"request_brandfam_invite\"}) {\n    nodes {\n      brandFamilyGrantWorkflows {\n        ...BFGrantWorkflowView_workflow\n      }\n      currentUserCan\n    }\n  }\n  viewableBFGrants: brandFamilyWorkflows(condition: {brandFamilySlug: $brandFamily, isOpenStatus: false, workflowT: \"request_brandfam_invite\"}) {\n    nodes {\n      brandFamilyGrantWorkflows {\n        ...BFGrantWorkflowView_workflow\n      }\n      currentUserCan\n    }\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
