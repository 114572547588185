// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var RevokeBfCustUserTransfer$Thick = require("./mutations/RevokeBfCustUserTransfer.bs.js");

function RevokeInvitationForm(props) {
  var onClose = props.onClose;
  var match = RevokeBfCustUserTransfer$Thick.useMutation(props.refresh, props.accountId, props.brandFamily, onClose);
  var setError = match.setError;
  var error = match.error;
  var description = "Are you sure you want to revoke the invitation to " + props.invitee + " to take over the account \"" + props.fullName + "?\"";
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: (function () {
                  onClose();
                  setError(function (param) {
                        
                      });
                }),
              title: "Revoke a Transfer Invitation",
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        textColor: "text.tertiary",
                                        children: Caml_option.some(description)
                                      }),
                                  error !== undefined ? JsxRuntime.jsx(Typography, {
                                          color: "danger",
                                          children: Caml_option.some(error)
                                        }) : null
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                component: "form",
                                onSubmit: match.onSubmit,
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          loading: match.isMutating,
                                          type: "submit",
                                          color: "danger",
                                          children: "Revoke invitation"
                                        }))
                              }))
                    })
              ]
            });
}

var make = RevokeInvitationForm;

exports.make = make;
/* Dialog-Thick Not a pure module */
