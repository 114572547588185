// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ApplyDraftModal$Thick = require("./drafts/ApplyDraftModal.bs.js");

function BDAnnouncementBar(props) {
  var latestVersion = props.latestVersion;
  var redirect = props.redirect;
  var status = props.status;
  var match = React.useState(function () {
        return false;
      });
  var setApplyModalOpen = match[1];
  var url = latestVersion !== undefined ? redirect + "/" + String(latestVersion) : redirect;
  var match$1;
  var exit = 0;
  if (status === "CANCELED" || status === "STAGED" || status === "DRAFT" || status === "ACTIVE" || status === "HISTORY") {
    switch (status) {
      case "DRAFT" :
          match$1 = [
            (function (param) {
                setApplyModalOpen(function (param) {
                      return true;
                    });
              }),
            "Apply"
          ];
          break;
      case "CANCELED" :
          match$1 = [
            (function (param) {
                
              }),
            undefined
          ];
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    match$1 = status !== "ACTIVE" ? [
        (function (param) {
            RescriptReactRouter.push(url);
          }),
        "Exit"
      ] : [
        (function (param) {
            
          }),
        undefined
      ];
  }
  var text = match$1[1];
  var tmp;
  tmp = (status === "CANCELED" || status === "STAGED" || status === "DRAFT" || status === "ACTIVE" || status === "HISTORY") && status === "DRAFT" ? JsxRuntime.jsx(Button, {
          onClick: (function (param) {
              RescriptReactRouter.push(url);
            }),
          variant: "text",
          size: "sm",
          sx: {
            borderRadius: "xs",
            px: 1,
            my: 1,
            color: "white"
          },
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    color: "inherit",
                    level: "body-sm",
                    sx: {
                      textDecoration: "underline"
                    },
                    fontWeight: "400",
                    children: "Discard"
                  }))
        }) : null;
  return JsxRuntime.jsxs(Stack, {
              sx: {
                display: "flex",
                flexDirection: "column",
                borderTop: "3px solid",
                borderLeft: "3px solid",
                borderRight: "3px solid",
                borderColor: Common$Thick.Lifecycle.getPrimaryColor(status),
                alignItems: "center",
                justifyContent: "center"
              },
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 1,
                      sx: {
                        px: 2,
                        py: 1,
                        backgroundColor: Common$Thick.Lifecycle.getPrimaryColor(status),
                        width: "fit-content",
                        borderBottomLeftRadius: "4px",
                        borderBottomRightRadius: "4px"
                      },
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "body-md",
                              sx: {
                                color: "white"
                              },
                              children: Caml_option.some(Common$Thick.Lifecycle.toString(status))
                            }),
                        text !== undefined ? JsxRuntime.jsx(Button, {
                                onClick: match$1[0],
                                variant: "soft",
                                color: "neutral",
                                size: "sm",
                                sx: {
                                  borderRadius: "xs",
                                  px: 1,
                                  my: 1
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "body-sm",
                                          children: Caml_option.some(text)
                                        }))
                              }) : null,
                        tmp
                      ]
                    }),
                JsxRuntime.jsx(ApplyDraftModal$Thick.make, {
                      paxes: props.paxes,
                      diffStruct: props.diffStruct,
                      applyArgs: props.applyArgs,
                      versionBilling: props.versionBilling,
                      redirect: redirect,
                      open_: match[0],
                      onClose: (function () {
                          setApplyModalOpen(function (param) {
                                return false;
                              });
                        })
                    })
              ]
            });
}

var make = BDAnnouncementBar;

exports.make = make;
/* react Not a pure module */
