// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var ReactState$Util = require("util/src/ReactState.bs.js");
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var SharedDrawer$Thick = require("../common/molecules/SharedDrawer.bs.js");
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var FetchBfCustUserVoyages$Thick = require("../common/queries/FetchBfCustUserVoyages.bs.js");
var UpdateAccountAndPaxData$Thick = require("../common/mutations/UpdateAccountAndPaxData.bs.js");

function UpdateAccountDataModal(props) {
  var fragmentRefs = props.countryFrag;
  var user = props.user;
  var onClose = props.onClose;
  var match = React.useState(function () {
        return false;
      });
  var setShowVoyageOptions = match[1];
  var match$1 = ReactState$Util.useDebouncedState((function () {
          return UpdateAccountAndPaxData$Thick.Util.userToUpdate(user);
        }), undefined, undefined);
  var match$2 = match$1.state;
  var setUpdate = match$2[1];
  var update = match$2[0];
  React.useEffect((function () {
          setUpdate(function (param) {
                return UpdateAccountAndPaxData$Thick.Util.userToUpdate(user);
              });
        }), [user]);
  var match$3 = FetchBfCustUserVoyages$Thick.useQuery(props.brandFamily, undefined);
  var fetchVoyages = match$3.fetchVoyages;
  var voyOpt = match$3.voyOpt;
  React.useEffect((function () {
          fetchVoyages(user.accountId);
        }), []);
  var match$4 = UpdateAccountAndPaxData$Thick.useMutation(props.refetch, (function () {
          setUpdate(function (param) {
                return UpdateAccountAndPaxData$Thick.Util.userToUpdate(user);
              });
          setShowVoyageOptions(function (param) {
                return false;
              });
          onClose();
        }));
  var updateAccountData = match$4.updateAccountData;
  var setError = match$4.setError;
  var error = match$4.error;
  var onSubmit = function (e) {
    e.preventDefault();
    var update$1 = UpdateAccountAndPaxData$Thick.Util.toAccountDataUpdate(update);
    if (update$1.TAG === "Ok") {
      return updateAccountData(update$1._0);
    }
    var error = update$1._0;
    setError(function (param) {
          return error;
        });
  };
  var clearAll = function () {
    setShowVoyageOptions(function (param) {
          return false;
        });
    setUpdate(function (param) {
          return UpdateAccountAndPaxData$Thick.Util.userToUpdate(user);
        });
    setError(function (param) {
          
        });
  };
  var cancel = function () {
    clearAll();
    onClose();
  };
  var tmp;
  tmp = typeof voyOpt !== "object" ? null : (
      voyOpt.TAG === "Failure" ? JsxRuntime.jsx(Typography, {
              color: "danger",
              children: Caml_option.some(voyOpt._0)
            }) : JsxRuntime.jsx(UpdateAccountAndPaxData$Thick.Form.Voyages.make, {
              update: update,
              setUpdate: setUpdate,
              showVoyageOptions: match[0],
              setShowVoyageOptions: setShowVoyageOptions,
              voyageOptions: voyOpt._0,
              display: true
            })
    );
  return JsxRuntime.jsxs(SharedDrawer$Thick.make, {
              open_: props.open_,
              title: "Update passenger details",
              onClose: onClose,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 2,
                                sx: {
                                  px: 2,
                                  py: 3
                                },
                                children: [
                                  JsxRuntime.jsx(UpdateAccountAndPaxData$Thick.Form.Personal.make, {
                                        fragmentRefs: fragmentRefs,
                                        title: "User Profile",
                                        update: update,
                                        setUpdate: setUpdate,
                                        variant: "Gray",
                                        noHelperText: true,
                                        noErrors: true,
                                        forceValidate: true
                                      }),
                                  JsxRuntime.jsx(UpdateAccountAndPaxData$Thick.Form.Travel.make, {
                                        fragmentRefs: fragmentRefs,
                                        update: update,
                                        setUpdate: setUpdate,
                                        variant: "Gray",
                                        noErrors: true,
                                        forceValidate: true
                                      }),
                                  tmp,
                                  error !== undefined ? JsxRuntime.jsx(Typography, {
                                          color: "danger",
                                          children: Caml_option.some(error)
                                        }) : null
                                ]
                              }))
                    }),
                JsxRuntime.jsx(Divider, {}),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: {
                                  xs: "column-reverse",
                                  sm: "row"
                                },
                                component: "form",
                                justifyContent: "space-between",
                                spacing: 1,
                                sx: {
                                  width: "100%",
                                  p: 2
                                },
                                onSubmit: onSubmit,
                                children: [
                                  JsxRuntime.jsx(Button, {
                                        onClick: cancel,
                                        variant: "outlined",
                                        color: "neutral",
                                        children: "Cancel"
                                      }),
                                  JsxRuntime.jsx(Button, {
                                        loading: match$4.isMutating,
                                        type: "submit",
                                        disabled: Belt_Result.isError(UpdateAccountAndPaxData$Thick.Util.toAccountDataUpdate(update)),
                                        children: "Update"
                                      })
                                ]
                              }))
                    })
              ]
            });
}

var make = UpdateAccountDataModal;

exports.make = make;
/* react Not a pure module */
